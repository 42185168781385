import { IDevListService, HomeData } from '@/services/devList.service';
import { ISessionService } from '@/services/session.service';

const w = window as any;
const nav = navigator as any;

export interface GeolocationSetup {
  serialId: string;
  apiEndpoint: string;
  clientBasicAuth: string;
}

export interface GeoPosition {
  longitude: number;
  latitude: number;
}

export interface IGeoLocationService {
  getCurrentPosition(): Promise<GeoPosition>;
  hasPermission(): Promise<boolean>;
  requestPermission(): Promise<void>;
  restartTracking(): Promise<void>;
}

export class geolocation implements IGeoLocationService {
  private readyPromiseResolve: any;
  private readyPromise: Promise<void> = new Promise((resolve) => this.readyPromiseResolve = resolve);
  private geoTrackerPlugin: any;
  private mobilePermissions?: any;
  private webPermissions?: any;

  constructor(
    private setup: GeolocationSetup,
    private devListService: IDevListService,
    private sessionService: ISessionService,
  ) {
    if (!w.cordova) {
      this.deviceReady();
    } else {
      document.addEventListener('deviceready', () => this.deviceReady());
    }
  }

  public async getCurrentPosition(): Promise<GeoPosition> {
    return new Promise((resolve, reject) => {
      if (!nav || !nav.geolocation) {
        reject(new Error('geolocation not supported'));
      } else {
        const onSuccess = (data: any) => {
          resolve({
            longitude: data.coords.longitude,
            latitude: data.coords.latitude,
          });
        };

        const onError = (err: any) => {
          reject(err);
        };

        nav.geolocation.getCurrentPosition(onSuccess, onError);
      }
    });
  }

  public async hasPermission(): Promise<boolean> {
    if (this.sessionService.getPlatform() === 'ios') {
      return Promise.resolve(true);
    }
    if (this.isMobile()) {
      return this.hasMobilePermission();
    }
    if (this.isWeb()) {
      return this.hasWebPermission();
    }
    return Promise.resolve(false);
  }

  public async requestPermission(): Promise<void> {
    return Promise.resolve();
    // Let cordova plugin handle permission request
    const windowObj: any = window as any;
    if (windowObj.cordova !== undefined) {
      return Promise.resolve();
    }
    return this.hasPermission()
    .then((result) => {
      if (result) {
        return;
      }

      if (this.isMobile()) {
        return this.requestMobilePermission();
      }
      if (this.isWeb()) {
        return this.requestWebPermission();
      }
    });
  }

  public async restartTracking(): Promise<any> {
    return this.onReady()
    .then(() => {
      const sessionData = this.sessionService.getSessionData();
      return new Promise((resolve, reject) => (this.geoTrackerPlugin.config({
        serial_id: this.setup.serialId,
        host: this.setup.apiEndpoint,
        account_name: sessionData?.username,
        token: sessionData?.token,
        refresh_token: sessionData?.refreshToken,
        client_auth: this.setup.clientBasicAuth,
        app_id: this.sessionService.getAppId(),
      }, resolve, reject)));
    })
    .then(() => this.getLocationDataFromHomes())
    .then((results) => {
      const homesToSet = results.map((home: any) => {
        return {
          ID: home.id,
          LATITUDE: home.position.latitude,
          LONGITUDE: home.position.longitude,
          RADIUS: home.radius,
        };
      });
      return new Promise((resolve, reject) => (
        this.geoTrackerPlugin.setHomes(homesToSet, resolve, reject)));
    })
    .then(() => {
      return new Promise((resolve, reject) => this.geoTrackerPlugin.start(null, resolve, reject));
    })
    .catch(() => {
      // Do nothing. Just catch error
    });
  }

  private deviceReady() {
    this.geoTrackerPlugin = w.geoTrackerPlugin;
    this.mobilePermissions = (w.cordova && w.cordova.plugins) ? w.cordova.plugins.permissions : null;
    this.webPermissions = nav ? nav.permissions : null;
    this.readyPromiseResolve();
  }

  private async onReady(): Promise<void> {
    return this.readyPromise;
  }

  private isMobile() {
    return Boolean(this.mobilePermissions);
  }

  private isWeb(): boolean {
    return false;
    return Boolean(this.webPermissions);
  }

  private async hasMobilePermission(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.mobilePermissions.hasPermission(this.mobilePermissions.ACCESS_FINE_LOCATION, (status: any) => {
        if (!status.hasPermission) {
          return resolve(false);
        }
        resolve(true);
      }, reject);
    });
  }

  private async hasWebPermission(): Promise<boolean> {
    return nav.permissions.query({ name: 'geolocation' })
    .then((permissionStatus: any) => {
      return permissionStatus.state === 'granted';
    });
  }

  private async requestMobilePermission(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.mobilePermissions.requestPermission(this.mobilePermissions.ACCESS_FINE_LOCATION, (status: any) => {
        if (!status.hasPermission) {
          return reject();
        }
        resolve();
      }, reject);
    });
  }

  private async requestWebPermission(): Promise<void> {
    return this.getCurrentPosition()
    .then(() => {
      // do not return anything
    })
    .catch((e) => {
      if (e.constructor.name === 'PositionError') {
        return;
      }
      throw e;
    });
  }

  private async getLocationDataFromHomes(): Promise<any[]> {
    return this.devListService.getHomeList()
    .then((results: HomeData[]) => {
      return results.filter((home) => {
        return this.sessionService.getHomeGeolocationPermission(home.id);
      });
    })
    .then((homeList: HomeData[]) => {
      const promises = homeList.map((home) => {
        return this.devListService.getGeoData(home.id)
        .then((geoData) => {
          return {
            id: home.id,
            position: geoData.position,
            radius: geoData.outdoor_range || 150,
          };
        });
      });

      return Promise.all(promises);
    })
    .then((results: any) => {
      return results.filter((result: any) => Boolean(result.position));
    });
  }
}
