import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import Slider from '@/components/slider/Slider.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    MainButton,
  },
})
export default class discovery extends Vue {

    private devId: any = {};
    private justInstalled: any = {};
    private timeout: any;
    private homeId!: string;

    private mounted() {
      this.homeId = this.$route.params.homeid;
      this.devId = (window as any).devId;
      this.$userService.addDiscoveryListener(this.homeId, this.discoveryListener);
      this.$userService.addNewNodeListener(this.homeId, this.newDeviceListener);
    }

    private destroyed() {
      this.$userService.removeDiscoveryListener(this.homeId, this.discoveryListener);
      this.$userService.removeNewNodeListener(this.homeId, this.newDeviceListener);
    }

    private close(): any {
      this.$emit('close');
    }

    private stopDiscovery(): any {
      return this.$devListService.getDevProxy(this.homeId, (window as any).devId).then((devProxy: any) => {
        devProxy.setDiscovery({ discovery: 'off' });
        this.close();
      });
    }

    private discoveryListener(devId?: any, discovery?: any): any {
      if (discovery === 'off') {
        this.close();
      }
    }

    private newDeviceListener(): any {
      clearTimeout(this.timeout);
      this.justInstalled = true;
      this.timeout = setTimeout(() => {
        this.justInstalled = false;
      }, 5000);
    }

}
