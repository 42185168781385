const messages = {
 'es': {
  'ASCII_ERROR': 'Los nombres y contraseñas de redes WiFi deben tener únicamente caracteres ASCII.',
  'QR_SCANN_ERROR': 'Los nombres y contraseñas de redes WiFi con más de 32 caracteres no están soportadas.',
  'QR_SCANN_ERROR_TITLE': 'Ha habido un problema con las credenciales introducidas',
  'EXTRA_PRESS_ON_PROVISIONING': 'Presione cualquier botón para activar la pantalla',
  'BT_ACTIVATION_WARNING': 'Asegúrese de tener el bluetooth activado en tu teléfono móvil',
  'DEVICE_DISCONNECTED_STEP_1': 'Abra el menú principal',
  'DEVICE_DISCONNECTED_STEP_2': 'Abra la sección Viviendas y seleccione su vivienda',
  'DEVICE_DISCONNECTED_STEP_3': 'Seleccione su dispositivo',
  'DEVICE_DISCONNECTED_STEP_4': 'Configure la red WiFi del dispositivo',
  'DEVICE_DISCONNECTED_HELP_1': 'Su red WiFi tiene conexión a internet y es una red de 2.4GHz.',
  'DEVICE_DISCONNECTED_HELP_2': 'El dispositivo está situado en una zona con buena cobertura WiFi.',
  'DEVICE_DISCONNECTED_HELP_3': 'Vuelva a configurar las credenciales WiFi del dispositivo desde <a class="alink" href=\'{{link}}\'>aquí</a> o siga estas instrucciones:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'El dispositivo se ha instalado, pero no se ha conectado correctamente. Pruebe a desenchufarlo y volver a enchufarlo. Compruebe si se ha conectado a la red WiFi. Si no, considere los siguientes puntos:',
  'DEVICE_DISCONNECTED_HEADER': 'Ha habido un problema durante la configuración del dispositivo.',
  'DEVICE_INSTALLATION_ERROR': 'Ha habido un problema durante la instalación del dispositivo.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Compruebe el estado de los LEDs del dispositivo. Seleccione uno de los estados para proporcionarle más información para poder resolver el problema:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'El dispositivo no tiene IP asociada.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'El dispositivo no tiene conexión con el servidor.',
  'DEVICE_INSTALLATION_ERROR_OK': 'El dispositivo está correctamente conectado.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Compruebe que el dispositivo está correctamente conectado al WiFi a través del cable Ethernet.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Vuelva a realizar el proceso de instalación.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Compruebe la conexión a internet del router y verifica que no tiene ninguna restricción de puertos.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Cierre y abra de nuevo la aplicación para comprobar que el dispositivo se ha instalado correctamente.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Compruebe el estado del LED del dispositivo. Seleccione uno de los estados para proporcionarle más información para poder resolver el problema:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'La red WiFi no ha sido configurada.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'El dispositivo no tiene conexión con el router.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Compruebe el estado del equipo mirando el estado del icono de la antena. Seleccione uno de los estados para proporcionarle más información para poder resolver el problema:',
  'SERVER_CONNECTION_ERROR': 'La aplicación no ha podido conectarse con el servidor. Compruebe la conexión de su teléfono',
  'AP_FALLBACK_TITLE': 'Comencemos de nuevo utilizando el método Access Point',
  'HANDSHAKE_WARNING': 'Por favor, asegúrese de que su teléfono esté conectado a la misma red WiFi.',
  'GEOLOCATION_RANGE': 'Radio de vivienda',
  'PROVISIONING_METHOD_HELP': 'Seleccione un método para conectar su dispositivo',
  'RECOMMENDED': 'Recomendado',
  'BACK': 'Volver',
  'HELP': 'Ayuda',
  'SUPPORT': 'Soporte',
  'MY_ACCOUNT': 'Mi cuenta',
  'HOMES': 'Viviendas',
  'PRIVACY': 'Privacidad',
  'VERSION': 'Versión',
  'PRIVACY_INFO': 'Aquí puede ver la información personal que tenemos de Ud. Puede ejercer sus derechos en la dirección de email de contacto que aparece más abajo',
  'HOME_GEOLOCATION': 'Geoposición de vivienda',
  'LATITUDE': 'Latitud',
  'LONGITUDE': 'Longitud',
  'CONTACT_EMAIL': 'Email de contacto',
  'PP_POLICY_CHANGE_WARNING': 'La política de privacidad y terminos y condiciones han cambiado, es necesaria su conformidad para continuar',
  'PP_READ_AND_ACCEPTED': 'He leído y acepto la <a href=\'{{frontendHost}}/es/privacy.html\' target=\'_blank\' class=\'alink\'>Política de Privacidad</a> y los <a href=\'{{frontendHost}}/es/terms.html\' target=\'_blank\' class=\'alink\'>Términos y Condiciones</a>',
  'ACCEPTED': 'Aceptado',
  'SIGN_OUT': 'Cerrar sesión',
  'SIGN_IN': 'Iniciar Sesión',
  'SIGN_UP': 'Registrarse',
  'EMAIL': 'Email',
  'PASSWORD': 'Clave',
  'ALL_FIELDS_REQUIRED': 'Ha de rellenar todos los campos',
  'REMEMBER_ME': 'Recuérdame',
  'NEW_ON_HELKI': 'Nuevo usuario, regístrate aquí!',
  'I_FORGOT_MY_PASSWORD': 'Olvidé la contraseña',
  'TYPE_EMAIL': 'Introduce tu email',
  'TYPE_PASSWORD': 'Introduce tu contraseña',
  'CONFIRM_PASSWORD': 'Confirma tu contraseña',
  'INVALID_EMAIL': 'Email no válido',
  'SIGN_IN_ERROR': 'Email o clave incorrectos',
  'INVALID_PASSWORD': 'Clave no válida',
  'PASSWORDS_DO_NOT_MATCH': 'Las claves no coinciden',
  'USER_CONFLICT_ERROR': 'Email ya está dado de alta',
  'SIGN_UP_SUCCESS': '¡Registro realizado con éxito! Le hemos enviado un email para la confirmación de su cuenta',
  'FORGOT_PASS_MESSAGE': 'No se preocupe, tan solo facilítenos su email de registro para poder iniciar el proceso de cambio de contraseña.',
  'RESET_PASSWORD': 'Resetear contraseña',
  'USER_NOT_FOUND_ERROR': 'Usuario no encontrado',
  'FORGOT_PASS_SUCCESS': 'Le hemos enviado un email para reiniciar su clave',
  'SIGN_UP_VERIFY_SUCCESS': 'Su cuenta ha sido verificada correctamente',
  'SIGN_UP_VERIFY_ERROR': 'Su cuenta no ha podido ser verificado. Por favor, póngase en contacto',
  'CHANGE_EMAIL_SUCCESS': 'Su email ha sido actualizado correctamente',
  'CHANGE_EMAIL_ERROR': 'Su email no ha podido ser actualizado. Por favor, póngase en contacto',
  'RESET_PASS_SUCCESS': 'Su clave ha sido reiniciada correctamente',
  'RESET_PASS_ERROR': 'Su clave no ha podido ser reiniciada. Por favor, póngase en contacto',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Cambio de email',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Le hemos enviado un email a su nueva cuenta para completar el cambio',
  'CONNECTION_ERROR_MSG': 'No ha sido posible conectar con el servidor',
  'TRY_AGAIN': 'Reintentar',
  'NOW': 'Ahora',
  'HEATING': 'Calefacción',
  'ELECTRICITY': 'Electricidad',
  'WEATHER': 'El tiempo',
  'TEMPERATURE': 'Temperatura',
  'TURN_OFF': 'Apagar',
  'TURN_ON': 'Encender',
  'SCHEDULE': 'Programar',
  'SETUP': 'Configurar',
  'SET_TEMPERATURE': 'Establecer temperatura',
  'SET_COMFORT_TEMPERATURE': 'Establecer temperatura de comfort',
  'SET_COMFORT_TEMPERATURE_WARNING': '¡Atención! Si modifica la temperatura de confort, la temperatura de economía será automáticamente reajustada.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Por favor, seleccione la misma gama de radiadores (No es posible seleccionar los radiadores con geolocalización con radiadores sin geolocalización)',
  'CANCEL': 'Cancelar',
  'REMOVE': 'Eliminar',
  'CONFIRM': 'Confirmar',
  'RECORDS': 'Consumos',
  'SELECT_A_DAY': 'Seleccione un día',
  'DAY1_SHORT': 'Lu',
  'DAY2_SHORT': 'Ma',
  'DAY3_SHORT': 'Mi',
  'DAY4_SHORT': 'Ju',
  'DAY5_SHORT': 'Vi',
  'DAY6_SHORT': 'Sá',
  'DAY7_SHORT': 'Do',
  'MONDAY': 'Lunes',
  'TUESDAY': 'Martes',
  'WEDNESDAY': 'Miércoles',
  'THURSDAY': 'Jueves',
  'FRIDAY': 'Viernes',
  'SATURDAY': 'Sábado',
  'SUNDAY': 'Domingo',
  'MONDAY_SHORT': 'Lun',
  'TUESDAY_SHORT': 'Mar',
  'WEDNESDAY_SHORT': 'Mie',
  'THURSDAY_SHORT': 'Jue',
  'FRIDAY_SHORT': 'Vie',
  'SATURDAY_SHORT': 'Sab',
  'SUNDAY_SHORT': 'Dom',
  'COPY_DAY': 'Copiar día',
  'ADD': 'Agregar',
  'FROM': 'De',
  'TO': 'a',
  'SELECT_A_DAY_MSG': 'Seleccione un día para establecer o modificar la programación del termostato',
  'EDIT': 'Editar',
  'START': 'Inicio',
  'END': 'Fin',
  'DONE': 'Listo',
  'COPY_DAYS': 'Copiar días',
  'USE_SAME_SCHEDULE_ON': 'Usar la misma programación los ...',
  'COPY': 'Copiar',
  'POWER_METER': 'Medidor',
  'USAGE': 'Consumo',
  'USAGE_VALLEY': 'Consumo de carga',
  'USAGE_PEAK': 'Consumo sin carga',
  'REAL_TIME_USAGE': 'Consumo instántaneo',
  'DAILY': 'Diario',
  'MONTHLY': 'Mensual',
  'ANNUAL': 'Anual',
  'JAN': 'Ene',
  'FEB': 'Feb',
  'MAR': 'Mar',
  'APR': 'Abr',
  'MAY': 'May',
  'JUN': 'Jun',
  'JUL': 'Jul',
  'AUG': 'Ago',
  'SEP': 'Sep',
  'OCT': 'Oct',
  'NOV': 'Nov',
  'DEC': 'Dic',
  'POWER': 'Potencia',
  'NOMINAL_POWER': 'Potencia nominal',
  'EFFECTIVE_POWER': 'Potencia efectiva',
  'ZERO_POWER_WARNING': 'El radiador no tiene potencia asignada, cámbiela aquí',
  'SET_MODE_TEMP': 'Def. Temp.',
  'SET_MODE_TEMPERATURE': 'Definir temperatura modos',
  'COMFORT': 'Confort',
  'ECO': 'Eco',
  'ANTI_ICE': 'Antihielo',
  'INSTALL': 'Instalar',
  'INSTALL_ERROR_MSG': 'Error en el proceso de instalación. Inténtelo de nuevo.',
  'MORE': 'Más',
  'SELECT_ALL': 'Sel. todos',
  'DESELECT_ALL': 'Desel. todos',
  'INSTALL_DEVICE': 'Instalar dispositivo',
  'THERMOSTAT': 'Termostato',
  'HEATER': 'Radiador',
  'HEATER_HAVERLAND': 'Radiador con geolocalización',
  'HEATER_WITH_SMARTBOX': 'Radiador con Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Toallero con Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'Radiador WiFi con 4 botones',
  'WIFI_HEATER_W2BUTTONS': 'Radiador WiFi con 2 botones',
  'INSTALL_THERMOSTAT_STEP1': 'Instalar termostato: Paso 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Pulse el botón <strong>COMENZAR BÚSQUEDA</strong> para comenzar la instalación del termostato.',
  'INSTALL_THERMOSTAT_STEP2': 'Instalar termostato: Paso 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Para emparejar el termostato pulse las teclas <strong>Standby</strong> (el botón izquierdo) y <strong>Link</strong> (el botón derecho) durante <strong>3 segundos</strong> hasta que aparezca el símbolo de red en la parte superior derecha del termostato (tal como se muestra en la imagen).',
  'START_SEARCH': 'Comenzar búsqueda',
  'SEARCHING': 'Buscando...',
  'INSTALL_HEATER_STEP1': 'Instalar radiador: Paso 1',
  'INSTALL_HEATER_HELP_STEP1': 'Pulse el botón <strong>COMENZAR BÚSQUEDA</strong> para comenzar la instalación del radiador.',
  'INSTALL_HEATER_STEP2': 'Instalar radiador: Paso 2',
  'INSTALL_HEATER_HELP_STEP2': 'Para emparejar el radiador eléctrico <strong>PULSE</strong> la tecla <strong>MODE/OK</strong> en el dispositivo durante <strong>3 segundos</strong> hasta que aparezca el símbolo de red en la parte superior derecha de la pantalla.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Asegúrese de que el radiador está en \'Standby\' y mantenga pulsado los botones subir y bajar temperatura al menos 3 segundos.',
  'STORAGE_HEATER': 'Acumulador',
  'STORAGE_HEATERS': 'Acumuladores',
  'INSTALL_STORAGE_HEATER_STEP1': 'Instalar acumulador: Paso 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Pulse la tecla <strong>COMENZAR BÚSQUEDA</strong> para comenzar la instalación del acumulador.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Instalar acumulador: Paso 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Para emparejar el acumulador <strong>PULSE</strong> la tecla <strong>OK</strong> en el dispositivo durante <strong>3 segundos</strong> hasta que aparezca el símbolo de red en la parte superior derecha de la pantalla.',
  'CHARGE_PROGRAM': 'Programación carga',
  'VALLEY_1': 'Periodo 1',
  'VALLEY_2': 'Periodo 2',
  'INSTALL_POWER_METER_STEP1': 'Instalar medidor eléctrico: Paso 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Pulse el botón <strong>COMENZAR BÚSQUEDA</strong> para comenzar la instalación del medidor.',
  'INSTALL_POWER_METER_STEP2': 'Instalar medidor eléctrico: Paso 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Para emparejar el medidor PULSE con un clip (subministrado) en el pequeño pulsador situado en la parte superior del medidor (ver imagen). Puede comprobar que está emparejado observando el LED del medidor.',
  'LED_INDICATOR_STATES': 'Estados del indicador LED',
  'UNLINKED': 'No emparejado',
  'LED_OFF': 'Led apagado',
  'CORRECTLY_LINKED': 'Emparejado correctamente',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'El LED parpadea una vez cada dos segundos y medio.',
  'LINKED_BUT_LOST': 'Emparejado pero perdido',
  'LED_BLINKS_EVERY_HALF_SEC': 'El LED parpadea una vez cada medio segundo. Esto ocurre cuando se desconecta la centralita, por ejemplo. Una vez vuelva a poder comunicarse con la centralita el medidor volverá a conectarse automáticamente.',
  'NAME': 'Nombre',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Seleccione un nombre para identificar el dispositivo',
  'TYPE': 'Tipo',
  'HEAT': 'Calor',
  'COLD': 'Frío',
  'CONTROL': 'Control',
  'SELECT': 'Seleccione...',
  'HYST_0P2': 'Histéresis (0,2 ºC)',
  'HYST_0P3': 'Histéresis (0,3 ºC)',
  'HYST_0P4': 'Histéresis (0,4 ºC)',
  'HYST_0P5': 'Histéresis (0,5 ºC)',
  'HYST_0P7': 'Histéresis (0,7 ºC)',
  'HYST_0P8': 'Histéresis (0,8 ºC)',
  'HYST_1P6': 'Histéresis (1,6 ºC)',
  'HYST_0P4F': 'Histéresis (0,4 ºF)',
  'HYST_0P5F': 'Histéresis (0,5 ºF)',
  'HYST_0P7F': 'Histéresis (0,7 ºF)',
  'HYST_0P9F': 'Histéresis (0,9 ºF)',
  'HYST_1P2F': 'Histéresis (1,2 ºF)',
  'HYST_1P4F': 'Histéresis (1,4 ºF)',
  'HYST_2P9F': 'Histéresis (2,9 ºF)',
  'PID_T10MIN': 'PID (periodo 10 min.)',
  'PID_T15MIN': 'PID (periodo 15 min.)',
  'PID_T20MIN': 'PID (periodo 20 min.)',
  'PID_T25MIN': 'PID (periodo 25 min.)',
  'PID_T30MIN': 'PID (periodo 30 min.)',
  'PID_T45MIN': 'PID (periodo 45 min.)',
  'PID_T60MIN': 'PID (periodo 60 min.)',
  'ANTI_GRIPPAGE': 'Anti-agarrotamiento de bomba',
  'CONTRACTED_POWER_W': 'Potencia contratada (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Introduce tu potencia contratada en Watios',
  'PRIORITY': 'Prioridad',
  'LOW': 'Baja',
  'MEDIUM': 'Media',
  'HIGH': 'Alta',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Modo ventana',
  'DELETE_DEVICES': 'Borrar dispositivos',
  'DELETE_DEVICES_WARNING': 'Se borrarán los dispositivos seleccionados del sistema y sus datos asociados',
  'DELETE': 'Borrar',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Máximo de potencia total de radiadores',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Potencia para todos los radiadores (W)',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Introduce la potencia máxima (W)',
  'LIMITLESS': 'Sin límite de potencia',
  'HOME_INFO': 'Datos de la vivienda',
  'HOME_NAME': 'Nombre de la vivienda',
  'TYPE_HOME_NAME': 'Introduce el nombre de tu vivienda',
  'TIME_ZONE': 'Huso horario',
  'TIME_ZONE_CET': '(Hora centroeuropa) Berlín, París, Madrid, Roma',
  'TIME_ZONE_WET': '(Hora Europa occidental) Londres, Dublín, Lisboa, Islas Canarias',
  'TIME_ZONE_EET': '(Hora Europa oriental) Helsinki, Sofía, Atenas',
  'TIME_ZONE_PET': '(Hora Perú) Lima',
  'TIME_ZONE_NZST': '(Hora Nueva Zelanda) Auckland',
  'TIME_ZONE_CIST': '(Hora de Islas Chatham) Islas Chatham',
  'TIME_ZONE_CST': '(Hora China) Shanghai',
  'TIME_ZONE_UYT': '(Hora Uruguay) Montevideo',
  'DEVICE_IDENTIFIER': 'Identificator de dispositivo',
  'TYPE_DEVICE_IDENTIFIER': 'Introduce el identificador de tu dispositivo',
  'INVALID_IDENTIFIER': 'El identificador introducido no es válido',
  'DEVICE_ALREADY_TAKEN': 'El dispositivo con ID DEVID ya se encuentra dado de alta. Por favor póngase en contacto con soporte técnico para indicar esta incidencia.',
  'COPY_ERROR_DATA': '  Copiar el ID del dispositivo',
  'MY_HOMES': 'Mis viviendas',
  'ADD_HOME': 'Agregar vivienda',
  'SAVE': 'Guardar',
  'MY_INFO': 'Mis datos',
  'USER': 'Usuario',
  'LANGUAGE': 'Idioma',
  'CHANGE_EMAIL_ADDRESS': 'Cambiar email',
  'NEW_EMAIL_ADDRESS': 'Nuevo email',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Introduce tu nuevo email',
  'CONFIRM_WITH_PASSWORD': 'Confirmar con clave',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Introduce tu clave actual',
  'CHANGE_PASSWORD': 'Cambiar clave',
  'CURRENT_PASSWORD': 'Clave actual',
  'TYPE_YOUR_NEW_PASSWORD': 'Introduce tu nueva clave',
  'NEW_PASSWORD': 'Nueva clave',
  'TEMPERATURE_UNITS': 'Unidad de temperatura',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Autoaprendizaje',
  'SELF_LEARN_SHORT': 'AutoAprend.',
  'PRESENCE': 'Presencia',
  'AWAY_OFFSET': 'Bajada de temperatura en ausencia',
  'ERASE_DATA_PERMANENTLY': 'Borrar datos permanentemente',
  'NO_CONNECTION_WARNING': 'Sin conexión, acceso sólo a historial',
  'SET_MODE': 'Establecer modo',
  'AT_HOME': 'En casa',
  'AWAY': 'Ausente',
  'DEVICES_SUMMARY': 'Resumen de dispositivos',
  'THERMOSTATS': 'Termostatos',
  'HEATERS': 'Radiadores',
  'POWER_METERS': 'Medidores',
  'CLOSE': 'Cerrar',
  'DETECT_SMARTBOX': 'Detectar SmartBox',
  'DETECT_SMARTBOX_HELP_MSG': 'Para localizar su SmartBox pulse el botón situado en la parte frontal de la misma. Asegúrese de que está conectada correctamente a su red WiFi.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Lo sentimos pero no ha sido posible encontrar la SmartBox. Por favor pinche en continuar e introduzca manualmente el identificador del dispositivo.',
  'CONTINUE': 'Continuar',
  'CANCEL_SEARCH': 'Cancelar búsqueda',
  'SEARCHING_NEW_DEVICES': 'Buscando nuevos dispositivos ...',
  'DISCOVERY_MODE_MSG': 'SmartBox {{devId}} en modo descubrimiento. Pulse el botón adecuado de su dispositivo a instalar según su manual',
  'DEVICE_FOUND_MSG': '¡Se ha encontrado un nuevo dispositivo!',
  'GETTING_STARTED': 'Primera ayuda',
  'MAIN_MENU': 'Menú principal',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Acceda fácilmente a las opciones generales de configuración',
  'NEXT': 'Siguiente',
  'GEOLOCATION': 'Geolocalización',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Ausente reducirá por defecto 2ºC',
  'DEVICES': 'Dispositivos',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Los dispositivos se dividen en secciones Calefacción y Electricidad',
  'GO_TO_A_DEVICE': 'Acceda al dispositivo',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Configure el dispositivo y acceda a sus estadísticas',
  'MULTIDEVICE_ACTIONS': 'Acciones múltiples',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Seleccione los dispositivos y acceda a las opciones en el botón MÁS',
  'READY': 'Listo',
  'SKIP': 'Saltar',
  'SKIP_HELP': 'Saltarse la ayuda',
  'QUICK_SETTING': 'Ajuste rápido',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Desliza lateralmente la barra de temperaturas para cambiar rápidamente entre temperaturas.',
  'TEMPERATURES': 'Temperaturas',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Establece las temperaturas de cada uno de los modos disponibles.',
  'MODE': 'Modo',
  'WORKING_MODES': 'Modos',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Selecciona uno de los tres modos disponibles.',
  'SELECT_MODE': 'Aplica el modo',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Marca las horas en las que se aplicará ese modo.',
  'SUPPORT_BODY': 'Su cuenta tiene un periodo mínimo de soporte para actualizaciones de seguridad de 3 años desde la fecha de adquisición del producto. En el caso de que detecte algún problema de seguridad o de otra naturaleza envíenos por favor un email a la dirección que figura más abajo describiendo el problema.',
  'SUPPORT_REQUEST_INTRO_HELP': 'Dispone de un botón de ayuda en la parte inferior izquierda para facilitarle el manejo de la app.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Si desea realizar una consulta o requiere soporte con la app, por favor contacte con nosotros:',
  'ADDRESS': 'Dirección',
  'TELEPHONE': 'Teléfono',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'Al registrarme, acepto los <a href=\'{{frontendHost}}/es/terms.html\' target=\'_blank\'>Términos y Condiciones</a> y la <a href=\'{{frontendHost}}/es/privacy.html\' target=\'_blank\'>Política de Privacidad</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/es/privacy.html\' target=\'_blank\'>Política de Privacidad</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Su clave se ha actualizado',
  'PASSWORD_CHANGE_ERROR_MSG': 'No se ha podido actualizar su clave. Por favor, compruebe su clave actual',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Email no válido',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Este email ya pertenece a otro usuario',
  'EMAIL_CHANGE_ERROR_MSG': 'No se ha podido actualizar su email.Por favor, inténtelo más tarde.',
  'HOME_TYPE': 'Tipo de la vivienda',
  'ETHERNET': 'Conexión por cable',
  'WIFI': 'Conexión por WiFi',
  'DEVICE_REGISTER_ERROR': 'No se ha podido completar el registro',
  'WRONG_CREDENTIALS_ERROR': 'Usuario/contraseña incorrecto',
  'DEVICE_ADDED_MSG': 'Se ha añadido un nuevo dispositivo',
  'CONNECTION_WARNING': 'Conexión WiFi',
  'WIFI_CONNECTION_1': 'Abra los ajustes del teléfono',
  'WIFI_CONNECTION_2': 'Seleccione WiFi',
  'WIFI_CONNECTION_3': 'Conéctese a la WiFi',
  'WIFI_CONNECTION_4': 'Si ve una notificación del tipo \'WiFi sin acceso a Internet\', indique que desea mantener la conexión',
  'WIFI_CONNECTION_5': 'Vuelva a esta vista',
  'DEVICE_CONNECTION_ERROR': 'No se pudo conectar con el dispositivo',
  'WIFI_SCAN_ERROR': 'No se han encontrado redes',
  'WRONG_PASS_ERROR': 'La contraseña de la WiFi configurada es incorrecta',
  'WRONG_ESSID_ERROR': 'La red WiFi configurada no existe',
  'SET_WIFI_ERROR': 'No se pudo establecer la red WiFi',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Conexión satisfactoria',
  'WIFI_SELECTION': 'Seleccione su red WiFi',
  'WIFI_NOT_FOUND': 'No encuentro mi red',
  'TYPE_AP_ESSID': 'Introduce el nombre de tu red',
  'TYPE_AP_PASS': 'Introduce la contraseña de tu red',
  'RETRY': 'REINTENTAR',
  'AP_DATA': 'Datos de la red',
  'AP_ESSID': 'Nombre',
  'AP_PASS': 'Contraseña',
  'EDIT_WIFI': 'Editar configuración WiFi',
  'WRONG_WIFI_ERROR': 'No se ha conectado a la WiFi correspondiente',
  'COPY_PASS': 'Copiar contraseña',
  'EDIT_DATA': 'Datos de vivienda',
  'WEB_WIFI_WARNING': 'La opción WiFi solo está disponible en nuestra app',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Agregue hasta 9 bloques de temperatura',
  'ACCEPT': 'Aceptar',
  'INVITE_USERS': 'Invitar usuarios',
  'INVITE_USER': 'Invitar usuario',
  'INVITED_USERS': 'Usuarios invitados',
  'INVITE_USER_TITLE': 'Invitar usuario',
  'INVITE_USER_SUCCESS_MSG': 'Nuevo usuario invitado. Se le ha enviado un email para indicarle que tiene acceso a esta vivienda',
  'INVITE_USER_ERROR_MSG': 'Error al invitar usuario.Por favor, inténtelo más tarde.',
  'REVOKE_INVITE_TITLE': 'Eliminar invitado',
  'REVOKE_INVITE_MSG': 'El usuario seleccionado dejará de tener acceso a esta vivienda',
  'REVOKE_INVITE_SUCCESS_MSG': 'Invitado eliminado',
  'REVOKE_INVITE_ERROR_MSG': 'Error al eliminar invitado.Por favor, inténtelo más tarde.',
  'CONFIRM_INVITE_SUCCESS': 'Su cuenta se ha creado correctamente',
  'GUEST': 'Invitado',
  'TRV': 'Válvula termostática',
  'TRVS': 'Válvulas termostáticas',
  'INSTALL_TRV_STEP1': 'Instalar válvula termostática: Paso 1',
  'INSTALL_TRV_HELP_STEP1': 'Pulse el botón <strong>COMENZAR BÚSQUEDA</strong> para comenzar la instalación de la válvula termostática.',
  'INSTALL_TRV_STEP2': 'Instalar válvula termostática: Paso 2',
  'INSTALL_TRV_HELP_STEP2': 'Para emparejar la válvula <strong>PULSE</strong> la tecla <strong>helki</strong> (el botón central) durante <strong>3 segundos</strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Cambio de modo frío/calor',
  'CONTROL_MODE_CHANGE_MSG': 'Advertencia: la programación actual se restablecerá',
  'SAVE_THIS_POSITION': 'Guardar esta posición',
  'SET_POSITION_MSG': 'La posición de su vivienda aún no ha sido establecida. Seleccione la posición de su vivienda en el mapa y haga click en el botón inferior para guardarla.',
  'ENABLE_GEOLOCATION': 'Activar geolocalización',
  'OUTDOOR_RANGE': 'Radio de vivienda (m.)',
  'DEV_TYPE_WIFI': 'SmartBox WiFi',
  'DEV_TYPE_WIFIHTR': 'Radiador WiFi',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Selección de dispositivo',
  'SELECT_DEVICE_THM_HELP': 'El termostato se asociará al siguiente dispositivo',
  'SELECT_DEVICE_HTR_HELP': 'El radiador se asociará al siguiente dispositivo',
  'SELECT_DEVICE_ACM_HELP': 'El acumulador se asociará al siguiente dispositivo',
  'SELECT_DEVICE_PMO_HELP': 'El medidor de potencia se asociará al siguiente dispositivo',
  'SELECT_DEVICE_TRV_HELP': 'La válvula se asociará al siguiente dispositivo',
  'ADD_DEVICE': 'Añadir dispositivo',
  'DEVICE_INSTALLATION': 'Instalación de dispositivo',
  'WIFI_HEATER': 'Radiador WiFi',
  'WIFI_HEATER_HAVERLAND': 'Serie Connect',
  'LETS_START': 'Empecemos',
  'INSTALL_WIFI_HEATER_HELP': 'Conecta tu radiador al enchufe. Pulsa la tecla OK de tu radiador hasta que la pantalla parpadee.',
  'INSTALL_WIFI_HEATER2_HELP': 'Conecta tu radiador al enchufe. Pulsa las teclas que aperecen en la imagen hasta que la pantalla parpadee.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Conecta tu SmartBox WiFi. Si ya estaba conectada, desconéctala y vuelve a conectarla.',
  'DEV_TYPE_WIFI_CONNECTION': 'Conexión de SmartBox WiFi',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'Conexión de Radiador WiFi',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Ahora vamos a conectar la SmartBox a la WiFi de tu casa.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Ahora vamos a conectar el Radiador a la WiFi de tu casa.',
  'GHZ_WIFI_WARNING': 'Atención: sólo son válidas las redes de 2.4GHz',
  'CONFIGURING_WIFI_HTR': 'Configurando Radiador WiFi',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'Configuración de SmartBox WiFi en modo AP',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'Configuración de Radiador WiFi en modo AP',
  'SET_ETHERNET_SMARTBOX_NAME': '¿Cómo llamamos a esta SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': '¿Cómo llamamos a esta SmartBox WiFi?',
  'SET_WIFI_HTR_NAME': '¿Cómo llamamos a este Radiador WiFi?',
  'TYPE_DEVICE_NAME': 'Introduce un nombre para el dispositivo',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Conectando a SmartBox WiFi',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Conectando a Radiador WiFi',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Conectando a SmartBox WiFi con id:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Conectando a Radiador WiFi con id:',
  'MY_DEVICES': 'Mis dispositivos',
  'DEVICE_TYPE': '¿Cómo es tu dispositivo?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Conectando a SmartBox WiFi',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Conectando a Radiador WiFi',
  'CONFIGURING_DEV_TYPE_WIFI': 'Configurando SmartBox WiFi',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'Configurando Radiador WiFi',
  'DEVICE_LIST': 'Dispositivos',
  'CONNECTION_STATE': 'Estado de conexión',
  'CONFIGURE_WIFI': 'Configurar WiFi',
  'CHANGE_HOME': 'Mover a otra vivienda',
  'DELETE_DEVICE': 'Eliminar dispositivo',
  'TRANSFER_HOME_TITLE': 'Mover a otra vivienda',
  'TRANSFER_HOME_WARNING': 'Atención: tu dispositivo se va a transferir a la vivienda seleccionada',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Eliminar dispositivo',
  'CONFIRM_DEVICE_REMOVAL_HELP': '¿Estás seguro de que quieres borrar este dispositivo?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Por favor, conecta tu SmartBox Ethernet',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox WiFi instalada con éxito',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox Ethernet instalada con éxito',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'Radiador WiFi instalado con éxito',
  'HOME_REGISTRY': 'Registro de vivienda',
  'HOME_REGISTRY_HELP': 'Ahora mismo no tienes ninguna vivienda registrada. Vamos a añadir una nueva.',
  'SET_HOME_NAME': 'Establecer nombre de la vivienda',
  'SET_HOME_TIMEZONE': 'Establecer zona horaria de la vivienda',
  'DEVICE_CONNECTION_START': 'Ahora vamos a conectar tu dispositivo',
  'WIFI_DEVICE': 'Dispositivo WiFi',
  'WIFI_DEVICE_HELP': 'La instalación de dispositivos WiFi solo está disponible en nuestras apps',
  'TYPE_ESSID': 'Introduce tu red WiFi',
  'REGISTERING_DEVICE': 'Registrando dispositivo',
  'WELCOME': 'Bienvenido',
  'ESSID': 'Selecciona red WiFi',
  'SET_BOOST_MODE': 'Activar modo Boost',
  'BOOST_END_TIME': 'Hora de finalización del Boost',
  'BOOST_ACTIVE_UNTIL': 'Boost activo hasta las',
  'OPERATIONAL_MODE': 'Modo operacional',
  'FIXED_TEMPERATURE': 'Temperatura fija',
  'WEEKLY_SCHEDULE': 'Programación semanal',
  'ANTIFROST_PROTECTION': 'Protección anti-hielo',
  'ID_COPIED': 'Identificador copiado',
  'NEW_PROFILE': 'Nuevo perfil',
  'PROFILE_COLOR': 'Color',
  'TYPE_PROFILE_NAME': 'Introduce un nombre para el perfil',
  'TYPE_POWER_LIMIT': 'Introduce un valor de potencia',
  'POWER_LIMIT': 'Potencia (W)',
  'EDIT_PROFILES': 'Perfiles',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Eliminar perfil de potencia',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': '¿Está seguro de que quiere eliminar este perfil?',
  'POWER_MANAGEMENT': 'Configuración Potencia Contratada',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Ha de estar entre ',
  'AND': ' y ',
  'MANUAL_NETWORK_INPUT': 'Introducir manualmente',
  'GEOLOCATION_INFO': 'Esta funcionalidad permite bajar la temperatura de los dispositivos cuando se encuentre fuera del radio de vivienda fijado.',
  'GEOLOCATION_NEW_FEAT': 'Descubre la funcionalidad que te permitirá ahorrar en el consumo de tus dispositivos cuando no te encuentres en casa.',
  'VOICE_CONTROL': 'Asistentes de voz',
  'VOICE_CONTROL_NEW_FEAT': 'Recuerde que este dispositivo es compatible con: ',
  'CONFIGURE': 'Configuración',
  'MORE_INFO_LONG': 'Más información',
  'GEOLOCATION_RATIONALE': 'Para el correcto funcionamiento del modo de geolocalización, esta aplicación necesita acceder a su ubicación todo el tiempo, incluso cuando no está usando el teléfono. Habilite el permiso en ajustes.',
  'USER_DUTY_FACTOR': 'Factor de limitación de potencia',
  'CIRCUIT_TYPE': 'Tipo de circuito',
  'PRINCIPAL_CIRCUIT': 'Circuito Principal',
  'REVERSE_SIGN': 'Invertir signo',
  'SMARTBOX_CONFIGURATION': 'Configuración de centralita',
  'MAIN_CIRCUIT_GENERATION': 'Circuito principal de Generación',
  'MAIN_CIRCUIT_CONSUMPTION': 'Circuito principal de Consumo',
  'SELECT_GENERATION_POWERMETER': 'Seleccione un medidor de generación',
  'SELECT_CONSUMPTION_POWERMETER': 'Seleccione un medidor de consumo',
  'POWERMETER_CONFIG': 'Configuración Medidor',
  'MAX_POWER_TERM_MANAGEMENT': 'Potencia Contratada',
  'MANAGE_MAX_POWER': 'Programar',
  'MANAGE_ENERGY_TARIFFS': 'Programar',
  'COLOR': 'Color',
  'CONSUMPTION': 'Consumo',
  'GENERATION': 'Generación',
  'EDIT_ENERGY_TARIFFS': 'Perfiles',
  'SELECT_MAX_POWER_CONFIG': 'Seleccione un perfil de potencia contratada',
  'CUSTOM_TARIFF_CONFIG': 'Nuevo perfil personalizado',
  'ANTI_FROST_IN_OFF': 'Protección Antihielo',
  'POWER_LEVEL_LIMIT': 'Nivel Límite Potencia',
  'E2_USAGE_ENABLED': 'Energía Solar',
  'E2_MODE': 'Tipo de Carga',
  'E2_ACTIVATION_PER': 'Potencia Solar Activación',
  'E2_CHARGE_EXCESS_PER': 'Carga Máxima Valle',
  'NODE_E2_MODE_0': 'Exclusivo Solar',
  'NODE_E2_MODE_1': 'Solar y Valle',
  'NODE_E2_MODE_2': 'Solar y Valle Corregida',
  'E2_TEMP_EXCESS': 'Margen Tª Ambiente',
  'E2_PRIORITY': 'Prioridad de Carga',
  'MAIN_CIRCUIT': 'Principal',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Dispositivo actualizado con éxito',
  'DEVICE_UPDATE_ERROR_MSG': 'No se ha podido actualizar el equipo.Inténtelo de nuevo.',
  'SYSTEM_MAINTENANCE': 'Sistema en mantenimiento',
  'SYSTEM_MAINTENANCE_MSG': 'Disculpe las molestias, estamos trabajando para mejorar el servicio.',
  'SOLD_SEPARATELY': '(Se vende por separado)',
  'REMOVE_ACCOUNT': 'Eliminar cuenta',
  'TYPE_PASSWORD_TO_CONFIRM': 'Introduzca contraseña para confirmar',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Eliminar cuenta',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'La eliminación de la cuenta implica eliminar todos los nodos y datos asociados a ella. Si está seguro de eliminar la cuenta, ingrese su contraseña para confirmar.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Cuenta eliminada con éxito.',
  'ACCOUNT_REMOVAL_ERROR': 'No se ha podido eliminar la cuenta.',
  'USE_QR': 'Escanear QR WiFi',
  'USE_MANUAL_AP': 'Introducir credenciales WiFi',
  'LOADING': 'Cargando',
  'SIGN_IN_WITH_GOOGLE': 'Iniciar sesión con Google',
  'LOCK': 'Bloquear',
  'UNLOCK': 'Desbloquear',
  'BRIGHT_LEVEL': 'Nivel de brillo pantalla',
  'BACKLIGHT_TIME': 'Tiempo encendido pantalla',
  'BACKLIGHT_TIME_10': '10 segundos',
  'BACKLIGHT_TIME_20': '20 segundos',
  'BACKLIGHT_TIME_30': '30 segundos',
  'BACKLIGHT_TIME_ALWAYS': 'Siempre',
  'DELETE_ACCOUNT_MSG': 'Al eliminar la cuenta de usuario estarás eliminando todos los datos asociados a ella de forma permanente. Si estas de acuerdo sigue los siguientes pasos',
  'DELETE_ACCOUNT_STEPS': 'Pasos para eliminar tu cuenta',
  'DELETE_ACCOUNT_STEP1': '1. Inicia sesión en la aplicación con tu usuario y contraseña.',
  'DELETE_ACCOUNT_STEP2': '2. Entra en el menú superior.',
  'DELETE_ACCOUNT_STEP3': '3. Selecciona Mi Cuenta.',
  'DELETE_ACCOUNT_STEP4': '4. En la sección Eliminar Cuenta haz click en el botón Eliminar cuenta.',
  'DEPRECATION_MESSAGE': 'Utilice por favor la aplicación TARGET'
 },
 'en': {
  'ASCII_ERROR': 'WiFi network names and passwords must contain only ASCII characters.',
  'QR_SCANN_ERROR': 'WiFi network names and passwords with more than 32 characters are not supported.',
  'QR_SCANN_ERROR_TITLE': 'There was a problem with the introduced credentials',
  'EXTRA_PRESS_ON_PROVISIONING': 'Press any button to activate the display',
  'BT_ACTIVATION_WARNING': 'Make sure you have bluetooth enabled on your mobile phone',
  'DEVICE_DISCONNECTED_STEP_1': 'Open main menu',
  'DEVICE_DISCONNECTED_STEP_2': 'Go to Homes and select your home',
  'DEVICE_DISCONNECTED_STEP_3': 'Select device',
  'DEVICE_DISCONNECTED_STEP_4': 'Configure WiFi',
  'DEVICE_DISCONNECTED_HELP_1': 'WiFi network has internet connection and is a 2.4GHz network.',
  'DEVICE_DISCONNECTED_HELP_2': 'Consider areas with proper WiFi coverage during device installation.',
  'DEVICE_DISCONNECTED_HELP_3': 'Check and reconfigure WiFi credentials for your device directly from <a class="alink" href=\'{{link}}\'>here</a> or follow this sequence:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'The device is already registered but it is not correctly connected. Try to disconnect your device from the plug and reconnect it. Verify if it is connected to your WiFi network. If not, please consider the following points:',
  'DEVICE_DISCONNECTED_HEADER': 'There was a problem in the provisioning process, the device could be lost.',
  'DEVICE_INSTALLATION_ERROR': 'There was a problem during the device installation.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Check the status of the device LEDs. Select one of the states for more information to help resolve the issue:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'The device does not have an associated IP.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'The device is not connected to the server.',
  'DEVICE_INSTALLATION_ERROR_OK': 'The device is correctly connected.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Ensure the device is properly connected to WiFi via the Ethernet cable.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Retry the installation process.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Check the router\'s internet connection and ensure there are no port restrictions.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Close and reopen the application to check if the device has been installed correctly.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Check the status of the device LED. Select one of the states for more information to help resolve the issue:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'The WiFi network has not been configured.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'The device is not connected to the router.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Check the device status by looking at the antenna icon. Select one of the states for more information to help resolve the issue:',
  'SERVER_CONNECTION_ERROR': 'The application is not able to connect to the server. Check the internet connection of your SmartPhone and retry the process',
  'AP_FALLBACK_TITLE': 'Let\'s restart the process using Access Point method',
  'HANDSHAKE_WARNING': 'Please, make sure that your smartphone is now connected to the same WiFi network.',
  'GEOLOCATION_RANGE': 'Home radius',
  'PROVISIONING_METHOD_HELP': 'Select a provisioning method to connect your device',
  'RECOMMENDED': 'Recommended',
  'BACK': 'Back',
  'HELP': 'Help',
  'SUPPORT': 'Support',
  'MY_ACCOUNT': 'My account',
  'HOMES': 'Homes',
  'PRIVACY': 'Privacy',
  'VERSION': 'Version',
  'PRIVACY_INFO': 'Here you can see the personal information we have about you. You can exercise your rights at the contact email address that appears below',
  'HOME_GEOLOCATION': 'Home geolocation',
  'LATITUDE': 'Latitude',
  'LONGITUDE': 'Longitude',
  'CONTACT_EMAIL': 'Contact email',
  'PP_POLICY_CHANGE_WARNING': 'The privacy policy and terms and conditions have been modified. You must agree in order to continue',
  'PP_READ_AND_ACCEPTED': 'I have read and accept the <a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\' class=\'alink\'>Privacy Policy</a> and <a href=\'{{frontendHost}}/en/terms.html\' target=\'_blank\' class=\'alink\'>Terms and Conditions</a>',
  'ACCEPTED': 'Accepted',
  'SIGN_OUT': 'Sign out',
  'SIGN_IN': 'Sign in',
  'SIGN_UP': 'Sign up',
  'EMAIL': 'Email',
  'PASSWORD': 'Password',
  'ALL_FIELDS_REQUIRED': 'All fields are required',
  'REMEMBER_ME': 'Remember me',
  'NEW_ON_HELKI': 'New user, sign up here!',
  'I_FORGOT_MY_PASSWORD': 'I forgot my password',
  'TYPE_EMAIL': 'Type your email',
  'TYPE_PASSWORD': 'Type your password',
  'CONFIRM_PASSWORD': 'Confirm Password',
  'INVALID_EMAIL': 'Invalid email',
  'SIGN_IN_ERROR': 'Incorrect email or password',
  'INVALID_PASSWORD': 'Invalid password',
  'PASSWORDS_DO_NOT_MATCH': 'Passwords do not match',
  'USER_CONFLICT_ERROR': 'Email already taken',
  'SIGN_UP_SUCCESS': 'Sign up successfull! We have sent you an email for account validation',
  'FORGOT_PASS_MESSAGE': 'Don\'t worry, just provide your registration email so that you can start the password reset process',
  'RESET_PASSWORD': 'Reset password',
  'FORGOT_PASS_SUCCESS': 'We have sent you an email to reset your password',
  'USER_NOT_FOUND_ERROR': 'User not found',
  'SIGN_UP_VERIFY_SUCCESS': 'Your account has been successfully verified',
  'SIGN_UP_VERIFY_ERROR': 'Your account could not be verified. Please, contact us',
  'CHANGE_EMAIL_SUCCESS': 'Your email address has been successfully updated',
  'CHANGE_EMAIL_ERROR': 'Your email address could not be updated. Please, contact us',
  'RESET_PASS_SUCCESS': 'Your password has been successfully reset',
  'RESET_PASS_ERROR': 'Your password could not be reset. Please, contact us',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Email address update',
  'CHANGE_EMAIL_SUCCESS_MSG': 'We have sent you an email to the new address to complete the process',
  'CONNECTION_ERROR_MSG': 'It has not been possible to connect to the server',
  'TRY_AGAIN': 'Retry',
  'NOW': 'Now',
  'HEATING': 'Heating',
  'ELECTRICITY': 'Power',
  'WEATHER': 'Weather',
  'TEMPERATURE': 'Temperature',
  'TURN_OFF': 'Turn off',
  'TURN_ON': 'Turn on',
  'SCHEDULE': 'Schedule',
  'SETUP': 'Setup',
  'SET_TEMPERATURE': 'Set temperature',
  'SET_COMFORT_TEMPERATURE': 'Set comfort temperature',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Attention! If you change the Comfort Temperature the Eco Temperature will be automatically readjusted.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Please, select same system of radiators only (Heaters can not be group selected with  Heaters with Geolocation)',
  'REMOVE': 'Remove',
  'CANCEL': 'Cancel',
  'CONFIRM': 'Confirm',
  'RECORDS': 'Records',
  'SELECT_A_DAY': 'Select a day',
  'DAY1_SHORT': 'Su',
  'DAY2_SHORT': 'Mo',
  'DAY3_SHORT': 'Tu',
  'DAY4_SHORT': 'We',
  'DAY5_SHORT': 'Th',
  'DAY6_SHORT': 'Fr',
  'DAY7_SHORT': 'Sa',
  'MONDAY': 'Monday',
  'TUESDAY': 'Tuesday',
  'WEDNESDAY': 'Wednesday',
  'THURSDAY': 'Thursday',
  'FRIDAY': 'Friday',
  'SATURDAY': 'Saturday',
  'SUNDAY': 'Sunday',
  'MONDAY_SHORT': 'Mon',
  'TUESDAY_SHORT': 'Tue',
  'WEDNESDAY_SHORT': 'Wed',
  'THURSDAY_SHORT': 'Thu',
  'FRIDAY_SHORT': 'Fri',
  'SATURDAY_SHORT': 'Sat',
  'SUNDAY_SHORT': 'Sun',
  'COPY_DAY': 'Copy day',
  'ADD': 'Add new',
  'FROM': 'From',
  'TO': 'to',
  'SELECT_A_DAY_MSG': 'Select a day for setting or modifying the thermostat schedule',
  'EDIT': 'Edit',
  'START': 'Start',
  'END': 'End',
  'DONE': 'Done',
  'COPY_DAYS': 'Copy days',
  'USE_SAME_SCHEDULE_ON': 'Use same schedule on...',
  'COPY': 'Copy',
  'POWER_METER': 'Power Meter',
  'USAGE': 'Usage',
  'USAGE_VALLEY': 'Charge consumption',
  'USAGE_PEAK': 'No charge consumption',
  'REAL_TIME_USAGE': 'Real time usage',
  'DAILY': 'Daily',
  'MONTHLY': 'Monthly',
  'ANNUAL': 'Annual',
  'JAN': 'Jan',
  'FEB': 'Feb',
  'MAR': 'Mar',
  'APR': 'Apr',
  'MAY': 'May',
  'JUN': 'Jun',
  'JUL': 'Jul',
  'AUG': 'Aug',
  'SEP': 'Sep',
  'OCT': 'Oct',
  'NOV': 'Nov',
  'DEC': 'Dec',
  'POWER': 'Power',
  'NOMINAL_POWER': 'Nominal power',
  'EFFECTIVE_POWER': 'Effective power',
  'ZERO_POWER_WARNING': 'This heater has no assigned power. Set it up here',
  'SET_MODE_TEMP': 'Set Temp.',
  'SET_MODE_TEMPERATURE': 'Set mode temperatures',
  'COMFORT': 'Comfort',
  'ECO': 'Eco',
  'ANTI_ICE': 'Frost',
  'INSTALL': 'Install',
  'INSTALL_ERROR_MSG': 'Error in the installation process. Try again!',
  'MORE': 'More',
  'INSTALL_DEVICE': 'Install device',
  'SELECT_ALL': 'Sel. all',
  'DESELECT_ALL': 'Desel. all',
  'THERMOSTAT': 'Thermostat',
  'HEATER': 'Heater',
  'HEATER_HAVERLAND': 'Heater with geolocation',
  'HEATER_WITH_SMARTBOX': 'Heater w/ smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Towel rail w/ smartbox',
  'WIFI_HEATER_W4BUTTONS': 'WiFi Heater w/4 buttons',
  'WIFI_HEATER_W2BUTTONS': 'WiFi Heater w/2 buttons',
  'INSTALL_THERMOSTAT_STEP1': 'Install thermostat: Step 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Press <strong>START SEARCH</strong> button to start thermostat installation.',
  'INSTALL_THERMOSTAT_STEP2': 'Install thermostat: Step 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'In order to link the thermostat, press the <strong>Stdby</strong> button (left button) and <strong>Link</strong> (right button)for <strong>3 seconds</strong> until the network icon shows up on the top-right corner of the thermostat (as shown in the image above).',
  'START_SEARCH': 'Start search',
  'SEARCHING': 'Searching...',
  'INSTALL_HEATER_STEP1': 'Install heater: Step 1',
  'INSTALL_HEATER_HELP_STEP1': 'Press <strong>START SEARCH</strong> button to start heater installation.',
  'INSTALL_HEATER_STEP2': 'Install heater: Step 2',
  'INSTALL_HEATER_HELP_STEP2': 'In order to link the electric heater, <strong>PRESS</strong> the <strong>MODE/OK</strong> button in the device for <strong>3 seconds</strong> until the network icon shows up on the top-right corner of the screen.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Make sure the radiator is on Standby mode and hold both up and down temperature buttons for at least 3 seconds.',
  'STORAGE_HEATER': 'Storage heater',
  'STORAGE_HEATERS': 'Storage heaters',
  'INSTALL_STORAGE_HEATER_STEP1': 'Install storage heater: Step 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Press <strong>START SEARCH</strong> button to start storage heater installation.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Install storage heater: Step 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'In order to link the storage heater, <strong>PRESS</strong> the <strong>OK</strong> button in the device for <strong>3 seconds</strong> until the network icon shows up on the top-right corner of the screen.',
  'CHARGE_PROGRAM': 'Charge Program',
  'VALLEY_1': 'Period 1',
  'VALLEY_2': 'Period 2',
  'INSTALL_POWER_METER_STEP1': 'Install Power meter: Step 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Press <strong>START SEARCH</strong> button to start power meter installation.',
  'INSTALL_POWER_METER_STEP2': 'Install Power meter: Step 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'In order to link the power meter, use a clip (already provided) to PRESS the  small button placed at the top of the device (see image above). You can check that it has been linked by looking at the device LED.',
  'LED_INDICATOR_STATES': 'LED indicator states',
  'UNLINKED': 'Unlinked',
  'LED_OFF': 'LED off',
  'CORRECTLY_LINKED': 'Correctly linked',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'LED blinks every two seconds and a half.',
  'LINKED_BUT_LOST': 'Linked but lost',
  'LED_BLINKS_EVERY_HALF_SEC': 'LED blinks every half second. This can happen for example when the gateway is unpowered. Once the power meter and the gateway reestablish communication, it will be linked automatically.',
  'NAME': 'Name',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Select a name for identifying this device',
  'TYPE': 'Type',
  'HEAT': 'Heat',
  'COLD': 'Cold',
  'CONTROL': 'Control',
  'SELECT': 'Select...',
  'HYST_0P2': 'Hysteresis (0.2 ºC)',
  'HYST_0P3': 'Hysteresis (0.3 ºC)',
  'HYST_0P4': 'Hysteresis (0.4 ºC)',
  'HYST_0P5': 'Hysteresis (0.5 ºC)',
  'HYST_0P7': 'Hysteresis (0.7 ºC)',
  'HYST_0P8': 'Hysteresis (0.8 ºC)',
  'HYST_1P6': 'Hysteresis (1.6 ºC)',
  'HYST_0P4F': 'Hysteresis (0.4 ºF)',
  'HYST_0P5F': 'Hysteresis (0.5 ºF)',
  'HYST_0P7F': 'Hysteresis (0.7 ºF)',
  'HYST_0P9F': 'Hysteresis (0.9 ºF)',
  'HYST_1P2F': 'Hysteresis (1.2 ºF)',
  'HYST_1P4F': 'Hysteresis (1.4 ºF)',
  'HYST_2P9F': 'Hysteresis (2.9 ºF)',
  'PID_T10MIN': 'PID (10 min. period)',
  'PID_T15MIN': 'PID (15 min. period)',
  'PID_T20MIN': 'PID (20 min. period)',
  'PID_T25MIN': 'PID (25 min. period)',
  'PID_T30MIN': 'PID (30 min. period)',
  'PID_T45MIN': 'PID (45 min. period)',
  'PID_T60MIN': 'PID (60 min. period)',
  'ANTI_GRIPPAGE': 'Pump anti-grippage',
  'CONTRACTED_POWER_W': 'Maximum Power (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Type your maximum power in Watts',
  'PRIORITY': 'Radiator Priority',
  'LOW': 'Low',
  'MEDIUM': 'Medium',
  'HIGH': 'High',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Window mode',
  'DELETE_DEVICES': 'Delete devices',
  'DELETE_DEVICES_WARNING': 'This will delete the selected devices from the system and their associated data',
  'DELETE': 'Delete',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Maximum Radiator Power Allowance',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Radiator Power Limit',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Type the max power for all radiators (W)',
  'LIMITLESS': 'No limit',
  'HOME_INFO': 'Home info',
  'HOME_NAME': 'Home name',
  'TYPE_HOME_NAME': 'Type the name of your home',
  'TIME_ZONE': 'Time zone',
  'TIME_ZONE_CET': '(Central European Time) Berlin, Paris, Madrid, Rome',
  'TIME_ZONE_WET': '(Western European Time) London, Dublin, Lisbon, Canary Islands',
  'TIME_ZONE_EET': '(Eastern European Time) Helsinki, Sofia, Athens',
  'TIME_ZONE_PET': '(Peruvian Time) Lima',
  'TIME_ZONE_NZST': '(New Zealand Standard Time) Auckland',
  'TIME_ZONE_CIST': '(Chatham Island Standard Time) Chatham Islands',
  'TIME_ZONE_CST': '(China Standard Time) Shanghai',
  'TIME_ZONE_UYT': '(Uruguay Time) Montevideo',
  'DEVICE_IDENTIFIER': 'Device Id code',
  'TYPE_DEVICE_IDENTIFIER': 'Type your device Id code',
  'INVALID_IDENTIFIER': 'The provided device Id code is not valid',
  'DEVICE_ALREADY_TAKEN': 'The device with ID DEVID is already registered. Please contact technical support to report this issue.',
  'COPY_ERROR_DATA': '  Copy device ID',
  'MY_HOMES': 'My homes',
  'ADD_HOME': 'Add new home',
  'SAVE': 'Save',
  'MY_INFO': 'My info',
  'USER': 'User',
  'LANGUAGE': 'Language',
  'CHANGE_EMAIL_ADDRESS': 'Change email address',
  'NEW_EMAIL_ADDRESS': 'New email address',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Type your new email address',
  'CONFIRM_WITH_PASSWORD': 'Confirm with password',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Type your current password',
  'CHANGE_PASSWORD': 'Change password',
  'CURRENT_PASSWORD': 'Current password',
  'TYPE_YOUR_NEW_PASSWORD': 'Type your new password',
  'NEW_PASSWORD': 'New password',
  'TEMPERATURE_UNITS': 'Temperature measurements',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Self-Learning',
  'SELF_LEARN_SHORT': 'Self-Learn',
  'PRESENCE': 'Presence',
  'AWAY_OFFSET': 'Away offset',
  'ERASE_DATA_PERMANENTLY': 'Erase data permanently',
  'NO_CONNECTION_WARNING': 'No connection. Access to records only',
  'SET_MODE': 'Set mode',
  'AT_HOME': 'At home',
  'AWAY': 'Away',
  'DEVICES_SUMMARY': 'Devices summary',
  'THERMOSTATS': 'Thermostats',
  'HEATERS': 'Heaters',
  'POWER_METERS': 'Power meters',
  'CLOSE': 'Close',
  'DETECT_SMARTBOX': 'Detect SmartBox',
  'DETECT_SMARTBOX_HELP_MSG': 'In order to find your SmartBox, press the button located at the front side of the device. Please make sure that it is correctly connected to your WIFI network.',
  'DETECT_SMARTBOX_ERROR_MSG': 'We are sorry that the SmartBox could not be found. Please click on next and input manually the device identifier',
  'CONTINUE': 'Next',
  'CANCEL_SEARCH': 'Cancel search',
  'SEARCHING_NEW_DEVICES': 'Searching new devices ...',
  'DISCOVERY_MODE_MSG': 'SmartBox {{devId}} in discovery mode. Press the corresponding device button to install it according to your manual',
  'DEVICE_FOUND_MSG': 'A new device has been found!',
  'GETTING_STARTED': 'Getting started',
  'MAIN_MENU': 'Main menu',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Click icon to Access account setup options',
  'NEXT': 'Next',
  'GEOLOCATION': 'Geolocation',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Away will reduce by default 2ºC',
  'DEVICES': 'Devices',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Your devices are divided in sections: Heaters or Power Meter.',
  'GO_TO_A_DEVICE': 'Go to a device',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Click on the device to setup and view its records',
  'MULTIDEVICE_ACTIONS': 'Multi-device actions',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Click on devices and select more to enter multiple option settings',
  'READY': 'Ready!',
  'SKIP': 'Skip',
  'SKIP_HELP': 'Skip help',
  'QUICK_SETTING': 'Quick setting',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Slide the temperature bar to quickly change the temperature',
  'TEMPERATURES': 'Temperatures',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Set the temperatures for the available modes',
  'MODE': 'Mode',
  'WORKING_MODES': 'Modes',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Select one of the available modes',
  'SELECT_MODE': 'Select mode',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Select the mode for each time slot',
  'SUPPORT_BODY': 'Your account has a minimum support period for security updates of 3 years from the date of product purchase. In the event that you become aware of any security or other issues, please send us an email to the address below describing the issue.',
  'SUPPORT_REQUEST_INTRO_HELP': 'On the left bottom corner there is a help button to display some tips for using the app.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'If you would like to ask for support, please use the following contact details:',
  'ADDRESS': 'Address',
  'TELEPHONE': 'Telephone',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'By registering, I accept the <a href=\'{{frontendHost}}/en/terms.html\' target=\'_blank\'>Terms and Conditions</a> and <a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\'>Privacy Policy</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\'>Privacy Policy</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Your password has been successfully updated',
  'PASSWORD_CHANGE_ERROR_MSG': 'Your password could not be updated. Please check if your current password is correct.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Invalid email',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Email already taken',
  'EMAIL_CHANGE_ERROR_MSG': 'It was not possible to update your email.Please try again later.',
  'HOME_TYPE': 'Home type',
  'ETHERNET': 'Cable connection',
  'WIFI': 'WiFi connection',
  'DEVICE_REGISTER_ERROR': 'The register process could not be completed',
  'WRONG_CREDENTIALS_ERROR': 'Bad user/password',
  'DEVICE_ADDED_MSG': 'New device added',
  'CONNECTION_WARNING': 'WiFi connection',
  'WIFI_CONNECTION_1': 'Open phone settings',
  'WIFI_CONNECTION_2': 'Select WiFi',
  'WIFI_CONNECTION_3': 'Connect your phone to WiFi',
  'WIFI_CONNECTION_4': 'If you see a notification stating this: \'WiFi has no Internet access\', select \'Yes\' to stay connected',
  'WIFI_CONNECTION_5': 'Come back here',
  'DEVICE_CONNECTION_ERROR': 'Could not connect to the device',
  'WIFI_SCAN_ERROR': 'No networks found',
  'WRONG_PASS_ERROR': 'Currently configured WiFi has wrong password',
  'WRONG_ESSID_ERROR': 'Currently configured WiFi does not exist',
  'SET_WIFI_ERROR': 'Could not establish WiFi network',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Connection succesful',
  'WIFI_SELECTION': 'Select your WiFi network',
  'WIFI_NOT_FOUND': 'Can\'t find my network',
  'TYPE_AP_ESSID': 'Type your network\'s name',
  'TYPE_AP_PASS': 'Type your network\'s password',
  'RETRY': 'Retry',
  'AP_DATA': 'Network data',
  'AP_ESSID': 'Name',
  'AP_PASS': 'Password',
  'EDIT_WIFI': 'Edit WiFi configuration',
  'WRONG_WIFI_ERROR': 'You are not connected to the corresponding WiFi',
  'COPY_PASS': 'Copy password',
  'EDIT_DATA': 'Home data',
  'WEB_WIFI_WARNING': 'WiFi configuration is only available in our app',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Add up to 9 temperature slots',
  'ACCEPT': 'Accept',
  'INVITE_USERS': 'Invite users',
  'INVITE_USER': 'Invite user',
  'INVITED_USERS': 'Invited users',
  'INVITE_USER_TITLE': 'Invite user',
  'INVITE_USER_SUCCESS_MSG': 'New user invited. An email has been sent to the guest to let them know that they have access to this home',
  'INVITE_USER_ERROR_MSG': 'Error inviting user. Please try again later.',
  'REVOKE_INVITE_TITLE': 'Remove invited user',
  'REVOKE_INVITE_MSG': 'The selected user will not have access to this home anymore',
  'REVOKE_INVITE_SUCCESS_MSG': 'Invited user has been removed',
  'REVOKE_INVITE_ERROR_MSG': 'Error removing invited user.Please try again later.',
  'CONFIRM_INVITE_SUCCESS': 'Your account has been created successfully',
  'GUEST': 'Guest',
  'TRV': 'TRV',
  'TRVS': 'TRVs',
  'INSTALL_TRV_STEP1': 'Install TRV: Step 1',
  'INSTALL_TRV_HELP_STEP1': 'Press <strong>START SEARCH</strong> button to start TRV installation.',
  'INSTALL_TRV_STEP2': 'Install TRV: Step 2',
  'INSTALL_TRV_HELP_STEP2': 'In order to link the TRV, <strong>PRESS</strong> the <strong>helki</strong> button (middle button) for <strong>3 seconds</strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Heat/Cool mode change',
  'CONTROL_MODE_CHANGE_MSG': 'Warning: the current schedule will be reset',
  'SAVE_THIS_POSITION': 'Save this position',
  'SET_POSITION_MSG': 'You have not positioned your home yet. Please select the position in the map and save it by clicking the button below.',
  'ENABLE_GEOLOCATION': 'Enable geolocation',
  'OUTDOOR_RANGE': 'Home radius (m.)',
  'DEV_TYPE_WIFI': 'WiFi SmartBox',
  'DEV_TYPE_WIFIHTR': 'WiFi Heater',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Select device',
  'SELECT_DEVICE_THM_HELP': 'The thermostat will be assigned to the following device',
  'SELECT_DEVICE_HTR_HELP': 'The heater will be assigned to the following device',
  'SELECT_DEVICE_ACM_HELP': 'The accumulator will be assigned to the following device',
  'SELECT_DEVICE_PMO_HELP': 'The power meter will be assigned to the following device',
  'SELECT_DEVICE_TRV_HELP': 'The valve will be assigned to the following device',
  'ADD_DEVICE': 'Add device',
  'DEVICE_INSTALLATION': 'Device installation',
  'WIFI_HEATER': 'WiFi Heater',
  'WIFI_HEATER_HAVERLAND': 'Serie Connect',
  'LETS_START': 'Let\'s start',
  'INSTALL_WIFI_HEATER_HELP': 'Connect your radiator to the socket. Press the OK button on your radiator until the screen flashes.',
  'INSTALL_WIFI_HEATER2_HELP': 'Connect your radiator to the socket. Press the buttons shown on the following image until the screen flashes.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Plug in your WiFi SmartBox. If it was already plugged in, please unplug it and plug it in again.',
  'DEV_TYPE_WIFI_CONNECTION': 'WiFi SmartBox connection',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'WiFi Heater connection',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'We\'re now going to connect the SmartBox to your home\'s WiFi network.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'We\'re now going to connect the heater to your home\'s WiFi network.',
  'GHZ_WIFI_WARNING': 'Warning: only 2.4GHz networks supported',
  'CONFIGURING_WIFI_HTR': 'Configuring WiFi Heater',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'AP mode WiFi SmartBox configuration',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'AP mode WiFi Heater configuration',
  'SET_ETHERNET_SMARTBOX_NAME': 'What do we call this SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'What do we call this WiFi SmartBox?',
  'SET_WIFI_HTR_NAME': 'What do we call this WiFi Heater?',
  'TYPE_DEVICE_NAME': 'Type device name',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Connecting to WiFi SmartBox',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Connecting to WiFi Heater',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Connected to WiFi SmartBox with id:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Connected to WiFi Heater with id:',
  'MY_DEVICES': 'My devices',
  'DEVICE_TYPE': 'How does your device look like?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Connecting to WiFi SmartBox',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Connecting to WiFi Heater',
  'CONFIGURING_DEV_TYPE_WIFI': 'Configuring WiFi SmartBox',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'Configuring WiFi Heater',
  'DEVICE_LIST': 'My devices',
  'CONNECTION_STATE': 'Connection state',
  'CONFIGURE_WIFI': 'Configure WiFi',
  'CHANGE_HOME': 'Move to another home',
  'DELETE_DEVICE': 'Delete device',
  'TRANSFER_HOME_TITLE': 'Move to another home',
  'TRANSFER_HOME_WARNING': 'Warning: your device will be transferred to the selected home',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Delete device',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Are you sure you want to delete this device?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Please connect your ethernet SmartBox',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'WiFi SmartBox installation success',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'Ethernet SmartBox installation success',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'WiFi Heater installation success',
  'HOME_REGISTRY': 'Home registry',
  'HOME_REGISTRY_HELP': 'Right now you don\'t have any home registered. Let\'s add a new one.',
  'SET_HOME_NAME': 'Set home name',
  'SET_HOME_TIMEZONE': 'Set home timezone',
  'DEVICE_CONNECTION_START': 'Now we\'re going to connect your device',
  'WIFI_DEVICE': 'WiFi device',
  'WIFI_DEVICE_HELP': 'Installation of WiFi devices is only available on our apps',
  'TYPE_ESSID': 'Type your WiFi network',
  'REGISTERING_DEVICE': 'Registering device',
  'WELCOME': 'Welcome',
  'ESSID': 'Select WiFi network',
  'SET_BOOST_MODE': 'Set Boost mode',
  'BOOST_END_TIME': 'Boost end time',
  'BOOST_ACTIVE_UNTIL': 'Boost active until',
  'OPERATIONAL_MODE': 'Operational mode',
  'FIXED_TEMPERATURE': 'Fixed temperature',
  'WEEKLY_SCHEDULE': 'Weekly schedule',
  'ANTIFROST_PROTECTION': 'Anti-frost protection',
  'ID_COPIED': 'Identifier copied',
  'NEW_PROFILE': 'New profile',
  'PROFILE_COLOR': 'Colour',
  'TYPE_PROFILE_NAME': 'Introduce a profile name',
  'TYPE_POWER_LIMIT': 'Introduce a power value',
  'POWER_LIMIT': 'Power (W)',
  'EDIT_PROFILES': 'Profiles',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Delete profile',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Are you sure you want to remove this power profile?',
  'POWER_MANAGEMENT': 'Maximum power term configuration',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Must be between ',
  'AND': ' and ',
  'MANUAL_NETWORK_INPUT': 'Enter manually',
  'GEOLOCATION_INFO': 'This feature allows you to decrease the temperature of the devices when you are out home radius',
  'GEOLOCATION_NEW_FEAT': 'Discover the functionality that allows you to save on your devices consumption when you are not at home.',
  'VOICE_CONTROL': 'Voice assistant',
  'VOICE_CONTROL_NEW_FEAT': 'Remember that this device is compatible with: ',
  'CONFIGURE': 'Configure',
  'MORE_INFO_LONG': 'More information',
  'GEOLOCATION_RATIONALE': 'For the proper operation of the geolocation mode, this app needs access to your location all the time, even when you are not using the phone. Allow in settings.',
  'USER_DUTY_FACTOR': 'Power limit factor',
  'CIRCUIT_TYPE': 'Circuit type',
  'PRINCIPAL_CIRCUIT': 'Main circuit',
  'REVERSE_SIGN': 'Reverse sign',
  'SMARTBOX_CONFIGURATION': 'Smartbox Configuration',
  'MAIN_CIRCUIT_GENERATION': 'Main generation circuit',
  'MAIN_CIRCUIT_CONSUMPTION': 'Main consumption circuit',
  'SELECT_GENERATION_POWERMETER': 'Select a generation powermeter',
  'SELECT_CONSUMPTION_POWERMETER': 'Select a consumption powermeter',
  'POWERMETER_CONFIG': 'Powermeter Configuration',
  'MAX_POWER_TERM_MANAGEMENT': 'Maximum power term',
  'MANAGE_MAX_POWER': 'Schedule',
  'MANAGE_ENERGY_TARIFFS': 'Schedule',
  'COLOR': 'Colour',
  'CONSUMPTION': 'Consumption',
  'GENERATION': 'Generation',
  'EDIT_ENERGY_TARIFFS': 'Profiles',
  'SELECT_MAX_POWER_CONFIG': 'Select a power profile',
  'CUSTOM_TARIFF_CONFIG': 'New custom profile',
  'ANTI_FROST_IN_OFF': 'Frost Protection',
  'POWER_LEVEL_LIMIT': 'Power Level Limit',
  'E2_USAGE_ENABLED': 'Solar Energy',
  'E2_MODE': 'Charge Settings',
  'E2_ACTIVATION_PER': 'Solar Power Activation',
  'E2_CHARGE_EXCESS_PER': 'Off-Peak Maximum Charge',
  'NODE_E2_MODE_0': 'Solar Alone',
  'NODE_E2_MODE_1': 'Solar and Off-Peak',
  'NODE_E2_MODE_2': 'Solar and Grid adjustment',
  'E2_TEMP_EXCESS': 'Ambiente Temperature Offset',
  'E2_PRIORITY': 'Charge Priority',
  'MAIN_CIRCUIT': 'Main',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Device updated successfully',
  'DEVICE_UPDATE_ERROR_MSG': 'The device could not be updated.Please try again later.',
  'SYSTEM_MAINTENANCE': 'System under maintenance',
  'SYSTEM_MAINTENANCE_MSG': 'Sorry for the inconvenience, we are working to improve the service.',
  'SOLD_SEPARATELY': '(Sold separately)',
  'REMOVE_ACCOUNT': 'Remove account',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Remove account',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'Account removal involves deleting all nodes and data associated to it. If your are sure about deleting the account, enter your password to confirm.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Account successfully removed.',
  'ACCOUNT_REMOVAL_ERROR': 'Could not remove account.',
  'USE_QR': 'Scan WiFi QR',
  'USE_MANUAL_AP': 'Enter WiFi credentials',
  'LOADING': 'Loading',
  'SIGN_IN_WITH_GOOGLE': 'Sign in with Google',
  'LOCK': 'Lock',
  'UNLOCK': 'Unlock',
  'BRIGHT_LEVEL': 'Display brightness level',
  'BACKLIGHT_TIME': 'Display backlight time',
  'BACKLIGHT_TIME_10': '10 seconds',
  'BACKLIGHT_TIME_20': '20 seconds',
  'BACKLIGHT_TIME_30': '30 seconds',
  'BACKLIGHT_TIME_ALWAYS': 'Always',
  'DELETE_ACCOUNT_MSG': 'By deleting the user account you will be deleting all data associated with it permanently. If you agree, please follow the steps below.',
  'DELETE_ACCOUNT_STEPS': 'Steps to delete your account',
  'DELETE_ACCOUNT_STEP1': '1. Login to the app with your username and password.',
  'DELETE_ACCOUNT_STEP2': '2. Go to the top Menu.',
  'DELETE_ACCOUNT_STEP3': '3. Select My Account.',
  'DELETE_ACCOUNT_STEP4': '4. In the Delete Account section, click on Delete Account button.',
  'DEPRECATION_MESSAGE': 'Please use TARGET app'
 },
 'fr': {
  'ASCII_ERROR': 'Les noms de réseau WiFi et les mots de passe doivent contenir uniquement des caractères ASCII.',
  'QR_SCANN_ERROR': 'Les noms de réseau WiFi et les mots de passe de plus de 32 caractères ne sont pas pris en charge.',
  'QR_SCANN_ERROR_TITLE': 'Il y a eu un problème avec les informations d\'identification introduites',
  'EXTRA_PRESS_ON_PROVISIONING': 'Appuyez sur n’importe quel bouton pour activer l’affichage',
  'BT_ACTIVATION_WARNING': 'Assurez-vous que le Bluetooth est activé sur votre téléphone mobile',
  'DEVICE_DISCONNECTED_STEP_1': 'Ouvrir le menu principal',
  'DEVICE_DISCONNECTED_STEP_2': 'Accédez à Maisons et sélectionnez votre maison',
  'DEVICE_DISCONNECTED_STEP_3': 'Choisir un appareil',
  'DEVICE_DISCONNECTED_STEP_4': 'Configurer le Wi-Fi',
  'DEVICE_DISCONNECTED_HELP_1': 'Le réseau WiFi a une connexion Internet et est un réseau 2,4 GHz.',
  'DEVICE_DISCONNECTED_HELP_2': 'Considérez les zones avec une couverture WiFi appropriée lors de l\'installation du dispositif.',
  'DEVICE_DISCONNECTED_HELP_3': 'Vérifiez et reconfigurez les identifiants Wi-Fi de votre appareil directement depuis <a class="alink" href=\'{{link}}\'>ici</a> ou suivez cette séquence:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'Le dispositif est déjà enregistré mais il n\'est pas correctement connecté. Essayez de débrancher votre dispositif et de le reconnecter. Vérifiez s\'il est connecté à votre réseau WiFi. Sinon, veuillez prendre en compte les points suivants:',
  'DEVICE_DISCONNECTED_HEADER': 'Il y a eu un problème dans le processus d\'approvisionnement, l\'appareil pourrait être perdu.',
  'DEVICE_INSTALLATION_ERROR': 'Il y a eu un problème lors de l\'installation du périphérique.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Vérifiez l\'état des LED du périphérique. Sélectionnez l\'un des états pour plus d\'informations afin de résoudre le problème :',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'Le périphérique n\'a pas d\'IP associée.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'Le périphérique n\'est pas connecté au serveur.',
  'DEVICE_INSTALLATION_ERROR_OK': 'Le périphérique est correctement connecté.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Assurez-vous que le périphérique est correctement connecté au WiFi via le câble Ethernet.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Répétez le processus d\'installation.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Vérifiez la connexion Internet du routeur et assurez-vous qu\'il n\'y a pas de restrictions de port.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Fermez et rouvrez l\'application pour vérifier si le périphérique a été correctement installé.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Vérifiez l\'état de la LED du périphérique. Sélectionnez l\'un des états pour plus d\'informations afin de résoudre le problème :',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'Le réseau WiFi n\'a pas été configuré.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'Le périphérique n\'est pas connecté au routeur.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Vérifiez l\'état du périphérique en regardant l\'état de l\'icône de l\'antenne. Sélectionnez l\'un des états pour plus d\'informations afin de résoudre le problème :',
  'SERVER_CONNECTION_ERROR': 'L\'application n\'arrive pas à se connecter au serveur. Vérifiez la connexion Internet de votre SmartPhone et réessayez le processus',
  'AP_FALLBACK_TITLE': 'Redémarrons le processus en utilisant la méthode Access Point',
  'HANDSHAKE_WARNING': 'Veuillez vous assurer que votre smartphone est maintenant connecté au même réseau WiFi.',
  'GEOLOCATION_RANGE': 'Radio de logement',
  'PROVISIONING_METHOD_HELP': 'Sélectionnez une méthode de provisionnement pour connecter votre appareil',
  'RECOMMENDED': 'Recommandé',
  'BACK': 'Retour',
  'HELP': 'Aide',
  'SUPPORT': 'Support technique',
  'MY_ACCOUNT': 'Mon compte',
  'HOMES': 'Logements',
  'PRIVACY': 'Confidentialité',
  'VERSION': 'Version',
  'PRIVACY_INFO': 'Vous pouvez voir ici les informations personnelles que nous avons sur vous. Vous pouvez exercer vos droits à l\'adresse e-mail de contact qui apparaît ci-dessous',
  'HOME_GEOLOCATION': 'Géolocalisation de la maison',
  'LATITUDE': 'Latitude',
  'LONGITUDE': 'Longitude',
  'CONTACT_EMAIL': 'Email du contact',
  'PP_POLICY_CHANGE_WARNING': 'La politique de confidentialité et les conditions générales ont changé. Votre accord est nécessaire pour continuer',
  'PP_READ_AND_ACCEPTED': 'J\'ai lu et accepte la <a href=\'{{frontendHost}}/fr/privacy.html\' target=\'_blank\' class=\'alink\'>Politique de Confidentialité</a> et les <a href=\'{{frontendHost}}/fr/terms.html\' target=\'_blank\' class=\'alink\'>Conditions générales</a>',
  'ACCEPTED': 'Accepté',
  'SIGN_OUT': 'Déconnexion',
  'SIGN_IN': 'Connexion',
  'SIGN_UP': 'Créer un compte',
  'EMAIL': 'Adresse mail',
  'PASSWORD': 'Mot de passe',
  'ALL_FIELDS_REQUIRED': 'Merci de remplir tous les champs du formulaire',
  'REMEMBER_ME': 'Se souvenir de moi',
  'NEW_ON_HELKI': 'Créer un nouveau compte !',
  'I_FORGOT_MY_PASSWORD': 'Mot de passe oublié',
  'TYPE_EMAIL': 'Indiquez votre adresse mail',
  'TYPE_PASSWORD': 'Indiquez votre mot de passe',
  'CONFIRM_PASSWORD': 'Confirmez votre mot de passe',
  'INVALID_EMAIL': 'Adresse mail incorrecte',
  'SIGN_IN_ERROR': 'Adresse mail ou mot de passe incorrects',
  'INVALID_PASSWORD': 'Mot de passe incorrect',
  'PASSWORDS_DO_NOT_MATCH': 'Les mots de passe ne coïncident pas',
  'USER_CONFLICT_ERROR': 'Un compte est déjà associé à cette adresse mail',
  'SIGN_UP_SUCCESS': 'Bravo, vous venez de créer votre compte. Nous vous avons envoyé un mail pour confirmer votre adresse',
  'FORGOT_PASS_MESSAGE': 'Pas de problème, il suffit de nous indiquer l’adresse mail avec laquelle vous avez créé votre compte.',
  'RESET_PASSWORD': 'Réinitialiser votre mot de passe',
  'USER_NOT_FOUND_ERROR': 'Utilisateur non trouvé',
  'FORGOT_PASS_SUCCESS': 'Nous vous avons envoyé un mail pour réinitialiser votre mot de passe',
  'SIGN_UP_VERIFY_SUCCESS': 'Votre compte a été validé correctement',
  'SIGN_UP_VERIFY_ERROR': 'Erreur. Nous n’avons pas pu valider votre compte. Veuillez prendre contact avec nous.',
  'CHANGE_EMAIL_SUCCESS': 'Votre email a été mis à jour avec succès',
  'CHANGE_EMAIL_ERROR': 'Votre e-mail ne peut pas être mis à jour. S´il vous plaît contacter',
  'RESET_PASS_SUCCESS': 'Votre mot de passe a été réinitialisé correctement',
  'RESET_PASS_ERROR': 'Erreur. Votre mot de passe n’a pas pu être réinitialisé. Veuillez prendre contact avec nous',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Changement email',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Nous avons envoyé un email à la nouvelle adresse',
  'CONNECTION_ERROR_MSG': 'Impossible de se connecter au serveur',
  'TRY_AGAIN': 'Réessayer',
  'NOW': 'Température actuelle',
  'HEATING': 'Chauffage',
  'ELECTRICITY': 'Electricité',
  'WEATHER': 'Météo',
  'TEMPERATURE': 'Température',
  'TURN_OFF': 'Eteindre',
  'TURN_ON': 'Allumer',
  'SCHEDULE': 'Programmer',
  'SETUP': 'Configurer',
  'SET_TEMPERATURE': 'Choisir température',
  'SET_COMFORT_TEMPERATURE': 'Choisir température de confort',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Attention! Si vous modifiez la température de confort, la température Eco sera automatiquement reprogrammée.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Veuillez uniquement sélectionner le même système de radiateurs (les appareils de chauffage ne peuvent pas être regroupés s\'ils sont sélectionnés avec des Appareils de Chauffage avec Géolocalisation)',
  'REMOVE': 'Retirer',
  'CANCEL': 'Annuler',
  'CONFIRM': 'Confirmer',
  'RECORDS': 'Consommations',
  'SELECT_A_DAY': 'Choisir un jour',
  'DAY1_SHORT': 'Lu',
  'DAY2_SHORT': 'Ma',
  'DAY3_SHORT': 'Me',
  'DAY4_SHORT': 'Je',
  'DAY5_SHORT': 'Ve',
  'DAY6_SHORT': 'Sa',
  'DAY7_SHORT': 'Di',
  'MONDAY': 'Lundi',
  'TUESDAY': 'Mardi',
  'WEDNESDAY': 'Mercredi',
  'THURSDAY': 'Jeudi',
  'FRIDAY': 'Vendredi',
  'SATURDAY': 'Samedi',
  'SUNDAY': 'Dimanche',
  'MONDAY_SHORT': 'Lun',
  'TUESDAY_SHORT': 'Mar',
  'WEDNESDAY_SHORT': 'Mer',
  'THURSDAY_SHORT': 'Jeu',
  'FRIDAY_SHORT': 'Ven',
  'SATURDAY_SHORT': 'Sam',
  'SUNDAY_SHORT': 'Dim',
  'COPY_DAY': 'Copier programmation hebdomadaire',
  'ADD': 'Ajouter',
  'FROM': 'De',
  'TO': 'a',
  'SELECT_A_DAY_MSG': 'Choisissez le jour que vous désirez programmer.',
  'EDIT': 'Editer',
  'START': 'Début',
  'END': 'Fin',
  'DONE': 'Valider',
  'COPY_DAYS': 'Copier programmation hebdomadaire',
  'USE_SAME_SCHEDULE_ON': 'Copier cette programmation pour les jours...',
  'COPY': 'Copier',
  'POWER_METER': 'Compteur',
  'USAGE': 'Consommation',
  'USAGE_VALLEY': 'Consommation de charge',
  'USAGE_PEAK': 'Consommation sans charge',
  'REAL_TIME_USAGE': 'Consommation instantanée',
  'DAILY': 'Par jour',
  'MONTHLY': 'Par mois',
  'ANNUAL': 'Par an',
  'JAN': 'Jan',
  'FEB': 'Fev',
  'MAR': 'Mar',
  'APR': 'Avr',
  'MAY': 'Mai',
  'JUN': 'Jui',
  'JUL': 'Juil',
  'AUG': 'Aou',
  'SEP': 'Sep',
  'OCT': 'Oct',
  'NOV': 'Nov',
  'DEC': 'Dec',
  'POWER': 'Puissance',
  'NOMINAL_POWER': 'Puissance nominale',
  'EFFECTIVE_POWER': 'Puissance effective',
  'ZERO_POWER_WARNING': 'Ce chauffage n\'a pas de puissance assignée. Configurez-le ici',
  'SET_MODE_TEMP': 'Déf. Temp.',
  'SET_MODE_TEMPERATURE': 'Définir la température de chaque mode de fonctionnement',
  'COMFORT': 'Confort',
  'ECO': 'Eco',
  'ANTI_ICE': 'Hors gel',
  'INSTALL': 'Installer',
  'INSTALL_ERROR_MSG': 'Erreur dans le processus d\'installation. Réessayer!',
  'MORE': 'Plus',
  'INSTALL_DEVICE': 'Associer un nouveau dispositif',
  'SELECT_ALL': 'Sél. tout',
  'DESELECT_ALL': 'Désél. tout',
  'THERMOSTAT': 'Thermostat',
  'HEATER': 'Radiateur',
  'HEATER_HAVERLAND': 'Radiateur avec géolocalisation',
  'HEATER_WITH_SMARTBOX': 'Radiateur avec Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Sèche-serviettes avec Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'Radiateur WiFi avec 4 boutons',
  'WIFI_HEATER_W2BUTTONS': 'Radiateur WiFi avec 2 boutons',
  'INSTALL_THERMOSTAT_STEP1': 'Associer un nouveau thermostat: Étape 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Appuyez sur le bouton <strong>RECHERCHER</strong> pour démarrer l\'installation du thermostat',
  'INSTALL_THERMOSTAT_STEP2': 'Associer un nouveau thermostat: Étape 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Pour associer un nouveau thermostat, appuyez sur la touche <strong>Standby</strong> (la touche gauche) et la touche <strong>Link</strong> (la bonne touche) pendant <strong>3 secondes</strong> jusqu’à ce qu’apparaisse le symbole de réseau en haut à gauche de votre écran (voir image).',
  'START_SEARCH': 'Rechercher',
  'SEARCHING': 'Recherche en cours...',
  'INSTALL_HEATER_STEP1': 'Installer un nouveau radiateur: Étape 1',
  'INSTALL_HEATER_HELP_STEP1': 'Appuyez sur le bouton <strong>RECHERCHER</strong> pour démarrer l\'installation du radiateur.',
  'INSTALL_HEATER_STEP2': 'Installer un nouveau radiateur: Étape 2',
  'INSTALL_HEATER_HELP_STEP2': 'Pour associer un nouveau radiateur, <strong>APPUYEZ</strong> sur la touche <strong>MODE/OK</strong> de votre radiateur pendant <strong>3 secondes</strong> jusqu’à ce qu’apparaisse le symbole de réseau en haut à droite de votre écran.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Veillez á ce que le radiateur est en \'Standby\' et appuyez les touches haut et bas de la température pendant 3 secondes',
  'STORAGE_HEATER': 'Radiateur à accumulation',
  'STORAGE_HEATERS': 'Radiateurs à accumulation',
  'INSTALL_STORAGE_HEATER_STEP1': 'Installer un nouveau radiateur à accumulation: Étape 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Appuyez sur le bouton <strong>RECHERCHER</strong> pour démarrer l\'installation du radiateur à accumulation.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Installer un nouveau radiateur à accumulation: Étape 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Pour associer un nouveau radiateur à accumulation, <strong>APPUYEZ</strong> sur la touche <strong>OK</strong> de votre radiateur à accumulation pendant <strong>3 secondes</strong> jusqu’à ce qu’apparaisse le symbole de réseau en haut à droite de votre écran.',
  'CHARGE_PROGRAM': 'Programme de charge',
  'VALLEY_1': 'Période 1',
  'VALLEY_2': 'Période 2',
  'INSTALL_POWER_METER_STEP1': 'Associer compteur électrique: Étape 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Appuyez sur le bouton <strong>RECHERCHER</strong> pour démarrer l\'installation du compteur électrique.',
  'INSTALL_POWER_METER_STEP2': 'Associer compteur électrique: Étape 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Pour associer le compteur, APPUYEZ avec un clip sur le petit bouton sur le dessus de l´appareil (voir photo). Vous pouvez vérifier qu´il est associé en observant le compteur LED.',
  'LED_INDICATOR_STATES': 'États LED',
  'UNLINKED': 'Unassociated',
  'LED_OFF': 'LED éteinte',
  'CORRECTLY_LINKED': 'Associé correctement',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'La LED clignote une fois toutes les deux secondes et demi',
  'LINKED_BUT_LOST': 'Associate mais a perdu',
  'LED_BLINKS_EVERY_HALF_SEC': 'La LED clignote une fois toutes les demi-seconde. Cela se produit lorsque la SmartBox est déconnecté, par exemple. Une fois que la communication avec la SmartBox soit restitué, le compteur se connectera automatiquement.',
  'NAME': 'Nom',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Choisissez un nom pour identifier le nouveau dispositif',
  'TYPE': 'Type',
  'HEAT': 'Chaleur',
  'COLD': 'Froid',
  'CONTROL': 'Régulation',
  'SELECT': 'Sélectionnez...',
  'HYST_0P2': 'Hystéresis (0,2 ºC)',
  'HYST_0P3': 'Hystéresis (0,3 ºC)',
  'HYST_0P4': 'Hystéresis (0,4 ºC)',
  'HYST_0P5': 'Hystéresis (0,5 ºC)',
  'HYST_0P7': 'Hystéresis (0,7 ºC)',
  'HYST_0P8': 'Hystéresis (0,8 ºC)',
  'HYST_1P6': 'Hystéresis (1,6 ºC)',
  'HYST_0P4F': 'Hystéresis (0,4 ºF)',
  'HYST_0P5F': 'Hystéresis (0,5 ºF)',
  'HYST_0P7F': 'Hystéresis (0,7 ºF)',
  'HYST_0P9F': 'Hystéresis (0,9 ºF)',
  'HYST_1P2F': 'Hystéresis (1,2 ºF)',
  'HYST_1P4F': 'Hystéresis (1,4 ºF)',
  'HYST_2P9F': 'Hystéresis (2,9 ºF)',
  'PID_T10MIN': 'PID (période 10 min.)',
  'PID_T15MIN': 'PID (période 15 min.)',
  'PID_T20MIN': 'PID (période 20 min.)',
  'PID_T25MIN': 'PID (période 25 min.)',
  'PID_T30MIN': 'PID (période 30 min.)',
  'PID_T45MIN': 'PID (période 45 min.)',
  'PID_T60MIN': 'PID (période 60 min.)',
  'ANTI_GRIPPAGE': 'Anti-agarrotamiento de bomba',
  'CONTRACTED_POWER_W': 'Puissance souscrite (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Indiquez la puissance souscrite auprès de votre fournisseur d’électricité (en Watt)',
  'PRIORITY': 'Priorité',
  'LOW': 'Basse',
  'MEDIUM': 'Moyenne',
  'HIGH': 'Haute',
  'TRUE_RADIANT': 'Contrôle Adaptatif de l’activation',
  'WINDOW_MODE': 'Mode fenêtre',
  'DELETE_DEVICES': 'Effacer dispositif',
  'DELETE_DEVICES_WARNING': 'Les dispositifs sélectionnés seront effacés du système, ainsi que les informations associées',
  'DELETE': 'Effacer',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Puissance maximale destinée au chauffage',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Puissance maximale que vous désirez donner aux radiateurs (W)',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Indiquez la puissance maximale (W)',
  'LIMITLESS': 'Sans limite de puissance',
  'HOME_INFO': 'Informations concernant le logement',
  'HOME_NAME': 'Nom du logement',
  'TYPE_HOME_NAME': 'Indiquez le nom du logement',
  'TIME_ZONE': 'Fuseau horaire',
  'TIME_ZONE_CET': 'Berlin, Paris, Madrid, Rome',
  'TIME_ZONE_WET': 'Londres, Dublin, Lisbonne, Iles Canaries',
  'TIME_ZONE_EET': 'Helsinki, Sofia, Athènes',
  'TIME_ZONE_PET': 'Lima',
  'TIME_ZONE_NZST': 'Auckland',
  'TIME_ZONE_CIST': 'Chatham Islands',
  'TIME_ZONE_CST': 'Shanghai',
  'TIME_ZONE_UYT': 'Montevideo',
  'DEVICE_IDENTIFIER': 'Identificateur de dispositif',
  'TYPE_DEVICE_IDENTIFIER': 'Indiquez l’identificateur de votre dispositif',
  'INVALID_IDENTIFIER': 'L’identificateur indiqué n’est pas valide',
  'DEVICE_ALREADY_TAKEN': 'L\'appareil avec ID DEVID est déjà enregistré. Veuillez contacter le support technique pour signaler ce problème.',
  'COPY_ERROR_DATA': '  Copier appareil ID',
  'MY_HOMES': 'Mes logements',
  'ADD_HOME': 'Ajouter un logement',
  'SAVE': 'Sauvegarder',
  'MY_INFO': 'Mes informations',
  'USER': 'Identifiant',
  'LANGUAGE': 'Langue',
  'CHANGE_EMAIL_ADDRESS': 'Changer adresse mail',
  'NEW_EMAIL_ADDRESS': 'Nouvelle adresse mail',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Introduisez votre nouvelle adresse mail',
  'CONFIRM_WITH_PASSWORD': 'Confirmer avec mot de passe',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Introduisez votre mot de passe actuel',
  'CHANGE_PASSWORD': 'Modifiez votre mot de passe',
  'CURRENT_PASSWORD': 'Mot de passe actuel',
  'TYPE_YOUR_NEW_PASSWORD': 'Introduisez votre nouveau mot de passe',
  'NEW_PASSWORD': 'Nouveau mot de passe',
  'TEMPERATURE_UNITS': 'Unité de température',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Auto apprendre',
  'SELF_LEARN_SHORT': 'AutoAppren.',
  'PRESENCE': 'Présence',
  'AWAY_OFFSET': 'Baisse de température pendant absence',
  'ERASE_DATA_PERMANENTLY': 'Effacer informations de manière permanente',
  'NO_CONNECTION_WARNING': 'Sans connexion, accès uniquement à l’historique',
  'SET_MODE': 'Etablir mode',
  'AT_HOME': 'A la maison',
  'AWAY': 'Absence',
  'DEVICES_SUMMARY': 'Résumé des dispositifs',
  'THERMOSTATS': 'Thermostats',
  'HEATERS': 'Radiateurs',
  'POWER_METERS': 'Compteurs',
  'CLOSE': 'Fermer',
  'DETECT_SMARTBOX': 'Associer SmartBox',
  'DETECT_SMARTBOX_HELP_MSG': 'Pour associer la SmartBox à votre compte appuyez sur la touche située derrière la SmartBox. Vérifiez que la SmartBox est correctement connectée à votre réseau WiFi.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Nous sommes désolés, mais nous n’avons pas trouvé votre SmartBox. Veuillez s’il vous plait cliquer sur le lien suivant pour introduire manuellement l’identificateur du dispositif.',
  'CONTINUE': 'Continuer',
  'CANCEL_SEARCH': 'Annuler la recherche',
  'SEARCHING_NEW_DEVICES': 'Recherche de nouveaux dispositifs en cours ...',
  'DISCOVERY_MODE_MSG': 'SmartBox {{devId}} en mode synchronisation. Appuyez sur la touche de votre dispositif tel qu’indiqué dans la notice d’instructions',
  'DEVICE_FOUND_MSG': 'Nous avons trouvé un nouveau dispositif !',
  'GETTING_STARTED': 'Aide pour la première navigation',
  'MAIN_MENU': 'Menu principal',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Accédez facilement aux options générales de configuration',
  'NEXT': 'Suivant',
  'GEOLOCATION': 'Géolocalisation',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Absence réduira par défaut 2ºC',
  'DEVICES': 'Dispositifs',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Les dispositifs sont divisés en sections chauffage et électricité',
  'GO_TO_A_DEVICE': 'Accédez au radiateur',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Configurez le radiateur et accédez à son historique.',
  'MULTIDEVICE_ACTIONS': 'Actions multiples',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Sélectionnez tous les radiateurs et accédez aux options avec le bouton PLUS',
  'READY': 'OK',
  'SKIP': 'Sauter',
  'SKIP_HELP': 'Sauter l’aide',
  'QUICK_SETTING': 'Configuration rapide',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Glissez votre souris latéralement sur la barre de température pour changer rapidement la température de consigne.',
  'TEMPERATURES': 'Températures',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Définir les températures de chaque mode disponible.',
  'MODE': 'Mode',
  'WORKING_MODES': 'Modes',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Sélectionnez un des trois modes disponibles : Confort, Eco ou Hors Gel.',
  'SELECT_MODE': 'Appliquez le mode choisi',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Indiquez les heures d’application de ce mode.',
  'SUPPORT_BODY': 'Votre compte dispose d\'une période d\'assistance minimale de 3 ans à compter de la date d\'achat du produit. Si vous avez connaissance d\'un problème de sécurité ou autre, veuillez nous envoyer un e-mail à l\'adresse ci-dessous en décrivant le problème.',
  'SUPPORT_REQUEST_INTRO_HELP': 'Cliquez sur “Aide” en bas à gauche de votre écran pour obtenir des explications sur l’utilisation de l’application.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'En cas de doute technique, vous pouvez nous contacter à l’adresse suivante :',
  'ADDRESS': 'Adresse',
  'TELEPHONE': 'Téléphone',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'J’accepte les <a href=\'{{frontendHost}}/fr/terms.html\' target=\'_blank\'>Conditions générales</a> et la <a href=\'{{frontendHost}}/fr/privacy.html\' target=\'_blank\'>Politique de Confidentialité</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/fr/privacy.html\' target=\'_blank\'>Politique de Confidentialité</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Votre mot de passe a été modifié avec succès',
  'PASSWORD_CHANGE_ERROR_MSG': 'Votre mot de passe n´a pas pu être misà jour. Veuillez vérifier si votre mot depasse actuel est correct.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Adresse mail non valide',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Adresse mail déjà utilisée',
  'EMAIL_CHANGE_ERROR_MSG': 'Une erreur s’est produite,merci de réessayer plus tard.',
  'HOME_TYPE': 'Version Box',
  'ETHERNET': 'Connexion par câble Ethernet',
  'WIFI': 'Connexion par WiFi',
  'DEVICE_REGISTER_ERROR': 'Une erreur s’est produite lors de l’enregistrement',
  'WRONG_CREDENTIALS_ERROR': 'Identifiant ou mot de passe incorrect',
  'DEVICE_ADDED_MSG': 'Vous avez ajouté un nouveau dispositif',
  'CONNECTION_WARNING': 'Connexion WiFi',
  'WIFI_CONNECTION_1': 'Ouvrez les paramètres de votre téléphone',
  'WIFI_CONNECTION_2': 'Sélectionnez WiFi',
  'WIFI_CONNECTION_3': 'Connectez-vous au réseau WiFi',
  'WIFI_CONNECTION_4': 'Si un message “WiFi sans accès à internet” ou similaire apparaît, indiquez que vous souhaitez maintenir la connexion',
  'WIFI_CONNECTION_5': 'Retournez à cet écran',
  'DEVICE_CONNECTION_ERROR': 'Impossible de se connecter à l\'appareil',
  'WIFI_SCAN_ERROR': 'Aucun réseau trouvé',
  'WRONG_PASS_ERROR': 'Le mot de passe du réseau WiFi configuré est incorrect',
  'WRONG_ESSID_ERROR': 'Le réseau WiFi configuré n’existe pas',
  'SET_WIFI_ERROR': 'Impossible d’établir le réseau WiFi',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Connexion établie avec succès',
  'WIFI_SELECTION': 'Sélectionnez votre réseau WiFi',
  'WIFI_NOT_FOUND': 'Je te trouve pas mon réseau',
  'TYPE_AP_ESSID': 'Indiquez le nom de votre réseau',
  'TYPE_AP_PASS': 'Indiquez le mot de passe de votre réseau',
  'RETRY': 'ESSAYER DE NOUVEAU',
  'AP_DATA': 'Paramètres du réseau',
  'AP_ESSID': 'Nom',
  'AP_PASS': 'Mot de passe',
  'EDIT_WIFI': 'Editer configuration WiFi',
  'WRONG_WIFI_ERROR': 'Vous n’êtes pas connecté au WiFi correspondant',
  'COPY_PASS': 'Copier mot de passe',
  'EDIT_DATA': 'Données du logement',
  'WEB_WIFI_WARNING': 'L’option WiFi n’est disponible que sur notre app',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Ajoutez jusqu’à 9 blocs de température',
  'ACCEPT': 'Accepter',
  'INVITE_USERS': 'Inviter utilisateurs',
  'INVITE_USER': 'Inviter utilisateur',
  'INVITED_USERS': 'Utilisateurs invités',
  'INVITE_USER_TITLE': 'Inviter utilisateur',
  'INVITE_USER_SUCCESS_MSG': 'Nouvel utilisateur invité. Nous vous avons envoyé un mail pour vous confirmer l’accès à ce logement',
  'INVITE_USER_ERROR_MSG': 'Une erreur s’est produite.Merci de réessayer plus tard.',
  'REVOKE_INVITE_TITLE': 'Supprimer invité',
  'REVOKE_INVITE_MSG': 'L’utilisateur sélectionné n’aura plus accès à ce logement',
  'REVOKE_INVITE_SUCCESS_MSG': 'Invité supprimé',
  'REVOKE_INVITE_ERROR_MSG': 'Une erreur s’est produite.Merci de réessayer plus tard.',
  'CONFIRM_INVITE_SUCCESS': 'Compte créé avec succès',
  'GUEST': 'Invité',
  'TRV': 'Vanne thermostatique',
  'TRVS': 'Vannes thermostatiques',
  'INSTALL_TRV_STEP1': 'Installer vanne thermostatique: Étape 1',
  'INSTALL_TRV_HELP_STEP1': 'Appuyez sur le bouton <strong>RECHERCHER</strong> pour démarrer l\'installation du vannes thermostatiques',
  'INSTALL_TRV_STEP2': 'Installer vanne thermostatique: Étape 2',
  'INSTALL_TRV_HELP_STEP2': 'Pour accoupler la vanne thermostatique, appuyer sur la touche  <strong>helki</strong> (la touche centrale) pendant <strong>3 secondes</strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Changement de mode froid/chaud',
  'CONTROL_MODE_CHANGE_MSG': 'Attention, la programmation actuelle se restaurera',
  'SAVE_THIS_POSITION': 'Garder cette position',
  'SET_POSITION_MSG': 'La position de votre logement n’est pas encore établie. Sélectionnez la position de votre logement sur la carte et cliquez sur la touche du bas pour valider.',
  'ENABLE_GEOLOCATION': 'Activer géolocalisation',
  'OUTDOOR_RANGE': 'Rayon du logement (m.)',
  'DEV_TYPE_WIFI': 'SmartBox WiFi',
  'DEV_TYPE_WIFIHTR': 'Radiateur WiFi',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Sélection de l\'appareil',
  'SELECT_DEVICE_THM_HELP': 'Le thermostat sera associé à l\'appareil sélectionné',
  'SELECT_DEVICE_HTR_HELP': 'Le radiateur sera associé à l\'appareil sélectionné',
  'SELECT_DEVICE_ACM_HELP': 'L\'accumulateur sera associé à l\'appareil sélectionné',
  'SELECT_DEVICE_PMO_HELP': 'Le wattmètre sera associé au périphérique sélectionné',
  'SELECT_DEVICE_TRV_HELP': 'La valve sera associée à l\'appareil sélectionné',
  'ADD_DEVICE': 'Ajouter un appareil',
  'DEVICE_INSTALLATION': 'Installation de l\'appareil',
  'WIFI_HEATER': 'Radiadeur WiFi',
  'WIFI_HEATER_HAVERLAND': 'Connect Series',
  'LETS_START': 'Commençons',
  'INSTALL_WIFI_HEATER_HELP': 'Connectez votre radiateur à la prise. Appuyez sur le bouton OK de votre radiateur jusqu\'à ce que l\'écran clignote.',
  'INSTALL_WIFI_HEATER2_HELP': 'Connectez votre radiateur à la prise. Appuyez sur les boutons indiqués sur l\'image suivante jusqu\'à ce que l\'écran clignote.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Connectez votre SmartBox WiFi. S\'il était déjà connecté, déconnectez-le et reconnectez-le.',
  'DEV_TYPE_WIFI_CONNECTION': 'Connexion de SmartBox WiFi',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'Connexion de Radiateur WiFi',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Maintenant, nous allons connecter la SmartBox à votre réseau Wi-Fi domestique.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Maintenant, nous allons connecter le Radiateur à votre WiFi domestique.',
  'GHZ_WIFI_WARNING': 'Attention: seuls les réseaux 2,4 GHz sont valides',
  'CONFIGURING_WIFI_HTR': 'Configuration du Radiateur WiFi',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'Configuration SmartBox WiFi en mode AP',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'Configuration Radiateur WiFi en mode AP',
  'SET_ETHERNET_SMARTBOX_NAME': 'Comment appelons-nous cette SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Comment appelons-nous cette SmartBox WiFi?',
  'SET_WIFI_HTR_NAME': 'Comment appelons-nous cette Radiateur WiFi?',
  'TYPE_DEVICE_NAME': 'Entrez un nom pour votre appareil',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Connexion à la SmartBox WiFi',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Connexion au Radiateur WiFi',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Connecté à la SmartBox WiFi avec l\'id:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Connecté au Radiateur WiFi avec l\'id:',
  'MY_DEVICES': 'Mes appareils',
  'DEVICE_TYPE': 'À quoi ressemble votre appareil?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Connexion à la SmartBox WiFi',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Connexion au Radiateur WiFi',
  'CONFIGURING_DEV_TYPE_WIFI': 'Configuration de SmartBox WiFi',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'Configuration de Radiateur WiFi',
  'DEVICE_LIST': 'Appareils',
  'CONNECTION_STATE': 'Statut de connexion',
  'CONFIGURE_WIFI': 'Configurer le WiFi',
  'CHANGE_HOME': 'Déménager dans une autre maison',
  'DELETE_DEVICE': 'Supprimer l\'appareil',
  'TRANSFER_HOME_TITLE': 'Déménager dans une autre maison',
  'TRANSFER_HOME_WARNING': 'Attention: votre appareil sera transféré à la maison sélectionnée',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Supprimer l\'appareil',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Êtes-vous sûr de vouloir supprimer ce périphérique?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Veuillez connecter votre SmartBox Ethernet',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox WiFi installé avec succès',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox Ethernet installé avec succès',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'Radiateur WiFi installé avec succès',
  'HOME_REGISTRY': 'Enregistrement de la maison',
  'HOME_REGISTRY_HELP': 'Pour le moment, vous n’avez pas de maison enregistrée. Ajoutons un nouveau.',
  'SET_HOME_NAME': 'Définir le nom de la maison',
  'SET_HOME_TIMEZONE': 'Définir le fuseau horaire de la maison',
  'DEVICE_CONNECTION_START': 'Maintenant, nous allons connecter votre appareil',
  'WIFI_DEVICE': 'Appareil WiFi',
  'WIFI_DEVICE_HELP': 'L\'installation d\'appareils WiFi n\'est disponible que sur nos applications',
  'TYPE_ESSID': 'Tapez votre réseau WiFi',
  'REGISTERING_DEVICE': 'Enregistrement d\'un appareil',
  'WELCOME': 'Bienvenue',
  'ESSID': 'Sélectionnez le réseau WiFi',
  'SET_BOOST_MODE': 'Définir le mode Boost',
  'BOOST_END_TIME': 'Heure de fin du mode Boost',
  'BOOST_ACTIVE_UNTIL': 'Boost actif jusqu\'au',
  'OPERATIONAL_MODE': 'Mode opérationnel',
  'FIXED_TEMPERATURE': 'Température fixe',
  'WEEKLY_SCHEDULE': 'Programmation hebdomadaire',
  'ANTIFROST_PROTECTION': 'Protection antigel',
  'ID_COPIED': 'Identifiant copié',
  'NEW_PROFILE': 'Nouveaux Profils',
  'PROFILE_COLOR': 'Couleur',
  'TYPE_PROFILE_NAME': 'Entrez un nom pour le profil',
  'TYPE_POWER_LIMIT': 'Entrez une valeur de puissance',
  'POWER_LIMIT': 'Puissance (W)',
  'EDIT_PROFILES': 'Profils',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Supprimer le profil',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Êtes-vous sûr de vouloir supprimer ce profil?',
  'POWER_MANAGEMENT': 'Puissance contractée configuration',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Doit être entre ',
  'AND': ' et ',
  'MANUAL_NETWORK_INPUT': 'Entrer manuellement',
  'GEOLOCATION_INFO': 'Cette fonction vous permet de baisser la température des appareils lorsque vous êtes en dehors du rayon de votre maison.',
  'GEOLOCATION_NEW_FEAT': 'Découvrez la fonctionnalité qui vous permet d\'économiser sur la consommation de vos appareils lorsque vous n\'êtes pas chez vous.',
  'VOICE_CONTROL': 'Assistant vocal',
  'VOICE_CONTROL_NEW_FEAT': 'Rappelez-vous que cet appareil est compatible avec: ',
  'CONFIGURE': 'Configurer',
  'MORE_INFO': 'Plus d\'informations',
  'GEOLOCATION_RATIONALE': 'Pour le bon fonctionnement du mode de géolocalisation, cette application doit accéder à votre emplacement en permanence, événement lorsque vous n\'utilisez pas le téléphone. Autoriser dans les paramètres.',
  'USER_DUTY_FACTOR': 'Facteur de limite de puissance',
  'CIRCUIT_TYPE': 'Type de circuit',
  'PRINCIPAL_CIRCUIT': 'Circuit Principal',
  'REVERSE_SIGN': 'Signe inversé',
  'SMARTBOX_CONFIGURATION': 'Configuration du SmartBox',
  'MAIN_CIRCUIT_GENERATION': 'Circuit principal de Génération',
  'MAIN_CIRCUIT_CONSUMPTION': 'Circuito principal de Consommation',
  'SELECT_GENERATION_POWERMETER': 'Sélectionnez un compteur de Génération',
  'SELECT_CONSUMPTION_POWERMETER': 'Sélectionnez un compteur de Consommation',
  'POWERMETER_CONFIG': 'Configuration du Compteur',
  'MAX_POWER_TERM_MANAGEMENT': 'Puissance contractée',
  'MANAGE_MAX_POWER': 'Programmer',
  'MANAGE_ENERGY_TARIFFS': 'Programmer',
  'COLOR': 'Couleur',
  'CONSUMPTION': 'Consommation',
  'GENERATION': 'Génération',
  'EDIT_ENERGY_TARIFFS': 'Profils',
  'SELECT_MAX_POWER_CONFIG': 'Sélectionnez un profil de puissance contractée',
  'CUSTOM_TARIFF_CONFIG': 'Nouveau profil personnalisé',
  'ANTI_FROST_IN_OFF': 'Protection anti-gel',
  'POWER_LEVEL_LIMIT': 'Niveau Limite Puissance',
  'E2_USAGE_ENABLED': 'Energi Solaire',
  'E2_MODE': 'Type de Charge',
  'E2_ACTIVATION_PER': 'Puissance Solaire Activation',
  'E2_CHARGE_EXCESS_PER': 'Charge Maximale Creuses',
  'NODE_E2_MODE_0': 'Exclusif Solaire',
  'NODE_E2_MODE_1': 'Solaire et Creuses',
  'NODE_E2_MODE_2': 'Solaire et Creuses Corrigé',
  'E2_TEMP_EXCESS': 'Margen Tª Ambiante',
  'E2_PRIORITY': 'Priorité de Charge',
  'MAIN_CIRCUIT': 'Principal',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Appareil mis à jour avec succèss',
  'DEVICE_UPDATE_ERROR_MSG': 'L\'appareil n\'a pas pu être mis à jour.Veuillez réessayer plus tard.',
  'SYSTEM_MAINTENANCE': 'Système en maintenance',
  'SYSTEM_MAINTENANCE_MSG': 'Désolé pour le dérangement, nous travaillons pour améliorer le service.',
  'SOLD_SEPARATELY': '(Vendu séparément)',
  'REMOVE_ACCOUNT': 'Supprimer le compte',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Supprimer le compte',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'La suppression d\'un compte implique la suppression de tous les nœuds et données qui lui sont associés. Si vous êtes sûr de supprimer le compte, entrez votre mot de passe pour confirmer.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Compte supprimé avec succès',
  'ACCOUNT_REMOVAL_ERROR': 'Impossible de supprimer le compte.',
  'USE_QR': 'Numérisez le code QR Wi-Fi',
  'USE_MANUAL_AP': 'Entrez les informations d\'identification Wi-Fi',
  'LOADING': 'Chargement',
  'SIGN_IN_WITH_GOOGLE': 'Se connecter avec Google',
  'LOCK': 'Verrouiller',
  'UNLOCK': 'Déverrouiller',
  'BRIGHT_LEVEL': 'Niveau de luminosité de l\'écran',
  'BACKLIGHT_TIME': 'Durée d\'allumage de l\'écran',
  'BACKLIGHT_TIME_10': '10 secondes',
  'BACKLIGHT_TIME_20': '20 secondes',
  'BACKLIGHT_TIME_30': '30 secondes',
  'BACKLIGHT_TIME_ALWAYS': 'Toujours',
  'DELETE_ACCOUNT_MSG': 'En supprimant le compte d\'utilisateur, vous supprimez définitivement toutes les données qui y sont associées. Si vous êtes d\'accord, veuillez suivre les étapes ci-dessous.',
  'DELETE_ACCOUNT_STEPS': 'Étapes pour supprimer votre compte',
  'DELETE_ACCOUNT_STEP1': '1. Connectez-vous à l\'application avec votre nom d\'utilisateur et votre mot de passe.',
  'DELETE_ACCOUNT_STEP2': '2. Allez dans le menu supérieur.',
  'DELETE_ACCOUNT_STEP3': '3. Sélectionnez Mon compte.',
  'DELETE_ACCOUNT_STEP4': '4. Dans la section Supprimer le compte, cliquez sur le bouton Supprimer le compte.',
  'DEPRECATION_MESSAGE': 'Veuillez utiliser l\'application TARGET'
 },
 'it': {
  'ASCII_ERROR': 'I nomi e le password della rete WiFi devono contenere solo caratteri ASCII.',
  'QR_SCANN_ERROR': 'I nomi e le password delle reti WiFi con più di 32 caratteri non sono supportati.',
  'QR_SCANN_ERROR_TITLE': 'Si è verificato un problema con le credenziali introdotte',
  'EXTRA_PRESS_ON_PROVISIONING': 'Premere qualsiasi pulsante per attivare il display',
  'BT_ACTIVATION_WARNING': 'Assicurati di avere il Bluetooth abilitato sul tuo cellulare',
  'DEVICE_DISCONNECTED_STEP_1': 'Apri il menu principale',
  'DEVICE_DISCONNECTED_STEP_2': 'Vai a Case e seleziona la tua casa',
  'DEVICE_DISCONNECTED_STEP_3': 'Seleziona dispositivo',
  'DEVICE_DISCONNECTED_STEP_4': 'Configura Wi-Fi',
  'DEVICE_DISCONNECTED_HELP_1': 'La rete Wi-Fi dispone di connessione a Internet ed è una rete a 2,4 GHz.',
  'DEVICE_DISCONNECTED_HELP_2': 'Considerare le aree con un\'adeguata copertura WiFi durante l\'installazione del dispositivo.',
  'DEVICE_DISCONNECTED_HELP_3': 'Controlla e riconfigura le credenziali WiFi del tuo dispositivo direttamente da <a class="alink" href=\'{{link}}\'>Qui< oppure segui questa sequenza:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'Il dispositivo è già registrato ma non è collegato correttamente. Prova a scollegare il dispositivo dalla presa e a ricollegarlo. Verifica se è connesso alla tua rete WiFi. In caso contrario, considerare i seguenti punti:',
  'DEVICE_DISCONNECTED_HEADER': 'Si è verificato un problema nel processo di provisioning, il dispositivo potrebbe essere perso.',
  'DEVICE_INSTALLATION_ERROR': 'C\'è stato un problema durante l\'installazione del dispositivo.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Verifica lo stato dei LED del dispositivo. Seleziona uno degli stati per ulteriori informazioni per aiutarti a risolvere il problema:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'Il dispositivo non ha un IP associato.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'Il dispositivo non è connesso al server.',
  'DEVICE_INSTALLATION_ERROR_OK': 'Il dispositivo è correttamente connesso.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Assicurati che il dispositivo sia correttamente collegato al WiFi tramite il cavo Ethernet.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Ripeti il processo di installazione.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Verifica la connessione internet del router e assicurati che non ci siano restrizioni di porta.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Chiudi e riapri l\'applicazione per verificare se il dispositivo è stato installato correttamente.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Verifica lo stato del LED del dispositivo. Seleziona uno degli stati per ulteriori informazioni per aiutarti a risolvere il problema:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'La rete WiFi non è stata configurata.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'Il dispositivo non è connesso al router.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Verifica lo stato del dispositivo guardando lo stato dell\'icona dell\'antenna. Seleziona uno degli stati per ulteriori informazioni per aiutarti a risolvere il problema:',
  'SERVER_CONNECTION_ERROR': 'L\'applicazione non è in grado di connettersi al server. Controlla la connessione Internet del tuo smartphone e riprova il processo',
  'AP_FALLBACK_TITLE': 'Riavviamo il processo utilizzando il metodo Access Point',
  'HANDSHAKE_WARNING': 'Per favore, assicurati che il tuo smartphone sia ora connesso alla stessa rete WiFi.',
  'GEOLOCATION_RANGE': 'Radio abitativa',
  'PROVISIONING_METHOD_HELP': 'Seleziona un metodo di provisioning per connettere il tuo dispositivo',
  'RECOMMENDED': 'Consigliato',
  'BACK': 'Back',
  'HELP': 'Help',
  'SUPPORT': 'Supporto',
  'MY_ACCOUNT': 'Mio account',
  'HOMES': 'Case',
  'PRIVACY': 'Privacy',
  'VERSION': 'Versione',
  'PRIVACY_INFO': 'Qui puoi vedere le informazioni personali che abbiamo su di te. Puoi esercitare i tuoi diritti all\'indirizzo di contatto che appare sotto',
  'HOME_GEOLOCATION': 'Geolocalizzazione di casa',
  'LATITUDE': 'Latitudine',
  'LONGITUDE': 'Longitudine',
  'CONTACT_EMAIL': 'Email di contatto',
  'PP_POLICY_CHANGE_WARNING': 'La politica sulla privacy e i termini e condizioni di utilizzo sono cambiati. Il tuo accordo è necessario per continuare',
  'PP_READ_AND_ACCEPTED': 'Ho letto e accetto l\'informativa sulla <a href=\'{{frontendHost}}/it/privacy.html\' target=\'_blank\' class=\'alink\'>Privacy</a> e i <a href=\'{{frontendHost}}/it/terms.html\' target=\'_blank\' class=\'alink\'>Termini e condizioni di utilizzo</a>',
  'ACCEPTED': 'Accettato',
  'SIGN_OUT': 'Esci',
  'SIGN_IN': 'Entra',
  'SIGN_UP': 'Registrati',
  'EMAIL': 'Email',
  'PASSWORD': 'Password',
  'ALL_FIELDS_REQUIRED': 'Compilare tutti i campi',
  'REMEMBER_ME': 'Ricordati di me',
  'NEW_ON_HELKI': 'Nuovo utente, registrati qui!',
  'I_FORGOT_MY_PASSWORD': 'Ho dimenticato la password',
  'TYPE_EMAIL': 'Digita la tua email',
  'TYPE_PASSWORD': 'Digita la tua password',
  'CONFIRM_PASSWORD': 'Conferma Password',
  'INVALID_EMAIL': 'Email non valida',
  'SIGN_IN_ERROR': 'Email o Password non corretta',
  'INVALID_PASSWORD': 'Password non valida',
  'PASSWORDS_DO_NOT_MATCH': 'Passwords non combaciano',
  'USER_CONFLICT_ERROR': 'Email già in uso',
  'SIGN_UP_SUCCESS': 'Registrazione avvenuta con successo! Ti abbiamo inviato una Email per convalidare l’account',
  'FORGOT_PASS_MESSAGE': 'Non ti preoccupare, basta fornire l´email di registrazione in modo da poter avviare il processo di reimpostazione della password',
  'RESET_PASSWORD': 'Reset password',
  'FORGOT_PASS_SUCCESS': 'Ti abbiamo mandato una Email per resettare la tua password',
  'USER_NOT_FOUND_ERROR': 'Utente non trovato',
  'SIGN_UP_VERIFY_SUCCESS': 'Il tuo account è stato verificato con successo',
  'SIGN_UP_VERIFY_ERROR': 'Il tuo account non può essere verificato. Per favore, contattaci',
  'CHANGE_EMAIL_SUCCESS': 'Il tuo indirizzo email è stato aggiornato con successo',
  'CHANGE_EMAIL_ERROR': 'Il tuo indirizzo email non può essere aggiornato. Si prega di contattare',
  'RESET_PASS_SUCCESS': 'La tua password è stata resettata con successo',
  'RESET_PASS_ERROR': 'La tua password non può essere resettata. Per favore contattaci',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Modifica email',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Abbiamo inviato una email al nuovo account per completare la modifica',
  'CONNECTION_ERROR_MSG': 'Impossibile connettersi al server',
  'TRY_AGAIN': 'Riprovare',
  'NOW': 'Ora',
  'HEATING': 'Riscaldamento',
  'ELECTRICITY': 'Energia',
  'WEATHER': 'Tempo',
  'TEMPERATURE': 'Temperatura',
  'TURN_OFF': 'Spegnimento',
  'TURN_ON': 'Accensione',
  'SCHEDULE': 'Programma',
  'SETUP': 'Setup',
  'SET_TEMPERATURE': 'Set temperatura',
  'SET_COMFORT_TEMPERATURE': 'Set temperatura di comfort',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Attenzione! Se la Temperatura Confort cambia, la Temperatura Eco si ripristinerà automaticamente.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Si prega di selezionare solo lo stesso sistema di radiatori (i Termosifoni non possono essere selezionati in gruppi con Termosifoni con Geolocalizzazione)',
  'REMOVE': 'Rimuovere',
  'CANCEL': 'Cancella',
  'CONFIRM': 'Conferma',
  'RECORDS': 'Records',
  'SELECT_A_DAY': 'Seleziona il giorno',
  'DAY1_SHORT': 'Lu',
  'DAY2_SHORT': 'Ma',
  'DAY3_SHORT': 'Me',
  'DAY4_SHORT': 'Gi',
  'DAY5_SHORT': 'Ve',
  'DAY6_SHORT': 'Sa',
  'DAY7_SHORT': 'Do',
  'MONDAY': 'Lunedì',
  'TUESDAY': 'Martedì',
  'WEDNESDAY': 'Mercoledì',
  'THURSDAY': 'Giovedì',
  'FRIDAY': 'Venerdì',
  'SATURDAY': 'Sabato',
  'SUNDAY': 'Domenica',
  'MONDAY_SHORT': 'Lun',
  'TUESDAY_SHORT': 'Mar',
  'WEDNESDAY_SHORT': 'Mer',
  'THURSDAY_SHORT': 'Gio',
  'FRIDAY_SHORT': 'Ven',
  'SATURDAY_SHORT': 'Sab',
  'SUNDAY_SHORT': 'Dom',
  'COPY_DAY': 'Copia giorno',
  'ADD': 'Aggiungi nuovo',
  'FROM': 'Da',
  'TO': 'a',
  'SELECT_A_DAY_MSG': 'Selezione il giorno in cui definire o modificare la programmazione',
  'EDIT': 'Modifica',
  'START': 'Inizio',
  'END': 'Fine',
  'DONE': 'Fatto',
  'COPY_DAYS': 'Copia giorni',
  'USE_SAME_SCHEDULE_ON': 'Usa stessa programmazione su…',
  'COPY': 'Copia',
  'POWER_METER': 'Power Meter',
  'USAGE': 'Uso',
  'USAGE_VALLEY': 'Consumo di carico',
  'USAGE_PEAK': 'Consumo senza carico',
  'REAL_TIME_USAGE': 'Tempo di utilizzo',
  'DAILY': 'Quotidiano',
  'MONTHLY': 'Mensile',
  'ANNUAL': 'Annuale',
  'JAN': 'Gen',
  'FEB': 'Feb',
  'MAR': 'Mar',
  'APR': 'Apr',
  'MAY': 'Mag',
  'JUN': 'Giu',
  'JUL': 'Lug',
  'AUG': 'Aug',
  'SEP': 'Set',
  'OCT': 'Ott',
  'NOV': 'Nov',
  'DEC': 'Dic',
  'POWER': 'Energia',
  'NOMINAL_POWER': 'Energia nominale',
  'EFFECTIVE_POWER': 'Energia effettiva',
  'ZERO_POWER_WARNING': 'Questo riscaldatore non ha potenza assegnata. Impostalo qui',
  'SET_MODE_TEMP': 'Set Temp.',
  'SET_MODE_TEMPERATURE': 'Set temperature',
  'COMFORT': 'Comfort',
  'ECO': 'Eco',
  'ANTI_ICE': 'Antigelo',
  'INSTALL': 'Installa',
  'INSTALL_ERROR_MSG': 'Processo di installazione non riuscito. Riprova',
  'MORE': 'Opzioni',
  'INSTALL_DEVICE': 'Installa dispositivo',
  'SELECT_ALL': 'Sel. tutto',
  'DESELECT_ALL': 'Desel. tutto',
  'THERMOSTAT': 'Termostato',
  'HEATER': 'Riscaldatore',
  'HEATER_HAVERLAND': 'Riscaldatore con geolocalizzazione',
  'HEATER_WITH_SMARTBOX': 'Riscaldatore con Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Scaldasalviette con Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'Riscaldatore WiFi con 4 pulsanti',
  'WIFI_HEATER_W2BUTTONS': 'Riscaldatore WiFi con 2 pulsanti',
  'INSTALL_THERMOSTAT_STEP1': 'Installa termostato: Passo 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Premere il pulsante di <strong>INIZIO RICERCA</strong> per avviare l\'installazione del termostato.',
  'INSTALL_THERMOSTAT_STEP2': 'Installa termostato: Passo 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Al fine di collegare il termostato, premere contemporaneamente i tasti <strong>accensione</strong>(tasto a sinistra)e <strong>link</strong>  per <strong>3 secondi</strong>, fino a quando l´icona della rete compare nell´angolo in alto a destra del termostato (come mostrato nell´immagine sopra).',
  'START_SEARCH': 'Inizio ricerca',
  'SEARCHING': 'Ricerca...',
  'INSTALL_HEATER_STEP1': 'Installa riscaldatore: Passo 1',
  'INSTALL_HEATER_HELP_STEP1': 'Premere il pulsante di <strong>INIZIO RICERCA</strong> per avviare l\'installazione del riscaldatore.',
  'INSTALL_HEATER_STEP2': 'Installa riscaldatore: Passo 2',
  'INSTALL_HEATER_HELP_STEP2': 'Al fine di collegare il riscaldatore elettrico, <strong>PREMERE</strong> il tasto <strong>MODE/OK</strong> del dispositivo per <strong>3 secondi</strong> fino a quando l´icona della rete compare nell´angolo in alto a destra del riscaldatore (come mostrato nell´immagine sopra)',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Assicurati che il radiatore sia in modalità Standby e tieni premuti entrambi i pulsanti di aumento e diminuzione della temperatura per almeno 3 secondi.',
  'STORAGE_HEATER': 'Riscaldatore ad accumulo',
  'STORAGE_HEATERS': 'Riscaldatori ad accumulo',
  'INSTALL_STORAGE_HEATER_STEP1': 'Installa riscaldatore ad accumulo: Passo 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Premere il pulsante di <strong>INIZIO RICERCA</strong> per avviare l\'installazione del riscaldatore ad accumulo.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Installa riscaldatore ad accumulo: Passo 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Al fine di collegare il riscaldatore ad accumulo, <strong>PREMERE</strong> il tasto <strong>OK</strong> del dispositivo per <strong>3 secondi</strong> fino a quando l´icona della rete compare nell´angolo in alto a destra del termostato (come mostrato nell´immagine sopra)',
  'CHARGE_PROGRAM': 'Programma di ricarica',
  'VALLEY_1': 'Periodo 1',
  'VALLEY_2': 'Periodo 2',
  'INSTALL_POWER_METER_STEP1': 'Installa Power meter: Passo 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Premere il pulsante di <strong>INIZIO RICERCA</strong> per avviare l\'installazione del power meter.',
  'INSTALL_POWER_METER_STEP2': 'Installa Power meter: Passo 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Al fine di collegare il Power meter, usare la clip (già disponibile) e PREMERE il piccolo bottone posizionato in cima al dispositivo (guarda immagine sopra). È possibile verificare che c’è connessione, osservando lo stato del LED che si trova sul dispositivo.',
  'LED_INDICATOR_STATES': 'Stato dell’indicatore LED',
  'UNLINKED': 'Non connesso',
  'LED_OFF': 'LED off',
  'CORRECTLY_LINKED': 'Correttamente connesso',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'LED lampeggia ogni 2 secondi e mezzo.',
  'LINKED_BUT_LOST': 'Collegato ma sconnesso',
  'LED_BLINKS_EVERY_HALF_SEC': 'LED lampeggia ogni mezzo secondo. Questo può accadere per esempio quando il gateway è disalimentato. Una volta che il misuratore di potenza e il gateway potranno ristabilire una comunicazione, saranno collegati automaticamente.',
  'NAME': 'Nome',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Selezionare un nome per individuare il dispositivo',
  'TYPE': 'Tipo',
  'HEAT': 'Riscaldamento',
  'COLD': 'Raffrescamento',
  'CONTROL': 'Controllo',
  'SELECT': 'Seleziona...',
  'HYST_0P2': 'Isteresi (0,2 ºC)',
  'HYST_0P3': 'Isteresi (0,3 ºC)',
  'HYST_0P4': 'Isteresi (0,4 ºC)',
  'HYST_0P5': 'Isteresi (0,5 ºC)',
  'HYST_0P7': 'Isteresi (0,7 ºC)',
  'HYST_0P8': 'Isteresi (0,8 ºC)',
  'HYST_1P6': 'Isteresi (1,6 ºC)',
  'HYST_0P4F': 'Isteresi (0,4 ºF)',
  'HYST_0P5F': 'Isteresi (0,5 ºF)',
  'HYST_0P7F': 'Isteresi (0,7 ºF)',
  'HYST_0P9F': 'Isteresi (0,9 ºF)',
  'HYST_1P2F': 'Isteresi (1,2 ºF)',
  'HYST_1P4F': 'Isteresi (1,4 ºF)',
  'HYST_2P9F': 'Isteresi (2,9 ºF)',
  'PID_T10MIN': 'PID (10 min. periodo)',
  'PID_T15MIN': 'PID (15 min. periodo)',
  'PID_T20MIN': 'PID (20 min. periodo)',
  'PID_T25MIN': 'PID (25 min. periodo)',
  'PID_T30MIN': 'PID (30 min. periodo)',
  'PID_T45MIN': 'PID (45 min. periodo)',
  'PID_T60MIN': 'PID (60 min. periodo)',
  'ANTI_GRIPPAGE': 'Pompa anti calcare',
  'CONTRACTED_POWER_W': 'Potenza massima (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Digita la potenza massima disponibile in Watt',
  'PRIORITY': 'Priorità radiatori',
  'LOW': 'Basso',
  'MEDIUM': 'Medio',
  'HIGH': 'Alto',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Modalità finestra',
  'DELETE_DEVICES': 'Cancellazione dispositivi',
  'DELETE_DEVICES_WARNING': 'Questo cancellerà il dispositivo dal sistema con tutti i dati associati',
  'DELETE': 'Cancella',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Massima potenza associata ai radiatori elettrici',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Limite Potenza radiatori',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Digita la Potenza massima(W)',
  'LIMITLESS': 'Nessuno limite',
  'HOME_INFO': 'Info CASA',
  'HOME_NAME': 'Nome CASA',
  'TYPE_HOME_NAME': 'Digita il nome della tua casa',
  'TIME_ZONE': 'Time zone',
  'TIME_ZONE_CET': '(Central European Time) Berlino, Parigi, Madrid, Roma',
  'TIME_ZONE_WET': '(Western European Time) Londra, Dublino, Lisbona, Isole Canarie',
  'TIME_ZONE_EET': '(Eastern European Time) Helsinki, Sofia, Atene',
  'TIME_ZONE_PET': '(Peruvian Time) Lima',
  'TIME_ZONE_NZST': '(New Zealand Standard Time) Auckland',
  'TIME_ZONE_CIST': '(Chatham Island Standard Time) Chatham Islands',
  'TIME_ZONE_CST': '(China Standard Time) Shanghai',
  'TIME_ZONE_UYT': '(Uruguay Time) Montevideo',
  'DEVICE_IDENTIFIER': 'ID CODE dispositivo',
  'TYPE_DEVICE_IDENTIFIER': 'Digita l’ID CODE del dispositivo',
  'INVALID_IDENTIFIER': ' Il codice dispositivo ID CODE fornito non è valido ',
  'DEVICE_ALREADY_TAKEN': 'Il dispositivo con ID DEVID è già registrato. Si prega di contattare il supporto tecnico per segnalare questo problema.',
  'COPY_ERROR_DATA': '  Copia l\'id del dispositivo',
  'MY_HOMES': 'Casa mia',
  'ADD_HOME': 'Aggiungi nuova casa',
  'SAVE': 'Salva',
  'MY_INFO': 'Mie info',
  'USER': 'Utente',
  'LANGUAGE': 'Lingua',
  'CHANGE_EMAIL_ADDRESS': 'Cambio indirizzo Email',
  'NEW_EMAIL_ADDRESS': 'Nuovo indirizzo Email',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Digita il nuovo indirizzo Email',
  'CONFIRM_WITH_PASSWORD': 'Conferma con password',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Digita la password corrente',
  'CHANGE_PASSWORD': 'Cambio password',
  'CURRENT_PASSWORD': 'Password corrente',
  'TYPE_YOUR_NEW_PASSWORD': 'Digita la nuova password',
  'NEW_PASSWORD': 'Nuova password',
  'TEMPERATURE_UNITS': 'Unità di misura delle temperature',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Auto-apprendimento',
  'SELF_LEARN_SHORT': 'AutoAppren.',
  'PRESENCE': 'Presenza',
  'AWAY_OFFSET': 'Offset allontanamento',
  'ERASE_DATA_PERMANENTLY': 'Cancellazione permanente dei dati',
  'NO_CONNECTION_WARNING': ' Nessuna connessione. Accesso solo alle registrazioni',
  'SET_MODE': 'Modalità impostate',
  'AT_HOME': 'A casa',
  'AWAY': 'Fuori casa',
  'DEVICES_SUMMARY': 'Sommario dispositivi',
  'THERMOSTATS': 'Termostati',
  'HEATERS': 'Riscaldatori',
  'POWER_METERS': 'Power meters',
  'CLOSE': 'Chiudi',
  'DETECT_SMARTBOX': 'Rileva Gateway',
  'DETECT_SMARTBOX_HELP_MSG': 'Al fine di trovare il tuo Gateway, premi il pulsante situato sul lato superiore del dispositivo. Assicurarsi che sia correttamente connesso alla rete WiFi.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Il Gateway non è stato trovato. Fare clic su Avanti e inserire manualmente il codice identificatore del dispositivo',
  'CONTINUE': 'Avanti',
  'CANCEL_SEARCH': 'Cancella ricerca',
  'SEARCHING_NEW_DEVICES': 'Ricerca nuovi dispositivi...',
  'DISCOVERY_MODE_MSG': 'Gateway {{devId}} in modalità ricerca. Premere il pulsante sul dispositivo che vuoi installare.',
  'DEVICE_FOUND_MSG': 'Nuovo dispositivo individuato!',
  'GETTING_STARTED': 'Per iniziare',
  'MAIN_MENU': 'Menu principale',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Cliccare l´icona opzioni account per accedere alle opzioni di configurazione dell’account',
  'NEXT': 'Avanti',
  'GEOLOCATION': 'Geolocalizzazione',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Fuori casa ridurrà automaticamente di 2ºC',
  'DEVICES': 'Dispositivi',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'I tuoi dispositivi sono divisi in sezioni: Riscaldatori o Power Meter',
  'GO_TO_A_DEVICE': 'Vai al dispositivo',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Clicca il dispositivo per regolare o vedere le sue registrazioni',
  'MULTIDEVICE_ACTIONS': 'Azione su più dispositivi',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Clicca sui dispositivi e seleziona opzioni per modificare più opzioni',
  'READY': 'Pronto!',
  'SKIP': 'Salta',
  'SKIP_HELP': 'Salta help',
  'QUICK_SETTING': 'Configurazione veloce',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Far scorrere la barra di temperatura per cambiare rapidamente la temperatura',
  'TEMPERATURES': 'Temperature',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Impostare le temperature per le modalità disponibili',
  'MODE': 'Modalità',
  'WORKING_MODES': 'Modalità',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Selezionare una delle modalità disponibili',
  'SELECT_MODE': 'Selezionare modalità',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Selezionare la modalità per ciascun intervallo di tempo',
  'SUPPORT_BODY': 'Il tuo account ha un periodo minimo di supporto per gli aggiornamenti di sicurezza di 3 anni dalla data di acquisto del prodotto. Nel caso in cui venissi a conoscenza di problemi di sicurezza o di altro tipo, ti preghiamo di inviarci un\'e-mail all\'indirizzo sottostante descrivendo il problema.',
  'SUPPORT_REQUEST_INTRO_HELP': 'In basso a sinistra c\'è un pulsante di aiuto per visualizzare suggerimenti nell’utilizzare l´applicazione.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Per chiedere supporto, si prega di utilizzare i seguenti recapiti:',
  'ADDRESS': 'Indirizzo',
  'TELEPHONE': 'Telefono',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'Con la registrazione, si accettano i <a href=\'{{frontendHost}}/it/terms.html\' target=\'_blank\'>Termini e condizioni di utilizzo</a> e la <a href=\'{{frontendHost}}/it/privacy.html\' target=\'_blank\'>Politica di Privacy</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/it/privacy.html\' target=\'_blank\'>Politica di Privacy</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'La tua password è stata aggiornata con successo',
  'PASSWORD_CHANGE_ERROR_MSG': 'La tua password non può esseremodificata. Si prega di controllarese la password corrente è corretta.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Indirizzo mail non valido',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Indirizzo mail già registrato',
  'EMAIL_CHANGE_ERROR_MSG': 'Non è stato possibile aggiornarel’indirizzo mail. Si prega di riprovarepiù tardi.',
  'HOME_TYPE': 'Tipologia di abitazione',
  'ETHERNET': 'Connessione via cavo',
  'WIFI': 'connessione WiFi',
  'DEVICE_REGISTER_ERROR': 'La registrazione non può essere completata',
  'WRONG_CREDENTIALS_ERROR': 'Nome utente/password errati',
  'DEVICE_ADDED_MSG': 'Nuovo dispositivo aggiunto',
  'CONNECTION_WARNING': 'Connessione WiFi',
  'WIFI_CONNECTION_1': 'Impostazioni telefono aperte',
  'WIFI_CONNECTION_2': 'Seleziona rete WiFi',
  'WIFI_CONNECTION_3': 'Connetti il telefono alla rete WiFi',
  'WIFI_CONNECTION_4': 'Se visualizzi la seguente notifica: \'La rete WiFi non è connessa a Internet\', seleziona \'Sì\' per rimanere collegato',
  'WIFI_CONNECTION_5': 'Torna qui',
  'DEVICE_CONNECTION_ERROR': 'Impossibile connettersi al dispositivo',
  'WIFI_SCAN_ERROR': 'Nessuna rete trovata',
  'WRONG_PASS_ERROR': 'Il WiFi configurato ha una password errata',
  'WRONG_ESSID_ERROR': 'Il WiFi configurato non esiste',
  'SET_WIFI_ERROR': 'Impossibile stabilire una rete WiFi',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Connessione avvenuta con successo',
  'WIFI_SELECTION': 'Seleziona la tua rete WiFi',
  'WIFI_NOT_FOUND': 'Impossibile trovare la rete',
  'TYPE_AP_ESSID': 'Digita il nome della tua rete',
  'TYPE_AP_PASS': 'Digita la password della tua rete',
  'RETRY': 'Riprova',
  'AP_DATA': 'Dati rete',
  'AP_ESSID': 'Nome',
  'AP_PASS': 'Password',
  'EDIT_WIFI': 'Modifica configurazione WiFi',
  'WRONG_WIFI_ERROR': 'Non sei collegato alla rete WiFi corrispondente',
  'COPY_PASS': 'Copia password',
  'EDIT_DATA': 'Dati dell’abitazione',
  'WEB_WIFI_WARNING': 'La configurazione WiFi è disponibile solo tramite app',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Aggiungere fino a 9 slot di temperatura',
  'ACCEPT': 'Accettare',
  'INVITE_USERS': 'Invita utenti',
  'INVITE_USER': 'Invita utente',
  'INVITED_USERS': 'Utenti invitati',
  'INVITE_USER_TITLE': 'Invita utente',
  'INVITE_USER_SUCCESS_MSG': 'Nuovo utente invitato. E’ stata inviata una mail al nuovo utente per confermare l’accesso a questa abitazione',
  'INVITE_USER_ERROR_MSG': 'Errore durante l\'invito di un nuovoutente. Si prega di riprovare più tardi.',
  'REVOKE_INVITE_TITLE': 'Rimuovi utente invitato',
  'REVOKE_INVITE_MSG': 'L\'utente selezionato non avrà più accesso a questa abitazione',
  'REVOKE_INVITE_SUCCESS_MSG': 'L\'utente invitato è stato rimosso',
  'REVOKE_INVITE_ERROR_MSG': 'Errore durante la rimozione dell\'utente.Si prega di riprovare più tardi.',
  'CONFIRM_INVITE_SUCCESS': 'Il tuo account è stato creato con successo',
  'GUEST': 'Ospite',
  'TRV': 'Valvola termostatica',
  'TRVS': 'Valvole termostatiche',
  'INSTALL_TRV_STEP1': 'Installa valvola termostatica: Passo 1',
  'INSTALL_TRV_HELP_STEP1': 'Premere il pulsante di <strong>INIZIO RICERCA</strong> per avviare l\'installazione della valvola termostatica',
  'INSTALL_TRV_STEP2': 'Installa valvola termostatica: Passo 2',
  'INSTALL_TRV_HELP_STEP2': 'Per abbinare la valvola, premere contemporaneamente i tasti <strong>accensione</strong>(tasto a sinistra)e <strong>link</strong>  per <strong>3 secondi</strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Cambio di modalità Riscaldamento/Raffrescamento',
  'CONTROL_MODE_CHANGE_MSG': 'Attenzione: la programmazione corrente verrà ripristinata',
  'SAVE_THIS_POSITION': 'Salva questa posizione',
  'SET_POSITION_MSG': 'La posizione della tua abitazione non è ancora stata stabilita. Seleziona la posizione della tua abitazione sulla mappa e salva cliccando sul tasto in basso.',
  'ENABLE_GEOLOCATION': 'Attivare la geolocalizzazione',
  'OUTDOOR_RANGE': 'Raggio da casa (m.)',
  'DEV_TYPE_WIFI': 'SmartBox WiFi',
  'DEV_TYPE_WIFIHTR': 'Radiatore WiFi',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Selezione del dispositivo',
  'SELECT_DEVICE_THM_HELP': 'Il termostato verrà associato al dispositivo selezionato',
  'SELECT_DEVICE_HTR_HELP': 'Il radiatore verrà associato al dispositivo selezionato',
  'SELECT_DEVICE_ACM_HELP': 'L\'accumulatore verrà associato al dispositivo selezionato',
  'SELECT_DEVICE_PMO_HELP': 'Il misuratore di potenza verrà associato al dispositivo selezionato',
  'SELECT_DEVICE_TRV_HELP': 'La valvola verrà associata al dispositivo selezionato',
  'ADD_DEVICE': 'Aggiungi un dispositivo',
  'DEVICE_INSTALLATION': 'Installazione del dispositivo',
  'WIFI_HEATER': 'Radiatore WiFi',
  'WIFI_HEATER_HAVERLAND': 'Serie Connect',
  'LETS_START': 'Cominciamo',
  'INSTALL_WIFI_HEATER_HELP': 'Collegare il termosifone alla presa elettrica. Premi il pulsante OK finché lo schermo non lampeggia.',
  'INSTALL_WIFI_HEATER2_HELP': 'Collega il tuo radiatore alla presa. Premere i pulsanti mostrati nell\'immagine seguente finché lo schermo non lampeggia.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Collega il tuo SmartBox WiFi. Se è già collegato, scollegalo e ricollegalo.',
  'DEV_TYPE_WIFI_CONNECTION': 'Connessione del SmartBox WiFi',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'Connessione del Radiatore WiFi',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Ora collegheremo lo SmartBox alla tua rete Wi-Fi domestica.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Ora collegheremo il Radiatore al tuo WiFi di casa.',
  'GHZ_WIFI_WARNING': 'Attenzione: sono valide solo reti a 2,4 GHz',
  'CONFIGURING_WIFI_HTR': 'Configurazione del Radiatore WiFi',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'Configurazione SmartBox WiFi in modalità AP',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'Configurazione Radiatore WiFi in modalità AP',
  'SET_ETHERNET_SMARTBOX_NAME': 'Come chiamiamo questa SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Come chiamiamo questa SmartBox WiFi?',
  'SET_WIFI_HTR_NAME': 'Come chiamiamo questo Radiatore WiFi?',
  'TYPE_DEVICE_NAME': 'Inserisci un nome per il tuo dispositivo',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Connessione alla SmartBox WiFi',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Connessione allo Radiatore WiFi',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Connesso a SmartBox WiFi con id:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Connesso a Radiatore WiFi con id:',
  'MY_DEVICES': 'I miei dispositivi',
  'DEVICE_TYPE': 'Che aspetto ha il tuo dispositivo?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Connessione alla SmartBox WiFi',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Connessione allo Radiatore WiFi',
  'CONFIGURING_DEV_TYPE_WIFI': 'Configurazione di SmartBox WiFi',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'Configurazione di Radiatore WiFi',
  'DEVICE_LIST': 'Dispositivi',
  'CONNECTION_STATE': 'Stato della connessione',
  'CONFIGURE_WIFI': 'Configura il WiFi',
  'CHANGE_HOME': 'Trasferirsi in un\'altra casa',
  'DELETE_DEVICE': 'Elimina dispositivo',
  'TRANSFER_HOME_TITLE': 'Trasferirsi in un\'altra casa',
  'TRANSFER_HOME_WARNING': 'Attenzione: il dispositivo verrà trasferito nella casa selezionata',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Elimina dispositivo',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Sei sicuro di voler eliminare questo dispositivo?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Si prega di collegare la SmartBox Ethernet',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox WiFi installata correttamente',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox Ethernet installata correttamente',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'Radiatore WiFi installato correttamente',
  'HOME_REGISTRY': 'Registro di casa',
  'HOME_REGISTRY_HELP': 'In questo momento non hai nessuna casa registrata. Aggiungiamone uno nuovo.',
  'SET_HOME_NAME': 'Imposta il nome della casa',
  'SET_HOME_TIMEZONE': 'Imposta il fuso orario di casa',
  'DEVICE_CONNECTION_START': 'Ora collegheremo il tuo dispositivo',
  'WIFI_DEVICE': 'Dispositivo WiFi',
  'WIFI_DEVICE_HELP': 'L\'installazione d\'appareils WiFi non è disponibile per le applicazioni',
  'TYPE_ESSID': 'Digita la tua rete WiFi',
  'REGISTERING_DEVICE': 'Registrazione di dispositivo',
  'WELCOME': 'Benvenuto',
  'ESSID': 'Seleziona la rete wi-fi',
  'SET_BOOST_MODE': 'Imposta la modalità Boost',
  'BOOST_END_TIME': 'Ora di fine della modalità boost',
  'BOOST_ACTIVE_UNTIL': 'Boost attivo fino al',
  'OPERATIONAL_MODE': 'Modalità operativa',
  'FIXED_TEMPERATURE': 'Temperatura fissa',
  'WEEKLY_SCHEDULE': 'Programmazione settimanale',
  'ANTIFROST_PROTECTION': 'Protezione antigelo',
  'ID_COPIED': 'Identificatore copiato',
  'NEW_PROFILE': 'Nuovi Profili',
  'PROFILE_COLOR': 'Colore',
  'TYPE_PROFILE_NAME': 'Inserisci un nome per il profilo',
  'TYPE_POWER_LIMIT': 'Inserisci un valore di potenza',
  'POWER_LIMIT': 'Energia (W)',
  'EDIT_PROFILES': 'Profili',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Elimina profilo di potenza',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Sei sicuro di voler eliminare questo profilo?',
  'POWER_MANAGEMENT': 'Configurazione di potenza assunta',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Deve essere tra ',
  'AND': ' e ',
  'MANUAL_NETWORK_INPUT': 'Inserisci manualmente',
  'GEOLOCATION_INFO': 'Questa funzione consente di abbassare la temperatura dei dispositivi quando ci si trova al di fuori del raggio di casa impostato.',
  'GEOLOCATION_NEW_FEAT': 'Scopri la funzione che ti permette di risparmiare sui consumi dei tuoi dispositivi quando non sei a casa.',
  'VOICE_CONTROL': 'Assistente vocale',
  'VOICE_CONTROL_NEW_FEAT': 'Ricorda che questo dispositivo è compatibile con: ',
  'CONFIGURE': 'Configurare',
  'MORE_INFO_LONG': 'Maggiori informazioni',
  'GEOLOCATION_RATIONALE': 'Per il corretto funzionamento della modalità di geolocalizzazione, questa app ha bisogno di accedere alla tua posizione tutto il tempo, anche quando non stai usando il telefono. Consenti nelle impostazioni.',
  'USER_DUTY_FACTOR': 'Fattore limite di potenza',
  'CIRCUIT_TYPE': 'Tipo di circuito',
  'PRINCIPAL_CIRCUIT': 'Circuito Principale',
  'REVERSE_SIGN': 'Segno inverso',
  'SMARTBOX_CONFIGURATION': 'Configurazione del SmartBox',
  'MAIN_CIRCUIT_GENERATION': 'Circuito principale di Generazione',
  'MAIN_CIRCUIT_CONSUMPTION': 'Circuito principale di Consumo',
  'SELECT_GENERATION_POWERMETER': 'Seleziona un misuratore di Generazione',
  'SELECT_CONSUMPTION_POWERMETER': 'Seleziona un misuratore di Consumo',
  'POWERMETER_CONFIG': 'Configurazione del misuratore',
  'MAX_POWER_TERM_MANAGEMENT': 'Potenza asunta',
  'MANAGE_MAX_POWER': 'Programma',
  'MANAGE_ENERGY_TARIFFS': 'Programma',
  'COLOR': 'Colore',
  'CONSUMPTION': 'Consumo',
  'GENERATION': 'Generazione',
  'EDIT_ENERGY_TARIFFS': 'Profili',
  'SELECT_MAX_POWER_CONFIG': 'Seleziona un profilo di potenza assunta',
  'CUSTOM_TARIFF_CONFIG': 'Nuovo profilo personalizzato',
  'ANTI_FROST_IN_OFF': 'Protezione Antigelo',
  'POWER_LEVEL_LIMIT': 'Limite del livello di Potenza',
  'E2_USAGE_ENABLED': 'Energia Solare',
  'E2_MODE': 'Configurazione di Carica',
  'E2_ACTIVATION_PER': 'Attivazione Energia Solare',
  'E2_CHARGE_EXCESS_PER': 'Carico massimo a Valle',
  'NODE_E2_MODE_0': 'Solare esclusivo',
  'NODE_E2_MODE_1': 'Solare e Valle',
  'NODE_E2_MODE_2': 'Solare e regolazione di valle',
  'E2_TEMP_EXCESS': 'Intervallo di temperatura ambiente',
  'E2_PRIORITY': 'Priorità di caricamento',
  'MAIN_CIRCUIT': 'Principale',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Dispositivo aggiornato con successo',
  'DEVICE_UPDATE_ERROR_MSG': 'Il dispotivio non può essere aggiornato.Per favore riprova più tardi.',
  'SYSTEM_MAINTENANCE': 'Sistema in manutenzione',
  'SYSTEM_MAINTENANCE_MSG': 'Ci scusiamo per il disagio, stiamo lavorando per migliorare il servizio.',
  'SOLD_SEPARATELY': '(Si vende separato)',
  'REMOVE_ACCOUNT': 'Rimuovi account',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Rimuovi l\'account',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'La rimozione dell\'account comporta l\'eliminazione di tutti i nodi e dei dati ad esso associati. Se sei sicuro di eliminare l\'account, inserisci la tua password per confermare.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Account rimosso con successo',
  'ACCOUNT_REMOVAL_ERROR': 'Impossibile rimuovere l\'account.',
  'USE_QR': 'Scansiona WiFi QR',
  'USE_MANUAL_AP': 'Inserisci le credenziali Wi-Fi',
  'LOADING': 'Caricamento',
  'SIGN_IN_WITH_GOOGLE': 'Accedi con Google',
  'LOCK': 'Blocco',
  'UNLOCK': 'Sbloccare',
  'BRIGHT_LEVEL': 'Livello di luminosità del display',
  'BACKLIGHT_TIME': 'Tempo di accensione del display',
  'BACKLIGHT_TIME_10': '10 secondi',
  'BACKLIGHT_TIME_20': '20 secondi',
  'BACKLIGHT_TIME_30': '30 secondi',
  'BACKLIGHT_TIME_ALWAYS': 'Sempre',
  'DELETE_ACCOUNT_MSG': 'Eliminando l\'account utente si cancellano definitivamente tutti i dati ad esso associati. Se siete d\'accordo, seguite i passaggi indicati di seguito.',
  'DELETE_ACCOUNT_STEPS': 'Procedura per eliminare l\'account',
  'DELETE_ACCOUNT_STEP1': '1. Accedere all\'applicazione con il proprio nome utente e la propria password.',
  'DELETE_ACCOUNT_STEP2': '2. Andare al menu superiore.',
  'DELETE_ACCOUNT_STEP3': '3. Selezionare Il mio account.',
  'DELETE_ACCOUNT_STEP4': '4. Nella sezione Elimina account, fare clic sul pulsante Elimina account.',
  'DEPRECATION_MESSAGE': 'Utilizza l\'app TARGET'
 },
 'pt': {
  'ASCII_ERROR': 'Le password WiFi devono contenere solo caratteri ASCII.',
  'QR_SCANN_ERROR': 'Nomes e senhas de redes WiFi com mais de 32 caracteres não são suportados.',
  'QR_SCANN_ERROR_TITLE': 'Houve um problema com as credenciais introduzidas',
  'EXTRA_PRESS_ON_PROVISIONING': 'Pressione qualquer botão para ativar o display',
  'BT_ACTIVATION_WARNING': 'Certifique-se de ter o Bluetooth ativado no seu celular',
  'DEVICE_DISCONNECTED_STEP_1': 'Abra o menu principal',
  'DEVICE_DISCONNECTED_STEP_2': 'Vá para Casas e selecione sua casa',
  'DEVICE_DISCONNECTED_STEP_3': 'Selecionar dispositivo',
  'DEVICE_DISCONNECTED_STEP_4': 'Configurar Wi-Fi',
  'DEVICE_DISCONNECTED_HELP_1': 'Rede Wi-Fi tem conexão com a internet e é uma rede de 2,4 GHz.',
  'DEVICE_DISCONNECTED_HELP_2': 'Considere áreas com cobertura WiFi adequada durante a instalação do dispositivo.',
  'DEVICE_DISCONNECTED_HELP_3': 'Verifique e reconfigure as credenciais WiFi para seu dispositivo diretamente em <a class="alink" href=\'{{link}}\'>aqui< ou siga esta sequência:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'O dispositivo já está registrado, mas não está conectado corretamente. Tente desconectar o dispositivo do plugue e reconectá-lo. Verifique se ele está conectado à sua rede WiFi. Caso contrário, considere os seguintes pontos:',
  'DEVICE_DISCONNECTED_HEADER': 'Houve um problema no processo de provisionamento, o dispositivo pode ser perdido.',
  'DEVICE_INSTALLATION_ERROR': 'Houve um problema durante a instalação do dispositivo.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Verifique o estado dos LEDs do dispositivo. Selecione um dos estados para obter mais informações e ajudar a resolver o problema:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'O dispositivo não possui um IP associado.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'O dispositivo não está conectado ao servidor.',
  'DEVICE_INSTALLATION_ERROR_OK': 'O dispositivo está corretamente conectado.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Certifique-se de que o dispositivo esteja corretamente conectado ao WiFi através do cabo Ethernet.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Repita o processo de instalação.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Verifique a conexão à internet do roteador e certifique-se de que não há restrições de porta.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Feche e abra o aplicativo novamente para verificar se o dispositivo foi instalado corretamente.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Verifique o estado do LED do dispositivo. Selecione um dos estados para obter mais informações e ajudar a resolver o problema:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'A rede WiFi não foi configurada.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'O dispositivo não está conectado ao roteador.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Verifique o estado do dispositivo observando o estado do ícone da antena. Selecione um dos estados para obter mais informações e ajudar a resolver o problema:',
  'SERVER_CONNECTION_ERROR': 'O aplicativo não consegue se conectar ao servidor. Verifique a conexão de internet do seu SmartPhone e repita o processo',
  'AP_FALLBACK_TITLE': 'Vamos reiniciar o processo usando o método Access Point',
  'HANDSHAKE_WARNING': 'Por favor, certifique-se de que seu smartphone agora está conectado à mesma rede wi-fi.',
  'GEOLOCATION_RANGE': 'Rádio do Habitação',
  'PROVISIONING_METHOD_HELP': 'Selecione um método de provisionamento para conectar seu dispositivo',
  'RECOMMENDED': 'Recomendado',
  'BACK': 'Voltar',
  'HELP': 'Ajuda',
  'SUPPORT': 'Suporte',
  'MY_ACCOUNT': 'Minha conta',
  'HOMES': 'Casas',
  'PRIVACY': 'Privacidade',
  'VERSION': 'Versão',
  'PRIVACY_INFO': 'Aqui você pode ver as informações pessoais que temos sobre você. Você pode exercer seus direitos no endereço de e-mail de contato que aparece abaixo',
  'HOME_GEOLOCATION': 'Geolocalização de casa',
  'LATITUDE': 'Latitude',
  'LONGITUDE': 'Longitude',
  'CONTACT_EMAIL': 'Email de contato',
  'PP_POLICY_CHANGE_WARNING': 'A política de privacidade e os termos e condições foram alterados. Seu acordo é necessário para continuar',
  'PP_READ_AND_ACCEPTED': 'Eu li e aceito a <a href=\'{{frontendHost}}/pt/privacy.html\' target=\'_blank\' class=\'alink\'>Política de Privacidade</a> e os <a href=\'{{frontendHost}}/pt/terms.html\' target=\'_blank\' class=\'alink\'>Termos e Condições</a>',
  'ACCEPTED': 'Aceito',
  'SIGN_OUT': 'Encerrar Sessão',
  'SIGN_IN': 'Iniciar Sessão',
  'SIGN_UP': 'Registar',
  'EMAIL': 'Email',
  'PASSWORD': 'Password',
  'ALL_FIELDS_REQUIRED': 'Tem que preencher todos os campos',
  'REMEMBER_ME': 'Recorde-me',
  'NEW_ON_HELKI': 'Novo usuário, registe-se aqui!',
  'I_FORGOT_MY_PASSWORD': 'Esqueci-me da password',
  'TYPE_EMAIL': 'Introduza o seu email',
  'TYPE_PASSWORD': 'Introduza a sua password',
  'CONFIRM_PASSWORD': 'Confirme Password',
  'INVALID_EMAIL': 'Email inválido',
  'SIGN_IN_ERROR': 'Email ou password incorretos',
  'INVALID_PASSWORD': 'Password inválida',
  'PASSWORDS_DO_NOT_MATCH': 'As passwords não coincidem',
  'USER_CONFLICT_ERROR': 'Email já existente',
  'SIGN_UP_SUCCESS': '¡Registo realizado com êxito! Enviámos um email para a confirmação da sua conta',
  'FORGOT_PASS_MESSAGE': 'Não se preocupe, envie-nos apenas o seu email de registo para poder iniciar o processo de mudança da password.',
  'RESET_PASSWORD': 'Nova password',
  'USER_NOT_FOUND_ERROR': 'Usuário não encontrado',
  'FORGOT_PASS_SUCCESS': 'Enviámos um email para reiniciar a sua password',
  'SIGN_UP_VERIFY_SUCCESS': 'A sua conta foi verificada corretamente',
  'SIGN_UP_VERIFY_ERROR': 'A sua conta não pode ser verificada. Por favor, contacte-nos',
  'CHANGE_EMAIL_SUCCESS': 'O seu email foi atualizado corretamente',
  'CHANGE_EMAIL_ERROR': 'Não foi possível atualizar o seu email. Por favor, contacte-nos',
  'RESET_PASS_SUCCESS': 'A sua password foi reiniciada corretamente',
  'RESET_PASS_ERROR': 'A sua password não pode ser reiniciada. Por favor, contacte-nos',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Mudar email',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Enviámos um email para a sua nova conta para completar a mudança',
  'CONNECTION_ERROR_MSG': 'Não é possível se conectar ao servidor',
  'TRY_AGAIN': 'Repetir',
  'NOW': 'Agora',
  'HEATING': 'Aquecimento',
  'ELECTRICITY': 'Eletricidade',
  'WEATHER': 'O tempo',
  'TEMPERATURE': 'Temperatura',
  'TURN_OFF': 'Apagar',
  'TURN_ON': 'Ligar',
  'SCHEDULE': 'Programar',
  'SETUP': 'Configurar',
  'SET_TEMPERATURE': 'Estabelecer temperatura',
  'SET_COMFORT_TEMPERATURE': 'Estabelecer temperatura de conforto',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Atenção! Se alterar a temperatura do Modo Conforto, a Temperatura do modo ECO é automaticamente reajustada.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Selecione apenas o mesmo sistema de radiadores (nos aquecedores com geolocalização, a seleção não pode ser feita por grupo)',
  'REMOVE': 'Retirar',
  'CANCEL': 'Cancelar',
  'CONFIRM': 'Confirmar',
  'RECORDS': 'Consumos',
  'SELECT_A_DAY': 'Selecione um dia',
  'DAY1_SHORT': 'Se',
  'DAY2_SHORT': 'Te',
  'DAY3_SHORT': 'Qu',
  'DAY4_SHORT': 'Qu',
  'DAY5_SHORT': 'Se',
  'DAY6_SHORT': 'Sá',
  'DAY7_SHORT': 'Do',
  'MONDAY': 'Segunda-feira',
  'TUESDAY': 'Terça-feira',
  'WEDNESDAY': 'Quarta-feira',
  'THURSDAY': 'Quinta-feira',
  'FRIDAY': 'Sexta-feira',
  'SATURDAY': 'Sábado',
  'SUNDAY': 'Domingo',
  'MONDAY_SHORT': 'Seg',
  'TUESDAY_SHORT': 'Ter',
  'WEDNESDAY_SHORT': 'Qua',
  'THURSDAY_SHORT': 'Qui',
  'FRIDAY_SHORT': 'Sex',
  'SATURDAY_SHORT': 'Sáb',
  'SUNDAY_SHORT': 'Dom',
  'COPY_DAY': 'Copiar dia',
  'ADD': 'Adicionar',
  'FROM': 'De',
  'TO': 'para',
  'SELECT_A_DAY_MSG': 'Selecione um dia para estabelecer ou modificar a programação do termostato',
  'EDIT': 'Editar',
  'START': 'Início',
  'END': 'Fim',
  'DONE': 'Efetuado',
  'COPY_DAYS': 'Copiar dias',
  'USE_SAME_SCHEDULE_ON': 'Usar a mesma programação nos ...',
  'COPY': 'Copiar',
  'POWER_METER': 'Medidor',
  'USAGE': 'Consumo',
  'USAGE_VALLEY': 'Consumo de carga',
  'USAGE_PEAK': 'Consumo sem carga',
  'REAL_TIME_USAGE': 'Consumo instantâneo',
  'DAILY': 'Diário',
  'MONTHLY': 'Mensal',
  'ANNUAL': 'Anual',
  'JAN': 'Jan',
  'FEB': 'Fev',
  'MAR': 'Mar',
  'APR': 'Abr',
  'MAY': 'Mai',
  'JUN': 'Jun',
  'JUL': 'Jul',
  'AUG': 'Ago',
  'SEP': 'Set',
  'OCT': 'Out',
  'NOV': 'Nov',
  'DEC': 'Dez',
  'POWER': 'Potência',
  'NOMINAL_POWER': 'Potência nominal',
  'EFFECTIVE_POWER': 'Potência efetiva',
  'ZERO_POWER_WARNING': 'Este aquecedor não tem energia atribuída. Configure aqui',
  'SET_MODE_TEMP': 'Def. Temp.',
  'SET_MODE_TEMPERATURE': 'Definir modo temperatura',
  'COMFORT': 'Conforto',
  'ECO': 'Eco',
  'ANTI_ICE': 'Anti gelo',
  'INSTALL': 'Instalar',
  'INSTALL_ERROR_MSG': 'Erro no processo de instalação. Tente de novo.',
  'MORE': 'Mais',
  'INSTALL_DEVICE': 'Instalar dispositivo',
  'SELECT_ALL': 'Sel. todos',
  'DESELECT_ALL': 'Desel. todos',
  'THERMOSTAT': 'Termostato',
  'HEATER': 'Radiador',
  'HEATER_HAVERLAND': 'Radiador com geolocalização',
  'HEATER_WITH_SMARTBOX': 'Radiador com Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Toalheiro com Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'Radiador WiFi 4 botões',
  'WIFI_HEATER_W2BUTTONS': 'Radiador WiFi 2 botões',
  'INSTALL_THERMOSTAT_STEP1': 'Instalar termostato: Passo 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Pressione o botão <strong>COMEÇAR A BUSCA</strong> para iniciar a instalação do termostato.',
  'INSTALL_THERMOSTAT_STEP2': 'Instalar termostato: Passo 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Para emparelhar o termostato pulse a tecla <strong>Standby</strong> (o botão esquerdo) e a tecla <strong>Link</strong> (o botão direito ) durante <strong>3 segundos</strong> até que apareça o símbolo de rede na parte superior esquerda do termostato (tal como se mostra na imagem).',
  'START_SEARCH': 'Começar a busca',
  'SEARCHING': 'Procurando...',
  'INSTALL_HEATER_STEP1': 'Instalar radiador: Passo 1',
  'INSTALL_HEATER_HELP_STEP1': 'Pressione o botão <strong>COMEÇAR A BUSCA</strong> para iniciar a instalação do radiador.',
  'INSTALL_HEATER_STEP2': 'Instalar radiador: Passo 2',
  'INSTALL_HEATER_HELP_STEP2': 'Para emparelhar o emissor térmico elétrico <strong>PULSE</strong> a tecla <strong>Mod/OK</strong> no dispositivo durante <strong>3 segundos</strong> até que apareça o símbolo de rede na parte superior direita do ecrã.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Certifique-se que o radiador está desligado e mantenha pressionados os botões de subir e baixar temperatura por pelo menos 3 segundos',
  'STORAGE_HEATER': 'Termoacumulador',
  'STORAGE_HEATERS': 'Termoacumuladores',
  'INSTALL_STORAGE_HEATER_STEP1': 'Instalar termoacumuladores: Passo 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Pressione o botão <strong>COMEÇAR A BUSCA</strong> para iniciar a instalação do termoacumulador.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Instalar termoacumuladores: Passo 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Para emparelhar o termoacumulador elétrico <strong>PULSE</strong> a tecla <strong>Mod</strong> no dispositivo durante <strong>3 segundos</strong> até que apareça o símbolo de rede na parte superior direita do ecrã.',
  'CHARGE_PROGRAM': 'Programa de carregamento',
  'VALLEY_1': 'Periodo 1',
  'VALLEY_2': 'Periodo 2',
  'INSTALL_POWER_METER_STEP1': 'Instalar medidor elétrico: Passo 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Pressione o botão <strong>COMEÇAR A BUSCA</strong> para iniciar a instalação do medidor elétrico.',
  'INSTALL_POWER_METER_STEP2': 'Instalar medidor eléctrico: Passo 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Para emparelhar o medidor PULSE com um clip (fornecido) no pequeno pulsador situado na parte superior do medidor (ver imagem). Pode comprovar que está emparelhado observando o LED do medidor.',
  'LED_INDICATOR_STATES': 'Estados do indicador LED',
  'UNLINKED': 'Não emparelhado',
  'LED_OFF': 'Led apagado',
  'CORRECTLY_LINKED': 'Emparelhado corretamente',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'O LED pisca uma vez cada dois segundos meio.',
  'LINKED_BUT_LOST': 'Emparelhado mas perdido',
  'LED_BLINKS_EVERY_HALF_SEC': 'O LED pisca uma vez cada meio segundo. Isto ocorre quando por exemplo se desconecta a central. Logo que volte a poder comunicar com a central o medidor voltará a conectar-se automaticamente.',
  'NAME': 'Nome',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Selecione um nome para identificar o dispositivo',
  'TYPE': 'Tipo',
  'HEAT': 'Calor',
  'COLD': 'Frio',
  'CONTROL': 'Controlo',
  'SELECT': 'Selecione...',
  'HYST_0P2': 'Histerese (0,2 ºC)',
  'HYST_0P3': 'Histerese (0,3 ºC)',
  'HYST_0P4': 'Histerese (0,4 ºC)',
  'HYST_0P5': 'Histerese (0,5 ºC)',
  'HYST_0P7': 'Histerese (0,7 ºC)',
  'HYST_0P8': 'Histerese (0,8 ºC)',
  'HYST_1P6': 'Histerese (1,6 ºC)',
  'HYST_0P4F': 'Histerese (0,4 ºF)',
  'HYST_0P5F': 'Histerese (0,5 ºF)',
  'HYST_0P7F': 'Histerese (0,7 ºF)',
  'HYST_0P9F': 'Histerese (0,9 ºF)',
  'HYST_1P2F': 'Histerese (1,2 ºF)',
  'HYST_1P4F': 'Histerese (1,4 ºF)',
  'HYST_2P9F': 'Histerese (2,9 ºF)',
  'PID_T10MIN': 'PID (período 10 min.)',
  'PID_T15MIN': 'PID (período 15 min.)',
  'PID_T20MIN': 'PID (período 20 min.)',
  'PID_T25MIN': 'PID (período 25 min.)',
  'PID_T30MIN': 'PID (período 30 min.)',
  'PID_T45MIN': 'PID (período 45 min.)',
  'PID_T60MIN': 'PID (período 60 min.)',
  'ANTI_GRIPPAGE': 'Anti bloqueio de bomba',
  'CONTRACTED_POWER_W': 'Potência contratada (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Introduza a sua potência contratada em Watts',
  'PRIORITY': 'Prioridade',
  'LOW': 'Baixa',
  'MEDIUM': 'Média',
  'HIGH': 'Alta',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Modo janela',
  'DELETE_DEVICES': 'Apagar dispositivos',
  'DELETE_DEVICES_WARNING': 'São apagados os dispositivos selecionados do sistema e os seus dados associados',
  'DELETE': 'Apagar',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Máximo de potência total de radiadores',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Potência para todos os radiadores (W)',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Introduza a potência máxima para todos os radiadores (W)',
  'LIMITLESS': 'Sem limite de potência',
  'HOME_INFO': 'Dados da casa',
  'HOME_NAME': 'Nome da casa',
  'TYPE_HOME_NAME': 'Introduza o nome da sua casa',
  'TIME_ZONE': 'Fuso horário',
  'TIME_ZONE_CET': '(Hora Europa central) Berlim, Paris, Madrid, Roma',
  'TIME_ZONE_WET': '(Hora Europa ocidental) Londres, Dublin, Lisboa, Ilhas Canárias',
  'TIME_ZONE_EET': '(Hora Europa oriental) Helsinki, Sófia, Atenas',
  'TIME_ZONE_PET': '(Hora Perú) Lima',
  'TIME_ZONE_NZST': '(Hora Nueva Zelanda) Auckland',
  'TIME_ZONE_CIST': '(Hora Chatham Islands) Chatham Islands',
  'TIME_ZONE_CST': '(Hora China) Shanghai',
  'TIME_ZONE_UYT': '(Hora Uruguay) Montevideo',
  'DEVICE_IDENTIFIER': 'Identificador de dispositivo',
  'TYPE_DEVICE_IDENTIFIER': 'Introduza o identificador do seu dispositivo',
  'INVALID_IDENTIFIER': 'O identificador introduzido não é válido',
  'DEVICE_ALREADY_TAKEN': 'O dispositivo com ID DEVID já está registrado. Entre em contato com o suporte técnico para relatar esse problema.',
  'COPY_ERROR_DATA': '  Copiar ID do dispositivo',
  'MY_HOMES': 'Minhas casas',
  'ADD_HOME': 'Adicionar casa',
  'SAVE': 'Guardar',
  'MY_INFO': 'Meus dados',
  'USER': 'Usuário',
  'LANGUAGE': 'Idioma',
  'CHANGE_EMAIL_ADDRESS': 'Mudar email',
  'NEW_EMAIL_ADDRESS': 'Novo email',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Introduza o seu novo email',
  'CONFIRM_WITH_PASSWORD': 'Confirmar com password',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Introduza a sua password atual',
  'CHANGE_PASSWORD': 'Mudar password',
  'CURRENT_PASSWORD': 'Password atual',
  'TYPE_YOUR_NEW_PASSWORD': 'Introduza a sua nova password',
  'NEW_PASSWORD': 'Nova password',
  'TEMPERATURE_UNITS': 'Unidade de temperatura',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Auto aprendizagem',
  'SELF_LEARN_SHORT': 'AutoAprend.',
  'PRESENCE': 'Presença',
  'AWAY_OFFSET': 'Diminuição de temperatura na ausência',
  'ERASE_DATA_PERMANENTLY': 'Apagar dados permanentemente',
  'NO_CONNECTION_WARNING': 'Sem conexão, acesso apenas ao historial',
  'SET_MODE': 'Estabelecer modo',
  'AT_HOME': 'Em casa',
  'AWAY': 'Ausente',
  'DEVICES_SUMMARY': 'Resumo de dispositivos',
  'THERMOSTATS': 'Termostatos',
  'HEATERS': 'Radiadores',
  'POWER_METERS': 'Medidores',
  'CLOSE': 'Fechar',
  'DETECT_SMARTBOX': 'Detetar SmartBox',
  'DETECT_SMARTBOX_HELP_MSG': 'Para localizar a sua SmartBox pulse o botão situado na parte superior da mesma. Assegure-se de que está conectada corretamente à sua reda WiFi.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Lamentamos mas não foi possível encontrar a SmartBox. Por favor carregue em continuar e introduza manualmente o identificador do dispositivo.',
  'CONTINUE': 'Continuar',
  'CANCEL_SEARCH': 'Cancelar busca',
  'SEARCHING_NEW_DEVICES': 'A procurar novos dispositivos ...',
  'DISCOVERY_MODE_MSG': 'SmartBox {{devId}} em modo procura. Pulse o botão adequado do seu dispositivo a instalar segundo o seu manual',
  'DEVICE_FOUND_MSG': '¡Foi encontrado um novo dispositivo!',
  'GETTING_STARTED': 'Primeira ajuda',
  'MAIN_MENU': 'Menu principal',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Aceda facilmente às opções gerais de configuração',
  'NEXT': 'Seguinte',
  'GEOLOCATION': 'Geolocalização',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Ausente reduzirá por padrão 2ºC',
  'DEVICES': 'Dispositivos',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Os dispositivos dividem-se em seções Aquecimento e Eletricidade',
  'GO_TO_A_DEVICE': 'Aceda ao dispositivo',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Configure o dispositivo e aceda às suas estatísticas',
  'MULTIDEVICE_ACTIONS': 'Ações múltiplas',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Selecione os dispositivos e aceda às opções no botão MAIS',
  'READY': 'Pronto',
  'SKIP': 'Saltar',
  'SKIP_HELP': 'Saltar a ajuda',
  'QUICK_SETTING': 'Ajuste rápido',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Deslize lateralmente a barra de temperaturas para mudar rapidamente entre temperaturas.',
  'TEMPERATURES': 'Temperaturas',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Estabelece as temperaturas de cada um dos modos disponíveis.',
  'MODE': 'Modo',
  'WORKING_MODES': 'Modos',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Selecione um dos três modos disponíveis.',
  'SELECT_MODE': 'Selecione o modo',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Marque as horas nas quais se aplicará esse modo.',
  'SUPPORT_BODY': 'A sua conta tem um período mínimo de suporte para atualizações de segurança de 3 anos a partir da data de compra do produto. No caso de tomar conhecimento de quaisquer problemas de segurança ou outros, envie-nos um e-mail para o endereço abaixo descrevendo o problema.',
  'SUPPORT_REQUEST_INTRO_HELP': 'Dispõe de um botão de ajuda na parte inferior esquerda para ajudar na utilização da app.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Se deseja realizar um pedido de suporte, por favor utilize os seguintes dados de contacto:',
  'ADDRESS': 'Direção',
  'TELEPHONE': 'Telefone',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'Ao registar-me, aceito os <a href=\'{{frontendHost}}/pt/terms.html\' target=\'_blank\'>Termos e Condições</a> e a <a href=\'{{frontendHost}}/pt/privacy.html\' target=\'_blank\'>Política de Privacidade</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/pt/privacy.html\' target=\'_blank\'>Política de Privacidade</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Sua senha foi atualizada com sucesso',
  'PASSWORD_CHANGE_ERROR_MSG': 'Sua senha não pôde ser atualizada.  Verifique se a sua senha atual estácorreta.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'E-mail inválido',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'E-mail já recebido',
  'EMAIL_CHANGE_ERROR_MSG': 'Não foi possível atualizar seu e-mail.Por favor, tente novamente mais tarde.',
  'HOME_TYPE': 'Tipo de casa',
  'ETHERNET': 'Conexão por cabo',
  'WIFI': 'Conexão por WiFi',
  'DEVICE_REGISTER_ERROR': 'Não foi possível completar o registo',
  'WRONG_CREDENTIALS_ERROR': 'Usuário/password incorreto',
  'DEVICE_ADDED_MSG': 'Foi adicionado um novo dispositivo',
  'CONNECTION_WARNING': 'Conexão WiFi',
  'WIFI_CONNECTION_1': 'Abra as definições do telemóvel',
  'WIFI_CONNECTION_2': 'Selecione WiFi',
  'WIFI_CONNECTION_3': 'Conecte-se à WiFi ',
  'WIFI_CONNECTION_4': 'Se vê uma notificação do tipo \'WiFi sem acesso à Internet\', indique que deseja manter a conexão',
  'WIFI_CONNECTION_5': 'Volte a esta opção',
  'DEVICE_CONNECTION_ERROR': 'Não foi possível conectar ao dispositivo',
  'WIFI_SCAN_ERROR': 'Não foram encontradas redes',
  'WRONG_PASS_ERROR': 'A password da rede WiFi configurada está incorreta',
  'WRONG_ESSID_ERROR': 'A rede WiFi configurada não existe',
  'SET_WIFI_ERROR': 'Não é possível estabelecer a rede WiFi',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Conexão satisfatória',
  'WIFI_SELECTION': 'Selecione a sua rede WiFi',
  'WIFI_NOT_FOUND': 'Não encontro a minha rede',
  'TYPE_AP_ESSID': 'Introduza o nome da sua rede',
  'TYPE_AP_PASS': 'Introduza a password da sua rede',
  'RETRY': 'TENTAR DE NOVO',
  'AP_DATA': 'Dados da rede',
  'AP_ESSID': 'Nome',
  'AP_PASS': 'Password',
  'EDIT_WIFI': 'Editar configuração WiFi',
  'WRONG_WIFI_ERROR': 'Não se conectou à WiFi correspondente',
  'COPY_PASS': 'Copiar password',
  'EDIT_DATA': 'Dados da casa',
  'WEB_WIFI_WARNING': 'A opção WiFi apenas está disponível na nossa app',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Agregue até 9 blocos de temperatura',
  'ACCEPT': 'Aceitar',
  'INVITE_USERS': 'Convidar usuários',
  'INVITE_USER': 'Convidar usuário',
  'INVITED_USERS': 'Usuários convidados',
  'INVITE_USER_TITLE': 'Convidar usuário',
  'INVITE_USER_SUCCESS_MSG': 'Novo usuário convidado. Foi enviado um email para lhe indicar que tem acesso a esta casa',
  'INVITE_USER_ERROR_MSG': 'Erro ao convidar usuário.Por favor, tente mais tarde.',
  'REVOKE_INVITE_TITLE': 'Eliminar convidado',
  'REVOKE_INVITE_MSG': 'O usuário selecionado deixará de ter acesso a esta casa',
  'REVOKE_INVITE_SUCCESS_MSG': 'Convidado eliminado',
  'REVOKE_INVITE_ERROR_MSG': 'Erro ao eliminar convidado.Por favor, tente mais tarde.',
  'CONFIRM_INVITE_SUCCESS': 'A sua conta foi criada corretamente',
  'GUEST': 'Convidado',
  'TRV': 'Válvula termostática',
  'TRVS': 'Válvulas termostáticas',
  'INSTALL_TRV_STEP1': 'Instalar válvula termostática: Passo 1',
  'INSTALL_TRV_HELP_STEP1': 'Pressione o botão <strong>COMEÇAR A BUSCA</strong> para iniciar a instalação da válvula termostática.',
  'INSTALL_TRV_STEP2': 'Instalar válvula termostática: Passo 2',
  'INSTALL_TRV_HELP_STEP2': 'Para emparelhar a válvula pulse a tecla <strong>helki</strong> (o botão central) durante <strong>3 segundos</strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Mudança de modo frio/calor',
  'CONTROL_MODE_CHANGE_MSG': 'Advertência: a programação atual será restabelecida',
  'SAVE_THIS_POSITION': 'Guardar esta localização',
  'SET_POSITION_MSG': 'A localização da sua casa ainda não foi estabelecida. Selecione a posição da sua casa no mapa e faça click no botão inferior para guardar.',
  'ENABLE_GEOLOCATION': 'Ativar geolocalização',
  'OUTDOOR_RANGE': 'Rede da casa (m.)',
  'DEV_TYPE_WIFI': 'SmartBox WiFi',
  'DEV_TYPE_WIFIHTR': 'Radiador WiFi',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Seleção de dispositivo',
  'SELECT_DEVICE_THM_HELP': 'O termostato será associado ao dispositivo selecionado',
  'SELECT_DEVICE_HTR_HELP': 'O radiador será associado ao dispositivo selecionado',
  'SELECT_DEVICE_ACM_HELP': 'O acumulador será associado ao dispositivo selecionado',
  'SELECT_DEVICE_PMO_HELP': 'O medidor de energia será associado ao dispositivo selecionado',
  'SELECT_DEVICE_TRV_HELP': 'A válvula será associada ao dispositivo selecionado',
  'ADD_DEVICE': 'Adicionar um dispositivo',
  'DEVICE_INSTALLATION': 'Instalação do dispositivo',
  'WIFI_HEATER': 'Radiador WiFi',
  'WIFI_HEATER_HAVERLAND': 'Série Connect',
  'LETS_START': 'Vamos começar',
  'INSTALL_WIFI_HEATER_HELP': 'Conecte seu radiador à tomada. Pressione o botão OK no seu radiador até o visor piscar.',
  'INSTALL_WIFI_HEATER2_HELP': 'Conecte seu radiador à tomada. Pressione os botões mostrados na imagem a seguir até a tela piscar.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Conecte seu SmartBox WiFi. Se já estiver conectado, desconecte-o e reconecte-o.',
  'DEV_TYPE_WIFI_CONNECTION': 'Conectando o SmartBox WiFi',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'Conectando o Radiador WiFi',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Agora vamos conectar o SmartBox à sua rede Wi-Fi doméstica.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Agora vamos conectar o Radiador à sua rede Wi-Fi doméstica.',
  'GHZ_WIFI_WARNING': 'Aviso: apenas redes de 2,4 GHz são válidas',
  'CONFIGURING_WIFI_HTR': 'Configurando o Radiador WiFi',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'Configuração de SmartBox WiFi no modo AP',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'Configuração de Radiador WiFi no modo AP',
  'SET_ETHERNET_SMARTBOX_NAME': 'Como chamamos isso de SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Como chamamos isso de SmartBox WiFi?',
  'SET_WIFI_HTR_NAME': 'Como chamamos esse Radiador WiFi?',
  'TYPE_DEVICE_NAME': 'Digite um nome para o seu dispositivo',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Conexão com o SmartBox WiFi',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Conexão ao radiador WiFi',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Conectado ao SmartBox WiFi com id:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Conectado ao WiFi Radiador com id:',
  'MY_DEVICES': 'Meus dispositivos',
  'DEVICE_TYPE': 'Como é o seu dispositivo?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Conexão com o SmartBox WiFi',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Conexão com o Radiador WiFi',
  'CONFIGURING_DEV_TYPE_WIFI': 'Configuração do SmartBox WiFi',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'Configuração do Radiador WiFi',
  'DEVICE_LIST': 'Aparelhos',
  'CONNECTION_STATE': 'Status da conexão',
  'CONFIGURE_WIFI': 'Configure o WiFi',
  'CHANGE_HOME': 'Mover para outra casa',
  'DELETE_DEVICE': 'Remover dispositivo',
  'TRANSFER_HOME_TITLE': 'Mover para outra casa',
  'TRANSFER_HOME_WARNING': 'Atenção: o dispositivo será transferido para a casa selecionada',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Remover dispositivo',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Tem certeza de que deseja excluir este dispositivo?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Por favor, conecte o Ethernet SmartBox',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox WiFi instalada corretamente',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox Ethernet instalada corretamente',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'Radiador WiFi instalado corretamente',
  'HOME_REGISTRY': 'Registro da casa',
  'HOME_REGISTRY_HELP': 'No momento, você não tem nenhuma casa registrada. Vamos adicionar um novo.',
  'SET_HOME_NAME': 'Definir nome da casa',
  'SET_HOME_TIMEZONE': 'Conjunto de fuso horário em casa',
  'DEVICE_CONNECTION_START': 'Agora vamos conectar seu dispositivo',
  'WIFI_DEVICE': 'Dispositivo WiFi',
  'WIFI_DEVICE_HELP': 'A instalação de dispositivos WiFi está disponível apenas em nossos aplicativos',
  'TYPE_ESSID': 'Digite sua rede WiFi',
  'REGISTERING_DEVICE': 'Registrando dispositivo',
  'WELCOME': 'Bem-vindo',
  'ESSID': 'Selecione rede WiFi',
  'SET_BOOST_MODE': 'Definir modo Boost',
  'BOOST_END_TIME': 'Hora final do modo de reforço',
  'BOOST_ACTIVE_UNTIL': 'Modo Boost ativo até',
  'OPERATIONAL_MODE': 'Modo operacional',
  'FIXED_TEMPERATURE': 'Temperatura fissa',
  'WEEKLY_SCHEDULE': 'Programação semanal',
  'ANTIFROST_PROTECTION': 'Proteção anti-geada',
  'ID_COPIED': 'Identificador copiado',
  'NEW_PROFILE': 'Novo perfil',
  'PROFILE_COLOR': 'Cor',
  'TYPE_PROFILE_NAME': 'Introduza um nome para o perfil',
  'TYPE_POWER_LIMIT': 'Introduza um valor de potência',
  'POWER_LIMIT': 'Potência (W)',
  'EDIT_PROFILES': 'Perfis',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Remover perfil',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Tem certeza de que deseja excluir este perfil?',
  'POWER_MANAGEMENT': 'Configuração Potência Contratada',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Deve estar entre ',
  'AND': ' e ',
  'MANUAL_NETWORK_INPUT': 'Entre manualmente',
  'GEOLOCATION_INFO': 'Este recurso permite que você abaixe a temperatura dos dispositivos quando você estiver fora do raio residencial definido.',
  'GEOLOCATION_NEW_FEAT': 'Descubra a função que permite economizar no consumo de seus dispositivos quando você não está em casa.',
  'VOICE_CONTROL': 'Assistente de voz',
  'VOICE_CONTROL_NEW_FEAT': 'Lembre-se de que este dispositivo é compatível com: ',
  'CONFIGURE': 'Configurar',
  'MORE_INFO_LONG': 'Mais informação',
  'GEOLOCATION_RATIONALE': 'Para o correto funcionamento do modo de geolocalização, este aplicativo precisa acessar a sua localização o tempo todo, mesmo quando você não estiver usando o telefone. Permitir nas configurações.',
  'USER_DUTY_FACTOR': 'Factor de limite de potência',
  'CIRCUIT_TYPE': 'Tipo de circuito',
  'PRINCIPAL_CIRCUIT': 'Circuito Principal',
  'REVERSE_SIGN': 'Sinal reverso',
  'SMARTBOX_CONFIGURATION': 'Configuração da SmartBox',
  'MAIN_CIRCUIT_GENERATION': 'Circuito principal de Geração',
  'MAIN_CIRCUIT_CONSUMPTION': 'Circuito principal de Consumo',
  'SELECT_GENERATION_POWERMETER': 'Selecione um medidor de Geração',
  'SELECT_CONSUMPTION_POWERMETER': 'Selecione um medidor de Consumo',
  'POWERMETER_CONFIG': 'Configuração do medidor',
  'MAX_POWER_TERM_MANAGEMENT': 'Potência contratada',
  'MANAGE_MAX_POWER': 'Programa',
  'MANAGE_ENERGY_TARIFFS': 'Programa',
  'COLOR': 'Cor',
  'CONSUMPTION': 'Consumo',
  'GENERATION': 'Geração',
  'EDIT_ENERGY_TARIFFS': 'Perfis',
  'SELECT_MAX_POWER_CONFIG': 'Selecione um perfil de potência contratada',
  'CUSTOM_TARIFF_CONFIG': 'Novo perfil personalizado',
  'ANTI_FROST_IN_OFF': 'Proteção antigêlo',
  'POWER_LEVEL_LIMIT': 'Nível Limite Potência',
  'E2_USAGE_ENABLED': 'Energia Solar',
  'E2_MODE': 'Tipo de Carga',
  'E2_ACTIVATION_PER': 'Potência Solar Activação',
  'E2_CHARGE_EXCESS_PER': 'Carga Máxima Fora de pico',
  'NODE_E2_MODE_0': 'Só Solar',
  'NODE_E2_MODE_1': 'Solar e Fora de pico',
  'NODE_E2_MODE_2': 'Solar e Fora de pico corregido',
  'E2_TEMP_EXCESS': 'Margem Tª Ambiente',
  'E2_PRIORITY': 'Prioridade de Carga',
  'MAIN_CIRCUIT': 'Principal',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Dispositivo atualizado com sucesso',
  'DEVICE_UPDATE_ERROR_MSG': 'O dispositivo não pôde ser atualizado.Por favor, tente novamente mais tarde.',
  'SYSTEM_MAINTENANCE': 'Sistema em manutenção',
  'SYSTEM_MAINTENANCE_MSG': 'Descuple o transtorno, estamos trabalhando para melhorar o serviço.',
  'SOLD_SEPARATELY': '(Vendido separadamente)',
  'REMOVE_ACCOUNT': 'Remover conta',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Remover conta',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'A remoção da conta envolve a exclusão de todos os nós e dados associados a ela. Se você tiver certeza sobre a exclusão da conta, digite sua senha para confirmar.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Conta removida com sucesso',
  'ACCOUNT_REMOVAL_ERROR': 'Não foi possível remover a conta.',
  'USE_QR': 'Escanear Wi-Fi QR',
  'USE_MANUAL_AP': 'Digite as credenciais de Wi-Fi',
  'LOADING': 'Carregando',
  'SIGN_IN_WITH_GOOGLE': 'Iniciar sessão com o Google',
  'LOCK': 'Bloquear',
  'UNLOCK': 'Desbloquear',
  'BRIGHT_LEVEL': 'Nível de luminosidade do ecrã',
  'BACKLIGHT_TIME': 'Tempo di ativação do ecrã',
  'BACKLIGHT_TIME_10': '10 segundos',
  'BACKLIGHT_TIME_20': '20 segundos',
  'BACKLIGHT_TIME_30': '30 segundos',
  'BACKLIGHT_TIME_ALWAYS': 'Sempre',
  'DELETE_ACCOUNT_MSG': 'Ao apagar a conta de utilizador, estará a apagar permanentemente todos os dados associados à mesma. Se concordar, siga os passos abaixo.',
  'DELETE_ACCOUNT_STEPS': 'Passos para eliminar a sua conta',
  'DELETE_ACCOUNT_STEP1': '1. Inicie sessão na aplicação com o seu nome de utilizador e a sua palavra-passe.',
  'DELETE_ACCOUNT_STEP2': '2. Aceda ao menu superior.',
  'DELETE_ACCOUNT_STEP3': '3. Seleccione A minha conta.',
  'DELETE_ACCOUNT_STEP4': '4. Na secção Eliminar conta, clique no botão Eliminar conta.',
  'DEPRECATION_MESSAGE': 'Use o aplicativo TARGET'
 },
 'de': {
  'ASCII_ERROR': 'WLAN-Netzwerknamen und Passwörter dürfen nur ASCII-Zeichen enthalten.',
  'QR_SCANN_ERROR': 'WLAN-Netzwerknamen und Passwörter mit mehr als 32 Zeichen werden nicht unterstützt.',
  'QR_SCANN_ERROR_TITLE': 'Es gab ein Problem mit den eingegebenen Anmeldeinformationen',
  'EXTRA_PRESS_ON_PROVISIONING': 'Drücken Sie eine beliebige Taste, um die Anzeige zu aktivieren',
  'BT_ACTIVATION_WARNING': 'Stellen Sie sicher, dass Bluetooth auf Ihrem Mobiltelefon aktiviert ist',
  'DEVICE_DISCONNECTED_STEP_1': 'Hauptmenü öffnen',
  'DEVICE_DISCONNECTED_STEP_2': 'Gehen Sie zu Häuser und wählen Sie Ihr Zuhause aus',
  'DEVICE_DISCONNECTED_STEP_3': 'Gerät auswählen',
  'DEVICE_DISCONNECTED_STEP_4': 'WLAN konfigurieren',
  'DEVICE_DISCONNECTED_HELP_1': 'Das WLAN-Netzwerk verfügt über eine Internetverbindung und ist ein 2,4-GHz-Netzwerk.',
  'DEVICE_DISCONNECTED_HELP_2': 'Berücksichtigen Sie bei der Geräteinstallation Bereiche mit ausreichender WLAN-Abdeckung.',
  'DEVICE_DISCONNECTED_HELP_3': 'Überprüfen und konfigurieren Sie die WLAN-Anmeldeinformationen für Ihr Gerät direkt über <a class="alink" href=\'{{link}}\'>Hier< neu oder folgen Sie dieser Reihenfolge:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'Das Gerät ist bereits registriert, aber nicht richtig angeschlossen. Versuchen Sie, Ihr Gerät vom Stecker zu trennen und es erneut anzuschließen. Überprüfen Sie, ob eine Verbindung zu Ihrem WLAN-Netzwerk besteht. Wenn nicht, beachten Sie bitte folgende Punkte:',
  'DEVICE_DISCONNECTED_HEADER': 'Beim Bereitstellungsprozess ist ein Problem aufgetreten. Das Gerät könnte verloren gehen.',
  'DEVICE_INSTALLATION_ERROR': 'Es gab ein Problem bei der Installation des Geräts.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Überprüfen Sie den Status der LEDs des Geräts. Wählen Sie einen der Zustände für weitere Informationen, um das Problem zu lösen:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'Das Gerät hat keine zugeordnete IP.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'Das Gerät ist nicht mit dem Server verbunden.',
  'DEVICE_INSTALLATION_ERROR_OK': 'Das Gerät ist korrekt verbunden.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Stellen Sie sicher, dass das Gerät über das Ethernetkabel korrekt mit WiFi verbunden ist.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Wiederholen Sie den Installationsprozess.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Überprüfen Sie die Internetverbindung des Routers und stellen Sie sicher, dass keine Portbeschränkungen vorliegen.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Schließen Sie die Anwendung und öffnen Sie sie erneut, um zu überprüfen, ob das Gerät korrekt installiert wurde.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Überprüfen Sie den Status der LED des Geräts. Wählen Sie einen der Zustände für weitere Informationen, um das Problem zu lösen:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'Das WiFi-Netzwerk wurde nicht konfiguriert.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'Das Gerät ist nicht mit dem Router verbunden.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Überprüfen Sie den Gerätestatus, indem Sie den Status des Antennenicons betrachten. Wählen Sie einen der Zustände für weitere Informationen, um das Problem zu lösen:',
  'SERVER_CONNECTION_ERROR': 'Die Anwendung kann keine Verbindung zum Server herstellen. Überprüfen Sie die Internetverbindung Ihres SmartPhones und wiederholen Sie den Vorgang',
  'AP_FALLBACK_TITLE': 'Lassen Sie uns den Prozess mit der Access Point-Methode neu starten',
  'HANDSHAKE_WARNING': 'Bitte stellen Sie sicher, dass Ihr Smartphone jetzt mit demselben WLAN-Netzwerk verbunden ist.',
  'GEOLOCATION_RANGE': 'Gehäuse Radio',
  'PROVISIONING_METHOD_HELP': 'Wählen Sie eine Bereitstellungsmethode aus, um Ihr Gerät zu verbinden',
  'RECOMMENDED': 'Empfohlen',
  'BACK': 'Zurück',
  'HELP': 'Hilfe',
  'SUPPORT': 'Support',
  'MY_ACCOUNT': 'Mein Konto',
  'HOMES': 'Häuser',
  'PRIVACY': 'Datenschutz',
  'VERSION': 'Version',
  'PRIVACY_INFO': 'Hier können Sie die persönlichen Informationen sehen, die wir über Sie haben. Sie können Ihre Rechte an der unten angegebenen Kontakt-E-Mail-Adresse ausüben',
  'HOME_GEOLOCATION': 'Geolokalisierung von Zuhause',
  'LATITUDE': 'Breite',
  'LONGITUDE': 'Längengrad',
  'CONTACT_EMAIL': 'Kontakt Email',
  'PP_POLICY_CHANGE_WARNING': 'Die Datenschutzerklärung und die Bedingungen und Konditionen haben sich geändert. Ihre Zustimmung ist notwendig, um fortzufahren',
  'PP_READ_AND_ACCEPTED': 'Ich habe die <a href=\'{{frontendHost}}/de/privacy.html\' target=\'_blank\' class=\'alink\'>Datenschutzerklärung</a> und die <a href=\'{{frontendHost}}/de/terms.html\' target=\'_blank\' class=\'alink\'>Bedingungen und Konditionen</a>gelesen und akzeptiere sie hiermit',
  'ACCEPTED': 'Akzeptiert',
  'SIGN_OUT': 'Abmelden',
  'SIGN_IN': 'Anmeldung',
  'SIGN_UP': 'Anmeldung',
  'EMAIL': 'E-mail',
  'PASSWORD': 'Kennwort',
  'ALL_FIELDS_REQUIRED': 'Sie müssen alle Felder ausfüllen',
  'REMEMBER_ME': 'Erinnere mich',
  'NEW_ON_HELKI': 'Neuer Anwender? Registrieren Sie sich hier!',
  'I_FORGOT_MY_PASSWORD': 'Ich habe mein Kennwort vergessen',
  'TYPE_EMAIL': 'Ihre E-Mail Adresse',
  'TYPE_PASSWORD': 'Ihr Kennwort',
  'CONFIRM_PASSWORD': 'Wiederholen Sie Ihr Kennwort',
  'INVALID_EMAIL': 'Ungültige E-Mail',
  'SIGN_IN_ERROR': 'E-Mail oder Kennwort falsch',
  'INVALID_PASSWORD': 'Ungültiges Kennwort',
  'PASSWORDS_DO_NOT_MATCH': 'Die Kennwörter stimmen nicht überein',
  'USER_CONFLICT_ERROR': 'E-Mail ist bereits registriert',
  'SIGN_UP_SUCCESS': 'Eintragung erfolgreich! Wir haben Ihnen eine E-Mail zur Kontobestätigung zugeschickt',
  'FORGOT_PASS_MESSAGE': 'Keine Sorge. Geben Sie einfach die E-Mail-Adresse Ihrer Registrierung ein, um eine Passwort-Rückstellung zu veranlassen',
  'RESET_PASSWORD': 'Passwort zurücksetzen',
  'USER_NOT_FOUND_ERROR': 'Benutzer nicht gefunden',
  'FORGOT_PASS_SUCCESS': 'Wir haben Ihnen eine E-Mail gesendet um Ihr Passwort zurückzusetzen',
  'SIGN_UP_VERIFY_SUCCESS': 'Ihr Konto wurde erfolgreich verifiziert',
  'SIGN_UP_VERIFY_ERROR': 'Ihr Konto konnte nicht verifiziert werden. Bitte kontaktieren Sie uns',
  'CHANGE_EMAIL_SUCCESS': 'Ihre E-Mail wurde erfolgreich aktualisiert',
  'CHANGE_EMAIL_ERROR': 'Ihre E-Mailadresse konnte nicht aktualisiert werden. Bitte kontaktieren Sie uns',
  'RESET_PASS_SUCCESS': 'Ihr Passwort wurde zurückgesetzt',
  'RESET_PASS_ERROR': 'Ihr Passwort konnte nicht zurückgesetzt werden. Bitte kontaktieren Sie uns',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'E-Mail ändern',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Wir haben eine E-Mail auf Ihr neues Konto geschickt, um die Änderung abzuschließen',
  'CONNECTION_ERROR_MSG': 'Verbindung zum Server nicht möglich',
  'TRY_AGAIN': 'Wiederholen',
  'NOW': 'Temp.:',
  'HEATING': 'Heizen',
  'ELECTRICITY': 'Energie',
  'WEATHER': 'Wetter',
  'TEMPERATURE': 'Temperatur',
  'TURN_OFF': 'Ausschalten',
  'TURN_ON': 'Einschalten',
  'SCHEDULE': 'Zeitprogramme',
  'SETUP': 'Einrichten',
  'SET_TEMPERATURE': 'Soll-Temperatur',
  'SET_COMFORT_TEMPERATURE': 'Soll-Komforttemperatur',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Achtung! Wenn du die Komfort Temperatur änderst, wird die Eco Temperatur automatisch mit angepasst.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Bitte nur gleiche Heizungssysteme auswählen (Heizungen können nicht der selben Gruppe zugeordnet werden wie Heizungen mit Geolocation)',
  'REMOVE': 'Entfernen',
  'CANCEL': 'Abbrechen',
  'CONFIRM': 'Bestätigen',
  'RECORDS': 'Aufzeichnung',
  'SELECT_A_DAY': 'Wählen Sie einen Tag',
  'DAY1_SHORT': 'Mo',
  'DAY2_SHORT': 'Di',
  'DAY3_SHORT': 'Mi',
  'DAY4_SHORT': 'Do',
  'DAY5_SHORT': 'Fr',
  'DAY6_SHORT': 'Sa',
  'DAY7_SHORT': 'So',
  'MONDAY': 'Montag',
  'TUESDAY': 'Dienstag',
  'WEDNESDAY': 'Mittwoch',
  'THURSDAY': 'Donnerstag',
  'FRIDAY': 'Freitag',
  'SATURDAY': 'Samstag',
  'SUNDAY': 'Sonntag',
  'MONDAY_SHORT': 'Mo',
  'TUESDAY_SHORT': 'Di',
  'WEDNESDAY_SHORT': 'Mi',
  'THURSDAY_SHORT': 'Do',
  'FRIDAY_SHORT': 'Fr',
  'SATURDAY_SHORT': 'Sa',
  'SUNDAY_SHORT': 'So',
  'COPY_DAY': 'Tag kopieren',
  'ADD': 'neu hinzufügen',
  'FROM': 'Von',
  'TO': 'bis',
  'SELECT_A_DAY_MSG': 'Wählen Sie einen Tag um die Thermostatprogrammierung zu ändern',
  'EDIT': 'bearbeiten',
  'START': 'Anfang',
  'END': 'Ende',
  'DONE': 'erledigt',
  'COPY_DAYS': 'Tag kopieren',
  'USE_SAME_SCHEDULE_ON': 'Mit der gleichen Programmierung ...',
  'COPY': 'Kopie',
  'POWER_METER': 'Energiemessgerät',
  'USAGE': 'Verbrauch',
  'USAGE_VALLEY': 'Lastverbrauch',
  'USAGE_PEAK': 'Kein Lastverbrauch',
  'REAL_TIME_USAGE': 'Aktueller Verbrauch',
  'DAILY': 'Täglich',
  'MONTHLY': 'Monatlich',
  'ANNUAL': 'Jährlich',
  'JAN': 'Jan',
  'FEB': 'Feb',
  'MAR': 'Mär',
  'APR': 'Apr',
  'MAY': 'Mai',
  'JUN': 'Jun',
  'JUL': 'Jul',
  'AUG': 'Aug',
  'SEP': 'Sep',
  'OCT': 'Okt',
  'NOV': 'Nov',
  'DEC': 'Dez',
  'POWER': 'Power',
  'NOMINAL_POWER': 'Nennleistung',
  'EFFECTIVE_POWER': 'Wirkleistung',
  'ZERO_POWER_WARNING': 'Dieses Gerät hat keine eingestellte Leistung. Stellen Sie es hier ein',
  'SET_MODE_TEMP': 'Einstellen Temp.',
  'SET_MODE_TEMPERATURE': 'Stellen Sie die Temperatur ein',
  'COMFORT': 'Komfort',
  'ECO': 'Eco',
  'ANTI_ICE': 'Frostschutz ',
  'INSTALL': 'Installieren',
  'INSTALL_ERROR_MSG': 'Fehler im Installationsprozess. Versuchen Sie es nochmal.',
  'MORE': 'Mehr',
  'INSTALL_DEVICE': 'Gerät hinzufügen',
  'SELECT_ALL': 'Alle ausw.',
  'DESELECT_ALL': 'Abwl ausw.',
  'THERMOSTAT': 'Thermostat',
  'HEATER': 'Heizung',
  'HEATER_HAVERLAND': 'Heizung mit Geolokalisierung',
  'HEATER_WITH_SMARTBOX': 'Heizung mit Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Handtuchwärmer mit Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'WLAN Heizung mit 4 Knöpfen',
  'WIFI_HEATER_W2BUTTONS': 'WLAN Heizung mit 2 Knöpfen',
  'INSTALL_THERMOSTAT_STEP1': 'Thermostat hinzufügen: Schritt 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Drücken Sie die <strong>SUCHE NACH GERÄT STARTEN</strong> um mit der Installation des Thermostat',
  'INSTALL_THERMOSTAT_STEP2': 'Thermostat hinzufügen: Schritt 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Um das Thermostat mit der Box zu koppeln, drücken Sie <strong> Standby </ strong> (die linke Taste) und <strong> Link </ strong> (die rechte Taste) für <strong> 3 Sekunden </ strong>, bis das Netzwerk-Symbol in der oberen rechten Ecke des Thermostats erscheint (wie im Bild gezeigt).',
  'START_SEARCH': 'Suche nach Gerät starten',
  'SEARCHING': 'suchen...',
  'INSTALL_HEATER_STEP1': 'Heizung installieren: Schritt 1',
  'INSTALL_HEATER_HELP_STEP1': 'Drücken Sie die <strong>SUCHE NACH GERÄT STARTEN</strong> um mit der Installation des Heizung.',
  'INSTALL_HEATER_STEP2': 'Heizung installieren: Schritt 2',
  'INSTALL_HEATER_HELP_STEP2': 'Um die elektrische Heizung mit der SmartBox zu verbinden, <strong>DRÜCKEN SIE</strong> die <strong>MODE/OK</strong> taste am Heizgerät für <strong> 3 Sekunden </strong>, bis das Netzwerk-Symbol in der rechten oberen Ecke am Bildschirm des Heizgeräts erscheint.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Stellen Sie sicher, dass sich der Heizkörper im Standby-Modus befindet und halten Sie die Temperaturtasten nach oben und unten mindestens 3 Sekunden lang gedrückt.',
  'STORAGE_HEATER': 'Wärmespeicher',
  'STORAGE_HEATERS': 'Wärmespeichern',
  'INSTALL_STORAGE_HEATER_STEP1': 'Wärmespeicher installieren: Schritt 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Drücken Sie die <strong>SUCHE NACH GERÄT STARTEN</strong> um mit der Installation des Wärmespeicher.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Wärmespeicher installieren: Schritt 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Um den elektrischen Wärmespeicher mit der SmartBox zu verbinden, <strong>DRÜCKEN SIE</strong> die <strong>OK</strong> taste am Heizgerät für <strong>3 Sekunden</strong>, bis das Netzwerk-Symbol in der rechten oberen Ecke am Bildschirm des Heizgeräts erscheint.',
  'CHARGE_PROGRAM': 'Ladeprogramm',
  'VALLEY_1': 'Zeit 1',
  'VALLEY_2': 'Zeit 2',
  'INSTALL_POWER_METER_STEP1': 'Energiemessgerät installieren: Schritt 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Drücken Sie die <strong>SUCHE NACH GERÄT STARTEN</strong> um mit der Installation des Energiemessgerät.',
  'INSTALL_POWER_METER_STEP2': 'Energiemessgerät installieren: Schritt 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Um das Energiemessgerät zu verbinden, benutzten Sie die den mitgelieferten Clip und drücken den  kleinen Knopf  oben auf dem Gerät (siehe Bild oben). Schauen Sie auf das Gerät um den Verbindungsstatus zu prüfen. LED.',
  'LED_INDICATOR_STATES': 'LED-Anzeige-Status',
  'UNLINKED': 'Nicht verbunden',
  'LED_OFF': 'LED aus',
  'CORRECTLY_LINKED': 'richtig verbunden',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'Die LED blinkt einmal alle zwei 1/2 Sekunden .',
  'LINKED_BUT_LOST': 'Gepaart aber verloren',
  'LED_BLINKS_EVERY_HALF_SEC': 'Die LED blinkt einmal jede halbe Sekunde. Dies geschieht z.B. wenn das Gerät ausgeschaltet wird. Kommuniziert die TK-Anlage wieder mit dem Zähler, wird automatisch eine Verbindung herstellt.',
  'NAME': 'Name',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Wählen Sie einen Namen zur Identifizierung des Geräts',
  'TYPE': 'Typ',
  'HEAT': 'Wärme',
  'COLD': 'kalt',
  'CONTROL': 'Steuerung',
  'SELECT': 'Wählen Sie ...',
  'HYST_0P2': 'Hysterese (0,2 ºC)',
  'HYST_0P3': 'Hysterese (0,3 ºC)',
  'HYST_0P4': 'Hysterese (0,4 ºC)',
  'HYST_0P5': 'Hysterese (0,5 ºC)',
  'HYST_0P7': 'Hysterese (0,7 ºC)',
  'HYST_0P8': 'Hysterese (0,8 ºC)',
  'HYST_1P6': 'Hysterese (1,6 ºC)',
  'HYST_0P4F': 'Hysterese (0,4 ºF)',
  'HYST_0P5F': 'Hysterese (0,5 ºF)',
  'HYST_0P7F': 'Hysterese (0,7 ºF)',
  'HYST_0P9F': 'Hysterese (0,9 ºF)',
  'HYST_1P2F': 'Hysterese (1,2 ºF)',
  'HYST_1P4F': 'Hysterese (1,4 ºF)',
  'HYST_2P9F': 'Hysterese (2,9 ºF)',
  'PID_T10MIN': 'PID (zeit 10 min.)',
  'PID_T15MIN': 'PID (zeit 15 min.)',
  'PID_T20MIN': 'PID (zeit 20 min.)',
  'PID_T25MIN': 'PID (zeit 25 min.)',
  'PID_T30MIN': 'PID (zeit 30 min.)',
  'PID_T45MIN': 'PID (zeit 45 min.)',
  'PID_T60MIN': 'PID (zeit 60 min.)',
  'ANTI_GRIPPAGE': 'Anti-Blockier',
  'CONTRACTED_POWER_W': 'verfügbare Leistung (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Geben Sie Ihre vertraglich vereinbarten Leistung in Watt ein',
  'PRIORITY': 'Reihenfolge',
  'LOW': 'niedrig',
  'MEDIUM': 'mittel',
  'HIGH': 'hoch',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Fenstermodus',
  'DELETE_DEVICES': 'Geräte entfernen',
  'DELETE_DEVICES_WARNING': 'Die ausgewählten Geräte und ihre zugeordneten Daten werden gelöscht',
  'DELETE': 'löschen',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Die maximale Gesamtleistung aller Heizungen',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Maximale Leistung für alle Heizungen(W)',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Maximale Leistung für alle Heizungen(W)',
  'LIMITLESS': 'Unbegrenzt',
  'HOME_INFO': 'Haus info',
  'HOME_NAME': 'Raumname',
  'TYPE_HOME_NAME': 'Geben Sie den Namen des Hauses ein',
  'TIME_ZONE': 'Zeitzone',
  'TIME_ZONE_CET': '(Central Europe Time) Berlin, Paris, Madrid, Rom',
  'TIME_ZONE_WET': '(Westeuropäische Zeit) London, Dublin, Lissabon, Kanarische Inseln',
  'TIME_ZONE_EET': '(Eastern European Time) Helsinki, Sofia, Athen',
  'TIME_ZONE_PET': '(Peruvian Time) Lima',
  'TIME_ZONE_NZST': '(New Zealand Standard Time) Auckland',
  'TIME_ZONE_CIST': '(Chatham Island Standard Time) Chatham Islands',
  'TIME_ZONE_CST': '(China Standard Time) Shanghai',
  'TIME_ZONE_UYT': '(Uruguay Time) Montevideo',
  'DEVICE_IDENTIFIER': 'Gerätekennung',
  'TYPE_DEVICE_IDENTIFIER': 'Geben Sie die ID des Gerätes ein',
  'INVALID_IDENTIFIER': 'Die Kennung ist ungültig',
  'DEVICE_ALREADY_TAKEN': 'Das Gerät mit der ID DEVID ist bereits registriert. Bitte wenden Sie sich an den technischen Support, um dieses Problem zu melden.',
  'COPY_ERROR_DATA': '  Geräte-ID kopieren',
  'MY_HOMES': 'Meine Häuser',
  'ADD_HOME': 'Ein neues Haus hinzufügen',
  'SAVE': 'Speichern',
  'MY_INFO': 'Meine Info',
  'USER': 'Benutzer',
  'LANGUAGE': 'Sprache',
  'CHANGE_EMAIL_ADDRESS': 'E-Mail Adresse ändern',
  'NEW_EMAIL_ADDRESS': 'Neue E-Mail Adresse',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Geben Sie Ihre neue E-Mai einl',
  'CONFIRM_WITH_PASSWORD': 'Mit Kennwort bestätigen',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Geben Sie Ihr aktuelles Kennwort ein',
  'CHANGE_PASSWORD': 'Kennwort Änderung',
  'CURRENT_PASSWORD': 'Derzeitiges Kennwort',
  'TYPE_YOUR_NEW_PASSWORD': 'Geben Sie Ihr neues Kennwort ein',
  'NEW_PASSWORD': 'Neues Kennwort',
  'TEMPERATURE_UNITS': 'Temperaturauswahl',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Selbst-lernen ',
  'SELF_LEARN_SHORT': 'Selbst-lern.',
  'PRESENCE': 'Gegenwart',
  'AWAY_OFFSET': 'Ausserhaus-Funktion',
  'ERASE_DATA_PERMANENTLY': 'Dauerhaftes löschen der Daten',
  'NO_CONNECTION_WARNING': 'Warnung: Keine Verbindung',
  'SET_MODE': 'Wähle den Modus',
  'AT_HOME': 'Zuhause',
  'AWAY': 'Ausser haus',
  'DEVICES_SUMMARY': 'Geräte-Zusammenfassung',
  'THERMOSTATS': 'Thermostate',
  'HEATERS': 'Heizungen',
  'POWER_METERS': 'Meter',
  'CLOSE': 'schließen',
  'DETECT_SMARTBOX': 'SmartBox ermitteln',
  'DETECT_SMARTBOX_HELP_MSG': 'Um Ihre SmartBox zu verbinden, drücken Sie den Konpf an der SmartBox. Stellen Sie sicher, dass dieses ordnungsgemäß mit dem drahtlosen Netzwerk verbunden ist.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Sorry, aber es war nicht möglich, die SmartBox zu finden. Bitte weiter klicken und manuell die Geräte-ID eingeben.',
  'CONTINUE': 'Fortsetzen',
  'CANCEL_SEARCH': 'Suche abbrechen',
  'SEARCHING_NEW_DEVICES': 'Suche nach neuen Geräten ...',
  'DISCOVERY_MODE_MSG': 'SmartBox {{devId}} im Discovery-Modus. Drücken Sie die entsprechende Taste auf dem Gerät wie in der Anleitung beschrieben',
  'DEVICE_FOUND_MSG': 'Es wurde ein neues Gerät gefunden!',
  'GETTING_STARTED': 'Start',
  'MAIN_MENU': 'Hauptmenü ',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Zugriff auf die  Allgemeine Einstellungen </ strong>',
  'NEXT': 'Weiter',
  'GEOLOCATION': 'Geolokalisierung',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Weg</ strong> wird standardmäßig um 2ºC die Temperatur reduzieren',
  'DEVICES': 'Geräte',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Die Geräte sind in Abschnitte unterteilt  Heizen </ strong> und  Power </ strong>',
  'GO_TO_A_DEVICE': 'zum Gerät',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Einrichtung des Gerätes und Zugriff auf seine Statistiken',
  'MULTIDEVICE_ACTIONS': 'mehrere Aktionen',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Wählen Sie das Gerät um Zugriff auf die Optionen zu erhalten  Mehr </ strong>',
  'READY': 'bereit',
  'SKIP': 'Überspringen',
  'SKIP_HELP': 'Hilfe überspringen',
  'QUICK_SETTING': 'Schnelleinstellung',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Schieben Sie die Temperaturleiste seitlich weg um schnell zwischen Temperaturen zu wechseln.',
  'TEMPERATURES': 'Temperaturen',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Stellen Sie die Temperaturen für  verfügbare Modi ein',
  'MODE': 'Modus',
  'WORKING_MODES': 'Modi',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Wählen Sie einen der zur Verfügung stehenden Modi.',
  'SELECT_MODE': 'Wählen Sie den  Modus',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Markieren Sie die Stunden, in denen der Modus gilt.',
  'SUPPORT_BODY': 'Ihr Konto hat einen Mindestzeitraum von 3 Jahren ab dem Datum des Produktkaufs für Sicherheitsupdates. Für den Fall, dass Sie auf Sicherheits- oder andere Probleme aufmerksam werden, senden Sie uns bitte eine E-Mail an die unten angegebene Adresse, in der Sie das Problem beschreiben.',
  'SUPPORT_REQUEST_INTRO_HELP': 'In der linken unteren Ecke finden Sie eine Hilfe-Schaltfläche, um einige Tipps zur Verwendung der App anzuzeigen.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Wenn Sie Unterstützung wünschen, wenden Sie sich bitte an den folgenden Kontakt:',
  'ADDRESS': 'Adresse',
  'TELEPHONE': 'Telefon',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'Durch die Registrierung, akzeptiere ich  <a href=\'{{frontendHost}}/de/terms.html\' target=\'_blank\'>die Bedingungen und Konditionen</a> und <a href=\'{{frontendHost}}/de/privacy.html\' target=\'_blank\'>Datenschutz</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/de/privacy.html\' target=\'_blank\'>Datenschutz</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Ihr Passwort wurde erfolgreich geändert',
  'PASSWORD_CHANGE_ERROR_MSG': 'Bitte prüfen Sie ob Ihr aktuelles Passwort korrekt ist.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Ungültige email',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Email bereits belegt',
  'EMAIL_CHANGE_ERROR_MSG': 'Es war nicht möglich zu aktualisierenihre email. Bitte versuchen wiederspäter.',
  'HOME_TYPE': 'Haus Typ',
  'ETHERNET': 'Kabelverbindung',
  'WIFI': 'WLAN Verbindung',
  'DEVICE_REGISTER_ERROR': 'Der Registrierungsprozess konnte nicht vervollständigt werden',
  'WRONG_CREDENTIALS_ERROR': 'falscher Benutzer/Passwort',
  'DEVICE_ADDED_MSG': 'Neues Gerät hinzugefügt',
  'CONNECTION_WARNING': 'WLAN Verbindung',
  'WIFI_CONNECTION_1': 'Öffnen Telefon Einstellungen',
  'WIFI_CONNECTION_2': 'auswählen \'WLAN\'',
  'WIFI_CONNECTION_3': 'verbinden Sie Telefon mit WLAN ',
  'WIFI_CONNECTION_4': 'Wenn Sie sehen eine Nachricht wie folgt: \'WLAN hat keinen Internet Zugang\', Auswahl \'JA\' um verbunden zu bleiben',
  'WIFI_CONNECTION_5': 'zurück hierher',
  'DEVICE_CONNECTION_ERROR': 'Verbindung zum Gerät konnte nicht hergestellt werden',
  'WIFI_SCAN_ERROR': 'Kein Netzwerk gefunden',
  'WRONG_PASS_ERROR': 'Aktuelle Konfiguration WLAN hat falsches Passwort',
  'WRONG_ESSID_ERROR': 'Aktuelle Konfiguration WLAN existiert nicht',
  'SET_WIFI_ERROR': 'Konnte kein WLAN Netzwerk erstellen',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Verbindung erfolgreich',
  'WIFI_SELECTION': 'Auswahl Ihres WLAN Netzwerks',
  'WIFI_NOT_FOUND': 'Kein Netzwerk gefunden',
  'TYPE_AP_ESSID': 'Typ Ihres Netzwerks Namen',
  'TYPE_AP_PASS': 'Typ Ihres Netzwerks Passwort',
  'RETRY': 'erneuter Versuch',
  'AP_DATA': 'Netzwerk Daten',
  'AP_ESSID': 'Name',
  'AP_PASS': 'Passwort',
  'EDIT_WIFI': 'Erstellung WLAN Konfiguration',
  'WRONG_WIFI_ERROR': 'Sie sind nicht verbunden zum Korrespondierenden WLAN',
  'COPY_PASS': 'Kopieren Passwort',
  'EDIT_DATA': 'Hausdaten',
  'WEB_WIFI_WARNING': 'WLAN Konfiguration nur verfügbar über APP',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Bis zu 9 Temperaturblöcke hinzufügen',
  'ACCEPT': 'Akzeptieren',
  'INVITE_USERS': 'Benutzern einladen',
  'INVITE_USER': 'Benutzer einladen',
  'INVITED_USERS': 'Eingeladene Benutzer',
  'INVITE_USER_TITLE': 'Benutzer einladen',
  'INVITE_USER_SUCCESS_MSG': 'Neuer Benutzer eingeladen. Eine E-Mail wurde an den Gast gesendet, um ihn darüber zu informieren, dass er Zugang zu diesem Haus hat',
  'INVITE_USER_ERROR_MSG': 'Fehler beim Einladen des Benutzers.Bitte versuchen Sie wieder später.',
  'REVOKE_INVITE_TITLE': 'Eingeladenen Benutzer löschen',
  'REVOKE_INVITE_MSG': 'Der ausgewählte Benutzer hat keinen Zugriff mehr auf dieses Haus',
  'REVOKE_INVITE_SUCCESS_MSG': 'Der eingeladener Benutzer wurde gelöscht',
  'REVOKE_INVITE_ERROR_MSG': 'Fehler beim Löschen des EingeladenenBenutzers. Bitte versuchen Sie wiederspäter.',
  'CONFIRM_INVITE_SUCCESS': 'Ihr Konto wurde erfolgreich erstellt',
  'GUEST': 'Gast',
  'TRV': 'TRV',
  'TRVS': 'TRVs',
  'INSTALL_TRV_STEP1': 'TRV hinzufügen: Schritt 1',
  'INSTALL_TRV_HELP_STEP1': 'Drücken Sie die <strong>SUCHE NACH GERÄT STARTEN</strong> um mit der Installation de TRV.',
  'INSTALL_TRV_STEP2': 'TRV hinzufügen: Schritt 2',
  'INSTALL_TRV_HELP_STEP2': 'Um das TRV mit der Box zu koppeln, drücken Sie <strong> Helki </ strong> (die mittlere Taste) für <strong> 3 Sekunden </ strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Wärme/Kalt Mode Wechsel',
  'CONTROL_MODE_CHANGE_MSG': 'Warnung: Das aktuelle ZeitProgramm wird zurückgesetzt werden',
  'SAVE_THIS_POSITION': 'Sichern Sie diese Position',
  'SET_POSITION_MSG': 'Sie haben Ihr Haus noch nicht positioniert. Bitte wählen Sie die Position in der Karte aus und speichern Sie diese, indem Sie auf die Schaltfläche unten klicken.',
  'ENABLE_GEOLOCATION': 'Geolokalisierung aktivieren',
  'OUTDOOR_RANGE': 'Heimradius (m.)',
  'DEV_TYPE_WIFI': 'WiFi SmartBox',
  'DEV_TYPE_WIFIHTR': 'WiFi-Heizkörper',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Geräteauswahl',
  'SELECT_DEVICE_THM_HELP': 'Der Thermostat wird dem ausgewählten Gerät zugeordnet.',
  'SELECT_DEVICE_HTR_HELP': 'Der Heizkörper wird dem ausgewählten Gerät zugeordnet.',
  'SELECT_DEVICE_ACM_HELP': 'Der Akku wird dem ausgewählten Gerät zugeordnet.',
  'SELECT_DEVICE_PMO_HELP': 'Der Leistungsmesser wird dem ausgewählten Gerät zugeordnet.',
  'SELECT_DEVICE_TRV_HELP': 'Das Ventil wird dem ausgewählten Gerät zugeordnet.',
  'ADD_DEVICE': 'Fügen Sie ein Gerät hinzu',
  'DEVICE_INSTALLATION': 'Geräteinstallation',
  'WIFI_HEATER': 'WiFi-Heizkörper',
  'WIFI_HEATER_HAVERLAND': 'Connect-Serie',
  'LETS_START': 'Lasst uns beginnen',
  'INSTALL_WIFI_HEATER_HELP': 'Verbinden Sie Ihr Heizgerät mit dem Stromnetz. Drücken Sie die OK-Taste des Heizgeräts bis das Display blinkt.',
  'INSTALL_WIFI_HEATER2_HELP': 'Schließen Sie Ihren Heizkörper an die Steckdose an. Drücken Sie die im folgenden Bild gezeigten Tasten, bis der Bildschirm blinkt.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Verbinden Sie Ihre WiFi SmartBox. Wenn es bereits verbunden ist, trennen Sie es und verbinden Sie es erneut.',
  'DEV_TYPE_WIFI_CONNECTION': 'WiFi SmartBox-Verbindung',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'WiFi-Heizkörper-Verbindung',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Jetzt verbinden wir die SmartBox mit dem WLAN-Netzwerk Ihres Hauses.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Jetzt schließen wir die Heizkörper an das WLAN-Netzwerk Ihres Hauses an.',
  'GHZ_WIFI_WARNING': 'Warnung: Es sind nur 2,4-GHz-Netze gültig.',
  'CONFIGURING_WIFI_HTR': 'Konfigurieren oder WiFi Radiator',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'WiFi SmartBox-Konfiguration im AP-Modus',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'WiFi Radiator Konfiguration im AP Modus',
  'SET_ETHERNET_SMARTBOX_NAME': 'Wie nennen wir das die SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Wie nennen wir das die SmartBox WiFi?',
  'SET_WIFI_HTR_NAME': 'Wie nennen wir diesen WiFi-Radiator?',
  'TYPE_DEVICE_NAME': 'Geben Sie einen Namen für Ihr Gerät ein',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'SmartBox WiFi-Verbindung',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'WiFi Heizkörperanschluss',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Verbunden mit WiFi SmartBox mit id:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Verbunden mit WiFi-Heizkörper mit id:',
  'MY_DEVICES': 'Meine Geräte',
  'DEVICE_TYPE': 'Wie sieht Ihr Gerät aus?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'SmartBox WiFi-Verbindung',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'WiFi-Heizkörperanschluss',
  'CONFIGURING_DEV_TYPE_WIFI': 'SmartBox WiFi Konfiguration',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'WiFi Heizkörper Konfiguration',
  'DEVICE_LIST': 'Geräte',
  'CONNECTION_STATE': 'Verbindungsstatus',
  'CONFIGURE_WIFI': 'WLAN konfigurieren',
  'CHANGE_HOME': 'Umzug in ein anderes Zuhause',
  'DELETE_DEVICE': 'Gerät entfernen',
  'TRANSFER_HOME_TITLE': 'Umzug in ein anderes Zuhause',
  'TRANSFER_HOME_WARNING': 'Bitte beachten Sie: Das Gerät wird in das ausgewählte Haus übertragen.',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Gerät entfernen',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Möchten Sie dieses Gerät wirklich löschen?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Bitte verbinden oder Ethernet SmartBox',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox WiFi korrekt installiert',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'SmartBox Ethernet korrekt installiert',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'WiFi-Strahler korrekt installiert',
  'HOME_REGISTRY': 'Home-Registrierung',
  'HOME_REGISTRY_HELP': 'Im Moment haben Sie noch kein Zuhause registriert. Fügen wir einen neuen hinzu.',
  'SET_HOME_NAME': 'Legen Sie den Heimnamen fest',
  'SET_HOME_TIMEZONE': 'Zeitzone für Zuhause festlegen',
  'DEVICE_CONNECTION_START': 'Jetzt schließen wir Ihr Gerät an',
  'WIFI_DEVICE': 'WiFi-Gerät',
  'WIFI_DEVICE_HELP': 'Die Installation von WiFi-Geräten ist nur in unseren Anwendungen verfügbar',
  'TYPE_ESSID': 'Geben Sie Ihr WiFi-Netzwerk ein',
  'REGISTERING_DEVICE': 'Gerät registrieren',
  'WELCOME': 'Willkommen',
  'ESSID': 'WLAN wählen',
  'SET_BOOST_MODE': 'Stellen Sie den Boost-Modus ein',
  'BOOST_END_TIME': 'Boost-Modus Endzeit',
  'BOOST_ACTIVE_UNTIL': 'Boost-Modus aktiv bis',
  'OPERATIONAL_MODE': 'Betriebsmodus',
  'FIXED_TEMPERATURE': 'Feste Temperatur',
  'WEEKLY_SCHEDULE': 'Wöchentliche Programmierung',
  'ANTIFROST_PROTECTION': 'Frostschutz',
  'ID_COPIED': 'Kennung kopiert',
  'NEW_PROFILE': 'Neues Profil',
  'PROFILE_COLOR': 'Farbe',
  'TYPE_PROFILE_NAME': 'Geben Sie einen Namen für das Profil ein',
  'TYPE_POWER_LIMIT': 'Geben Sie einen Leistungswert ein',
  'POWER_LIMIT': 'Potenz (W)',
  'EDIT_PROFILES': 'Profile',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Profil löschen',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Sind Sie sicher, dass Sie dieses Profil entfernen möchten?',
  'POWER_MANAGEMENT': 'Kontrahierte Leistungskonfiguration',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Muss zwischen sein ',
  'AND': ' und ',
  'MANUAL_NETWORK_INPUT': 'Manuell eintragen',
  'GEOLOCATION_INFO': 'Mit dieser Funktion können Sie die Temperatur der Geräte senken, wenn Sie sich außerhalb des festgelegten Ausgangsradius befinden.',
  'GEOLOCATION_NEW_FEAT': 'Entdecken sie die funktion, mit der sie den Geräteverbrauch sparen können, wenn Sie nicht zu hause sind.',
  'VOICE_CONTROL': 'Sprachassistent',
  'VOICE_CONTROL_NEW_FEAT': 'Denken sie daran dass dieses Gerät mit kompatibel ist: ',
  'CONFIGURE': 'Konfigurieren',
  'MORE_INFO_LONG': 'Mehr informationen',
  'GEOLOCATION_RATIONALE': 'Für den ordnungsgemäßen Betrieb des Geolokalisierung-Modus benötigt diese App jederzeit Zugriff auf Ihren Standort, wenn Sie das Telefon nicht verwenden. Einstellungen zulassen.',
  'USER_DUTY_FACTOR': 'Leistungsbegrenzun gsfaktor',
  'CIRCUIT_TYPE': 'Hauptstromkreis',
  'PRINCIPAL_CIRCUIT': 'Circuito Principal',
  'REVERSE_SIGN': 'Umgekehrtes Vorzeichen',
  'SMARTBOX_CONFIGURATION': 'SmartBox-Konfiguration',
  'MAIN_CIRCUIT_GENERATION': 'Hauptgenerationsschaltung',
  'MAIN_CIRCUIT_CONSUMPTION': 'Hauptverbrauchskreislauf',
  'SELECT_GENERATION_POWERMETER': 'Wählen Sie einen Generationszähler',
  'SELECT_CONSUMPTION_POWERMETER': 'Wählen Sie einen Verbrauchszähler',
  'POWERMETER_CONFIG': 'Messgeräte-Setup',
  'MAX_POWER_TERM_MANAGEMENT': 'Angeheuerte potenz',
  'MANAGE_MAX_POWER': 'Zeitprogramme',
  'MANAGE_ENERGY_TARIFFS': 'Zeitprogramme',
  'COLOR': 'Farbe',
  'CONSUMPTION': 'Verbrauch',
  'GENERATION': 'Generation',
  'EDIT_ENERGY_TARIFFS': 'Profile',
  'SELECT_MAX_POWER_CONFIG': 'Wählen Sie ein Kontrahierte Leistungskonfiguration',
  'CUSTOM_TARIFF_CONFIG': 'Neues benutzerdefiniertes profil',
  'ANTI_FROST_IN_OFF': 'Frostschutz',
  'POWER_LEVEL_LIMIT': 'Leistungspegel Limit',
  'E2_USAGE_ENABLED': 'Solarenergie',
  'E2_MODE': 'Ladeparameter',
  'E2_ACTIVATION_PER': 'Solarstrom Aktivierung',
  'E2_CHARGE_EXCESS_PER': 'Maximale Ladung Nebentarif',
  'NODE_E2_MODE_0': 'Solarüberschuss',
  'NODE_E2_MODE_1': 'Solarüberschuss + Netz',
  'NODE_E2_MODE_2': 'Solarüberschuss + Netz + Heizelement',
  'E2_TEMP_EXCESS': 'Umgebungstemperatur Offset',
  'E2_PRIORITY': 'Ladungs-Priorität',
  'MAIN_CIRCUIT': 'Main',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Gerät erfolgreich aktualisiert',
  'DEVICE_UPDATE_ERROR_MSG': 'Das gerät konnte nicht aktualisiertwerden. Bitte versuchen sie es späternoch einmal.',
  'SYSTEM_MAINTENANCE': 'System in wartung',
  'SYSTEM_MAINTENANCE_MSG': 'Entschuldigen sie die unannehmlichkeiten, wir arbeiten daran den service zu verbessern.',
  'SOLD_SEPARATELY': '(Wird separat verkauft)',
  'REMOVE_ACCOUNT': 'Konto entfernen',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Konto entfernen',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'Das Entfernen des Kontos beinhaltet das Löschen aller Knoten und damit verbundenen Daten. Wenn Sie sicher sind, dass Sie das Konto löschen möchten, geben Sie zur Bestätigung Ihr Passwort ein.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Konto erfolgreich entfernt',
  'ACCOUNT_REMOVAL_ERROR': 'Konto konnte nicht entfernt werden.',
  'USE_QR': 'Scannen Sie WLAN-QR',
  'USE_MANUAL_AP': 'Geben Sie die WLAN-Anmeldedaten ein',
  'LOADING': 'Laden',
  'SIGN_IN_WITH_GOOGLE': 'Über Google anmelden',
  'LOCK': 'Sperren',
  'UNLOCK': 'Entsperren',
  'BRIGHT_LEVEL': 'Helligkeit der anzeige',
  'BACKLIGHT_TIME': 'Bildschirm-Aufwachzeit',
  'BACKLIGHT_TIME_10': '10 sekunden',
  'BACKLIGHT_TIME_20': '20 sekunden',
  'BACKLIGHT_TIME_30': '30 sekunden',
  'BACKLIGHT_TIME_ALWAYS': 'Immer',
  'DELETE_ACCOUNT_MSG': 'Mit der Löschung des Benutzerkontos werden alle damit verbundenen Daten endgültig gelöscht. Wenn Sie damit einverstanden sind, führen Sie bitte die folgenden Schritte aus.',
  'DELETE_ACCOUNT_STEPS': 'Schritte zum Löschen Ihres Kontos',
  'DELETE_ACCOUNT_STEP1': '1. Melden Sie sich mit Ihrem Benutzernamen und Passwort bei der Anwendung an.',
  'DELETE_ACCOUNT_STEP2': '2. Gehen Sie zum oberen Menü.',
  'DELETE_ACCOUNT_STEP3': '3. Wählen Sie Mein Konto.',
  'DELETE_ACCOUNT_STEP4': '4. Klicken Sie im Abschnitt Konto löschen auf die Schaltfläche Konto löschen.',
  'DEPRECATION_MESSAGE': 'Bitte nutzen Sie die TARGET-App'
 },
 'pl': {
  'ASCII_ERROR': 'Nazwy sieci Wi-Fi i hasła mogą zawierać wyłącznie znaki ASCII.',
  'QR_SCANN_ERROR': 'Nazwy sieci Wi-Fi i hasła zawierające więcej niż 32 znaki nie są obsługiwane.',
  'QR_SCANN_ERROR_TITLE': 'Wystąpił problem z wprowadzonymi danymi uwierzytelniającymi',
  'EXTRA_PRESS_ON_PROVISIONING': 'Naciśnij dowolny przycisk, aby włączyć wyświetlacz',
  'BT_ACTIVATION_WARNING': 'Upewnij się, że masz włączoną funkcję Bluetooth w swoim telefonie komórkowym',
  'DEVICE_DISCONNECTED_STEP_1': 'Otwórz menu główne',
  'DEVICE_DISCONNECTED_STEP_2': 'Przejdź do Domy i wybierz swój dom',
  'DEVICE_DISCONNECTED_STEP_3': 'Wybierz urządzenie',
  'DEVICE_DISCONNECTED_STEP_4': 'Skonfiguruj Wi-Fi',
  'DEVICE_DISCONNECTED_HELP_1': 'Sieć Wi-Fi ma połączenie z Internetem i działa w częstotliwości 2,4 GHz.',
  'DEVICE_DISCONNECTED_HELP_2': 'Podczas instalacji urządzenia weź pod uwagę obszary z odpowiednim zasięgiem Wi-Fi.',
  'DEVICE_DISCONNECTED_HELP_3': 'Sprawdź i ponownie skonfiguruj poświadczenia Wi-Fi dla swojego urządzenia bezpośrednio z <a class="alink" href=\'{{link}}\'>Tutaj< lub wykonaj następującą sekwencję:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'Urządzenie jest już zarejestrowane, ale nie jest prawidłowo podłączone. Spróbuj odłączyć urządzenie od wtyczki i podłączyć je ponownie. Sprawdź, czy jest podłączony do Twojej sieci Wi-Fi. Jeśli nie, rozważ następujące punkty:',
  'DEVICE_DISCONNECTED_HEADER': 'Wystąpił problem w procesie udostępniania, urządzenie mogło zostać utracone.',
  'DEVICE_INSTALLATION_ERROR': 'Wystąpił problem podczas instalacji urządzenia.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Sprawdź stan diod LED urządzenia. Wybierz jeden z stanów, aby uzyskać więcej informacji i pomóc w rozwiązaniu problemu:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'Urządzenie nie ma przypisanego adresu IP.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'Urządzenie nie jest połączone z serwerem.',
  'DEVICE_INSTALLATION_ERROR_OK': 'Urządzenie jest poprawnie podłączone.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Upewnij się, że urządzenie jest poprawnie podłączone do WiFi za pomocą kabla Ethernet.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Powtórz proces instalacji.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Sprawdź połączenie internetowe routera i upewnij się, że nie ma żadnych ograniczeń portów.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Zamknij i ponownie otwórz aplikację, aby sprawdzić, czy urządzenie zostało poprawnie zainstalowane.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Sprawdź stan diod LED urządzenia. Wybierz jeden z stanów, aby uzyskać więcej informacji i pomóc w rozwiązaniu problemu:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'Sieć WiFi nie została skonfigurowana.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'Urządzenie nie jest połączone z routerem.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Sprawdź stan urządzenia, patrząc na stan ikony anteny. Wybierz jeden z stanów, aby uzyskać więcej informacji i pomóc w rozwiązaniu problemu:',
  'SERVER_CONNECTION_ERROR': 'Aplikacja nie może połączyć się z serwerem. Sprawdź połączenie internetowe swojego smartfona i spróbuj ponownie',
  'AP_FALLBACK_TITLE': 'Zrestartujmy proces metodą Access Point',
  'HANDSHAKE_WARNING': 'Upewnij się, że Twój smartfon jest teraz podłączony do tej samej sieci Wi-Fi.',
  'GEOLOCATION_RANGE': 'Promień domu',
  'PROVISIONING_METHOD_HELP': 'Wybierz metodę obsługi administracyjnej, aby podłączyć urządzenie',
  'RECOMMENDED': 'Zalecana',
  'BACK': 'Powrót',
  'HELP': 'Pomoc',
  'SUPPORT': 'Wsparcie',
  'MY_ACCOUNT': 'Moje konto',
  'HOMES': 'Pomieszczenia',
  'PRIVACY': 'Prywatność',
  'VERSION': 'Wersja',
  'PRIVACY_INFO': 'Tutaj możesz zobaczyć dane osobowe, które mamy o Tobie. Prawa możesz wykonywać na kontaktowy adres e-mail podany poniżej',
  'HOME_GEOLOCATION': 'Geolokalizacja domu',
  'LATITUDE': 'Szerokość',
  'LONGITUDE': 'Długość geograficzna',
  'CONTACT_EMAIL': 'Kontaktowy adres e-mail',
  'PP_POLICY_CHANGE_WARNING': 'Polityka prywatności i warunki zostały zmienione. Twoja zgoda jest konieczna, aby kontynuować',
  'PP_READ_AND_ACCEPTED': 'Przeczytałem i akceptuję <a href=\'{{frontendHost}}/pl/privacy.html\' target=\'_blank\' class=\'alink\'>Politykę Prywatności</a> oraz <a href=\'{{frontendHost}}/pl/terms.html\' target=\'_blank\' class=\'alink\'>warunki</a>',
  'ACCEPTED': 'Zaakceptowana',
  'SIGN_OUT': 'Wyloguj ',
  'SIGN_IN': 'Zaloguj',
  'SIGN_UP': 'Zarejestruj',
  'EMAIL': 'Adres e-mail',
  'PASSWORD': 'Hasło',
  'ALL_FIELDS_REQUIRED': 'Należy wypełnić wszystkie pola',
  'REMEMBER_ME': 'Zapamiętaj mnie',
  'NEW_ON_HELKI': 'Nowy użytkownik: zarejestruj się tutaj',
  'I_FORGOT_MY_PASSWORD': 'Nie pamiętam hasła',
  'TYPE_EMAIL': 'Podaj adres e-mail',
  'TYPE_PASSWORD': 'Wpisz hasło',
  'CONFIRM_PASSWORD': 'Potwierdź hasło',
  'INVALID_EMAIL': 'Nieprawidłowy adres e-mail',
  'SIGN_IN_ERROR': 'Błąd logowania „email albo hasło nieprawidłowe',
  'INVALID_PASSWORD': 'Niepoprawne hasło',
  'PASSWORDS_DO_NOT_MATCH': 'Niezgodne hasła, spróbuj ponownie',
  'USER_CONFLICT_ERROR': 'Adres e-mail został już zarejestrowany',
  'SIGN_UP_SUCCESS': 'Konto zostało utworzone. na podany adres e-mail wysłano potwierdzenie założenia konta.',
  'FORGOT_PASS_MESSAGE': 'Poprawna rejestracja ! wysłaliśmy e-maila w celu  potwierdzenia konta',
  'RESET_PASSWORD': 'Zresetuj hasło',
  'USER_NOT_FOUND_ERROR': 'Nie znaleziono użytkownika',
  'FORGOT_PASS_SUCCESS': 'Wysłaliśmy e-maila do zresetowania hasła',
  'SIGN_UP_VERIFY_SUCCESS': 'Weryfikacja konta powiodła się',
  'SIGN_UP_VERIFY_ERROR': 'Weryfikacja konta nie powiodła się. skontaktuj się z nami',
  'CHANGE_EMAIL_SUCCESS': 'Zmieniono adres e-mail',
  'CHANGE_EMAIL_ERROR': 'Zmiana adresu e-mail nie powiodła się. prosimy o kontakt',
  'RESET_PASS_SUCCESS': 'Hasło zostało zresetowane',
  'RESET_PASS_ERROR': 'Próba zresetowania hasła nie powiodła się. prosimy o kontakt',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Zmień adres e-mail',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Wysłaliśmy ci  e-mail na nowe konto celem potwierdzenia ',
  'CONNECTION_ERROR_MSG': 'Nie można połączyć się z serwerem',
  'TRY_AGAIN': 'Spróbuj ponownie',
  'NOW': 'Teraz:',
  'HEATING': 'Ogrzewanie',
  'ELECTRICITY': 'Licznik',
  'WEATHER': 'Pogoda',
  'TEMPERATURE': 'Temperatura',
  'TURN_OFF': 'Wyłącz',
  'TURN_ON': 'Włącz',
  'SCHEDULE': 'Haromonogram',
  'SETUP': 'Ustawienia',
  'SET_TEMPERATURE': 'Ustaw temperaturę',
  'SET_COMFORT_TEMPERATURE': 'Ustaw temperaturę komfortu',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Uwaga! Jeśli zmienisz Temperaturę komfortową, Temperatura Eco zostanie automatycznie dostosowana.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Proszę wybrać tylko ten sam system grzejników (Grzejników nie można grupować z Grzejnikami z Geolokalizacją)',
  'REMOVE': 'Usunąć',
  'CANCEL': 'Anuluj',
  'CONFIRM': ' Potwierdź',
  'RECORDS': 'Aufzeichnung',
  'SELECT_A_DAY': 'Zapis',
  'DAY1_SHORT': 'Pn',
  'DAY2_SHORT': 'Wt',
  'DAY3_SHORT': 'Śr',
  'DAY4_SHORT': 'Cz',
  'DAY5_SHORT': 'Pt',
  'DAY6_SHORT': 'So',
  'DAY7_SHORT': 'Nd',
  'MONDAY': 'Poniedziałek',
  'TUESDAY': 'Wtorek',
  'WEDNESDAY': 'Środa',
  'THURSDAY': 'Czwartek',
  'FRIDAY': 'Piątek',
  'SATURDAY': 'Sobota',
  'SUNDAY': 'Niedziela',
  'MONDAY_SHORT': 'Pn',
  'TUESDAY_SHORT': 'Wt',
  'WEDNESDAY_SHORT': 'Śr',
  'THURSDAY_SHORT': 'Cz',
  'FRIDAY_SHORT': 'Pt',
  'SATURDAY_SHORT': 'So',
  'SUNDAY_SHORT': 'Nd',
  'COPY_DAY': 'Kopiuj dzień',
  'ADD': 'Dodaj',
  'FROM': 'Od',
  'TO': 'Do',
  'SELECT_A_DAY_MSG': 'Wybierz dzień w celu zmiany programowania termostatu',
  'EDIT': 'Edytuj',
  'START': 'Początek',
  'END': 'Koniec',
  'DONE': 'Gotowe',
  'COPY_DAYS': 'Kopiuj dni',
  'USE_SAME_SCHEDULE_ON': ' Użyj tego samego programu...',
  'COPY': 'Kopiuj',
  'POWER_METER': 'Miernik mocy',
  'USAGE': 'Zużycie',
  'USAGE_VALLEY': 'Zużycie ładunku',
  'USAGE_PEAK': 'Zużycie bez ładunku',
  'REAL_TIME_USAGE': 'Bieżące zużycie',
  'DAILY': 'Dzienne',
  'MONTHLY': 'Miesięczne',
  'ANNUAL': 'Roczne',
  'JAN': 'Sty',
  'FEB': 'Lut',
  'MAR': 'Mar',
  'APR': 'Kwi',
  'MAY': 'Maj',
  'JUN': 'Cze',
  'JUL': 'Lip',
  'AUG': 'Sie',
  'SEP': 'Wrz',
  'OCT': 'Paź',
  'NOV': ' Lis',
  'DEC': 'Gru',
  'POWER': 'Zasilanie',
  'NOMINAL_POWER': 'Zasilanie nominalna',
  'EFFECTIVE_POWER': 'Zasilanie efektywna',
  'ZERO_POWER_WARNING': 'Ta grzałka nie ma przypisanej mocy. Ustaw tutaj',
  'SET_MODE_TEMP': 'Ustaw temperaturę',
  'SET_MODE_TEMPERATURE': 'Ustaw temperaturę',
  'COMFORT': 'Komfort',
  'ECO': 'Eko',
  'ANTI_ICE': 'Antyzamrorzeniowa',
  'INSTALL': 'Dodaj',
  'INSTALL_ERROR_MSG': 'Proces instalacji nie powiódł się. Spróbuj ponownie.',
  'MORE': 'Więcej',
  'INSTALL_DEVICE': 'Dodaj urządzenie',
  'SELECT_ALL': 'Zaznacz. wsz.',
  'DESELECT_ALL': 'Odzcz. wsz.',
  'THERMOSTAT': 'Termostat',
  'HEATER': 'Grzejnik',
  'HEATER_HAVERLAND': 'Grzejnik z geolokalizacją',
  'HEATER_WITH_SMARTBOX': 'Grzejnik z Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Grzejnik na ręczniki z Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'Grzejnik WiFi z 4 przyciskami',
  'WIFI_HEATER_W2BUTTONS': 'Grzejnik WiFi z 2 przyciskami',
  'INSTALL_THERMOSTAT_STEP1': 'Dodaj termostat: Krok 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Naciśnij przycisk <strong>ZACZNIJ SZUKAĆ</<strong>, aby rozpocząć instalację termostat.',
  'INSTALL_THERMOSTAT_STEP2': 'Dodaj termostat: Krok 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Pomoc w instalacji termostatu: Aby podłączyć termostat do urządzenia, naciśnij i przytrzymaj <strong> OK </strong>. <strong> 3 sek. </strong>. Środkowy przycisk. symbol połączenia pojawi się w prawym górnym rogu wyświetlacza termostatu (jak na zdjęciu).',
  'START_SEARCH': 'Zacznij szukać',
  'SEARCHING': 'WYSZUKIWANIE...',
  'INSTALL_HEATER_STEP1': 'Dodaj (Zainstaluj) Grzejnik: Krok 1',
  'INSTALL_HEATER_HELP_STEP1': 'Naciśnij przycisk <strong>ZACZNIJ SZUKAĆ</strong>, aby rozpocząć instalację grzejnik.',
  'INSTALL_HEATER_STEP2': 'Dodaj (Zainstaluj) Grzejnik: Krok 2',
  'INSTALL_HEATER_HELP_STEP2': 'Pomoc w instalacji grzejnika: aby podłączyć urządzenia, naciśnij i przytrzymaj <strong> MODE/OK </strong>. <strong> 3 sek. </strong> środkowy przycisk „OK” w prawym górnym rogu wyświetlacza termostatu pokaże symbol połączenia (jak na zdjęciu)',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Upewnij się, że grzejnik jest w trybie gotowości i przytrzymaj przyciski podnoszenia i obniżania temperatury przez co najmniej 3 sekundy.',
  'STORAGE_HEATER': 'Podgrzewacz akumulacyjny',
  'STORAGE_HEATERS': 'Grzejniki akumulacyjne',
  'INSTALL_STORAGE_HEATER_STEP1': 'Zainstaluj Podgrzewacz akumulacyjny: Krok 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Naciśnij przycisk <strong>ZACZNIJ SZUKAĆ</strong>, aby rozpocząć instalację Podgrzewacz akumulacyjny.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Zainstaluj Podgrzewacz akumulacyjny: Krok 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Pomoc w instalacji podgrzewacz akumulacyjny: aby podłączyć urządzenia, naciśnij i przytrzymaj  <strong>MODE/OK .  3 sek.</strong>  środkowy przycisk „OK” w prawym górnym rogu wyświetlacza termostatu pokaże symbol połączenia (jak na zdjęciu)',
  'CHARGE_PROGRAM': 'Charge Program',
  'VALLEY_1': 'Okres 1',
  'VALLEY_2': 'Okres 2',
  'INSTALL_POWER_METER_STEP1': 'Zainstaluj miernik: Krok 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Naciśnij przycisk <strong>ZACZNIJ SZUKAĆ</strong>, aby rozpocząć instalację miernik.',
  'INSTALL_POWER_METER_STEP2': 'Zainstaluj miernik: Krok 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Pomoc w instalacji miernika w celu skonfigórowania licznikaimpulsów z dostarczonym clipem, mały przycisk.',
  'LED_INDICATOR_STATES': 'Stan diody led',
  'UNLINKED': 'Niepołączony',
  'LED_OFF': 'Wyłączony',
  'CORRECTLY_LINKED': 'Prawidłowo połączony ',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'Dioda led miga co 2,5 S.',
  'LINKED_BUT_LOST': 'Połączenie zostało utracone',
  'LED_BLINKS_EVERY_HALF_SEC': 'Dioda led miga co 2 s. tak dzieje się gdy np. urządzenie zostanie wyłączone. jesli komunikacja z licznikem zostanie wznowiona następuje automaczne połączenie.',
  'NAME': 'Nazwa',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Wybierz nazwę w celu identyfikacji urządzenia',
  'TYPE': 'Rodzaj',
  'HEAT': 'Ciepło',
  'COLD': 'Zimno',
  'CONTROL': 'Sterowanie',
  'SELECT': 'Wybierz …',
  'HYST_0P2': 'Hysterese (0,2 ºC)',
  'HYST_0P3': 'Hysterese (0,3 ºC)',
  'HYST_0P4': 'Hysterese (0,4 ºC)',
  'HYST_0P5': 'Hysterese (0,5 ºC)',
  'HYST_0P7': 'Hysterese (0,7 ºC)',
  'HYST_0P8': 'Hysterese (0,8 ºC)',
  'HYST_1P6': 'Hysterese (1,6 ºC)',
  'HYST_0P4F': 'Hysterese (0,4 ºF)',
  'HYST_0P5F': 'Hysterese (0,5 ºF)',
  'HYST_0P7F': 'Hysterese (0,7 ºF)',
  'HYST_0P9F': 'Hysterese (0,9 ºF)',
  'HYST_1P2F': 'Hysterese (1,2 ºF)',
  'HYST_1P4F': 'Hysterese (1,4 ºF)',
  'HYST_2P9F': 'Hysterese (2,9 ºF)',
  'PID_T10MIN': 'PID (zeit 10 min.)',
  'PID_T15MIN': 'PID (zeit 15 min.)',
  'PID_T20MIN': 'PID (zeit 20 min.)',
  'PID_T25MIN': 'PID (zeit 25 min.)',
  'PID_T30MIN': 'PID (zeit 30 min.)',
  'PID_T45MIN': 'PID (zeit 45 min.)',
  'PID_T60MIN': 'PID (zeit 60 min.)',
  'ANTI_GRIPPAGE': 'Funkcja anti block przy słabej bateri',
  'CONTRACTED_POWER_W': 'Moc zakontraktowana (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Podaj moc zakontraktowaną (W)',
  'PRIORITY': 'Kolejność (ważność)',
  'LOW': 'Niska',
  'MEDIUM': 'Średnia',
  'HIGH': 'Wysoka',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Fenstermodus',
  'DELETE_DEVICES': 'Usuń urządzenia',
  'DELETE_DEVICES_WARNING': ', Usuń urządzenia: wybrane urządzenia i przypisane do nich dane zostaną usunięte',
  'DELETE': 'Usuń',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Osiągnięto maksymalną moc wszystkich grzejników.',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Maksymalna moc wszyskich grzejników (W)',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Podaj maksymalną moc dla wszystkich grzejników (W)',
  'LIMITLESS': 'Bez ograniczeń',
  'HOME_INFO': 'Informacje o pomieszczeniu',
  'HOME_NAME': 'Nazwa pomieszczenia',
  'TYPE_HOME_NAME': 'Nazwa pomieszczenia',
  'TIME_ZONE': 'Strefa czasowa',
  'TIME_ZONE_CET': 'Strefa Czasowa (CET) Berlin, Paryż, Madryt, Rzym',
  'TIME_ZONE_WET': 'Strefa Czasowa (WET) Londyn, Dublin, Lizbona, Wyspy kanaryjskie',
  'TIME_ZONE_EET': 'Strefa Czasowa (EET) Helsinki, Sofia, Ateny',
  'TIME_ZONE_PET': 'Strefa Czasowa (PET) Lima',
  'TIME_ZONE_NZST': 'Strefa Czasowa (NZST) Auckland',
  'TIME_ZONE_CIST': 'Strefa Czasowa (CIST) Chatham Islands',
  'TIME_ZONE_CST': 'Strefa Czasowa (CST) Shanghai',
  'TIME_ZONE_UYT': 'Strefa Czasowa (UYT) Montevideo',
  'DEVICE_IDENTIFIER': 'Id urządzenia (Nr. identyfikacyjny)',
  'TYPE_DEVICE_IDENTIFIER': 'Podaj id urządzenia',
  'INVALID_IDENTIFIER': 'Podane id jest nieprawidłowe',
  'DEVICE_ALREADY_TAKEN': 'Urządzenie o ID DEVID jest już zarejestrowane. Skontaktuj się z pomocą techniczną, aby zgłosić ten problem.',
  'COPY_ERROR_DATA': '  Skopiuj identyfikator urządzenia',
  'MY_HOMES': 'Moje pomieszczenia',
  'ADD_HOME': 'Dodaj nowe pomieszczenie',
  'SAVE': 'Zapisz zmiany',
  'MY_INFO': 'Moje informacje',
  'USER': 'Użytkownik',
  'LANGUAGE': 'Język',
  'CHANGE_EMAIL_ADDRESS': 'Zmień adres e-mail',
  'NEW_EMAIL_ADDRESS': 'Nowy adres e-mail',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Podaj nowy adres e-mail',
  'CONFIRM_WITH_PASSWORD': 'Potwierdź hasłem',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Wpisz aktualne hasło',
  'CHANGE_PASSWORD': 'Zmień hasło',
  'CURRENT_PASSWORD': 'Bieżące hasło',
  'TYPE_YOUR_NEW_PASSWORD': 'Wpisz nowe hasło',
  'NEW_PASSWORD': 'Nowe hasło',
  'TEMPERATURE_UNITS': 'Wybór temperatury',
  'CELSIUS': 'Stopnie celsjusza',
  'FAHRENHEIT': 'Stopnie fahrenheita',
  'SELF_LEARN': 'Samouczek',
  'SELF_LEARN_SHORT': 'Samouczek',
  'PRESENCE': 'Obecność',
  'AWAY_OFFSET': 'Funkcja poza domem',
  'ERASE_DATA_PERMANENTLY': 'Trwałe usuwanie danych',
  'NO_CONNECTION_WARNING': 'Brak połączenia',
  'SET_MODE': 'Wybierz tryb',
  'AT_HOME': 'W domu',
  'AWAY': 'Poza domem',
  'DEVICES_SUMMARY': 'Podsumowanie urządzeń',
  'THERMOSTATS': 'Termostaty',
  'HEATERS': 'Grzejniki',
  'POWER_METERS': 'Mierniki mocy',
  'CLOSE': 'Zamknij',
  'DETECT_SMARTBOX': 'Wyszukaj SmartBox',
  'DETECT_SMARTBOX_HELP_MSG': ', Wyszukaj SmartBox: w celu wyszukania SmartBoxa naciśnij odpowiedni przycisk i spradz prawidłowość połączenia.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Błąd  podczas wyszukiwania SmartBoxa wprowadz manualnie numer id urządzenia.',
  'CONTINUE': 'Dalej',
  'CANCEL_SEARCH': 'Anuluj wyszukiwanie',
  'SEARCHING_NEW_DEVICES': 'Wyszukiwanie nowych urządzeń ...',
  'DISCOVERY_MODE_MSG': '{{devId}} w trybie wykrywania naciśnij odpowiedni przycisk na urządzeniu (zgodnie z instrukcją obsługi)',
  'DEVICE_FOUND_MSG': 'Wykryto nowe urządzenie',
  'GETTING_STARTED': 'Przejdź do pomocy',
  'MAIN_MENU': 'Menu główne',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Idź do opcji  ogólne ustawienia',
  'NEXT': 'Dalej',
  'GEOLOCATION': 'Geolokalizacja',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Poza domem- obniżenie temperatury domyślnie o 2ºC',
  'DEVICES': 'Urządzenia',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Twoje urządzenia zostały podzielone w sekcjach ogrzewanie i pomiar zużycia energii',
  'GO_TO_A_DEVICE': 'Przejdź do urządzenia',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Konfiguracja i statystyki urządzenia',
  'MULTIDEVICE_ACTIONS': 'Ustawienia dla grupy urządzeń',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': ', Wybierz urządzenia i przejdź do ustawień wciskając przycisk Więcej',
  'READY': 'Gotowe',
  'SKIP': 'Pomiń',
  'SKIP_HELP': 'Pomiń pomoc',
  'QUICK_SETTING': 'Szybka konfiguracja ustawień',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Przesuń suwak, aby szybko zmienić temperaturę',
  'TEMPERATURES': 'Temperatury',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Wybierz temperaturę dla dostępnych trybów',
  'MODE': 'Tryb',
  'WORKING_MODES': 'Tryby',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Wybierz jeden z dostępnych trybów',
  'SELECT_MODE': 'Wybierz tryb',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Wybierz tryb dla każdego przedziału czasu',
  'SUPPORT_BODY': 'Minimalny okres pomocy technicznej dla aktualizacji zabezpieczeń na koncie wynosi 3 lata od daty zakupu produktu. W przypadku, gdy dowiesz się o jakichkolwiek problemach z bezpieczeństwem lub innych, wyślij nam wiadomość e-mail na poniższy adres z opisem problemu.',
  'SUPPORT_REQUEST_INTRO_HELP': 'Prośba o pomoc',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Prośba o kontakt i pomoc',
  'ADDRESS': 'Adres',
  'TELEPHONE': 'Telefon',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'Dokonując rejestracji akceptuję <a href=\'{{frontendHost}}/pl/terms.html\' target=\'_blank\'>warunki</a> i regulamin producenta <a href=\'{{frontendHost}}/pl/privacy.html\' target=\'_blank\'>Datenschutz</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/pl/privacy.html\' target=\'_blank\'>Datenschutz</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Twoje hasło zostało pomyślnie zmienione',
  'PASSWORD_CHANGE_ERROR_MSG': 'Sprawdź, czy bieżące hasło jestpoprawne.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Invalid email',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Email already taken',
  'EMAIL_CHANGE_ERROR_MSG': 'It was not possible to update your email.Please try again later.',
  'HOME_TYPE': 'Home type',
  'ETHERNET': 'Cable connection',
  'WIFI': 'WiFi connection',
  'DEVICE_REGISTER_ERROR': 'The register process could not be completed',
  'WRONG_CREDENTIALS_ERROR': 'Bad user/password',
  'DEVICE_ADDED_MSG': 'New device added',
  'CONNECTION_WARNING': 'WiFi connection',
  'WIFI_CONNECTION_1': 'Open phone settings',
  'WIFI_CONNECTION_2': 'Select WiFi',
  'WIFI_CONNECTION_3': 'Connect your phone to WiFi',
  'WIFI_CONNECTION_4': 'If you see a notification stating this: \'WiFi has no Internet access\', select \'Yes\' to stay connected',
  'WIFI_CONNECTION_5': 'Come back here',
  'DEVICE_CONNECTION_ERROR': 'Could not connect to the device',
  'WIFI_SCAN_ERROR': 'No networks found',
  'WRONG_PASS_ERROR': 'Currently configured WiFi has wrong password',
  'WRONG_ESSID_ERROR': 'Currently configured WiFi does not exist',
  'SET_WIFI_ERROR': 'Could not establish WiFi network',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Connection succesful',
  'WIFI_SELECTION': 'Select your WiFi network',
  'WIFI_NOT_FOUND': 'Can\'t find my network',
  'TYPE_AP_ESSID': 'Type your network\'s name',
  'TYPE_AP_PASS': 'Type your network\'s password',
  'RETRY': 'Retry',
  'AP_DATA': 'Network data',
  'AP_ESSID': 'Name',
  'AP_PASS': 'Password',
  'EDIT_WIFI': 'Edit WiFi configuration',
  'WRONG_WIFI_ERROR': 'You are not connected to the corresponding WiFi',
  'COPY_PASS': 'Copy password',
  'EDIT_DATA': 'Dane domu',
  'WEB_WIFI_WARNING': 'WiFi configuration is only available in our app',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Dodać do 9 bloków temperatury',
  'ACCEPT': 'Zaakceptować',
  'INVITE_USERS': 'Zaproś użytkowników',
  'INVITE_USER': 'Zaproś użytkownika',
  'INVITED_USERS': 'Zaproszeni użytkownicy',
  'INVITE_USER_TITLE': 'Zaproś użytkownika',
  'INVITE_USER_SUCCESS_MSG': 'Zaproszenie nowego użytkownika. E-mail został wysłany do gościa, aby poinformować go, że ma dostęp do tego domu',
  'INVITE_USER_ERROR_MSG': 'Błąd podczas zaproszenia użytkownika.Spróbuj ponownie później.',
  'REVOKE_INVITE_TITLE': 'Usuń zaproszonego użytkownika',
  'REVOKE_INVITE_MSG': 'Wybrany użytkownik nie będzie już mieć dostępu do tego domu',
  'REVOKE_INVITE_SUCCESS_MSG': 'Zaproszony użytkownik został usunięty',
  'REVOKE_INVITE_ERROR_MSG': 'Podczas usuwania zaproszonegoużytkownika wystąpił błąd. Spróbujponownie później.',
  'CONFIRM_INVITE_SUCCESS': 'Twoje konto zostało pomyślnie utworzone',
  'GUEST': 'Gość',
  'TRV': 'TRV',
  'TRVS': 'TRVs',
  'INSTALL_TRV_STEP1': 'TRV dodać: Krok 1',
  'INSTALL_TRV_HELP_STEP1': 'Naciśnij przycisk <strong>ZACZNIJ SZUKAĆ</strong>, aby rozpocząć instalację TRV.',
  'INSTALL_TRV_STEP2': 'TRV dodać: Krok 2',
  'INSTALL_TRV_HELP_STEP2': 'Aby sparować TRV z pudełkiem, naciśnij <strong> Helki </ strong> (środkowy przycisk) dla <strong> 3 Sekund </ strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Zmiana trybu ogrzewania / zimna',
  'CONTROL_MODE_CHANGE_MSG': 'Ostrzeżenie: Aktualny program czasowy zostanie zresetowany',
  'SAVE_THIS_POSITION': 'Zapisz to stanowisko',
  'SET_POSITION_MSG': 'Jeszcze nie ustawiłeś swojego domu. Proszę wybrać pozycję na mapie i zapisać ją, klikając przycisk poniżej.',
  'ENABLE_GEOLOCATION': 'Włącz geolokalizacja',
  'OUTDOOR_RANGE': 'Promień domu (m.)',
  'DEV_TYPE_WIFI': 'WiFi SmartBox',
  'DEV_TYPE_WIFIHTR': 'Grzejnik WiFi',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Wybierz urządzenie',
  'SELECT_DEVICE_THM_HELP': 'Termostat zostanie przypisany do następującego urządzenia',
  'SELECT_DEVICE_HTR_HELP': 'Grzejnik zostanie przypisany do następującego urządzenia',
  'SELECT_DEVICE_ACM_HELP': 'Akumulator zostanie przypisany do następującego urządzenia',
  'SELECT_DEVICE_PMO_HELP': 'Miernik mocy zostanie przypisany do następującego urządzenia',
  'SELECT_DEVICE_TRV_HELP': 'Zawór zostanie przypisany do następującego urządzenia',
  'ADD_DEVICE': 'Dodaj urządzenie',
  'DEVICE_INSTALLATION': 'Instalacja urządzenia',
  'WIFI_HEATER': 'Grzejnik WiFi',
  'WIFI_HEATER_HAVERLAND': 'Grzejnik',
  'LETS_START': 'Zaczynajmy',
  'INSTALL_WIFI_HEATER_HELP': 'Naciśnij przycisk z przodu Grzejnika WiFi, aż jego ekran zacznie migać.',
  'INSTALL_WIFI_HEATER2_HELP': 'Podłącz grzejnik do gniazdka. Naciskaj przyciski pokazane na poniższym obrazku, aż ekran zacznie migać.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Podłącz Wi-Fi SmartBox. Jeśli był już podłączony, odłącz go i podłącz ponownie.',
  'DEV_TYPE_WIFI_CONNECTION': 'Połączenie WiFi SmartBox',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'Połączenie Grzałki WiFi',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Teraz podłączymy SmartBox do domowej sieci Wi-Fi.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Teraz podłączymy Grzejnik do domowej sieci Wi-Fi.',
  'GHZ_WIFI_WARNING': 'Ostrzeżenie: obsługiwane są tylko sieci 2,4 GHz',
  'CONFIGURING_WIFI_HTR': 'Konfigurowanie Grzałki WiFi',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'Konfiguracja AP WiFi SmartBox',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'Konfiguracja Grzałki WiFi w trybie AP',
  'SET_ETHERNET_SMARTBOX_NAME': 'Jak nazywamy ten SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Jak nazywamy ten WiFi SmartBox?',
  'SET_WIFI_HTR_NAME': 'Jak nazywamy ten Grzejnik WiFi?',
  'TYPE_DEVICE_NAME': 'Wpisz nazwę urządzenia',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Łączenie z WiFi SmartBox',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Podłączanie do Grzałki WiFi',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Połączony z WiFi SmartBox z identyfikatorem:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Połączony z grzałką WiFi za pomocą identyfikatora:',
  'MY_DEVICES': 'Moje urządzenia',
  'DEVICE_TYPE': 'Jak wygląda twoje urządzenie?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Łączenie z WiFi SmartBox',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Podłączanie do Grzałki WiFi',
  'CONFIGURING_DEV_TYPE_WIFI': 'Konfiguracja WiFi SmartBox',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'Konfigurowanie Grzałki WiFi',
  'DEVICE_LIST': 'Moje urządzenia',
  'CONNECTION_STATE': 'Stan połączenia',
  'CONFIGURE_WIFI': 'Skonfiguruj Wi-Fi',
  'CHANGE_HOME': 'Przenieś się do innego domu',
  'DELETE_DEVICE': 'Usuń urządzenie',
  'TRANSFER_HOME_TITLE': 'Przenieś się do innego domu',
  'TRANSFER_HOME_WARNING': 'Ostrzeżenie: Twoje urządzenie zostanie przeniesione do wybranego homme',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Usuń urządzenie',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Czy na pewno chcesz usunąć to urządzenie?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Podłącz swój Ethernet SmartBox',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'Sukces instalacji WiFi SmartBox',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'Sukces instalacji Ethernet SmartBox',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'Sukces instalacji Grzałki WiFi',
  'HOME_REGISTRY': 'Rejestr domowy',
  'HOME_REGISTRY_HELP': 'W tej chwili nie masz zarejestrowanego domu. Dodajmy nowy.',
  'SET_HOME_NAME': 'Ustaw nazwę domu',
  'SET_HOME_TIMEZONE': 'Ustaw domową strefę czasową',
  'DEVICE_CONNECTION_START': 'Teraz podłączymy twoje urządzenie',
  'WIFI_DEVICE': 'Urządzenie WiFi',
  'WIFI_DEVICE_HELP': 'Instalacja urządzeń WiFi jest dostępna tylko w naszych aplikacjach',
  'TYPE_ESSID': 'Wpisz swoją sieć Wi-Fi',
  'REGISTERING_DEVICE': 'Rejestracja urządzenia',
  'WELCOME': 'Witamy',
  'ESSID': 'Wybierz sieć Wi-Fi',
  'SET_BOOST_MODE': 'Ustaw tryb wzmocnienia',
  'BOOST_END_TIME': 'Czas zakończenia trybu doładowania',
  'BOOST_ACTIVE_UNTIL': 'Tryb przyspieszania aktywny do',
  'OPERATIONAL_MODE': 'Tryb operacyjny',
  'FIXED_TEMPERATURE': 'Naprawiono temperaturę',
  'WEEKLY_SCHEDULE': 'Plan tygodniowy',
  'ANTIFROST_PROTECTION': 'Ochrona przed mrozem',
  'ID_COPIED': 'Identyfikator skopiowany',
  'NEW_PROFILE': 'Nowy profil',
  'PROFILE_COLOR': 'Kolor',
  'TYPE_PROFILE_NAME': 'Nazwa profilu',
  'TYPE_POWER_LIMIT': 'Wprowadzić wartość energetyczną',
  'POWER_LIMIT': 'Potencja (W)',
  'EDIT_PROFILES': 'Profile',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Usuń profil',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Czy na pweno chcesz usunąć ten profil?',
  'POWER_MANAGEMENT': 'Zakontraktowana konfiguracja mocy',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Musi być pomiędzy ',
  'AND': ' i ',
  'MANUAL_NETWORK_INPUT': 'Wprowadzić ręcznie',
  'GEOLOCATION_INFO': 'Ta funkcja pozwala obniżyć temperaturę urządzeń, gdy znajdujesz się poza ustawionym promieniem domu.',
  'GEOLOCATION_NEW_FEAT': 'Odkryj funkcję, która pozwala zaoszczędzić na zużyciu energii przez Twoje urządzenia, gdy nie ma Cię w domu.',
  'VOICE_CONTROL': 'Asystent głosowy',
  'VOICE_CONTROL_NEW_FEAT': 'Pamiętaj, że to urządzenie jest kompatybilne z: ',
  'CONFIGURE': 'Ustawiać',
  'MORE_INFO_LONG': 'Więcej informacji',
  'GEOLOCATION_RATIONALE': 'Do poprawnego działania trybu geolokalizacji ta aplikacja potrzebuje dostępu do Twojej lokalizacji przez cały czas, nawet gdy nie używasz telefonu. Zezwól w ustawieniach.',
  'USER_DUTY_FACTOR': 'Współczynnik ograniczenia mocy',
  'CIRCUIT_TYPE': 'Typ Obwodu',
  'PRINCIPAL_CIRCUIT': 'Główny obwód',
  'REVERSE_SIGN': 'Znak Odwrotny',
  'SMARTBOX_CONFIGURATION': 'Konfiguracja SmartBox',
  'MAIN_CIRCUIT_GENERATION': 'Główny obwód Generacji',
  'MAIN_CIRCUIT_CONSUMPTION': 'Główny obwód Zużycia',
  'SELECT_GENERATION_POWERMETER': 'Wybierz licznik Generacji',
  'SELECT_CONSUMPTION_POWERMETER': 'Wybierz licznik Zużycia',
  'POWERMETER_CONFIG': 'Licznik konfiguracja',
  'MAX_POWER_TERM_MANAGEMENT': 'Wynajęta potencja',
  'MANAGE_MAX_POWER': 'Program',
  'MANAGE_ENERGY_TARIFFS': 'Program',
  'COLOR': 'Kolor',
  'CONSUMPTION': 'Konsumpcja',
  'GENERATION': 'Pokolenie',
  'EDIT_ENERGY_TARIFFS': 'Profile',
  'SELECT_MAX_POWER_CONFIG': 'Wybierz zakontraktowany profil mocy',
  'CUSTOM_TARIFF_CONFIG': 'Nowy profil niestandardowy',
  'ANTI_FROST_IN_OFF': 'Ochrona Przed mrozem',
  'POWER_LEVEL_LIMIT': 'Limit Poziomu mocy',
  'E2_USAGE_ENABLED': 'Energia słoneczna',
  'E2_MODE': 'Rodzaj ładunku',
  'E2_ACTIVATION_PER': 'Aktywacja Energii słonecznej',
  'E2_CHARGE_EXCESS_PER': 'Maksymalne Obciążenie Doliny',
  'NODE_E2_MODE_0': 'Ekskluzywny solar',
  'NODE_E2_MODE_1': 'Słoneczna i dolina',
  'NODE_E2_MODE_2': 'Słoneczna i Skorygowana Dolina',
  'E2_TEMP_EXCESS': 'Zakres temperature Otoczenia',
  'E2_PRIORITY': 'Priorytet ładowania',
  'MAIN_CIRCUIT': 'Główny',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Urządzenie zostało zaktualizowane pomyślnie',
  'DEVICE_UPDATE_ERROR_MSG': 'Urządzenie nie mogło zostaćzaktualizowane. Spróbuj ponowniepóźniej.',
  'SYSTEM_MAINTENANCE': 'System w trakcie konserwacji',
  'SYSTEM_MAINTENANCE_MSG': 'Przepraszam za niedogodności, pracujemy nad ulepszeniem usługi.',
  'SOLD_SEPARATELY': '(Sprzedawane oddzielnie)',
  'REMOVE_ACCOUNT': 'Usuń konto',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Usuń konto',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'Usunięcie konta polega na usunięciu wszystkich węzłów i powiązanych z nim danych. Jeśli jesteś pewien, że chcesz usunąć konto, wprowadź hasło, aby potwierdzić.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Konto pomyślnie usunięte',
  'ACCOUNT_REMOVAL_ERROR': 'Nie można usunąć konta.',
  'USE_QR': 'Zeskanuj kod QR Wi-Fi',
  'USE_MANUAL_AP': 'Wprowadź poświadczenia Wi-Fi',
  'LOADING': 'Ładowanie',
  'SIGN_IN_WITH_GOOGLE': 'Zaloguj się przez Google',
  'LOCK': 'Blokada',
  'UNLOCK': 'Odblokowanie',
  'BRIGHT_LEVEL': 'Poziom jasności ekranu',
  'BACKLIGHT_TIME': 'Czas włączenia ekranu',
  'BACKLIGHT_TIME_10': '10 sekundy',
  'BACKLIGHT_TIME_20': '20 sekundy',
  'BACKLIGHT_TIME_30': '30 sekundy',
  'BACKLIGHT_TIME_ALWAYS': 'Zawsze',
  'DELETE_ACCOUNT_MSG': 'Usunięcie konta użytkownika spowoduje trwałe usunięcie wszystkich powiązanych z nim danych. Jeśli wyrażasz na to zgodę, wykonaj poniższe kroki.',
  'DELETE_ACCOUNT_STEPS': 'Kroki usuwania konta użytkownika',
  'DELETE_ACCOUNT_STEP1': '1. Zaloguj się do aplikacji za pomocą nazwy użytkownika i hasła.',
  'DELETE_ACCOUNT_STEP2': '2. Przejdź do górnego menu.',
  'DELETE_ACCOUNT_STEP3': '3. Wybierz opcję Moje konto.',
  'DELETE_ACCOUNT_STEP4': '4. W sekcji Usuń konto kliknij przycisk Usuń konto.',
  'DEPRECATION_MESSAGE': 'Skorzystaj z aplikacji TARGET'
 },
 'nl': {
  'ASCII_ERROR': 'WiFi-netwerknamen en wachtwoorden mogen alleen ASCII-tekens bevatten.',
  'QR_SCANN_ERROR': 'WiFi-netwerknamen en wachtwoorden met meer dan 32 tekens worden niet ondersteund.',
  'QR_SCANN_ERROR_TITLE': 'Er is een probleem opgetreden met de geïntroduceerde inloggegevens',
  'EXTRA_PRESS_ON_PROVISIONING': 'Druk op een willekeurige knop om het display te activeren',
  'BT_ACTIVATION_WARNING': 'Zorg ervoor dat Bluetooth is ingeschakeld op uw mobiele telefoon',
  'DEVICE_DISCONNECTED_STEP_1': 'Hoofdmenu openen',
  'DEVICE_DISCONNECTED_STEP_2': 'Ga naar Huizen en selecteer je huis',
  'DEVICE_DISCONNECTED_STEP_3': 'Selecteer apparaat',
  'DEVICE_DISCONNECTED_STEP_4': 'Wi-Fi configureren',
  'DEVICE_DISCONNECTED_HELP_1': 'WiFi-netwerk heeft internetverbinding en is een 2,4GHz-netwerk.',
  'DEVICE_DISCONNECTED_HELP_2': 'Houd rekening met gebieden met een goede WiFi-dekking tijdens de installatie van het apparaat.',
  'DEVICE_DISCONNECTED_HELP_3': 'Controleer en configureer de WiFi-inloggegevens voor uw apparaat rechtstreeks vanuit <a class="alink" href=\'{{link}}\'>hier< of volg deze volgorde:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'Het apparaat is al geregistreerd, maar is niet correct aangesloten. Probeer uw apparaat los te koppelen van de stekker en opnieuw aan te sluiten. Controleer of deze is verbonden met uw WiFi-netwerk. Als dit niet het geval is, houd dan rekening met de volgende punten:',
  'DEVICE_DISCONNECTED_HEADER': 'Er is een probleem opgetreden tijdens het inrichtingsproces, het apparaat kan verloren zijn gegaan.',
  'DEVICE_INSTALLATION_ERROR': 'Er was een probleem tijdens de installatie van het apparaat.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Controleer de status van de LED\'s van het apparaat. Selecteer een van de staten voor meer informatie om het probleem op te lossen:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'Het apparaat heeft geen IP-adres toegewezen gekregen.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'Het apparaat is niet verbonden met de server.',
  'DEVICE_INSTALLATION_ERROR_OK': 'Het apparaat is correct verbonden.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Zorg ervoor dat het apparaat correct is verbonden met WiFi via de Ethernetkabel.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Herhaal het installatieproces.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Controleer de internetverbinding van de router en zorg ervoor dat er geen poortbeperkingen zijn.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Sluit de applicatie en open deze opnieuw om te controleren of het apparaat correct is geïnstalleerd.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Controleer de status van de LED van het apparaat. Selecteer een van de staten voor meer informatie om het probleem op te lossen:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'Het WiFi-netwerk is niet geconfigureerd.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'Het apparaat is niet verbonden met de router.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Controleer de status van het apparaat door naar de status van het antenne-icoon te kijken. Selecteer een van de staten voor meer informatie om het probleem op te lossen:',
  'SERVER_CONNECTION_ERROR': 'De applicatie kan geen verbinding maken met de server. Controleer de internetverbinding van uw SmartPhone en probeer het proces opnieuw',
  'AP_FALLBACK_TITLE': 'Laten we het proces opnieuw starten met behulp van de Access Point-methode',
  'HANDSHAKE_WARNING': 'Zorg ervoor dat uw smartphone nu is verbonden met hetzelfde WiFi-netwerk.',
  'GEOLOCATION_RANGE': 'Straal van het huis',
  'PROVISIONING_METHOD_HELP': 'Selecteer een inrichtingsmethode om uw apparaat te verbinden',
  'RECOMMENDED': 'Aanbevolen',
  'BACK': 'Terug',
  'HELP': 'Hulp',
  'SUPPORT': 'Support',
  'MY_ACCOUNT': 'Mijn account',
  'HOMES': 'Woningen',
  'PRIVACY': 'Privacy',
  'VERSION': 'Versie',
  'PRIVACY_INFO': 'Hier kunt u de persoonlijke informatie zien die we over u hebben. U kunt uw rechten uitoefenen op het onderstaande e-mailadres voor contact',
  'HOME_GEOLOCATION': 'Geolocatie van thuis',
  'LATITUDE': 'Breedtegraad',
  'LONGITUDE': 'Lengtegraad',
  'CONTACT_EMAIL': 'Contact email',
  'PP_POLICY_CHANGE_WARNING': 'Het privacybeleid en de algemene voorwaarden zijn gewijzigd. Uw toestemming is noodzakelijk om door te gaan',
  'PP_READ_AND_ACCEPTED': 'Ik heb het <a href=\'{{frontendHost}}/nl/privacy.html\' target=\'_blank\' class=\'alink\'>Privacybeleid</a> en de <a href=\'{{frontendHost}}/nl/terms.html\' target=\'_blank\' class=\'alink\'>Voorwaarden</a> gelezen en ga hiermee akkoord',
  'ACCEPTED': 'Geaccepteerd',
  'SIGN_OUT': 'Sessie sluiten',
  'SIGN_IN': 'Sessie beginnen',
  'SIGN_UP': 'Aanmelden',
  'EMAIL': 'Email',
  'PASSWORD': 'Wachtwoord',
  'ALL_FIELDS_REQUIRED': 'Alle velden moeten ingevuld worden',
  'REMEMBER_ME': 'Onthoud mij',
  'NEW_ON_HELKI': 'Nieuwe gebruiker, hier aanmelden!',
  'I_FORGOT_MY_PASSWORD': 'Ik ben mijn wachtwoord vergeten',
  'TYPE_EMAIL': 'Vul uw email in',
  'TYPE_PASSWORD': 'Vul uw wachtwoord in',
  'CONFIRM_PASSWORD': 'Bevestig wachtwoord',
  'INVALID_EMAIL': 'Onjuiste Email',
  'SIGN_IN_ERROR': 'Onjuiste Email of wachtwoord',
  'INVALID_PASSWORD': 'Onjuist wachtwoord',
  'PASSWORDS_DO_NOT_MATCH': 'De wachtwoorden komen niet overeen',
  'USER_CONFLICT_ERROR': 'De email bestaat niet meer',
  'SIGN_UP_SUCCESS': 'U heeft zich succesvol aangemeld! Wij hebben u een email voor bevestiging van uw account gestuurd',
  'FORGOT_PASS_MESSAGE': 'Maakt u zich geen zorgen, we verschaffen alleen uw aanmeldingsemailadres om het proces voor het wijzigen van uw wachtwoord aan te vangen.',
  'RESET_PASSWORD': 'Wachtwoord resetten',
  'USER_NOT_FOUND_ERROR': 'Gebruiker niet gevonden',
  'FORGOT_PASS_SUCCESS': 'Wij hebben u een email gestuurd om uw wachtwoord opnieuw in te stellen',
  'SIGN_UP_VERIFY_SUCCESS': 'Uw account is correct geverifieerd',
  'SIGN_UP_VERIFY_ERROR': 'Uw acccount kan niet worden geverifieerd. Neem alstublieft contact met ons op',
  'CHANGE_EMAIL_SUCCESS': 'Uw email is correct geactualiseerd',
  'CHANGE_EMAIL_ERROR': 'Uw account kan niet worden geactualiseerd. Neem alstublieft contact met ons op',
  'RESET_PASS_SUCCESS': 'Uw wachtwoord is correct opnieuw ingesteld',
  'RESET_PASS_ERROR': 'Uw wachtwoord kan niet opnieuw ingesteld worden. Neem alstublieft contact met ons op',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Wijziging van email',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Wij hebben een email naar uw nieuwe account gestuurd om de wijziging uit te voeren',
  'CONNECTION_ERROR_MSG': 'Wij hebben geen verbinding met de provider tot stand kunnen brengen',
  'TRY_AGAIN': 'Opnieuw proberen',
  'NOW': 'Nu',
  'HEATING': 'Verwarming',
  'ELECTRICITY': 'Elektriciteit',
  'WEATHER': 'Het weer',
  'TEMPERATURE': 'Temperatuur',
  'TURN_OFF': 'Uit',
  'TURN_ON': 'Aan',
  'SCHEDULE': 'Programmeren',
  'SETUP': 'Configuratie',
  'SET_TEMPERATURE': 'Temperatuur vastleggen',
  'SET_COMFORT_TEMPERATURE': 'Comforttemperatuur vastleggen',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Aandacht! Als je de Comforttemperatuur wijzigt, wordt de Ecotemperatuur automatisch aangepast.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Selecteer a.u.b. alleen hetzelfde systeem van radiatoren (verwarmingen kunnen niet in een groep worden geselecteerd met verwarmingen met geolocatie)',
  'REMOVE': 'Verwijder',
  'CANCEL': 'Annuleren',
  'CONFIRM': 'Bevestigen',
  'RECORDS': 'Gebruik',
  'SELECT_A_DAY': 'Selecteer een dag',
  'DAY1_SHORT': 'Ma',
  'DAY2_SHORT': 'Di',
  'DAY3_SHORT': 'Wo',
  'DAY4_SHORT': 'Do',
  'DAY5_SHORT': 'Vr',
  'DAY6_SHORT': 'Za',
  'DAY7_SHORT': 'Zo',
  'MONDAY': 'Maandag',
  'TUESDAY': 'Dinsdag',
  'WEDNESDAY': 'Woensdag',
  'THURSDAY': 'Donderdag',
  'FRIDAY': 'Vrijdag',
  'SATURDAY': 'Zaterdag',
  'SUNDAY': 'Zondag',
  'MONDAY_SHORT': 'Ma',
  'TUESDAY_SHORT': 'Di',
  'WEDNESDAY_SHORT': 'Wo',
  'THURSDAY_SHORT': 'Do',
  'FRIDAY_SHORT': 'Vr',
  'SATURDAY_SHORT': 'Za',
  'SUNDAY_SHORT': 'Zo',
  'COPY_DAY': 'Dag kopiëren',
  'ADD': 'Toevoegen',
  'FROM': 'Van',
  'TO': 'aan',
  'SELECT_A_DAY_MSG': 'Selecteer een dag om de programmering van de thermostaat vast te leggen of te wijzigen',
  'EDIT': 'Bewerken',
  'START': 'Begin',
  'END': 'Einde',
  'DONE': 'Klaar',
  'COPY_DAYS': 'Dagen kopiëren',
  'USE_SAME_SCHEDULE_ON': 'Gebruik dezelfde programmering met ...',
  'COPY': 'Kopiëren',
  'POWER_METER': 'Vermogensmeter',
  'USAGE': 'Verbruik',
  'USAGE_VALLEY': 'Verbruik laden',
  'USAGE_PEAK': 'Verbruik zoder laden',
  'REAL_TIME_USAGE': 'Direct verbruik',
  'DAILY': 'Dagelijks',
  'MONTHLY': 'Maandelijks',
  'ANNUAL': 'Jaarlijks',
  'JAN': 'Jan',
  'FEB': 'Feb',
  'MAR': 'Mrt',
  'APR': 'Apr',
  'MAY': 'Mei',
  'JUN': 'Jun',
  'JUL': 'Jul',
  'AUG': 'Aug',
  'SEP': 'Sep',
  'OCT': 'Okt',
  'NOV': 'Nov',
  'DEC': 'Dec',
  'POWER': 'Vermogen',
  'NOMINAL_POWER': 'Nominale vermogen',
  'EFFECTIVE_POWER': 'Effectief vermogen',
  'ZERO_POWER_WARNING': 'De radiator heeft niet het toegewezen vermogen, verander het hier',
  'SET_MODE_TEMP': 'Bep. Temp.',
  'SET_MODE_TEMPERATURE': 'Bepaal temperatuurmodus',
  'COMFORT': 'Comfort',
  'ECO': 'Eco',
  'ANTI_ICE': 'Anti-ijs',
  'INSTALL': 'Installeren',
  'INSTALL_ERROR_MSG': 'Installatieproces is mislukt. Probeer het opnieuw.',
  'MORE': 'Méér',
  'INSTALL_DEVICE': 'Installeer apparaat',
  'SELECT_ALL': 'Alles sel.',
  'DESELECT_ALL': 'Alles desel.',
  'THERMOSTAT': 'Thermostaat',
  'HEATER': 'Radiator',
  'HEATER_HAVERLAND': 'Radiator met geolocalisatie',
  'HEATER_WITH_SMARTBOX': 'Radiator met Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Handdoekradiator met Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'WiFi radiator met 4 knoppen',
  'WIFI_HEATER_W2BUTTONS': 'WiFi radiator met 2 knoppen',
  'INSTALL_THERMOSTAT_STEP1': 'Thermostaat installeren: Stap 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Druk up de <strong>START ZOEKEN</strong> om de thermostaat installatie te starten',
  'INSTALL_THERMOSTAT_STEP2': 'Thermostaat installeren: Stap 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Om de thermostaat te koppelen, druk je <strong>Stdby</strong> knop (linker knop) en <strong>Link</strong> (rechter knop) gedurende <strong>3 seconden</strong> totdat het netwerkpictogram verschijnt omhoog in de rechterbovenhoek van de thermostaat (zoals weergegeven in de afbeelding hierboven).',
  'START_SEARCH': 'Start zoeken',
  'SEARCHING': 'Aan het zoeken...',
  'INSTALL_HEATER_STEP1': 'Radiator installeren: Stap 1',
  'INSTALL_HEATER_HELP_STEP1': 'Druk up de <strong>START ZOEKEN</strong> om de radiator installatie te starten.',
  'INSTALL_HEATER_STEP2': 'Radiator installeren: Stap 2',
  'INSTALL_HEATER_HELP_STEP2': 'Druk voor het koppelen van de elektrische radiator op <strong>MODE/OK</strong> in het apparaat gedurende <strong>3 seconden</strong> tot het rode symbool aan de rechter bovenkant van de thermostaat verschijnt.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Verzeker uzelf ervan dat de radiator in \'Standby\' staat en houd de knoppen temperatuur omhoog en omlaag ten minste drie seconden ingedrukt.',
  'STORAGE_HEATER': 'Accumulator',
  'STORAGE_HEATERS': 'Accumulators',
  'INSTALL_STORAGE_HEATER_STEP1': 'Accumulator installeren: Stap 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Druk up de <strong>START ZOEKEN</strong> om de accumulator installatie te starten.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Accumulator installeren: Stap 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Druk voor het koppelen van de accumulator op <strong>OK</strong> in het apparaat gedurende <strong>3 seconden</strong> tot het rode symbool aan de rechter bovenkant van de thermostaat verschijnt.',
  'CHARGE_PROGRAM': 'Opladen programma',
  'VALLEY_1': 'Periode 1',
  'VALLEY_2': 'Periode 2',
  'INSTALL_POWER_METER_STEP1': 'Vermogensmeter installeren: Stap 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Druk up de <strong>START ZOEKEN</strong> om de Vermogensmeter installatie te starten.',
  'INSTALL_POWER_METER_STEP2': 'Vermogensmeter installeren: Stap 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Druk voor het koppelen van de meter met de clip (bijgeleverd) op kleine knop gelegen aan de bovenkant van de meter (zie afbeelding). U kunt controleren of hij gekoppeld is door te kijken naar het LEDlampje op de meter.',
  'LED_INDICATOR_STATES': 'Toestanden van de LED indicator',
  'UNLINKED': 'Niet gekoppeld',
  'LED_OFF': 'Led uit',
  'CORRECTLY_LINKED': 'Correct gekoppeld',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'Het LED lampje knippert iedere twee en halve seconde.',
  'LINKED_BUT_LOST': 'Gekoppeld maar onderbroken',
  'LED_BLINKS_EVERY_HALF_SEC': 'Het LED lampje zal iedere halve seconde knipperen. Dit gebeurt bijvoorbeeld als de centrale uitgaat. Zodra de meter weer verbinding met de centrale krijgt zal hij automatisch weer aangaan.',
  'NAME': 'Naam',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Selecteer een naam om het apparaat te identificeren',
  'TYPE': 'Type',
  'HEAT': 'Warm',
  'COLD': 'Koud',
  'CONTROL': 'Controle',
  'SELECT': 'Selecteer...',
  'HYST_0P2': 'Hysterese (0,2 ºC)',
  'HYST_0P3': 'Hysterese (0,3 ºC)',
  'HYST_0P4': 'Hysterese (0,4 ºC)',
  'HYST_0P5': 'Hysterese (0,5 ºC)',
  'HYST_0P7': 'Hysterese (0,7 ºC)',
  'HYST_0P8': 'Hysterese (0,8 ºC)',
  'HYST_1P6': 'Hysterese (1,6 ºC)',
  'HYST_0P4F': 'Hysterese (0,4 ºF)',
  'HYST_0P5F': 'Hysterese (0,5 ºF)',
  'HYST_0P7F': 'Hysterese (0,7 ºF)',
  'HYST_0P9F': 'Hysterese (0,9 ºF)',
  'HYST_1P2F': 'Hysterese (1,2 ºF)',
  'HYST_1P4F': 'Hysterese (1,4 ºF)',
  'HYST_2P9F': 'Hysterese (2,9 ºF)',
  'PID_T10MIN': 'PID (tijdsduur 10 min.)',
  'PID_T15MIN': 'PID (tijdsduur 15 min.)',
  'PID_T20MIN': 'PID (tijdsduur 20 min.)',
  'PID_T25MIN': 'PID (tijdsduur 25 min.)',
  'PID_T30MIN': 'PID (tijdsduur 30 min.)',
  'PID_T45MIN': 'PID (tijdsduur 45 min.)',
  'PID_T60MIN': 'PID (tijdsduur 60 min.)',
  'ANTI_GRIPPAGE': 'Anti-seize pasta pomp',
  'CONTRACTED_POWER_W': 'Gecontracteerde vermogen (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Vul het gecontracteerde vermogen in Watt in',
  'PRIORITY': 'Prioriteit',
  'LOW': 'Laag',
  'MEDIUM': 'Medium',
  'HIGH': 'Hoog',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Venster modus',
  'DELETE_DEVICES': 'Apparaten wissen',
  'DELETE_DEVICES_WARNING': 'De geselecteerde apparaten van het systeem en de geassocieerde gegevens zullen gewist worden.',
  'DELETE': 'Wissen',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Maximale totale vermogen van de radiatoren',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Vermogen voor alle radiatoren (W)',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Geef het maximale vermogen aan (W)',
  'LIMITLESS': 'Zonder vermogenslimiet',
  'HOME_INFO': 'Gegevens van de woning',
  'HOME_NAME': 'Naam van de woning',
  'TYPE_HOME_NAME': 'Vul de naam van de woning in',
  'TIME_ZONE': 'Tijdzone',
  'TIME_ZONE_CET': '(Midden-Europese Tijd) Berlijn, Parijs, Madrid, Rome',
  'TIME_ZONE_WET': '(West-Europese Tijd) Londen, Dublin, Lissabon, Canarische Eilanden',
  'TIME_ZONE_EET': '(Oost-Europese Tijd) Helsinki, Sofia, Athene',
  'TIME_ZONE_PET': '(Peruviaans Tijd) Lima',
  'TIME_ZONE_NZST': '(New Zealand Standard Time) Auckland',
  'TIME_ZONE_CIST': '(Chatham Island Standard Time) Chatham Islands',
  'TIME_ZONE_CST': '(China Standard Time) Shanghai',
  'TIME_ZONE_UYT': '(Uruguay Time) Montevideo',
  'DEVICE_IDENTIFIER': 'Apparaat ID',
  'TYPE_DEVICE_IDENTIFIER': 'Apparaat ID invoeren',
  'INVALID_IDENTIFIER': 'Het ingevoerde apparaat ID is niet correct',
  'DEVICE_ALREADY_TAKEN': 'Het apparaat met ID DEVID is al geregistreerd. Neem contact op met de technische ondersteuning om dit probleem te melden.',
  'COPY_ERROR_DATA': '  Apparaat-ID kopiëren',
  'MY_HOMES': 'Mijn woningen',
  'ADD_HOME': 'Woning toevoegen',
  'SAVE': 'Opslaan',
  'MY_INFO': 'Mijn gegevens',
  'USER': 'Gebruiker',
  'LANGUAGE': 'Taal',
  'CHANGE_EMAIL_ADDRESS': 'Email wijzigen',
  'NEW_EMAIL_ADDRESS': 'Nieuwe email',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Voer uw nieuwe email in',
  'CONFIRM_WITH_PASSWORD': 'Met wachtwoord bevestigen',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Voer uw huidige wachtwoord in',
  'CHANGE_PASSWORD': 'Wachtwoord wijzigen',
  'CURRENT_PASSWORD': 'Huidige wachtwoord',
  'TYPE_YOUR_NEW_PASSWORD': 'Voer uw nieuwe wachtwoord in',
  'NEW_PASSWORD': 'Nieuw wachtwoord',
  'TEMPERATURE_UNITS': 'Temperatuur unit',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Autodidactisme',
  'SELF_LEARN_SHORT': 'Autodidac.',
  'PRESENCE': 'Aanwezigheid',
  'AWAY_OFFSET': 'Temperatuurverlaging bij afwezigheid',
  'ERASE_DATA_PERMANENTLY': 'Gegevens definitief wissen',
  'NO_CONNECTION_WARNING': 'Geen verbinding, alleen toegang tot het historisch dossier',
  'SET_MODE': 'Modus instellen',
  'AT_HOME': 'Thuis',
  'AWAY': 'Afwezig',
  'DEVICES_SUMMARY': 'Samenvatting apparaten',
  'THERMOSTATS': 'Thermostaten',
  'HEATERS': 'Radiators',
  'POWER_METERS': 'Vermogensmeters',
  'CLOSE': 'Sluiten',
  'DETECT_SMARTBOX': 'SmartBox detecteren',
  'DETECT_SMARTBOX_HELP_MSG': 'Druk voor het lokaliseren van uw SmartBox op de knop aan de voorkant. Verzeker u ervan dat u een goede WiFi-verbinding hebt.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Helaas hebben wij de SmartBox niet kunnen vinden. Gelieve op doorgaan drukken en handmatig het apparaat ID invoegen.',
  'CONTINUE': 'Doorgaan',
  'CANCEL_SEARCH': 'Zoekopdracht annuleren',
  'SEARCHING_NEW_DEVICES': 'Nieuwe apparaten aan het zoeken ...',
  'DISCOVERY_MODE_MSG': 'SmartBox {{devId}} in ontdekking-modus. Druk op de juiste knop van uw apparaat dat volgens de handleiding geïnstalleerd moet worden',
  'DEVICE_FOUND_MSG': 'Er is een nieuw apparaat gevonden!',
  'GETTING_STARTED': 'Hulp bij het starten',
  'MAIN_MENU': 'Hoofdmenu',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Ga gemakkelijk naar de algemene optiesvan de configuratie',
  'NEXT': 'Volgende',
  'GEOLOCATION': 'Geolocalisatie',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Afwezig wordt standaard 2°C verlaagd',
  'DEVICES': 'Apparaten',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'De apparaten worden onderverdeeld in secties Verwarming en Elektriciteit',
  'GO_TO_A_DEVICE': 'Ga naar uw apparaat',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Stel het apparaat in en ga naar uw statistieken',
  'MULTIDEVICE_ACTIONS': 'Handelingen met meerdere apparaten',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Selecteer de apparaten en ga naar de opties onder de knop MÉÉR',
  'READY': 'Klaar',
  'SKIP': 'Overslaan',
  'SKIP_HELP': 'Hulp overslaan',
  'QUICK_SETTING': 'Snelle instelling',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Verschuif de temperatuurlat zijdelings om snel van temperatuur te veranderen.',
  'TEMPERATURES': 'Temperaturen',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Stel de temperaturen van ieder van de beschikbare modi in.',
  'MODE': 'Modus',
  'WORKING_MODES': 'Modi',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Selecteer een van de beschikbare modi.',
  'SELECT_MODE': 'Selecteer de modus',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Markeer de tijdstippen waarop deze modus toegepast gaat worden.',
  'SUPPORT_BODY': 'Uw account heeft een minimale ondersteuningsperiode voor beveiligingsupdates van 3 jaar vanaf de datum van aankoop van het product. In het geval dat u zich bewust wordt van beveiligings- of andere problemen, stuur ons dan een e-mail naar het onderstaande adres met een beschrijving van het probleem.',
  'SUPPORT_REQUEST_INTRO_HELP': 'Er is een hulpknop aan de linker onderkant om uw informatie over het omgaan met de app te geven.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Indien u iets wilt raadplegen of hulp met de app nodig hebt, gelieve dan contact met ons op te nemen:',
  'ADDRESS': 'Adres',
  'TELEPHONE': 'Telefoon',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'Door mij aan te melden, accepteer ik de <a href=\'{{frontendHost}}/nl/terms.html\' target=\'_blank\'>Voorwaarden</a> en de <a href=\'{{frontendHost}}/nl/privacy.html\' target=\'_blank\'>Privacyverklaring</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/nl/privacy.html\' target=\'_blank\'>Privacyverklaring</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Uw wachtwoord is geactualiseerd',
  'PASSWORD_CHANGE_ERROR_MSG': 'Uw wachtwoord kan niet geactualiseerd worden. Gelieve uw huidige wachtwoordte checken.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Onjuiste email',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Deze email behoort al tot een anderegebruiker',
  'EMAIL_CHANGE_ERROR_MSG': 'Uw email kan niet geactualiseerdworden. Gelieve het later nog eenste proberen.',
  'HOME_TYPE': 'Type woning',
  'ETHERNET': 'Kabelverbinding',
  'WIFI': 'Wifiverbinding',
  'DEVICE_REGISTER_ERROR': 'De aanmelding is niet succesvol beëindigd',
  'WRONG_CREDENTIALS_ERROR': 'Onjuiste gebruiker/wachtwoord',
  'DEVICE_ADDED_MSG': 'Er is een nieuw apparaat toegevoegd',
  'CONNECTION_WARNING': 'Wifi-verbinding',
  'WIFI_CONNECTION_1': 'Open de instellingen van de telefoon',
  'WIFI_CONNECTION_2': 'Selecteer WiFi',
  'WIFI_CONNECTION_3': 'Maak een WiFi-verbinding ',
  'WIFI_CONNECTION_4': 'Indien u een melding krijgt in de stijl van \'WiFi zonder Internetverbinding\', geef dan aan dat u de verbinding wilt behouden',
  'WIFI_CONNECTION_5': 'Ga terug naar dit beeld',
  'DEVICE_CONNECTION_ERROR': 'Kan geen verbinding maken met apparaat',
  'WIFI_SCAN_ERROR': 'Geen netwerken gevonden',
  'WRONG_PASS_ERROR': 'Het wachtwoord van de ingestelde WiFi is onjuist',
  'WRONG_ESSID_ERROR': 'Het ingestelde WiFi-netwerk bestaat niet',
  'SET_WIFI_ERROR': 'Onmogelijk WiFiverbinding te maken',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Goede verbinding',
  'WIFI_SELECTION': 'Selecteer uw WiFi-netwerk',
  'WIFI_NOT_FOUND': 'Ik kan mijn netwerk niet vinden',
  'TYPE_AP_ESSID': 'Voer de naam van uw netwerk in',
  'TYPE_AP_PASS': 'Voer het wachtwoord van uw netwerk in',
  'RETRY': 'OPNIEUW PROBEREN',
  'AP_DATA': 'Gegevens van het netwerk',
  'AP_ESSID': 'Naam',
  'AP_PASS': 'Wachtwoord',
  'EDIT_WIFI': 'WiFi configuratie bewerken',
  'WRONG_WIFI_ERROR': 'De desbetreffende WiFi is niet gevonden',
  'COPY_PASS': 'Wachtwoord kopiëren',
  'EDIT_DATA': 'Gegevens van de woning',
  'WEB_WIFI_WARNING': 'De WiFi-optie is alleen op onze app beschikbaar',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Voeg tot 9 temperatuurblokken toe',
  'ACCEPT': 'Aanvaarden',
  'INVITE_USERS': 'Gebruikers uitnodigen',
  'INVITE_USER': 'Gebruiker uitnodigen',
  'INVITED_USERS': 'Uitgenodigde gebruikers',
  'INVITE_USER_TITLE': 'Gebruiker uitnodigen',
  'INVITE_USER_SUCCESS_MSG': 'Nieuwe uitgenodigde gebruiker. Er is u een email gestuurd om aan te geven dat u toegang tot deze woning hebt',
  'INVITE_USER_ERROR_MSG': 'Vergissing bij het uitnodigen van eengebruiker. Gelieve het later te proberen.',
  'REVOKE_INVITE_TITLE': 'Uitgenodigde verwijderen',
  'REVOKE_INVITE_MSG': 'De geselecteerde gebruiker heeft toegang tot deze woning',
  'REVOKE_INVITE_SUCCESS_MSG': 'Uitgenodigde gewist',
  'REVOKE_INVITE_ERROR_MSG': 'Vergissing bij het verwijderen van deuitgenodigde. Gelieve het later teproberen.',
  'CONFIRM_INVITE_SUCCESS': 'Uw account is correct aangemaakt',
  'GUEST': 'Gast',
  'TRV': 'Thermostaatklep',
  'TRVS': 'Thermostatkleppen',
  'INSTALL_TRV_STEP1': 'Thermostaatklep installeren: Stap 1',
  'INSTALL_TRV_HELP_STEP1': 'Druk up de <strong>START ZOEKEN</strong> om de Thermostaatklep installatie te starten.',
  'INSTALL_TRV_STEP2': 'Thermostaatklep installeren: Stap 2',
  'INSTALL_TRV_HELP_STEP2': 'Voor het koppelen van de klep druk op de knop <strong>helki</strong> (de knop in het midden) gedurende <strong>3 seconden</strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Omschakelen koud/warm modus',
  'CONTROL_MODE_CHANGE_MSG': 'Waarschuwing: Het huidige programma zal hersteld worden',
  'SAVE_THIS_POSITION': 'Deze positie bewaren',
  'SET_POSITION_MSG': 'De locatie van de woning is nog niet vastgelegd. Selecteer de locatie van uw woning op de plattegrond en druk op de knop onderaan om hem te bewaren.',
  'ENABLE_GEOLOCATION': 'Geolocalisatie activeren',
  'OUTDOOR_RANGE': 'Bereik buitenshuis (m.)',
  'DEV_TYPE_WIFI': 'WiFi SmartBox',
  'DEV_TYPE_WIFIHTR': 'WiFi-verwarming',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Selecteer apparaat',
  'SELECT_DEVICE_THM_HELP': 'De thermostaat wordt toegewezen aan het volgende apparaat',
  'SELECT_DEVICE_HTR_HELP': 'De verwarmer wordt toegewezen aan het volgende apparaat',
  'SELECT_DEVICE_ACM_HELP': 'De accumulator wordt toegewezen aan het volgende apparaat',
  'SELECT_DEVICE_PMO_HELP': 'De vermogensmeter wordt toegewezen aan het volgende apparaat',
  'SELECT_DEVICE_TRV_HELP': 'De klep wordt toegewezen aan het volgende apparaat',
  'ADD_DEVICE': 'Voeg toestel toe',
  'DEVICE_INSTALLATION': 'Apparaat installatie',
  'WIFI_HEATER': 'WiFi-verwarming',
  'WIFI_HEATER_HAVERLAND': 'Serie Connect',
  'LETS_START': 'Laten we beginnen',
  'INSTALL_WIFI_HEATER_HELP': 'Doe de stekker van de radiator in het stopcontact. Druk op de knop OK van je radiator totdat het scherm knippert.',
  'INSTALL_WIFI_HEATER2_HELP': 'Sluit uw radiator aan op het stopcontact. Druk op de knoppen die in de volgende afbeelding worden weergegeven totdat het scherm knippert.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Sluit uw WiFi SmartBox aan. Als deze al is aangesloten, koppelt u deze los en sluit u deze opnieuw aan.',
  'DEV_TYPE_WIFI_CONNECTION': 'WiFi SmartBox-verbinding',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'WiFi Heater-verbinding',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'We gaan nu de SmartBox verbinden met het WiFi-netwerk van uw huis.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'We gaan nu de kachel aansluiten op het WiFi-netwerk van uw huis.',
  'GHZ_WIFI_WARNING': 'Waarschuwing: alleen 2,4 GHz-netwerken worden ondersteund',
  'CONFIGURING_WIFI_HTR': 'WiFi-verwarmer configureren',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'AP-modus WiFi SmartBox-configuratie',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'AP-modus WiFi-verwarmerconfiguratie',
  'SET_ETHERNET_SMARTBOX_NAME': 'Hoe noemen we deze SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Hoe noemen we deze WiFi SmartBox?',
  'SET_WIFI_HTR_NAME': 'Hoe noemen we deze WiFi-verwarmer?',
  'TYPE_DEVICE_NAME': 'Typ de apparaatnaam',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Verbinding maken met WiFi SmartBox',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Verbinding maken met WiFi-verwarming',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Verbonden met WiFi SmartBox met id:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Verbonden met WiFi-verwarmer met id:',
  'MY_DEVICES': 'Mijn apparaten',
  'DEVICE_TYPE': 'Hoe ziet uw apparaat eruit?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Verbinding maken met WiFi SmartBox',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Verbinding maken met WiFi-verwarming',
  'CONFIGURING_DEV_TYPE_WIFI': 'WiFi SmartBox configureren',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'WiFi-verwarmer configureren',
  'DEVICE_LIST': 'Mijn apparaten',
  'CONNECTION_STATE': 'Verbindingsstatus',
  'CONFIGURE_WIFI': 'Configureer WiFi',
  'CHANGE_HOME': 'Transfer naar een ander huis',
  'DELETE_DEVICE': 'Apparaat verwijderen',
  'TRANSFER_HOME_TITLE': 'Transfer naar een ander huis',
  'TRANSFER_HOME_WARNING': 'Waarschuwing: uw apparaat wordt overgedragen naar de geselecteerde woning',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Apparaat verwijderen',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Weet u zeker dat u dit apparaat wilt verwijderen?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Sluit uw ethernet SmartBox aan',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'WiFi SmartBox-installatie geslaagd',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'Installatie van Ethernet SmartBox geslaagd',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'WiFi-verwarming installatie succes',
  'HOME_REGISTRY': 'Thuisregister',
  'HOME_REGISTRY_HELP': 'Op dit moment heeft u geen huis geregistreerd. Laten we een nieuwe toevoegen.',
  'SET_HOME_NAME': 'Stel de huisnaam in',
  'SET_HOME_TIMEZONE': 'Stel de eigen tijdzone in',
  'DEVICE_CONNECTION_START': 'We gaan nu uw apparaat verbinden',
  'WIFI_DEVICE': 'WiFi-apparaat',
  'WIFI_DEVICE_HELP': 'De installatie van WiFi-apparaten is alleen beschikbaar in onze applicaties',
  'TYPE_ESSID': 'Typ uw WiFi-netwerk',
  'REGISTERING_DEVICE': 'Apparaat registreren',
  'WELCOME': 'Welkom',
  'ESSID': 'Selecteer netwerk Wifi',
  'SET_BOOST_MODE': 'Stel de boostmodus in',
  'BOOST_END_TIME': 'Boost mode eindtijd',
  'BOOST_ACTIVE_UNTIL': 'Boostmodus actief tot',
  'OPERATIONAL_MODE': 'Operationele modus',
  'FIXED_TEMPERATURE': 'Vaste temperatuur',
  'WEEKLY_SCHEDULE': 'Wekelijkse planning',
  'ANTIFROST_PROTECTION': 'Vorstbescherming',
  'ID_COPIED': 'Identifier gekopieerd',
  'NEW_PROFILE': 'Nieuw profiel',
  'PROFILE_COLOR': 'Kleur',
  'TYPE_PROFILE_NAME': 'Voer een profielnaam in',
  'TYPE_POWER_LIMIT': 'Voer een vermogenswaarde in',
  'POWER_LIMIT': 'Kracht (W)',
  'EDIT_PROFILES': 'Profielen',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Profiel verwijderen',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Weet u zeker dat u het profiel wilt verwijderen?',
  'POWER_MANAGEMENT': 'Configuratie ingehuurde energie',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Moet tussen zijn ',
  'AND': ' en ',
  'MANUAL_NETWORK_INPUT': 'Handmatig invoeren',
  'GEOLOCATION_INFO': 'Met deze functie kunt u de temperatuur van de apparaten verlagen wanneer u zich buiten de ingestelde thuisradius bevindt.',
  'GEOLOCATION_NEW_FEAT': 'Ontdek de functie waarmee u kunt besparen op het verbruik van uw apparaten wanneer u niet thuis bent.',
  'VOICE_CONTROL': 'Spraakassistent',
  'VOICE_CONTROL_NEW_FEAT': 'Onthoud dat dit apparaat compatibel is met: ',
  'CONFIGURE': 'Configureren',
  'MORE_INFO_LONG': 'Meer informatie',
  'GEOLOCATION_RATIONALE': 'Voor een goede werking van de geolocatiemodus moet deze app de hele tijd toegang hebben tot uw locatie, zelfs als u uw telefoon niet gebruikt. Machtiging inschakelen in instellingen.',
  'USER_DUTY_FACTOR': 'Vermogens limiet factor',
  'CIRCUIT_TYPE': 'Circuit type',
  'PRINCIPAL_CIRCUIT': 'Hoofd Circuit',
  'REVERSE_SIGN': 'Omgekeerd teken',
  'SMARTBOX_CONFIGURATION': 'SmartBox-Configuratie',
  'MAIN_CIRCUIT_GENERATION': 'Belangrijkste Generatie Circuit',
  'MAIN_CIRCUIT_CONSUMPTION': 'Hoofdeverbruikscircuit',
  'SELECT_GENERATION_POWERMETER': 'Selecteer een Generatiemeter',
  'SELECT_CONSUMPTION_POWERMETER': 'Selecteer een Verbruiksmeter',
  'POWERMETER_CONFIG': 'Configuratie krachtmeter',
  'MAX_POWER_TERM_MANAGEMENT': 'Ingehuurde Energie',
  'MANAGE_MAX_POWER': 'Programma',
  'MANAGE_ENERGY_TARIFFS': 'Programma',
  'COLOR': 'Kleur',
  'CONSUMPTION': 'Consumptie',
  'GENERATION': 'Generatie',
  'EDIT_ENERGY_TARIFFS': 'Profielen',
  'SELECT_MAX_POWER_CONFIG': 'Selecteer een gecontracteerd vermogen',
  'CUSTOM_TARIFF_CONFIG': 'Nieuw aangepast profiel',
  'ANTI_FROST_IN_OFF': 'Vorstbescherming',
  'POWER_LEVEL_LIMIT': 'Limiet Niveau Vermogen',
  'E2_USAGE_ENABLED': 'Zonne Energie',
  'E2_MODE': 'Type Lading',
  'E2_ACTIVATION_PER': 'Zonne-energie activering',
  'E2_CHARGE_EXCESS_PER': 'Maximale Dalbelasting',
  'NODE_E2_MODE_0': 'Exclusief zonne-energie',
  'NODE_E2_MODE_1': 'Zonne en Vallei',
  'NODE_E2_MODE_2': 'Zonne en Gecorrigeerde Vallei',
  'E2_TEMP_EXCESS': 'Omgevingstemperatuurbereik',
  'E2_PRIORITY': 'Laadprioriteit',
  'MAIN_CIRCUIT': 'Voornaamst',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Apparaat succesvol geüpdatet',
  'DEVICE_UPDATE_ERROR_MSG': 'Het apparaat kon niet wordenbijgewerkt. Probeer het later opnieuw.',
  'SYSTEM_MAINTENANCE': 'Systeem in onderhoud',
  'SYSTEM_MAINTENANCE_MSG': 'Sorry voor het ongemak, we werken eraan om de service te verbeteren.',
  'SOLD_SEPARATELY': '(Apart verkocht)',
  'REMOVE_ACCOUNT': 'Account verwijderen',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Verwijder account',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'Bij het verwijderen van een account worden alle knooppunten en bijbehorende gegevens verwijderd. Als u zeker weet dat u het account wilt verwijderen, voert u uw wachtwoord in om te bevestigen.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Account succesvol verwijderd',
  'ACCOUNT_REMOVAL_ERROR': 'Kan account niet verwijderen.',
  'USE_QR': 'Wifi-QR scannen',
  'USE_MANUAL_AP': 'Voer WiFi-referenties in',
  'LOADING': 'Laden',
  'SIGN_IN_WITH_GOOGLE': 'Inloggen met Google',
  'LOCK': 'Blok',
  'UNLOCK': 'Ontgrendelen',
  'BRIGHT_LEVEL': 'Helderheidsniveau van het scherm',
  'BACKLIGHT_TIME': 'Scherminschakeltijd',
  'BACKLIGHT_TIME_10': '10 seconden',
  'BACKLIGHT_TIME_20': '20 seconden',
  'BACKLIGHT_TIME_30': '30 seconden',
  'BACKLIGHT_TIME_ALWAYS': 'Altijd',
  'DELETE_ACCOUNT_MSG': 'Door het gebruikersaccount te verwijderen, verwijdert u alle gegevens die eraan gekoppeld zijn voorgoed. Als u hiermee akkoord gaat, volg dan de onderstaande stappen.',
  'DELETE_ACCOUNT_STEPS': 'Stappen om uw account te verwijderen',
  'DELETE_ACCOUNT_STEP1': '1. Log in op de applicatie met je gebruikersnaam en wachtwoord.',
  'DELETE_ACCOUNT_STEP2': '2. Ga naar het bovenste menu.',
  'DELETE_ACCOUNT_STEP3': '3. Selecteer Mijn account.',
  'DELETE_ACCOUNT_STEP4': '4. Klik in het gedeelte Account verwijderen op de knop Account verwijderen.',
  'DEPRECATION_MESSAGE': 'Gebruik de TARGET-app',
 },
 'hr': {
  'ASCII_ERROR': 'Imena i lozinke WiFi mreže moraju sadržavati samo ASCII znakove.',
  'QR_SCANN_ERROR': 'Nazivi WiFi mreža i lozinke s više od 32 znaka nisu podržani.',
  'QR_SCANN_ERROR_TITLE': 'Došlo je do problema s uvedenim vjerodajnicama',
  'EXTRA_PRESS_ON_PROVISIONING': 'Za aktiviranje zaslona pritisnite bilo koju tipku',
  'BT_ACTIVATION_WARNING': 'Provjerite je li bluetooth omogućen na vašem mobilnom telefonu',
  'DEVICE_DISCONNECTED_STEP_1': 'Otvorite glavni izbornik',
  'DEVICE_DISCONNECTED_STEP_2': 'Idite na Domove i odaberite svoj dom',
  'DEVICE_DISCONNECTED_STEP_3': 'Odaberite uređaj',
  'DEVICE_DISCONNECTED_STEP_4': 'Konfigurirajte WiFi',
  'DEVICE_DISCONNECTED_HELP_1': 'WiFi mreža ima internetsku vezu i mreža je od 2,4 GHz.',
  'DEVICE_DISCONNECTED_HELP_2': 'Razmotrite područja s odgovarajućom WiFi pokrivenošću tijekom instalacije uređaja.',
  'DEVICE_DISCONNECTED_HELP_3': 'Provjerite i ponovno konfigurirajte WiFi vjerodajnice za svoj uređaj izravno putem <a class="alink" href=\'{{link}}\'>ovdje< ili slijedite ovaj redoslijed:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'Uređaj je već registriran, ali nije ispravno spojen. Pokušajte isključiti uređaj iz utičnice i ponovno ga spojiti. Provjerite je li spojen na vašu WiFi mrežu. Ako ne, razmotrite sljedeće točke:',
  'DEVICE_DISCONNECTED_HEADER': 'Došlo je do problema u postupku dodjele, uređaj se mogao izgubiti.',
  'DEVICE_INSTALLATION_ERROR': 'Došlo je do problema tijekom instalacije uređaja.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Provjerite status LED dioda uređaja. Odaberite jedno od stanja za više informacija kako biste riješili problem:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'Uređaj nema dodijeljenu IP adresu.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'Uređaj nije povezan sa serverom.',
  'DEVICE_INSTALLATION_ERROR_OK': 'Uređaj je ispravno povezan.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Provjerite je li uređaj ispravno povezan s WiFi mrežom putem Ethernet kabela.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Ponovite postupak instalacije.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Provjerite internet vezu rutera i osigurajte da nema ograničenja porta.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Zatvorite i ponovno otvorite aplikaciju kako biste provjerili je li uređaj ispravno instaliran.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Provjerite status LED dioda uređaja. Odaberite jedno od stanja za više informacija kako biste riješili problem:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'WiFi mreža nije konfigurirana.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'Uređaj nije povezan s routerom.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Provjerite status uređaja promatrajući status ikone antene. Odaberite jedno od stanja za više informacija kako biste riješili problem:',
  'SERVER_CONNECTION_ERROR': 'Aplikacija se ne može spojiti na poslužitelj. Provjerite internetsku vezu vašeg pametnog telefona i pokušajte ponovno',
  'AP_FALLBACK_TITLE': 'Ponovno pokrenimo proces pomoću metode pristupne točke',
  'HANDSHAKE_WARNING': 'Provjerite je li vaš pametni telefon sada povezan na istu WiFi mrežu.',
  'GEOLOCATION_RANGE': 'Radijus kuće',
  'PROVISIONING_METHOD_HELP': 'Odaberite metodu opskrbe za povezivanje uređaja',
  'RECOMMENDED': 'Preporučeno',
  'BACK': 'Natrag',
  'HELP': 'Pomoć',
  'SUPPORT': 'Podrška',
  'MY_ACCOUNT': 'Moj račun',
  'HOMES': 'Kuće',
  'PRIVACY': 'Privatnost',
  'VERSION': 'Verzija',
  'PRIVACY_INFO': 'Ovdje možete vidjeti osobne podatke koje imamo o vama. Svoja prava možete ostvariti na kontakt adresi e-pošte koja je prikazana u nastavku ',
  'HOME_GEOLOCATION': 'Početna geolokacija',
  'LATITUDE': 'širina',
  'LONGITUDE': 'dužina',
  'CONTACT_EMAIL': 'Kontakt adresa e-pošte',
  'PP_POLICY_CHANGE_WARNING': 'Politika privatnosti i uvjeti su izmijenjeni. Morate se složiti da biste nastavili ',
  'PP_READ_AND_ACCEPTED': 'Pročitao sam i prihvaćam <a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\' class=\'alink\'> Pravila o privatnosti </a> i <a href = \'{{frontendHost}} / hr / terms.html \'target =\' _ blank \' class=\'alink\'> Uvjeti i odredbe </a> ',
  'ACCEPTED': 'Prihvaćeno',
  'SIGN_OUT': 'Odjava',
  'SIGN_IN': 'Prijava',
  'SIGN_UP': 'Prijava',
  'EMAIL': 'E-pošta',
  'PASSWORD': 'Zaporka',
  'ALL_FIELDS_REQUIRED': 'Sva su polja obavezna',
  'REMEMBER_ME': 'Zapamti me',
  'NEW_ON_HELKI': 'Novi korisnik, prijavite se ovdje!',
  'I_FORGOT_MY_PASSWORD': 'Zaboravio sam zaporku',
  'TYPE_EMAIL': 'Upišite svoju e-poštu',
  'TYPE_PASSWORD': 'Upišite zaporku',
  'CONFIRM_PASSWORD': 'Potvrdite zaporku',
  'INVALID_EMAIL': 'Nevažeća e-pošta',
  'SIGN_IN_ERROR': 'Netočna adresa e-pošte ili zaporka',
  'INVALID_PASSWORD': 'Nevažeća zaporka',
  'PASSWORDS_DO_NOT_MATCH': 'Lozinke se ne podudaraju',
  'USER_CONFLICT_ERROR': 'E-pošta je već zauzeta',
  'SIGN_UP_SUCCESS': 'Prijavite se uspješno! Poslali smo vam poruku e-pošte za potvrdu računa ',
  'FORGOT_PASS_MESSAGE': 'Ne brinite, samo unesite e-poštu za registraciju kako biste mogli pokrenuti postupak poništavanja zaporke',
  'RESET_PASSWORD': 'Ponovno postavi zaporku',
  'FORGOT_PASS_SUCCESS': 'Poslali smo vam poruku e-pošte za poništavanje zaporke',
  'USER_NOT_FOUND_ERROR': 'Korisnik nije pronađen',
  'SIGN_UP_VERIFY_SUCCESS': 'Vaš je račun uspješno potvrđen',
  'SIGN_UP_VERIFY_ERROR': 'Vaš račun nije mogao biti potvrđen. Molimo kontaktirajte nas',
  'CHANGE_EMAIL_SUCCESS': 'Vaša adresa e-pošte uspješno je ažurirana',
  'CHANGE_EMAIL_ERROR': 'Vaša adresa e-pošte nije mogla biti ažurirana. Molimo kontaktirajte nas',
  'RESET_PASS_SUCCESS': 'Vaša je lozinka uspješno poništena',
  'RESET_PASS_ERROR': 'Vaša zaporka nije se mogla vratiti. Molimo kontaktirajte nas',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Ažuriranje adrese e-pošte',
  'CHANGE_EMAIL_SUCCESS_MSG': 'Poslali smo vam poruku e-pošte na novu adresu da biste dovršili postupak',
  'CONNECTION_ERROR_MSG': 'Nije se bilo moguće povezati s poslužiteljem',
  'TRY_AGAIN': 'Pokušaj ponovo',
  'NOW': 'sad',
  'HEATING': 'Grijanje',
  'ELECTRICITY': 'Snaga',
  'WEATHER': 'Vrijeme',
  'TEMPERATURE': 'Temperatura',
  'TURN_OFF': 'Isključi',
  'TURN_ON': 'Uključi',
  'SCHEDULE': 'Raspored',
  'SETUP': 'Postavljanje',
  'SET_TEMPERATURE': 'Postavi temperaturu',
  'SET_COMFORT_TEMPERATURE': 'Postavite ugodnu temperaturu',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Pažnja! Ako promijenite temperaturu komfora, Eco temperatura će se automatski ponovno prilagoditi.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Molimo odaberite samo isti sustav radijatora (Grijalice se ne mogu grupirati s Grijačima s geolokacijom)',
  'REMOVE': 'Ukloniti',
  'CANCEL': 'Odustani',
  'CONFIRM': 'Potvrdi',
  'RECORDS': 'Zapisi',
  'SELECT_A_DAY': 'Odaberite dan',
  'DAY1_SHORT': 'Ne',
  'DAY2_SHORT': 'Po',
  'DAY3_SHORT': 'Ut',
  'DAY4_SHORT': 'Sr',
  'DAY5_SHORT': 'Če',
  'DAY6_SHORT': 'Pe',
  'DAY7_SHORT': 'Su',
  'MONDAY': 'Ponedjeljak',
  'TUESDAY': 'Utorak',
  'WEDNESDAY': 'Srijeda',
  'THURSDAY': 'Četvrtak',
  'FRIDAY': 'Petak',
  'SATURDAY': 'Subota',
  'SUNDAY': 'Nedjelja',
  'MONDAY_SHORT': 'Pon',
  'TUESDAY_SHORT': 'Uto',
  'WEDNESDAY_SHORT': 'Sri',
  'THURSDAY_SHORT': 'Čet',
  'FRIDAY_SHORT': 'Pet',
  'SATURDAY_SHORT': 'Sub',
  'SUNDAY_SHORT': 'Ned',
  'COPY_DAY': 'Kopiraj dan',
  'ADD': 'Dodaj novo',
  'FROM': 'Od',
  'TO': 'na',
  'SELECT_A_DAY_MSG': 'Odaberite dan za podešavanje ili izmjenu vremenskog programa',
  'EDIT': 'Uredi',
  'START': 'Početak',
  'END': 'Završi',
  'DONE': 'Gotovo',
  'COPY_DAYS': 'Kopiraj dane',
  'USE_SAME_SCHEDULE_ON': 'Koristite isti raspored na ...',
  'COPY': 'Kopiraj',
  'POWER_METER': 'Mjerač snage',
  'USAGE': 'Upotreba',
  'USAGE_VALLEY': 'Potrošnja opterećenja',
  'USAGE_PEAK': 'Potrošnja bez opterećenja',
  'REAL_TIME_USAGE': 'Korištenje u stvarnom vremenu',
  'DAILY': 'Dnevno',
  'MONTHLY': 'Mjesečno',
  'ANNUAL': 'Godišnje',
  'JAN': 'Siječanj',
  'FEB': 'Veljača',
  'MAR': 'Ožujak',
  'APR': 'Travanj',
  'MAY': 'Svibanj',
  'JUN': 'Lipanj',
  'JUL': 'Srpanj',
  'AUG': 'Kolovoz',
  'SEP': 'Rujan',
  'OCT': 'Listopad',
  'NOV': 'Studeni',
  'DEC': 'Prosinac',
  'POWER': 'Snaga',
  'NOMINAL_POWER': 'Nazivna snaga',
  'EFFECTIVE_POWER': 'Snaga učinkovit',
  'ZERO_POWER_WARNING': 'Ovaj grijač nema dodijeljenu snagu. Postavite ovdje ',
  'SET_MODE_TEMP': 'Postavi temp.',
  'SET_MODE_TEMPERATURE': 'Postavljanje temperature',
  'COMFORT': 'Udobnost',
  'ECO': 'Eko',
  'ANTI_ICE': 'Smrzavanje',
  'INSTALL': 'Instalirati',
  'INSTALL_ERROR_MSG': 'Postupak instalacije nije uspio. Pokušajte ponovo.',
  'MORE': 'više',
  'INSTALL_DEVICE': 'Instaliraj uređaj',
  'SELECT_ALL': 'Odaberi. sve.',
  'DESELECT_ALL': 'Odzč. sve.',
  'THERMOSTAT': 'Termostat',
  'HEATER': 'Grijač',
  'HEATER_HAVERLAND': 'Geolokacijski grijač',
  'HEATER_WITH_SMARTBOX': 'Grijač s Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Ručnik za grijanje s Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'WiFi Grijač s 4 gumba',
  'WIFI_HEATER_W2BUTTONS': 'WiFi Grijač s 2 gumba',
  'INSTALL_THERMOSTAT_STEP1': 'Instaliraj termostat: Korak 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Pritisnite gumb za <strong>POKRENI PRETRAžIVANJE</strong> da biste započeli instalaciju termostat',
  'INSTALL_THERMOSTAT_STEP2': 'Instaliraj termostat: Kprak 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'Da biste povezali termostat, pritisnite gumb <strong> helki </strong> (srednji gumb) za <strong> 3 sekunde </strong> dok se ikona mreže ne pojavi u gornjem desnom kutu termostata (kao što je prikazano na slici gore).',
  'START_SEARCH': 'Pokreni pretraživanje',
  'SEARCHING': 'Pretraživanje...',
  'INSTALL_HEATER_STEP1': 'Instaliraj grijač: Korak 1',
  'INSTALL_HEATER_HELP_STEP1': 'Pritisnite gumb za <strong>POKRENI PRETRAžIVANJE</strong> da biste započeli instalaciju grijač.',
  'INSTALL_HEATER_STEP2': 'Instaliraj grijač: Korak 2',
  'INSTALL_HEATER_HELP_STEP2': 'Da biste povezali električni grijač, <strong>PRITISNITE</strong> gumb <strong>U redu </strong> na uređaju za <strong> 3 sekunde </strong> dok se ikona mreže ne pojavi u gornjem desnom kutu zaslona.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Za kupnju električnog grijača  PRITISNITE  na prekidaču  MODE / OK  na napajanju  3 sekunde  crveni simbol mora biti prikazan u gornjem desnom kutu brektati.',
  'STORAGE_HEATER': 'Grijač spremnika',
  'STORAGE_HEATERS': 'Grijači spremnika',
  'INSTALL_STORAGE_HEATER_STEP1': 'Instalirajte grijač za pohranu: Korak 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Pritisnite gumb za <strong>POKRENI PRETRAžIVANJE</strong> da biste započeli instalaciju grijač za pohranu.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Instalirajte grijač za pohranu: Korak 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'Da biste povezali grijač, <strong>PRITISNITE</strong> gumb <strong>U</strong> redu na uređaju za <strong>3 sekunde</strong> dok se ikona mreže ne pojavi u gornjem desnom kutu zaslona.',
  'CHARGE_PROGRAM': 'Program za naplatu',
  'VALLEY_1': 'Razdoblje 1',
  'VALLEY_2': 'Razdoblje 2',
  'INSTALL_POWER_METER_STEP1': 'Instaliraj mjerač napajanja: Korak 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Pritisnite gumb za <strong>POKRENI PRETRAžIVANJE</strong> da biste započeli instalaciju mjerač napajanja.',
  'INSTALL_POWER_METER_STEP2': 'Instaliraj mjerač napajanja: Korak 2',
  'INSTALL_POWER_METER_HELP_STEP2': 'Da biste povezali mjerač snage, upotrijebite isječak (već naveden) da biste pritisnuli  mali gumb  koji se nalazi na vrhu uređaja (pogledajte sliku iznad). Možete provjeriti je li povezan tako da pogledate uređaj  LED .',
  'LED_INDICATOR_STATES': 'LED indikator stanja',
  'UNLINKED': 'Prekid veze',
  'LED_OFF': 'LED isključen',
  'CORRECTLY_LINKED': 'Ispravno povezano',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'LED treperi svake dvije sekunde i pol.',
  'LINKED_BUT_LOST': 'Povezano, ali izgubljeno',
  'LED_BLINKS_EVERY_HALF_SEC': 'LED treperi svakih pola sekunde. To se može dogoditi, na primjer, kada je pristupnik isključen. Kada mjerač snage i pristupnik ponovno uspostavljaju komunikaciju, automatski će se povezati. ',
  'NAME': 'Ime',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Odaberite naziv za identifikaciju ovog uređaja',
  'TYPE': 'Vrsta',
  'HEAT': 'Toplina',
  'COLD': 'Hladno',
  'CONTROL': 'Kontrola',
  'SELECT': 'Odaberi ...',
  'HYST_0P2': 'Histereza (0,2 ºC)',
  'HYST_0P3': 'Histereza (0,3 ºC)',
  'HYST_0P4': 'Histereza (0,4 ºC)',
  'HYST_0P5': 'Histereza (0,5 ºC)',
  'HYST_0P7': 'Histereza (0,7 ºC)',
  'HYST_0P8': 'Histereza (0.8 ºC)',
  'HYST_1P6': 'Histereza (1.6 ºC)',
  'HYST_0P4F': 'Histereza (0,4 ºF)',
  'HYST_0P35F': 'Histereza (0,35 ºF)',
  'HYST_0P5F': 'Histereza (0,5 ºF)',
  'HYST_0P7F': 'Histereza (0.7 ºF)',
  'HYST_0P9F': 'Histereza (0.9 ºF)',
  'HYST_1P2F': 'Histereza (1,2 ºF)',
  'HYST_1P4F': 'Histereza (1,4 ºF)',
  'HYST_1P25F': 'Histereza (1.25 ºF)',
  'HYST_1P45F': 'Histereza (1,45 ºF)',
  'HYST_2P9F': 'Histereza (2,9 ºF)',
  'PID_T10MIN': 'PID (10 min. Razdoblje)',
  'PID_T15MIN': 'PID (15 min. Razdoblje)',
  'PID_T20MIN': 'PID (razdoblje od 20 minuta)',
  'PID_T25MIN': 'PID (razdoblje od 25 minuta)',
  'PID_T30MIN': 'PID (razdoblje od 30 minuta)',
  'PID_T45MIN': 'PID (45 min. Razdoblje)',
  'PID_T60MIN': 'PID (60 min. Razdoblje)',
  'ANTI_GRIPPAGE': 'Anti blokada pumpe',
  'CONTRACTED_POWER_W': 'Maksimalna snaga (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Upišite maksimalnu snagu u vatima',
  'PRIORITY': 'Prioritet radijatora',
  'LOW': 'Nisko',
  'MEDIUM': 'Srednje',
  'HIGH': 'Visoko',
  'TRUE_RADIANT': 'Istinsko zračenje',
  'WINDOW_MODE': 'Način rada prozora',
  'DELETE_DEVICES': 'Brisanje uređaja',
  'DELETE_DEVICES_WARNING': 'Ovo će izbrisati odabrane uređaje iz sustava i njihovih povezanih podataka',
  'DELETE': 'Izbriši',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'Maksimalni napon radijatora',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'Ograničenje snage radijatora',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Upišite maksimalnu snagu za sve radijatore (W)',
  'LIMITLESS': 'Bez ograničenja',
  'HOME_INFO': 'Osnovne informacije',
  'HOME_NAME': 'Ime kuće',
  'TYPE_HOME_NAME': 'Upišite naziv svoje kuće',
  'TIME_ZONE': 'Vremenska zona',
  'TIME_ZONE_CET': '(Srednjeeuropsko vrijeme) Berlin, Pariz, Madrid, Rim',
  'TIME_ZONE_WET': '(Zapadnoeuropsko vrijeme) London, Dublin, Lisabon, Kanarski otoci',
  'TIME_ZONE_EET': '(Istočnoeuropsko vrijeme) Helsinki, Sofija, Atena',
  'TIME_ZONE_PET': '(Peruanski vrijeme) Lima',
  'TIME_ZONE_NZST': '(New Zealand Standard Time) Auckland',
  'TIME_ZONE_CIST': '(Chatham Island Standard Time) Chatham Islands',
  'TIME_ZONE_CST': '(China Standard Time) Shanghai',
  'TIME_ZONE_UYT': '(Uruguay Time) Montevideo',
  'DEVICE_IDENTIFIER': 'ID koda uređaja',
  'TYPE_DEVICE_IDENTIFIER': 'Upišite ID kôd uređaja',
  'INVALID_IDENTIFIER': 'Navedena identifikacijska šifra uređaja nije valjana',
  'DEVICE_ALREADY_TAKEN': 'Uređaj s ID -om DEVID već je registriran. Molimo prijavite tehničku podršku da prijavite ovaj problem.',
  'COPY_ERROR_DATA': '  Kopiraj ID uređaja',
  'MY_HOMES': 'Moji domovi',
  'ADD_HOME': 'Dodaj novi dom',
  'SAVE': 'Spremi',
  'MY_INFO': 'Moje informacije',
  'USER': 'Korisnik',
  'LANGUAGE': 'Jezik',
  'CHANGE_EMAIL_ADDRESS': 'Promijeni adresu e-pošte',
  'NEW_EMAIL_ADDRESS': 'Nova adresa e-pošte',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Upišite novu adresu e-pošte',
  'CONFIRM_WITH_PASSWORD': 'Potvrdi pomoću zaporke',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Upišite trenutnu lozinku',
  'CHANGE_PASSWORD': 'Promijeni zaporku',
  'CURRENT_PASSWORD': 'Trenutna zaporka',
  'TYPE_YOUR_NEW_PASSWORD': 'Upišite novu zaporku',
  'NEW_PASSWORD': 'Nova zaporka',
  'TEMPERATURE_UNITS': 'Mjerenja temperature',
  'CELSIUS': 'Celzijus',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Samoučenje',
  'SELF_LEARN_SHORT': 'Samoučenje',
  'PRESENCE': 'Prisutnost',
  'AWAY_OFFSET': 'Offset daleko',
  'ERASE_DATA_PERMANENTLY': 'Trajno brisanje podataka',
  'NO_CONNECTION_WARNING': 'Nema veze. Pristup samo zapisima ',
  'SET_MODE': 'Postavi način',
  'AT_HOME': 'Kod kuće',
  'AWAY': 'Daleko',
  'DEVICES_SUMMARY': 'Sažetak uređaja',
  'THERMOSTATS': 'Termostati',
  'HEATERS': 'Grijači',
  'POWER_METERS': 'Mjerači snage',
  'CLOSE': 'Zatvori',
  'DETECT_SMARTBOX': 'Otkrij SmartBox',
  'DETECT_SMARTBOX_HELP_MSG': 'Da biste pronašli svoj SmartBox, pritisnite gumb koji se nalazi na prednjoj strani uređaja. Provjerite je li ispravno spojen na vašu WIFI mrežu. ',
  'DETECT_SMARTBOX_ERROR_MSG': 'Žao nam je što nije bilo moguće pronaći SmartBox. Kliknite na sljedeći i ručno unesite identifikator uređaja ',
  'CONTINUE': 'Dalje',
  'CANCEL_SEARCH': 'Poništi pretraživanje',
  'SEARCHING_NEW_DEVICES': 'Pretraživanje novih uređaja ...',
  'DISCOVERY_MODE_MSG': 'SmartBox {{devId}} u načinu otkrivanja. Pritisnite odgovarajući gumb uređaja da biste ga instalirali u skladu s vašim priručnikom ',
  'DEVICE_FOUND_MSG': 'Pronađen je novi uređaj!',
  'GETTING_STARTED': 'Početak rada',
  'MAIN_MENU': 'Glavni izbornik',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Kliknite ikonu za pristup opcijama postavljanja računa ',
  'NEXT': 'Dalje',
  'GEOLOCALISATION': 'Geolokacija',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': ' Away  će se prema zadanim postavkama smanjiti za 2ºC',
  'DEVICES': 'Uređaji',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Vaši su uređaji podijeljeni u odjeljke:  Grijači  ili  Mjerač napajanja .',
  'GO_TO_A_DEVICE': 'Idi na uređaj',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Kliknite na uređaj da biste postavili i pregledali njegove zapise',
  'MULTIDEVICE_ACTIONS': 'Radnje s više uređaja',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Kliknite na uređaje i odaberite  više  da biste unijeli više postavki opcije',
  'READY': 'Spremni!',
  'SKIP': 'Preskočiti',
  'SKIP_HELP': 'Preskoči pomoć',
  'QUICK_SETTING': 'Brzo postavljanje',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Pomaknite traku temperature da biste brzo promijenili temperaturu',
  'TEMPERATURES': 'Temperature',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Postavite temperature za dostupne način rada',
  'MODE': 'Način',
  'WORKING_MODES': 'Načina',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Odaberite jedan od dostupnih načina',
  'SELECT_MODE': 'Odaberi način',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Odaberite način rada za svaki vremenski termin',
  'SUPPORT_BODY': 'Vaš račun ima minimalno razdoblje podrške za sigurnosna ažuriranja od 3 godine od datuma kupnje proizvoda. U slučaju da postanete svjesni bilo kakvih sigurnosnih ili drugih problema, pošaljite nam e-poštu na donju adresu s opisom problema.',
  'SUPPORT_REQUEST_INTRO_HELP': 'U donjem lijevom kutu nalazi se gumb pomoći za prikaz nekih savjeta za korištenje aplikacije.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Ako želite zatražiti podršku, upotrijebite sljedeće podatke za kontakt:',
  'ADDRESS': 'Adresa',
  'TELEPHONE': 'Telefon',
  'FAX': 'Faks',
  'TC_PP_LINK_TEXT': 'Registracijom prihvaćam <a href=\'{{frontendHost}}/hr/terms.html\' target=\'_blank\'> Uvjete i odredbe </a> i <a href=\'{{frontendHost}}/hr/privacy.html\' target=\'_ blank\'>Pravila o privatnosti</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\'> Pravila o privatnosti </a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Vaša je zaporka uspješno ažurirana',
  'PASSWORD_CHANGE_ERROR_MSG': 'Nije moguće ažurirati zaporku. Provjerite je li vaša trenutna zaporkaispravna.',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Nevažeća adresa e-pošte',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'E-adresa je već preuzeta',
  'EMAIL_CHANGE_ERROR_MSG': 'Nije bilo moguće ažurirati e-poštu.Molimo pokušajte ponovo kasnije.',
  'HOME_TYPE': 'Vrsta kuće',
  'ETHERNET': 'Kabelska veza',
  'WIFI': 'Wifi veza',
  'DEVICE_REGISTER_ERROR': 'Postupak registriranja nije mogao biti završen',
  'WRONG_CREDENTIALS_ERROR': 'Loš korisnik / lozinka',
  'DEVICE_ADDED_MSG': 'Dodan je novi uređaj',
  'CONNECTION_WARNING': 'Wifi veza',
  'WIFI_CONNECTION_1': 'Otvori postavke telefona',
  'WIFI_CONNECTION_2': 'Odaberite WiFi',
  'WIFI_CONNECTION_3': 'Povežite svoj telefon s WiFi',
  'WIFI_CONNECTION_4': 'Ako vidite obavijest koja glasi: \'WiFi nema pristup internetu\', odaberite \'Da\' da biste ostali povezani ',
  'WIFI_CONNECTION_5': 'Vratite se ovamo',
  'DEVICE_CONNECTION_ERROR': 'Povezivanje s uređajem nije uspjelo',
  'WIFI_SCAN_ERROR': 'Nema pronađenih mreža',
  'WRONG_PASS_ERROR': 'Trenutno konfigurirani WiFi ima pogrešnu lozinku',
  'WRONG_ESSID_ERROR': 'Trenutno konfigurirani WiFi ne postoji',
  'SET_WIFI_ERROR': 'Nije moguće uspostaviti WiFi mrežu',
  'DEVICE_CONNECTION_SUCCESSFUL': 'Veza uspješna',
  'WIFI_SELECTION': 'Odaberite svoju WiFi mrežu',
  'WIFI_NOT_FOUND': 'Ne mogu pronaći svoju mrežu',
  'TYPE_AP_ESSID': 'Upišite ime svoje mreže',
  'TYPE_AP_PASS': 'Upišite zaporku svoje mreže',
  'RETRY': 'Pokušaj ponovo',
  'AP_DATA': 'Podaci o mreži',
  'AP_ESSID': 'Ime',
  'AP_PASS': 'Zaporka',
  'EDIT_WIFI': 'Uredi konfiguraciju WiFi-ja',
  'WRONG_WIFI_ERROR': 'Niste povezani s odgovarajućim WiFi-jem',
  'COPY_PASS': 'Kopiraj zaporku',
  'EDIT_DATA': 'Osnovni podaci',
  'WEB_WIFI_WARNING': 'Wifi konfiguracija dostupna je samo u našoj aplikaciji',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Dodajte do 9 termina za temperaturu',
  'ACCEPT': 'Prihvati',
  'INVITE_USERS': 'Pozovite korisnike',
  'INVITE_USER': 'Pozovi korisnika',
  'INVITED_USERS': 'Pozvani korisnici',
  'INVITE_USER_TITLE': 'Pozovi korisnika',
  'INVITE_USER_SUCCESS_MSG': 'Novi korisnik je pozvan. Gostu je poslano e-mail da ga obavijesti da ima pristup ovom domu ',
  'INVITE_USER_ERROR_MSG': 'Pogreška u pozivanju korisnika.Molimo pokušajte ponovo kasnije.',
  'REVOKE_INVITE_TITLE': 'Ukloni pozvanog korisnika',
  'REVOKE_INVITE_MSG': 'Odabrani korisnik više neće imati pristup ovom domu',
  'REVOKE_INVITE_SUCCESS_MSG': 'Pozvani korisnik je uklonjen',
  'REVOKE_INVITE_ERROR_MSG': 'Pogreška prilikom uklanjanja pozvanogkorisnika. Molimo pokušajte ponovokasnije.',
  'CONFIRM_INVITE_SUCCESS': 'Vaš je račun uspješno kreiran',
  'GUEST': 'Gost',
  'TRV': 'TRV',
  'TRVS': 'TRVs',
  'INSTALL_TRV_STEP1': 'Instaliraj TRV: Korak 1',
  'INSTALL_TRV_HELP_STEP1': 'Pritisnite gumb za <strong>POKRENI PRETRAžIVANJE</strong> da biste započeli instalaciju TRV.',
  'INSTALL_TRV_STEP2': 'Instaliraj TRV: Korak 2',
  'INSTALL_TRV_HELP_STEP2': 'Da biste povezali TRV, pritisnite gumb <strong> helki </strong> (srednji gumb) za <strong> 3 sekunde </strong> ',
  'CONTROL_MODE_CHANGE_TITLE': 'Promjena načina grijanja / hlađenja',
  'CONTROL_MODE_CHANGE_MSG': 'Upozorenje: trenutni raspored bit će poništen',
  'SAVE_THIS_POSITION': 'Spremite ovu poziciju',
  'SET_POSITION_MSG': 'Još niste postavili svoj dom. Odaberite položaj na karti i spremite ga klikom na donji gumb.',
  'ENABLE_GEOLOCATION': 'Omogući geolokaciju',
  'OUTDOOR_RANGE': 'Radijus udaljenosti od kuće (m.)',
  'DEV_TYPE_WIFI': 'WiFi SmartBox',
  'DEV_TYPE_WIFIHTR': 'WiFi Radiátor',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Odaberite uređaj',
  'SELECT_DEVICE_THM_HELP': 'Termostat će biti dodijeljen sljedećem uređaju',
  'SELECT_DEVICE_HTR_HELP': 'Grijač je dodijeljen sljedećem uređaju',
  'SELECT_DEVICE_ACM_HELP': 'Grijači spremnika je dodijeljena sljedećem uređaju',
  'SELECT_DEVICE_PMO_HELP': 'Mjerač energije dodijeljen je sljedećim uređajima',
  'SELECT_DEVICE_TRV_HELP': 'Ventil je dodijeljen sljedećem uređaju',
  'ADD_DEVICE': 'Dodajte uređaj',
  'DEVICE_INSTALLATION': 'Instalacija uređaja',
  'WIFI_HEATER': 'WiFi Radijator',
  'WIFI_HEATER_HAVERLAND': 'Connect Series',
  'LETS_START': 'Počnimo',
  'INSTALL_WIFI_HEATER_HELP': 'Spojite radijator na utičnicu. Pritisnite tipku OK na svom radijatoru dok zaslon ne treperi.',
  'INSTALL_WIFI_HEATER2_HELP': 'Spojite radijator na utičnicu. Pritišćite gumbe prikazane na sljedećoj slici dok zaslon ne počne treperiti.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Spojite WiFi SmartBox. Ako je već spojen, odspojite ga i ponovo priključite.',
  'DEV_TYPE_WIFI_CONNECTION': 'WiFi SmartBox veza',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'WiFi radijatorska veza',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Sada SmartBox povezujemo s kućnom WiFi mrežom.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Sada spojite grijač na kućnu WiFi mrežu.',
  'GHZ_WIFI_WARNING': 'Pažnja: podržane su samo 2,4 GHz mreže',
  'CONFIGURING_WIFI_HTR': 'Konfigurirajte WiFi grijanje',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'AP način WiFi SmartBox konfiguracije',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'AP način WiFi konfiguracija radijatora',
  'SET_ETHERNET_SMARTBOX_NAME': 'Kako nazivamo ovaj SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Kako nazivamo ovaj WiFi SmartBox?',
  'SET_WIFI_HTR_NAME': 'Kako zovemo ovaj WiFi radijator?',
  'TYPE_DEVICE_NAME': 'Unesite naziv uređaja',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Spojite se na WiFi SmartBox',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Priključak na WiFi radijator',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Povezan na WiFi SmartBox s ID-om:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Spojen na WiFi radijator s ID-om:',
  'MY_DEVICES': 'Moji vlastiti uređaji',
  'DEVICE_TYPE': 'Kako izgleda uređaj?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Spojite se na WiFi SmartBox',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Priključak na WiFi radijator',
  'CONFIGURING_DEV_TYPE_WIFI': 'Konfigurirajte WiFi SmartBox',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'Konfigurirajte WiFi radijator',
  'DEVICE_LIST': 'Moji vlastiti uređaji',
  'CONNECTION_STATE': 'Status veze',
  'CONFIGURE_WIFI': 'Konfigurirajte WiFi uslugu',
  'CHANGE_HOME': 'Preselite se u drugi dom',
  'DELETE_DEVICE': 'Izbrišite uređaj',
  'TRANSFER_HOME_TITLE': 'Preselite se u drugi dom',
  'TRANSFER_HOME_WARNING': 'Pažnja: uređaj će biti premješten u odabranu kućnu kuću',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Izbrišite uređaj',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Jeste li sigurni da želite izbrisati ovaj uređaj?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Spojite Ethernet SmartBox',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'Uspješna instalacija WiFi SmartBox-a',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'Uspješna instalacija Ethernet SmartBox-a',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'WiFi radijator je uspješno instaliran',
  'HOME_REGISTRY': 'Prijava kuće',
  'HOME_REGISTRY_HELP': 'Trenutno niste prijavljeni kod kuće. Dodajte novi.',
  'SET_HOME_NAME': 'Postavite ime svog doma',
  'SET_HOME_TIMEZONE': 'Postavite svoju matičnu vremensku zonu',
  'DEVICE_CONNECTION_START': 'Sada spajaju uređaj',
  'WIFI_DEVICE': 'WiFi uređaj',
  'WIFI_DEVICE_HELP': 'Instalacija WiFi uređaja dostupna je samo u našoj aplikaciji',
  'TYPE_ESSID': 'Upišite svoju WiFi mrežu',
  'REGISTERING_DEVICE': 'Registracija uređaja',
  'WELCOME': 'Dobrodošli',
  'ESSID': 'Odaberite WiFi mrežu',
  'SET_BOOST_MODE': 'Postavite način Boost',
  'BOOST_END_TIME': 'Vrijeme završetka načina boost',
  'BOOST_ACTIVE_UNTIL': 'Način boost aktivan je do',
  'OPERATIONAL_MODE': 'Način rada',
  'FIXED_TEMPERATURE': 'Fiksna temperatura',
  'WEEKLY_SCHEDULE': 'Tjedni raspored',
  'ANTIFROST_PROTECTION': 'Zaštita od smrzavanja',
  'ID_COPIED': 'ID je kopiran',
  'NEW_PROFILE': 'Novi profil',
  'PROFILE_COLOR': 'Boja',
  'TYPE_PROFILE_NAME': 'Introduce a profile name',
  'TYPE_POWER_LIMIT': 'Introduce a power value',
  'POWER_LIMIT': 'Power (W)',
  'EDIT_PROFILES': 'Profili',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Ukloniti profil',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Jeste li sigurni da želite izbrisati ovaj profil?',
  'POWER_MANAGEMENT': 'Maximum power term configuration',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Mora biti između ',
  'AND': ' i ',
  'MANUAL_NETWORK_INPUT': 'Írja be manuálisan',
  'GEOLOCATION_INFO': 'Ova funkcionalnost omogućuje snižavanje temperature uređaja kada je izvan fiksnog radijusa stana.',
  'GEOLOCATION_NEW_FEAT': 'otkrijte funkciju koja vam omogućuje uštedu na potrošnji vaših uređaja kada niste kod kuće.',
  'VOICE_CONTROL': 'Glasovni asistent',
  'VOICE_CONTROL_NEW_FEAT': 'Imajte na umu da je ovaj uređaj kompatibilan s: ',
  'CONFIGURE': 'Konfigurirajte',
  'MORE_INFO_LONG': 'Više informacija',
  'GEOLOCATION_RATIONALE': 'Za ispravno funkcioniranje načina geolokacije, ovaj program mora cijelo vrijeme pristupati vašem mjestu, čak i kada ne koristite telefon. Omogućite dopuštenje u postavkama.',
  'USER_DUTY_FACTOR': 'Granični faktor snage',
  'CIRCUIT_TYPE': 'Circuit type',
  'PRINCIPAL_CIRCUIT': 'Main circuit',
  'REVERSE_SIGN': 'Reverse sign',
  'SMARTBOX_CONFIGURATION': 'Smartbox Configuration',
  'MAIN_CIRCUIT_GENERATION': 'Main generation circuit',
  'MAIN_CIRCUIT_CONSUMPTION': 'Main consumption circuit',
  'SELECT_GENERATION_POWERMETER': 'Select a generation powermeter',
  'SELECT_CONSUMPTION_POWERMETER': 'Select a consumption powermeter',
  'POWERMETER_CONFIG': 'Powermeter Configuration',
  'MAX_POWER_TERM_MANAGEMENT': 'Maximum power term',
  'MANAGE_MAX_POWER': 'Schedule',
  'MANAGE_ENERGY_TARIFFS': 'Schedule',
  'COLOR': 'Colour',
  'CONSUMPTION': 'Consumption',
  'GENERATION': 'Generation',
  'EDIT_ENERGY_TARIFFS': 'Profila',
  'SELECT_MAX_POWER_CONFIG': 'Odaberite ugovoreni profil snage',
  'CUSTOM_TARIFF_CONFIG': 'Novi prilagođeni profil',
  'ANTI_FROST_IN_OFF': 'Zaštita od Smrzavanja',
  'POWER_LEVEL_LIMIT': 'Razina Ograničenja Snage',
  'E2_USAGE_ENABLED': 'Solarna Energija',
  'E2_MODE': 'Vrsta Opterećenja',
  'E2_ACTIVATION_PER': 'Aktivacija Solarne Energije',
  'E2_CHARGE_EXCESS_PER': 'Maksimalno Opterećenje Doline',
  'NODE_E2_MODE_0': 'Ekskluzivno Solarno',
  'NODE_E2_MODE_1': 'Solarna i Dolina',
  'NODE_E2_MODE_2': 'Solarna i Korigirana Dolina',
  'E2_TEMP_EXCESS': 'Raspon temperature okoline',
  'E2_PRIORITY': 'Prioritet učitavanja',
  'MAIN_CIRCUIT': 'Glavni',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Uređaj uspješno ažuriran',
  'DEVICE_UPDATE_ERROR_MSG': 'Uređaj se nije mogao ažurirati.Molimo pokušajte ponovo kasnije.',
  'SYSTEM_MAINTENANCE': 'Sustav u održavanju',
  'SYSTEM_MAINTENANCE_MSG': 'Žao nam je zbog neugodnosti, radimo na poboljšanju usluge.',
  'SOLD_SEPARATELY': '(Prodaje se zasebno)',
  'REMOVE_ACCOUNT': 'Ukloni račun',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Ukloni račun',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'Uklanjanje računa uključuje brisanje svih čvorova i podataka povezanih s njim. Ako ste sigurni u brisanje računa, unesite svoju lozinku za potvrdu.',
  'ACCOUNT_REMOVAL_SUCCESS': 'Račun je uspješno uklonjen',
  'ACCOUNT_REMOVAL_ERROR': 'Nije moguće ukloniti račun.',
  'USE_QR': 'Skeniraj WiFi QR',
  'USE_MANUAL_AP': 'Unesite WiFi vjerodajnice',
  'LOADING': 'Učitavam',
  'SIGN_IN_WITH_GOOGLE': 'Prijava putem Googlea',
  'LOCK': 'Blok',
  'UNLOCK': 'Otključati',
  'BRIGHT_LEVEL': 'Razina svjetline zaslona',
  'BACKLIGHT_TIME': 'Vrijeme uključivanja zaslona',
  'BACKLIGHT_TIME_10': '10 sekunde',
  'BACKLIGHT_TIME_20': '20 sekunde',
  'BACKLIGHT_TIME_30': '30 sekunde',
  'BACKLIGHT_TIME_ALWAYS': 'Uvijek',
  'DELETE_ACCOUNT_MSG': 'Brisanjem korisničkog računa trajno brišete sve podatke povezane s njim. Ako se slažete, slijedite korake u nastavku.',
  'DELETE_ACCOUNT_STEPS': 'Koraci za brisanje računa',
  'DELETE_ACCOUNT_STEP1': '1. Prijavite se u aplikaciju svojim korisničkim imenom i lozinkom.',
  'DELETE_ACCOUNT_STEP2': '2. Idite na gornji izbornik.',
  'DELETE_ACCOUNT_STEP3': '3. Odaberite Moj račun.',
  'DELETE_ACCOUNT_STEP4': '4. U odjeljku Izbriši račun kliknite gumb Izbriši račun.',
  'DEPRECATION_MESSAGE': 'Koristite aplikaciju TARGET'
 },
 'hu': {
  'ASCII_ERROR': 'A WiFi hálózatok nevei és jelszavai csak ASCII karaktereket tartalmazhatnak.',
  'QR_SCANN_ERROR': 'A 32 karakternél hosszabb WiFi hálózatnevek és jelszavak nem támogatottak.',
  'QR_SCANN_ERROR_TITLE': 'Probléma lépett fel a bevezetett hitelesítési adatokkal',
  'EXTRA_PRESS_ON_PROVISIONING': 'Nyomja meg bármelyik gombot a kijelző aktiválásához',
  'BT_ACTIVATION_WARNING': 'Győződjön meg arról, hogy a Bluetooth engedélyezve van a mobiltelefonján',
  'DEVICE_DISCONNECTED_STEP_1': 'Nyissa meg a főmenüt',
  'DEVICE_DISCONNECTED_STEP_2': 'Lépjen az Otthonok oldalra, és válassza ki otthonát',
  'DEVICE_DISCONNECTED_STEP_3': 'Válassz eszközt',
  'DEVICE_DISCONNECTED_STEP_4': 'Konfigurálja a WiFi-t',
  'DEVICE_DISCONNECTED_HELP_1': 'A WiFi hálózat internetkapcsolattal rendelkezik, és 2,4 GHz-es hálózat.',
  'DEVICE_DISCONNECTED_HELP_2': 'Az eszköz telepítése során vegye figyelembe a megfelelő WiFi-lefedettséggel rendelkező területeket.',
  'DEVICE_DISCONNECTED_HELP_3': 'Ellenőrizze és konfigurálja újra eszköze WiFi hitelesítő adatait közvetlenül a <a class="alink" href=\'{{link}}\'>itt< webhelyről, vagy kövesse az alábbi sorrendet:',
  'DEVICE_DISCONNECTED_SUBHEADER': 'Az eszköz már regisztrálva van, de nincs megfelelően csatlakoztatva. Próbálja meg kihúzni a készüléket a csatlakozóból, majd csatlakoztassa újra. Ellenőrizze, hogy csatlakozik-e a WiFi hálózathoz. Ha nem, vegye figyelembe a következő szempontokat:',
  'DEVICE_DISCONNECTED_HEADER': 'Probléma lépett fel a kiépítési folyamat során, az eszköz elveszhetett.',
  'DEVICE_INSTALLATION_ERROR': 'Probléma merült fel az eszköz telepítése során.',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': 'Ellenőrizze az eszköz LED-jeinek állapotát. Válassza ki az egyik állapotot további információkért a probléma megoldásához:',
  'DEVICE_INSTALLATION_ERROR_NO_IP': 'Az eszköznek nincs hozzárendelt IP címe.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': 'Az eszköz nincs kapcsolódva a szerverhez.',
  'DEVICE_INSTALLATION_ERROR_OK': 'Az eszköz megfelelően van csatlakoztatva.',
  'DEVICE_ETHERNET_NO_IP_HELP_1': 'Győződjön meg róla, hogy az eszköz megfelelően csatlakozik-e a WiFi-hoz az Ethernet kábelen keresztül.',
  'DEVICE_ETHERNET_NO_IP_HELP_2': 'Ismételje meg a telepítési folyamatot.',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': 'Ellenőrizze a router internetkapcsolatát és győződjön meg arról, hogy nincsenek portkorlátozások.',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': 'Zárja be és nyissa meg újra az alkalmazást, hogy ellenőrizze, az eszköz helyesen települt-e.',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': 'Ellenőrizze az eszköz LED-jének állapotát. Válassza ki az egyik állapotot további információkért a probléma megoldásához:',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'A WiFi hálózat nincs beállítva.',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': 'Az eszköz nincs kapcsolódva a routerhez.',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': 'Ellenőrizze az eszköz állapotát az antenna ikon állapotának megfigyelésével. Válassza ki az egyik állapotot további információkért a probléma megoldásához:',
  'SERVER_CONNECTION_ERROR': 'Az alkalmazás nem tud csatlakozni a szerverhez. Ellenőrizze SmartPhone internetkapcsolatát, és próbálja meg újra a folyamatot',
  'AP_FALLBACK_TITLE': 'Indítsuk újra a folyamatot Access Point módszerrel',
  'HANDSHAKE_WARNING': 'Kérjük, győződjön meg arról, hogy okostelefonja most ugyanahhoz a WiFi hálózathoz csatlakozik.',
  'GEOLOCATION_RANGE': 'Otthoni sugár',
  'PROVISIONING_METHOD_HELP': 'Válasszon kiépítési módot az eszköz csatlakoztatásához',
  'RECOMMENDED': 'Ajánlott',
  'BACK': 'Vissza',
  'HELP': 'Segítség',
  'SUPPORT': 'Támogatás',
  'MY_ACCOUNT': 'Fiókom',
  'HOMES': 'Lakások',
  'PRIVACY': 'Magánélet',
  'VERSION': 'Változat',
  'PRIVACY_INFO': 'Itt láthatja a velünk kapcsolatos személyes adatainkat. Gyakorolhatja jogait az alább megjelenő kapcsolattartási e-mail címen',
  'HOME_GEOLOCATION': 'Otthoni hely',
  'LATITUDE': 'Szélességi',
  'LONGITUDE': 'Hosszúság',
  'CONTACT_EMAIL': 'Kapcsolattartó e-mail',
  'PP_POLICY_CHANGE_WARNING': 'Az adatvédelmi irányelvek és a feltételek módosultak. A folytatáshoz el kell fogadnia',
  'PP_READ_AND_ACCEPTED': 'Elolvastam és elfogadom <a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\' class=\'alink\'> adatvédelmi</a> és <a href=\'{{frontendHost}}/en/terms.html\' target=\'_blank\' class=\'alink\'>Feltételek és Kikötések</a>',
  'ACCEPTED': 'Elfogadott',
  'SIGN_OUT': 'Kijelentkezés',
  'SIGN_IN': 'Bejelentkezés',
  'SIGN_UP': 'Regisztráció',
  'EMAIL': 'Email',
  'PASSWORD': 'Jelszó',
  'ALL_FIELDS_REQUIRED': 'Minden mező kitöltése közelező',
  'REMEMBER_ME': 'Emlékezzen rám',
  'NEW_ON_HELKI': 'Új felhasználó regisztrációja itt!',
  'I_FORGOT_MY_PASSWORD': 'Elfelejtettem a jelszavam',
  'TYPE_EMAIL': 'Írja be az email címet',
  'TYPE_PASSWORD': 'Írja be a jelszót',
  'CONFIRM_PASSWORD': 'Jelszó megerősítése',
  'INVALID_EMAIL': 'Érvénytelen email cím',
  'SIGN_IN_ERROR': 'Helytelen email cím vagy jelszó',
  'INVALID_PASSWORD': 'Érvénytelen jelszó',
  'PASSWORDS_DO_NOT_MATCH': 'Jelszó nem egyezik',
  'USER_CONFLICT_ERROR': 'Már létező email cím',
  'SIGN_UP_SUCCESS': 'Sikeres regisztráció! Küldtünk egy emailt a fiók aktiválásához',
  'FORGOT_PASS_MESSAGE': 'Ne aggódjon, csak adja meg a regisztrációs email címét és változtassa meg a jelszót',
  'RESET_PASSWORD': 'Jelszó módosítása',
  'FORGOT_PASS_SUCCESS': 'Küldtünk egy emailt a jelszó módosításához',
  'USER_NOT_FOUND_ERROR': 'Felhasználó nem található',
  'SIGN_UP_VERIFY_SUCCESS': 'Fiók sikeresen aktiválva',
  'SIGN_UP_VERIFY_ERROR': 'Fiók aktiválása sikertelen. Lépjen velünk kapcsolatba!',
  'CHANGE_EMAIL_SUCCESS': 'E-mail címét sikeresen frissítettük',
  'CHANGE_EMAIL_ERROR': 'E-mail címét nem sikerült frissíteni. Lépjen velünk kapcsolatba!',
  'RESET_PASS_SUCCESS': 'Jelszó módosítása sikeres',
  'RESET_PASS_ERROR': 'Jelszó módosítása sikertelen. Lépjen velünk kapcsolatba!',
  'CHANGE_EMAIL_SUCCESS_TITLE': 'Az e-mail cím frissítése sikeresen megtörtént',
  'CHANGE_EMAIL_SUCCESS_MSG': 'A folyamat befejezéséhez e-mailt küldtünk az új címre',
  'CONNECTION_ERROR_MSG': 'Nem sikerült csatlakozni a szerverhez',
  'TRY_AGAIN': 'Próbálja újra',
  'NOW': 'Most',
  'HEATING': 'Fűtés',
  'ELECTRICITY': 'Teljesítmény',
  'WEATHER': 'Időjárás',
  'TEMPERATURE': 'Hőmérséklet',
  'TURN_OFF': 'Kikapcsolás',
  'TURN_ON': 'Bekapcsolás',
  'SCHEDULE': 'Ütemterv',
  'SETUP': 'Beállítások',
  'SET_TEMPERATURE': 'Állítsa be a hőmérsékletet',
  'SET_COMFORT_TEMPERATURE': 'Állítsa be a komfort hőmérsékletet',
  'SET_COMFORT_TEMPERATURE_WARNING': 'Figyelem! Ha megváltoztatja a komfort hőmérsékletet, az Eco hőmérséklet automatikusan újra beáll.',
  'SET_TEMPERATURE_MULTISELECT_ERROR': 'Kérjük, csak ugyanazt a radiátorrendszert válasszák (a fűtőtestek nem csoportosíthatók földrajzi helymeghatározással rendelkező fűtőtestekkel)',
  'REMOVE': 'Eltávolítani',
  'CANCEL': 'Mégse',
  'CONFIRM': 'Megerősít',
  'RECORDS': 'Feljegyzések',
  'SELECT_A_DAY': 'Válasszon napot',
  'DAY1_SHORT': 'He',
  'DAY2_SHORT': 'Ke',
  'DAY3_SHORT': 'Sz',
  'DAY4_SHORT': 'Cs',
  'DAY5_SHORT': 'Pé',
  'DAY6_SHORT': 'Sz',
  'DAY7_SHORT': 'Va',
  'MONDAY': 'Hétfő',
  'TUESDAY': 'Kedd',
  'WEDNESDAY': 'Szerda',
  'THURSDAY': 'Csütörtök',
  'FRIDAY': 'Péntek',
  'SATURDAY': 'Szombat',
  'SUNDAY': 'Vasárnap',
  'MONDAY_SHORT': 'H',
  'TUESDAY_SHORT': 'K',
  'WEDNESDAY_SHORT': 'S',
  'THURSDAY_SHORT': 'Cs',
  'FRIDAY_SHORT': 'P',
  'SATURDAY_SHORT': 'Sz',
  'SUNDAY_SHORT': 'Vas',
  'COPY_DAY': 'Nap másolása',
  'ADD': 'Új hozzáadása',
  'FROM': 'Kezdés',
  'TO': 'befejezés',
  'SELECT_A_DAY_MSG': 'Válasszon napot, hogy beállítsa vagy módosítsa a termosztát ütemtervét',
  'EDIT': 'Szerkesztés',
  'START': 'Indítás',
  'END': 'Befejezés',
  'DONE': 'Kész',
  'COPY_DAYS': 'Napok másolása',
  'USE_SAME_SCHEDULE_ON': 'Használja ugyanazon ütemtervet...',
  'COPY': 'Másolás',
  'POWER_METER': 'Teljesítménymérő',
  'USAGE': 'Használat',
  'USAGE_VALLEY': 'Terhelésfogyasztás',
  'USAGE_PEAK': 'Nincs töltésfogyasztás',
  'REAL_TIME_USAGE': 'Valós idejű használat',
  'DAILY': 'Napi',
  'MONTHLY': 'Havi',
  'ANNUAL': 'Éves',
  'JAN': 'Jan',
  'FEB': 'Feb',
  'MAR': 'Márc',
  'APR': 'Ápr',
  'MAY': 'Máj',
  'JUN': 'Jún',
  'JUL': 'Júl',
  'AUG': 'Aug',
  'SEP': 'Szep',
  'OCT': 'Okt',
  'NOV': 'Nov',
  'DEC': 'Dec',
  'POWER': 'Teljesítmény',
  'NOMINAL_POWER': 'Névleges teljesítmény',
  'EFFECTIVE_POWER': 'Hatékony teljesítmény',
  'ZERO_POWER_WARNING': 'Ennek a radiátornak nincs névleges teljesítménye. Itt állítsd be',
  'SET_MODE_TEMP': 'Hőmérséklet beállítása',
  'SET_MODE_TEMPERATURE': 'őmérséklet üzemmód beállítása',
  'COMFORT': 'Komfort',
  'ECO': 'Eco',
  'ANTI_ICE': 'Fagymentesítés',
  'INSTALL': 'Telepítés',
  'INSTALL_ERROR_MSG': 'Hiba a telepítési folyamatban. Próbáld újra!',
  'MORE': 'Több',
  'INSTALL_DEVICE': 'Eszköz telepítése',
  'SELECT_ALL': 'Mindet kijel',
  'DESELECT_ALL': 'Kivá mindet',
  'THERMOSTAT': 'Termosztát',
  'HEATER': 'Fűtőtest',
  'HEATER_HAVERLAND': 'Geolokációs fűtés',
  'HEATER_WITH_SMARTBOX': 'Fűtőtest Smartbox',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': 'Törölközőszárító Smartbox',
  'WIFI_HEATER_W4BUTTONS': 'WiFi Fűtőtest 4 gombbal',
  'WIFI_HEATER_W2BUTTONS': 'WiFi Fűtőtest 2 gombbal',
  'INSTALL_THERMOSTAT_STEP1': 'Termosztát telepítése: Lépés 1',
  'INSTALL_THERMOSTAT_HELP_STEP1': 'Nyomja meg <strong>KERESÉS INDÍTÁSA</strong> gombot a termosztát telepítésének megkezdéséhez.',
  'INSTALL_THERMOSTAT_STEP2': 'Termosztát telepítése: Lépés 2',
  'INSTALL_THERMOSTAT_HELP_STEP2': 'A termosztát párosításához tartsa lenyomva a <strong>Standby</strong> (bal gomb) és a <strong>Link</strong> (jobb gomb) gombot <strong>3 másodpercig</strong>, amíg a szimbólum meg nem jelenik. a termosztát jobb felső sarkában (a képen látható módon).',
  'START_SEARCH': 'Keresés indítása',
  'SEARCHING': 'Keresés...',
  'INSTALL_HEATER_STEP1': 'Fűtőtest telepítése: Lépés 1',
  'INSTALL_HEATER_HELP_STEP1': 'Nyomja meg <strong>KERESÉS INDÍTÁSA</strong> gombot a fűtőtest telepítésének megkezdéséhez.',
  'INSTALL_HEATER_STEP2': 'Fűtőtest telepítése: Lépés 2',
  'INSTALL_HEATER_HELP_STEP2': 'A radiátor összekapcsolásához, <strong>NYOMJA MEG</strong> az <strong>MODE/OK</strong> gombot <strong>3 másodpercig</strong> amíg a hálózat ikon meg nem jelenik a kijelző jobb felső sarkában.',
  'INSTALL_HEATER_MOD_HELP_STEP2': 'Győződjön meg arról, hogy a radiátor készenléti üzemmódban van, és tartsa lenyomva a hőmérséklet fel és le gombokat legalább 3 másodpercig.',
  'STORAGE_HEATER': 'Tároló fűtés',
  'STORAGE_HEATERS': 'Tároló fűtőberendezések',
  'INSTALL_STORAGE_HEATER_STEP1': 'Tároló fűtést telepítése: Lépés 1',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': 'Nyomja meg <strong>KERESÉS INDÍTÁSA</strong> gombot a tároló fűtést telepítésének megkezdéséhez.',
  'INSTALL_STORAGE_HEATER_STEP2': 'Tároló fűtést telepítése: Lépés 2',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': 'A tárolo fűtés összekapcsolásához, <strong>NYOMJA MEG</strong> az <strong>OK</strong> gombot <strong>3 másodpercig</strong> amíg a hálózat ikon meg nem jelenik a kijelző jobb felső sarkában.',
  'CHARGE_PROGRAM': 'Töltési program',
  'VALLEY_1': 'Időszak 1',
  'VALLEY_2': 'Időszak 2',
  'INSTALL_POWER_METER_STEP1': 'Teljesítménymérő telepítése: Lépés 1',
  'INSTALL_POWER_METER_HELP_STEP1': 'Nyomja meg <strong>KERESÉS INDÍTÁSA</strong> gombot a teljesítménymérő telepítésének megkezdéséhez.',
  'INSTALL_POWER_METER_STEP2': 'Teljesítménymérő telepítése: Lépés 1',
  'INSTALL_POWER_METER_HELP_STEP2': 'A teljesítménymérő összekapcsolásához tűvel nyomja meg a készülék tetején elhelyezett kis gombot. A készülék LED-jével ellenőrizheti, hogy létrejött-e a kapcsolat.',
  'LED_INDICATOR_STATES': 'LED jelzőfények',
  'UNLINKED': 'Nincs összekapcsolva',
  'LED_OFF': 'LED kikapcsolva',
  'CORRECTLY_LINKED': 'Megfelelő kapcsolat',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'A LED 2,5 másodpercenként villog.',
  'LINKED_BUT_LOST': 'A kapcsolat létrejött, de megszakadt',
  'LED_BLINKS_EVERY_HALF_SEC': 'A LED fél másodpercenként villog. Ez például akkor fordulhat elő, amikor a jeltovábbító nincs bekapcsolva. Amint a teljesítménymérő és a jeltovábbító helyreállítja a kommunikációt, automatikusan létrejön a kapcsolat.',
  'NAME': 'Név',
  'SELECT_A_NAME_FOR_IDENTIFYING': 'Válasszon egy nevet az eszköz beazonosításához',
  'TYPE': 'Típus',
  'HEAT': 'Meleg',
  'COLD': 'Hideg',
  'CONTROL': 'Ellenőrzés',
  'SELECT': 'Kiválaszt...',
  'HYST_0P2': 'Hőfokingadozás (0.2 ºC)',
  'HYST_0P3': 'Hőfokingadozás (0.3 ºC)',
  'HYST_0P4': 'Hőfokingadozás (0.4 ºC)',
  'HYST_0P5': 'Hőfokingadozás (0.5 ºC)',
  'HYST_0P7': 'Hőfokingadozás (0.7 ºC)',
  'HYST_0P8': 'Hőfokingadozás (0.8 ºC)',
  'HYST_1P6': 'Hőfokingadozás (1.6 ºC)',
  'HYST_0P4F': 'Hőfokingadozás (0.4 ºF)',
  'HYST_0P5F': 'Hőfokingadozás (0.5 ºF)',
  'HYST_0P7F': 'Hőfokingadozás (0.7 ºF)',
  'HYST_0P9F': 'Hőfokingadozás (0.9 ºF)',
  'HYST_1P2F': 'Hőfokingadozás (1.2 ºF)',
  'HYST_1P4F': 'Hőfokingadozás (1.4 ºF)',
  'HYST_2P9F': 'Hőfokingadozás (2.9 ºF)',
  'PID_T10MIN': 'PID szabályzó (10 perces időszakban)',
  'PID_T15MIN': 'PID szabályzó (15 perces időszakban)',
  'PID_T20MIN': 'PID szabályzó (20 perces időszakban)',
  'PID_T25MIN': 'PID szabályzó (25 perces időszakban)',
  'PID_T30MIN': 'PID szabályzó (30 perces időszakban)',
  'PID_T45MIN': 'PID szabályzó (45 perces időszakban)',
  'PID_T60MIN': 'PID szabályzó (60 perces időszakban)',
  'ANTI_GRIPPAGE': 'Pump anti-grippage',
  'CONTRACTED_POWER_W': 'Maximum teljesítmény (W)',
  'TYPE_CONTRACTED_POWER_IN_WATTS': 'Írja be a maximális teljesítményt wattban',
  'PRIORITY': 'Elsődleges radiátor',
  'LOW': 'Alacsony',
  'MEDIUM': 'Közepes',
  'HIGH': 'Magas',
  'TRUE_RADIANT': 'Adaptive Start Control',
  'WINDOW_MODE': 'Ablak mód',
  'DELETE_DEVICES': 'Eszköz törlése',
  'DELETE_DEVICES_WARNING': 'Kiválasztott eszköz és adatainak törlése',
  'DELETE': 'Törlés',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': 'A fűtőtest maximális teljesítménye',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': 'A fűtőtest teljesítményhatára',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': 'Írj be a maximális teljesítményt az összes radiátorhoz (W)',
  'LIMITLESS': 'Nincs limit',
  'HOME_INFO': 'Lakás információ',
  'HOME_NAME': 'Lakás neve',
  'TYPE_HOME_NAME': 'Írja be lakása nevét',
  'TIME_ZONE': 'Időzóna',
  'TIME_ZONE_CET': '(Közép-európai időzóna) Berlin, Párizs, Madrid, Róma, Budapest',
  'TIME_ZONE_WET': '(Nyugat-európai időzóna) London, Dublin, Lisszabon, Kanári-szigetek',
  'TIME_ZONE_EET': '(Kelet-európai időzóna) Helsinki, Szófia, Athén',
  'TIME_ZONE_PET': '(Perui idő szerint) Lima',
  'TIME_ZONE_NZST': '(New Zealand Standard Time) Auckland',
  'TIME_ZONE_CIST': '(Chatham Island Standard Time) Chatham Islands',
  'TIME_ZONE_CST': '(China Standard Time) Shanghai',
  'TIME_ZONE_UYT': '(Uruguay Time) Montevideo',
  'DEVICE_IDENTIFIER': 'Eszköz azonosító kód',
  'TYPE_DEVICE_IDENTIFIER': 'Írja be az eszköz azonosító kódját',
  'INVALID_IDENTIFIER': 'A megadott eszköz azonosító kód érvénytelen',
  'DEVICE_ALREADY_TAKEN': 'A DEVID azonosítójú eszköz már regisztrálva van. Kérjük, lépjen kapcsolatba a műszaki támogatással a probléma bejelentéséhez.',
  'COPY_ERROR_DATA': '  Másolja az eszközazonosítót',
  'MY_HOMES': 'Lakásaim',
  'ADD_HOME': 'Új lakás hozzáadás',
  'SAVE': 'Mentés',
  'MY_INFO': 'Adataim',
  'USER': 'Felhasználó',
  'LANGUAGE': 'Nyelv',
  'CHANGE_EMAIL_ADDRESS': 'Email cím módosítása',
  'NEW_EMAIL_ADDRESS': 'Új email cím',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': 'Írja be új email címét',
  'CONFIRM_WITH_PASSWORD': 'Erősítse meg jelszavát',
  'TYPE_YOUR_CURRENT_PASSWORD': 'Adja meg jelenlegi jelszavát',
  'CHANGE_PASSWORD': 'Jelszó módosítása',
  'CURRENT_PASSWORD': 'Jelenlegi jelszó',
  'TYPE_YOUR_NEW_PASSWORD': 'Adja meg új jelszavát',
  'NEW_PASSWORD': 'Új jelszó',
  'TEMPERATURE_UNITS': 'Hőmérséklet mérések',
  'CELSIUS': 'Celsius',
  'FAHRENHEIT': 'Fahrenheit',
  'SELF_LEARN': 'Önálló tanulás',
  'SELF_LEARN_SHORT': 'ÖnállóTanu.',
  'PRESENCE': 'Jelenlét',
  'AWAY_OFFSET': 'Hőmérséklet csökkentés távollét esetén',
  'ERASE_DATA_PERMANENTLY': 'Adatok végleges törlése',
  'NO_CONNECTION_WARNING': 'Nincs kapcsolat. Csak az adatokohoz való hozzáférés',
  'SET_MODE': 'Beállítások',
  'AT_HOME': 'Otthon',
  'AWAY': 'Távol',
  'DEVICES_SUMMARY': 'Összes eszköz',
  'THERMOSTATS': 'Termosztátok',
  'HEATERS': 'Fűtőtestek',
  'POWER_METERS': 'Teljesítmény mérők',
  'CLOSE': 'Bezár',
  'DETECT_SMARTBOX': 'Központi WIFI egység keresése',
  'DETECT_SMARTBOX_HELP_MSG': 'A WiFi központi egység megtalálásához nyomja meg a készülék tetején található gombot. Győződjön meg arról, hogy megfelelően csatlakozik-e a WIFI hálózathoz.',
  'DETECT_SMARTBOX_ERROR_MSG': 'Sajnáljuk, de a WiFi központi egység nem található. Kattintson a következő gombra, és írja be manuálisan az eszközazonosítót',
  'CONTINUE': 'Következő',
  'CANCEL_SEARCH': 'Keresés törlése',
  'SEARCHING_NEW_DEVICES': 'Új eszközök keresése...',
  'DISCOVERY_MODE_MSG': 'Wifi központi egység {{devId}} felfedezési módban. A kézikönyvnek megfelelően telepítéshez nyomja meg a megfelelő eszköz gombot',
  'DEVICE_FOUND_MSG': 'Új eszköz található!',
  'GETTING_STARTED': 'Kezdjük',
  'MAIN_MENU': 'Főmenü',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': 'Kattintson az ikonra a fiókbeállítások eléréséhez',
  'NEXT': 'Következő',
  'GEOLOCATION': 'Helymeghatározás',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': 'Távolléti módban a hőfok 2ºC-kal csökken',
  'DEVICES': 'Eszközök',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': 'Az eszközei két típusra oszthatók: fűtőberendezésekre és teljesítménymérőkre.',
  'GO_TO_A_DEVICE': 'Menjen egy eszközhöz',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': 'Kattintson egy eszközre, hogy beállítsa, és megnézze az adatait',
  'MULTIDEVICE_ACTIONS': 'Többeszközös műveletek',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': 'Kattintson az ESZKÖZÖK elemre, és válassza a TÖBB lehetőséget a további opciók beállításához',
  'READY': 'Kész!',
  'SKIP': 'Kihagy',
  'SKIP_HELP': 'Segítség kihagyása',
  'QUICK_SETTING': 'Gyors beállítás',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': 'Csúsztassa el a hőmérséklet-sávot a hőmérséklet gyors megváltoztatásához',
  'TEMPERATURES': 'Hőmérsékletek',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': 'Állítsa be a hőmérsékletet az elérhető üzemmódokhoz',
  'MODE': 'Üzemmód',
  'WORKING_MODES': 'Módok',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': 'Válasszon egyet az elérhető üzemmódok közül',
  'SELECT_MODE': 'Válasszon üzemmódot',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': 'Válassza ki az egyes időközök módját',
  'SUPPORT_BODY': 'Fiókjában a biztonsági frissítések minimális támogatási időszaka a termék megvásárlásának dátumától számított 3 év. Abban az esetben, ha bármilyen biztonsági vagy egyéb problémáról szerez tudomást, kérjük, küldjön nekünk egy e-mailt az alábbi címre, amelyben leírja a problémát.',
  'SUPPORT_REQUEST_INTRO_HELP': 'A bal alsó sarokban található egy súgó gomb, amely néhány tippet jelenít meg az alkalmazás használatához.',
  'SUPPORT_REQUEST_INTRO_CONTACT': 'Ha segítségre van szüksége, kérjük lépjen velünk kapcsolatba a következő elérhetőségeken:',
  'ADDRESS': 'Cím',
  'TELEPHONE': 'Telefonszám',
  'FAX': 'Fax',
  'TC_PP_LINK_TEXT': 'A regisztrációval elfogadom a <a href=\'{{frontendHost}}/en/terms.html\' target=\'_blank\'>Felhasználói feltételeket</a> és <a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\'>z Adatvédelmi nyilatkozatot</a>',
  'PP_LINK': '<a href=\'{{frontendHost}}/en/privacy.html\' target=\'_blank\'>Privacy Policy</a>',
  'PASSWORD_CHANGE_SUCCESS_MSG': 'Jelszavát sikeresen frissítettük',
  'PASSWORD_CHANGE_ERROR_MSG': 'Jelszavát nem sikerült frissíteni.Kérjük, ellenőrizze, hogy a jelenlegi jelszava helyes-e',
  'EMAIL_BAD_FORMAT_ERROR_MSG': 'Érvénytelen email',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': 'Az email cím már foglalt',
  'EMAIL_CHANGE_ERROR_MSG': 'Nem sikerült frissíteni az e-mailt.Kérlek, próbáld újra később.',
  'HOME_TYPE': 'Otthoni típus',
  'ETHERNET': 'Kábelcsatlakozás',
  'WIFI': 'Wifi kapcsolat',
  'DEVICE_REGISTER_ERROR': 'A regisztrációs folyamat nem fejeződött be',
  'WRONG_CREDENTIALS_ERROR': 'Rossz felhasználó / jelszó',
  'DEVICE_ADDED_MSG': 'Új eszköz hozzáadva',
  'CONNECTION_WARNING': 'Wifi kapcsolat',
  'WIFI_CONNECTION_1': 'Nyissa meg a telefon beállításait',
  'WIFI_CONNECTION_2': 'Válassza a WiFi',
  'WIFI_CONNECTION_3': 'Csatlakoztassa telefonját a WiFi-hez',
  'WIFI_CONNECTION_4': 'Ha lát egy értesítést, amely ezt állítja: \'A WiFi-nek nincs internet-hozzáférése\', válassza az \'Igen\' lehetőséget a kapcsolat fenntartásához',
  'WIFI_CONNECTION_5': 'Gyere vissza',
  'DEVICE_CONNECTION_ERROR': 'Nem sikerült csatlakozni az eszközhöz',
  'WIFI_SCAN_ERROR': 'Nem található hálózat',
  'WRONG_PASS_ERROR': 'A jelenleg konfigurált WiFi hibás jelszóval rendelkezik',
  'WRONG_ESSID_ERROR': 'A jelenleg konfigurált WiFi nem létezik',
  'SET_WIFI_ERROR': 'Nem sikerült létrehozni a WiFi hálózatot',
  'DEVICE_CONNECTION_SUCCESSFUL': 'A kapcsolat sikeres',
  'WIFI_SELECTION': 'Válassza ki a WiFi hálózatát',
  'WIFI_NOT_FOUND': 'Nem található a hálózatom',
  'TYPE_AP_ESSID': 'Írja be a hálózat nevét',
  'TYPE_AP_PASS': 'TÍrja be a hálózat jelszavát',
  'RETRY': 'Próbálja újra',
  'AP_DATA': 'Hálózati adatok',
  'AP_ESSID': 'Név',
  'AP_PASS': 'Jelszó',
  'EDIT_WIFI': 'Szerkessze a WiFi konfigurációt',
  'WRONG_WIFI_ERROR': 'Nincs csatlakoztatva a megfelelő WiFi-hez',
  'COPY_PASS': 'Jelszó másolása',
  'EDIT_DATA': 'Otthoni adatok',
  'WEB_WIFI_WARNING': 'A WiFi konfiguráció csak az alkalmazásunkban érhető el',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': 'Adjon hozzá legfeljebb 9 hőmérséklet-rést',
  'ACCEPT': 'Elfogad',
  'INVITE_USERS': 'Hívja meg a felhasználókat',
  'INVITE_USER': 'Felhasználó meghívása',
  'INVITED_USERS': 'Meghívott felhasználók',
  'INVITE_USER_TITLE': 'Felhasználó meghívása',
  'INVITE_USER_SUCCESS_MSG': 'Új felhasználó meghívva. E-mailt küldtünk a vendégnek, hogy tudassa velük, hogy hozzáférnek ehhez az otthonhoz',
  'INVITE_USER_ERROR_MSG': 'Hiba a felhasználó meghívásakor.Kérlek, próbáld újra később.',
  'REVOKE_INVITE_TITLE': 'Meghívott felhasználó eltávolítása',
  'REVOKE_INVITE_MSG': 'A kiválasztott felhasználó többé nem fog hozzáférni ehhez az otthonhoz',
  'REVOKE_INVITE_SUCCESS_MSG': 'A meghívott felhasználót eltávolítottuk',
  'REVOKE_INVITE_ERROR_MSG': 'Hiba történt a meghívott felhasználóeltávolításakor. Kérlek, próbáld újra később.',
  'CONFIRM_INVITE_SUCCESS': 'Fiók sikere létrehozva',
  'GUEST': 'Vendég',
  'TRV': 'TRV',
  'TRVS': 'TRVs',
  'INSTALL_TRV_STEP1': 'Telepítése TRV: Lépés 1',
  'INSTALL_TRV_HELP_STEP1': 'Nyomja meg <strong>KERESÉS INDÍTÁS</strong> gombot a TRV telepítésének megkezdéséhez.',
  'INSTALL_TRV_STEP2': 'Telepítése TRV: Lépés 2',
  'INSTALL_TRV_HELP_STEP2': 'A TRV összekapcsolásához, <strong>NYOMJA MEG</strong> az <strong>helki</strong> gombot <strong>3 másodpercig</strong>',
  'CONTROL_MODE_CHANGE_TITLE': 'Hő / Hűtés mód váltás',
  'CONTROL_MODE_CHANGE_MSG': 'Figyelem: az aktuális ütemezés visszaáll',
  'SAVE_THIS_POSITION': 'Mentse el ezt a pozíciót',
  'SET_POSITION_MSG': 'Még nem választott helyet. Kérjük, válassza ki a helyet a térképen, és mentse el az alábbi gomb megnyomásával.',
  'ENABLE_GEOLOCATION': 'Engedélyezze a helymeghatározást',
  'OUTDOOR_RANGE': 'Otthoni sugár  (m.)',
  'DEV_TYPE_WIFI': 'WiFi SmartBox',
  'DEV_TYPE_WIFIHTR': 'WiFi Fűtés',
  'DEV_TYPE_ETHERNET': 'SmartBox',
  'SELECT_DEVICE': 'Válassz eszközt',
  'SELECT_DEVICE_THM_HELP': 'A termosztát a következő eszközhöz lesz rendelve',
  'SELECT_DEVICE_HTR_HELP': 'A fűtés a következő eszközhöz lesz rendelve',
  'SELECT_DEVICE_ACM_HELP': 'A tároló fűtés a következő eszközhöz lesz rendelve',
  'SELECT_DEVICE_PMO_HELP': 'A teljesítménymérő a következő eszközhöz lesz rendelve',
  'SELECT_DEVICE_TRV_HELP': 'A szelep a következő eszközhöz lesz rendelve',
  'ADD_DEVICE': 'Eszköz hozzáadása',
  'DEVICE_INSTALLATION': 'Eszköz telepítése',
  'WIFI_HEATER': 'WiFi Fűtés',
  'WIFI_HEATER_HAVERLAND': 'Connect Series',
  'LETS_START': 'Kezdjük',
  'INSTALL_WIFI_HEATER_HELP': 'Kérjük, nyomja meg a WiFi fűtőelem elején található gombot, amíg a képernyő villogni nem kezd.',
  'INSTALL_WIFI_HEATER2_HELP': 'Csatlakoztassa a radiátort a konnektorhoz. Nyomja meg a következő képen látható gombokat, amíg a képernyő villogni nem kezd.',
  'INSTALL_WIFI_SMARTBOX_HELP': 'Csatlakoztassa a WiFi SmartBox-ot. Ha már be volt dugva, kérjük, húzza ki és csatlakoztassa újra.',
  'DEV_TYPE_WIFI_CONNECTION': 'WiFi SmartBox kapcsolat',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'WiFi Fűtés kapcsolat',
  'DEV_TYPE_WIFI_CONNECTION_HELP': 'Most összekapcsoljuk a SmartBoxot otthona WiFi hálózatával.',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': 'Most összekapcsoljuk a Fűtés otthona WiFi hálózatával.',
  'GHZ_WIFI_WARNING': 'Figyelem: csak 2,4 GHz-es hálózatok támogatottak',
  'CONFIGURING_WIFI_HTR': 'A WiFi fűtés beállítása',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': 'AP módban WiFi SmartBox konfiguráció',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': 'AP módban WiFi Fűtés konfiguráció',
  'SET_ETHERNET_SMARTBOX_NAME': 'Hogy hívjuk ezt SmartBox?',
  'SET_WIFI_SMARTBOX_NAME': 'Hogy hívjuk ezt Wifi SmartBox?',
  'SET_WIFI_HTR_NAME': 'Hogy hívjuk ezt Wifi Fűtés?',
  'TYPE_DEVICE_NAME': 'Írja be az eszköz nevét',
  'CONNECTING_TO_DEV_TYPE_WIFI': 'Csatlakozás a WiFi SmartBox',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': 'Csatlakozás a WiFi Fűtés',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': 'Csatlakoztatva a WiFi SmartBoxhoz azonosítóval:',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': 'Csatlakoztatva a WiFi Fűtés azonosítóval:',
  'MY_DEVICES': 'Saját készülékek',
  'DEVICE_TYPE': 'Hogyan néz ki a készüléke?',
  'CONNECT_TO_DEV_TYPE_WIFI': 'Csatlakozás a WiFi SmartBox',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': 'Csatlakozás a WiFi Fűtés',
  'CONFIGURING_DEV_TYPE_WIFI': 'A WiFi Smartbox konfigurálása',
  'CONFIGURING_DEV_TYPE_WIFIHTR': 'A WiFi Fűtés konfigurálása',
  'DEVICE_LIST': 'Saját készülékek',
  'CONNECTION_STATE': 'Kapcsolat állapota',
  'CONFIGURE_WIFI': 'Konfigurálja a WiFi',
  'CHANGE_HOME': 'Váltás másik házra',
  'DELETE_DEVICE': 'Eszköz törlése',
  'TRANSFER_HOME_TITLE': 'Váltás másik házra',
  'TRANSFER_HOME_WARNING': 'Figyelem: készüléke átkerül a kiválasztott otthonba',
  'CONFIRM_DEVICE_REMOVAL_TITLE': 'Eszköz törlése',
  'CONFIRM_DEVICE_REMOVAL_HELP': 'Biztosan törli ezt az eszközt?',
  'INSTALL_ETHERNET_SMARTBOX_HELP': 'Kérjük, csatlakoztassa az Ethernet SmartBox',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'A WiFi SmartBox telepítési sikere',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': 'Az Ethernet SmartBox telepítési sikere',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'A WiFi fűtés telepítésének sikere',
  'HOME_REGISTRY': 'Lakásregisztráció',
  'HOME_REGISTRY_HELP': 'Jelenleg nincs regisztrált otthona. Adjunk hozzá egy újat.',
  'SET_HOME_NAME': 'Állítsa be az otthon nevét',
  'SET_HOME_TIMEZONE': 'Állítsa be az otthoni időzónát',
  'DEVICE_CONNECTION_START': 'Most csatlakoztatni fogjuk az eszközét',
  'WIFI_DEVICE': 'WiFi eszköz',
  'WIFI_DEVICE_HELP': 'A WiFi eszközök telepítése csak az alkalmazásainkban érhető el',
  'TYPE_ESSID': 'Írja be WiFi hálózatát',
  'REGISTERING_DEVICE': 'Eszköz regisztrálása',
  'WELCOME': 'Üdvözöljük',
  'ESSID': 'Válassza a WiFi hálózat lehetőséget',
  'SET_BOOST_MODE': 'A Boost mód beállítása',
  'BOOST_END_TIME': 'Boost befejezési ideje',
  'BOOST_ACTIVE_UNTIL': 'Boost aktív, amíg',
  'OPERATIONAL_MODE': 'Működési mód',
  'FIXED_TEMPERATURE': 'Rögzített hőmérséklet',
  'WEEKLY_SCHEDULE': 'Heti beosztás',
  'ANTIFROST_PROTECTION': 'Fagyvédelem',
  'ID_COPIED': 'Azonosító másolva',
  'NEW_PROFILE': 'Új profil',
  'PROFILE_COLOR': 'Szín',
  'TYPE_PROFILE_NAME': 'Introduce a profile name',
  'TYPE_POWER_LIMIT': 'Introduce a power value',
  'POWER_LIMIT': 'Power (W)',
  'EDIT_PROFILES': 'Profilok',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': 'Profil törlése',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': 'Biztosan eltávolítja ezt az áramellátási profilt?',
  'POWER_MANAGEMENT': 'Maximum power term configuration',
  'NUMBER_INPUT_LABEL_HELP_MSG': 'Között kell lennie ',
  'AND': ' és ',
  'MANUAL_NETWORK_INPUT': 'Írja be manuálisan',
  'GEOLOCATION_INFO': 'Ez a funkció lehetővé teszi az eszközök hőmérsékletének csökkentését, ha kívül van az otthoni sugárzáson',
  'GEOLOCATION_NEW_FEAT': 'Fedezze fel azt a funkciót, amely lehetővé teszi az eszközfogyasztás megtakarítását, ha éppen nincs otthon.',
  'VOICE_CONTROL': 'Hangsegéd',
  'VOICE_CONTROL_NEW_FEAT': 'Ne feledje, hogy ez az eszköz kompatibilis a következőkkel: ',
  'CONFIGURE': 'Beállítás',
  'MORE_INFO_LONG': 'Több információ',
  'GEOLOCATION_RATIONALE': 'A helymeghatározási mód megfelelő működéséhez az alkalmazásnak folyamatosan hozzáférnie kell az Ön tartózkodási helyéhez, még akkor is, ha éppen nem használja a telefont. Engedélyezze a beállításokban.',
  'USER_DUTY_FACTOR': 'Teljesítménykorlát tényező',
  'CIRCUIT_TYPE': 'Circuit type',
  'PRINCIPAL_CIRCUIT': 'Main circuit',
  'REVERSE_SIGN': 'Reverse sign',
  'SMARTBOX_CONFIGURATION': 'Smartbox Configuration',
  'MAIN_CIRCUIT_GENERATION': 'Main generation circuit',
  'MAIN_CIRCUIT_CONSUMPTION': 'Main consumption circuit',
  'SELECT_GENERATION_POWERMETER': 'Select a generation powermeter',
  'SELECT_CONSUMPTION_POWERMETER': 'Select a consumption powermeter',
  'POWERMETER_CONFIG': 'Powermeter Configuration',
  'MAX_POWER_TERM_MANAGEMENT': 'Maximum power term',
  'MANAGE_MAX_POWER': 'Schedule',
  'MANAGE_ENERGY_TARIFFS': 'Schedule',
  'COLOR': 'Colour',
  'CONSUMPTION': 'Consumption',
  'GENERATION': 'Generation',
  'EDIT_ENERGY_TARIFFS': 'Profil',
  'SELECT_MAX_POWER_CONFIG': 'Válasszon egy szerződéses teljesítményprofilt',
  'CUSTOM_TARIFF_CONFIG': 'Új egyéni profil',
  'ANTI_FROST_IN_OFF': 'Jegesedés elleni védelem',
  'POWER_LEVEL_LIMIT': 'Teljesítménykorlát szintje',
  'E2_USAGE_ENABLED': 'Napenergia',
  'E2_MODE': 'Rakomány típusa',
  'E2_ACTIVATION_PER': 'Napenergiák Aktiválása',
  'E2_CHARGE_EXCESS_PER': 'Maximális Völgy terhelés',
  'NODE_E2_MODE_0': 'Szoláris Exkluzív',
  'NODE_E2_MODE_1': 'Szoláris és Völgy',
  'NODE_E2_MODE_2': 'Szoláris és Korrigált Völgy',
  'E2_TEMP_EXCESS': 'Környezeti hőmérséklet tartomány',
  'E2_PRIORITY': 'Betöltési prioritás',
  'MAIN_CIRCUIT': 'Fő',
  'DEVICE_UPDATE_SUCCESS_MSG': 'Az eszköz sikeresen frissítve',
  'DEVICE_UPDATE_ERROR_MSG': 'Az eszközt nem sikerült frissíteni.Kérlek, próbáld újra később.',
  'SYSTEM_MAINTENANCE': 'A rendszer a karbantartás ban',
  'SYSTEM_MAINTENANCE_MSG': 'Elnézést a kellemetlenségért, azon dolgozunk, hogy javítsuk a szolgáltatást.',
  'SOLD_SEPARATELY': '(Külön eladó)',
  'REMOVE_ACCOUNT': 'Fiók eltávolítása',
  'TYPE_PASSWORD_TO_CONFIRM': '',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': 'Fiók törlése',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': 'A fiók eltávolítása az összes hozzá tartozó csomópont és adat törlését jelenti. Ha biztos benne, hogy törli a fiókot, adja meg jelszavát a megerősítéshez.',
  'ACCOUNT_REMOVAL_SUCCESS': 'A fiók sikeresen eltávolítva',
  'ACCOUNT_REMOVAL_ERROR': 'Nem sikerült eltávolítani a fiókot.',
  'USE_QR': 'Scan WiFi QR',
  'USE_MANUAL_AP': 'Adja meg a WiFi hitelesítő adatait',
  'LOADING': 'Betöltés',
  'SIGN_IN_WITH_GOOGLE': 'Bejelentkzés Google-fiókkal',
  'LOCK': 'Blokk',
  'UNLOCK': 'Feloldásához',
  'BRIGHT_LEVEL': 'Kijelző fényerősségi szintje',
  'BACKLIGHT_TIME': 'Kijelző bekapcsolási ideje',
  'BACKLIGHT_TIME_10': '10 másodpercek',
  'BACKLIGHT_TIME_20': '20 másodpercek',
  'BACKLIGHT_TIME_30': '30 másodpercek',
  'BACKLIGHT_TIME_ALWAYS': 'Mindig',
  'DELETE_ACCOUNT_MSG': 'A felhasználói fiók törlésével véglegesen törli a hozzá tartozó összes adatot. Ha egyetért, kérjük, kövesse az alábbi lépéseket.',
  'DELETE_ACCOUNT_STEPS': 'A fiók törlésének lépései',
  'DELETE_ACCOUNT_STEP1': '1. Jelentkezzen be az alkalmazásba a felhasználónevével és jelszavával.',
  'DELETE_ACCOUNT_STEP2': '2. Menjen a felső menübe.',
  'DELETE_ACCOUNT_STEP3': '3. Válassza a Saját fiókot.',
  'DELETE_ACCOUNT_STEP4': '4. A Fiók törlése szakaszban kattintson a Fiók törlése gombra.',
  'DEPRECATION_MESSAGE': 'Kérjük, használja a TARGET alkalmazást'
},
 'zh': {
  'ASCII_ERROR': 'WiFi 网络名称和密码必须仅包含 ASCII 字符',
  'QR_SCANN_ERROR': '不支持超过 32 个字符的 WiFi 网络名称和密码',
  'QR_SCANN_ERROR_TITLE': '引入的凭据有问题',
  'EXTRA_PRESS_ON_PROVISIONING': '按任意按钮即可激活显示屏',
  'BT_ACTIVATION_WARNING': '请确保您的手机能够使用蓝牙功能',
  'PROVISIONING_METHOD_HELP': '选择连接到路由器的配置方法',
  'RECOMMENDED': '推荐',
  'BACK': '上一步',
  'HELP': '需要帮助',
  'SUPPORT': '需要支持',
  'MY_ACCOUNT': '我的账户',
  'HOMES': '主页',
  'PRIVACY': '隐私',
  'VERSION': '版本',
  'PRIVACY_INFO': '在这里您可以看到您的个人信息。您可以通过联系下方的邮箱行使您的权力。',
  'HOME_GEOLOCATION': '家的地理位置',
  'LATITUDE': '纬度',
  'LONGITUDE': '经度',
  'CONTACT_EMAIL': '联系邮箱',
  'PP_POLICY_CHANGE_WARNING': '隐私政策和条款与条件已更改。您必须同意以继续',
  'PP_READ_AND_ACCEPTED': '我已阅读并接受隐私政策和条款与条件。',
  'ACCEPTED': '已接受',
  'SIGN_OUT': '登出',
  'SIGN_IN': '登入',
  'SIGN_UP': '注册',
  'EMAIL': '邮箱',
  'PASSWORD': '密码',
  'ALL_FIELDS_REQUIRED': '所有内容均不能为空！',
  'REMEMBER_ME': '记住我',
  'NEW_ON_HELKI': '新用户点这里注册！',
  'I_FORGOT_MY_PASSWORD': '忘记密码',
  'TYPE_EMAIL': '输入您的邮箱',
  'TYPE_PASSWORD': '输入您的密码',
  'CONFIRM_PASSWORD': '确认密码',
  'INVALID_EMAIL': '无效的邮箱地址',
  'SIGN_IN_ERROR': '邮箱地址或密码错误',
  'INVALID_PASSWORD': '无效密码',
  'PASSWORDS_DO_NOT_MATCH': '两次密码不相同',
  'USER_CONFLICT_ERROR': '邮箱地址已被占用',
  'SIGN_UP_SUCCESS': '注册成功！我们已向您的邮箱发送一封账户认证的邮件。',
  'FORGOT_PASS_MESSAGE': '别担心，只需要提供您注册时的邮箱就可以开始重置密码。',
  'RESET_PASSWORD': '重置密码',
  'FORGOT_PASS_SUCCESS': '我们已向您的邮箱发送一封重置密码的邮件。',
  'USER_NOT_FOUND_ERROR': '用户不存在',
  'SIGN_UP_VERIFY_SUCCESS': '您的账号已成功认证',
  'SIGN_UP_VERIFY_ERROR': '您的账号无法认证，请联系我们',
  'CHANGE_EMAIL_SUCCESS': '您的邮箱地址已成功更新',
  'CHANGE_EMAIL_ERROR': '您的邮箱地址无法更新，请联系我们',
  'RESET_PASS_SUCCESS': '您的密码已成功重置',
  'RESET_PASS_ERROR': '您的密码无法被重置，请联系我们',
  'CHANGE_EMAIL_SUCCESS_TITLE': '更新您的邮箱地址',
  'CHANGE_EMAIL_SUCCESS_MSG': '我们已向您的新邮箱发送一封认证邮件。',
  'CONNECTION_ERROR_MSG': '无法连接到服务器',
  'TRY_AGAIN': '重试',
  'NOW': '现在',
  'HEATING': '加热',
  'ELECTRICITY': '功率',
  'WEATHER': '天气',
  'TEMPERATURE': '温度',
  'TURN_OFF': '关闭',
  'TURN_ON': '启动',
  'SCHEDULE': '时间表',
  'SETUP': '设置',
  'SET_TEMPERATURE': '设置温度',
  'SET_COMFORT_TEMPERATURE': '设置舒适温度',
  'SET_COMFORT_TEMPERATURE_WARNING': '注意！如果您调整舒适温度，那么节能温度也会自动调整。',
  'SET_TEMPERATURE_MULTISELECT_ERROR': '请选择同一系统的暖气片（不能同时选择带有地理定位的暖气片）',
  'REMOVE': '删除',
  'CANCEL': '取消',
  'CONFIRM': '确认',
  'RECORDS': '记录',
  'SELECT_A_DAY': '选择一天',
  'DAY1_SHORT': '日',
  'DAY2_SHORT': '一',
  'DAY3_SHORT': '二',
  'DAY4_SHORT': '三',
  'DAY5_SHORT': '四',
  'DAY6_SHORT': '五',
  'DAY7_SHORT': '六',
  'MONDAY': '星期一',
  'TUESDAY': '星期二',
  'WEDNESDAY': '星期三',
  'THURSDAY': '星期四',
  'FRIDAY': '星期五',
  'SATURDAY': '星期六',
  'SUNDAY': '星期天',
  'MONDAY_SHORT': '周一',
  'TUESDAY_SHORT': '周二',
  'WEDNESDAY_SHORT': '周三',
  'THURSDAY_SHORT': '周四',
  'FRIDAY_SHORT': '周五',
  'SATURDAY_SHORT': '周六',
  'SUNDAY_SHORT': '周日',
  'COPY_DAY': '复制这天',
  'ADD': '新建',
  'FROM': '从',
  'TO': '至',
  'SELECT_A_DAY_MSG': '选择一天以设置或调整温控器的时间表',
  'EDIT': '编辑',
  'START': '开始',
  'END': '结束',
  'DONE': '完成',
  'COPY_DAYS': '复制多日',
  'USE_SAME_SCHEDULE_ON': '把相同的时间表使用在',
  'COPY': '复制',
  'POWER_METER': '电表',
  'USAGE': '用量',
  'USAGE_VALLEY': '谷电量',
  'USAGE_PEAK': '峰电量',
  'REAL_TIME_USAGE': '实时用量',
  'DAILY': '每日',
  'MONTHLY': '每月',
  'ANNUAL': '每年',
  'JAN': '一月',
  'FEB': '二月',
  'MAR': '三月',
  'APR': '四月',
  'MAY': '五月',
  'JUN': '六月',
  'JUL': '七月',
  'AUG': '八月',
  'SEP': '九月',
  'OCT': '十月',
  'NOV': '十一月',
  'DEC': '十二月',
  'POWER': '功率',
  'NOMINAL_POWER': '标称功率',
  'EFFECTIVE_POWER': '有效功率',
  'ZERO_POWER_WARNING': '此暖气片没有指定功率，点击此处设置。',
  'SET_MODE_TEMP': '设置温度',
  'SET_MODE_TEMPERATURE': '设置模式温度',
  'COMFORT': '舒适',
  'ECO': '节能',
  'ANTI_ICE': '防冻',
  'INSTALL': '安装',
  'INSTALL_ERROR_MSG': '安装过程时出现错误，请重试！',
  'MORE': '更多',
  'INSTALL_DEVICE': '安装路由器',
  'SELECT_ALL': '翻译为 全选',
  'DESELECT_ALL': '取消全部',
  'THERMOSTAT': '温控器',
  'HEATER': '暖气片',
  'HEATER_HAVERLAND': '带地理定位的暖气片',
  'HEATER_WITH_SMARTBOX': '带智能盒的散热器',
  'TOWEL_RAIL_HEATER_WITH_SMARTBOX': '带智能盒的智能毛巾架',
  'WIFI_HEATER_W4BUTTONS': '带 4 个按钮的 WiFi 散热器',
  'WIFI_HEATER_W2BUTTONS': '带 2 个按钮的 WiFi 散热器',
  'INSTALL_THERMOSTAT_STEP1': '安装温控器：第一步',
  'INSTALL_THERMOSTAT_HELP_STEP1': '按<strong>START SEARCH</strong>键开始温控器安装',
  'INSTALL_THERMOSTAT_STEP2': '安装温控器：第二步',
  'INSTALL_THERMOSTAT_HELP_STEP2': '如果想要连接温控器，同时按住Stdby键（左键）和Link键（右键）3秒，直至网络图标出现在温控器的右上角（如上图所示）',
  'START_SEARCH': '开始搜索',
  'SEARCHING': '正在搜索...',
  'INSTALL_HEATER_STEP1': '安装加热器：第一步',
  'INSTALL_HEATER_HELP_STEP1': '按<strong>START SEARCH</strong>键开始加热器安装',
  'INSTALL_HEATER_STEP2': '安装暖气片：第二步',
  'INSTALL_HEATER_HELP_STEP2': '如果想要连接电暖器，按住路由器上的MODE/OK键3秒，直至网络图标出现在屏幕的右上角',
  'INSTALL_HEATER_MOD_HELP_STEP2': '请确保路由器处于Standby模式，并同时按住上、下温度键至少3秒。',
  'STORAGE_HEATER': '蓄热式暖气片',
  'STORAGE_HEATERS': '蓄热式暖气片',
  'INSTALL_STORAGE_HEATER_STEP1': '安装蓄热式暖气片：第一步',
  'INSTALL_STORAGE_HEATER_HELP_STEP1': '按<strong>START SEARCH</strong>键开始蓄热式暖气片安装',
  'INSTALL_STORAGE_HEATER_STEP2': '安装蓄热式暖气片：第二步',
  'INSTALL_STORAGE_HEATER_HELP_STEP2': '如果想要连接蓄热式暖气片，按住路由器上的OK键3秒，直到网络图标出现在屏幕的右上角',
  'CHARGE_PROGRAM': '电量预设',
  'VALLEY_1': '周期一',
  'VALLEY_2': '周期二',
  'INSTALL_POWER_METER_STEP1': '安装电表：第一步',
  'INSTALL_POWER_METER_HELP_STEP1': '按<strong>START SEARCH</strong>键开始电表安装',
  'INSTALL_POWER_METER_STEP2': '安装电表：第二步',
  'INSTALL_POWER_METER_HELP_STEP2': '如果想要连接电表，使用一个回形针（已提供）按压路由器顶部的小按钮（如上图所示）。你可以通过观察暖气片的LED灯来检查是否连接',
  'LED_INDICATOR_STATES': 'LED指示灯状态',
  'UNLINKED': '未连接',
  'LED_OFF': 'LED灯关闭',
  'CORRECTLY_LINKED': '正确连接',
  'LED_BLINKS_EVERY_TWO_SECS_AND_A_HALF': 'LED灯每2.5秒闪烁一次',
  'LINKED_BUT_LOST': '连接已断开',
  'LED_BLINKS_EVERY_HALF_SEC': 'LED灯每0.5秒闪烁一次。通路未通电时可能会出现这种情况。当电表和通路重新建立起通信，他将会自动连接',
  'NAME': '名称',
  'SELECT_A_NAME_FOR_IDENTIFYING': '为您的路由器起一个识别名称',
  'TYPE': '种类',
  'HEAT': '热',
  'COLD': '冷',
  'CONTROL': '控制',
  'SELECT': '选择',
  'HYST_0P2': '迟滞0.2摄氏度',
  'HYST_0P3': '迟滞0.3摄氏度',
  'HYST_0P4': '迟滞0.4摄氏度',
  'HYST_0P5': '迟滞0.5摄氏度',
  'HYST_0P7': '迟滞0.7摄氏度',
  'HYST_0P8': '迟滞0.8摄氏度',
  'HYST_1P6': '迟滞1.6摄氏度',
  'HYST_0P4F': '迟滞0.4华氏度',
  'HYST_0P5F': '迟滞0.5华氏度',
  'HYST_0P7F': '迟滞0.7华氏度',
  'HYST_0P9F': '迟滞0.9华氏度',
  'HYST_1P2F': '迟滞1.2华氏度',
  'HYST_1P4F': '迟滞1.4华氏度',
  'HYST_2P9F': '迟滞2.9华氏度',
  'PID': '温度控制算法',
  'PID_T10MIN': '温控10分钟周期',
  'PID_T15MIN': '温控15分钟周期',
  'PID_T20MIN': '温控20分钟周期',
  'PID_T25MIN': '温控25分钟周期',
  'PID_T30MIN': '温控30分钟周期',
  'PID_T45MIN': '温控45分钟周期',
  'PID_T60MIN': '温控60分钟周期',
  'ANTI_GRIPPAGE': '水泵防卡死',
  'CONTRACTED_POWER_W': '最大功率',
  'TYPE_CONTRACTED_POWER_IN_WATTS': '输入您的最大功率（瓦）',
  'PRIORITY': '暖气片优先级',
  'LOW': '低',
  'MEDIUM': '中',
  'HIGH': '高',
  'TRUE_RADIANT': '自适应启动控制',
  'WINDOW_MODE': '视窗模式',
  'DELETE_DEVICES': '删除路由器',
  'DELETE_DEVICES_WARNING': '此行为将会删除选中的路由器和路由器数据',
  'DELETE': '删除',
  'ELECTRIC_HEATING_MAXIMUM_TOTAL_POWER': '暖气片最大允许功率',
  'ELECTRIC_HEATING_MAXIMUM_POWER_IN_WATT': '暖气片功率极限',
  'TYPE_ELECTRIC_HEATING_MAX_POWER': '输入所有暖气片的最大功率（瓦）',
  'LIMITLESS': '没有上限',
  'HOME_INFO': '家庭信息',
  'HOME_NAME': '家庭名称',
  'TYPE_HOME_NAME': '输入您的家庭名称',
  'TIME_ZONE': '时区',
  'TIME_ZONE_CET': '（中欧时间）柏林、巴黎、马德里、罗马',
  'TIME_ZONE_WET': '（西欧时间）伦敦、都柏林、里斯本、加那利群岛',
  'TIME_ZONE_EET': '（东欧时间）赫尔辛基、索非亚、雅典',
  'TIME_ZONE_PET': '（秘鲁时间）利马',
  'TIME_ZONE_NZST': '（新西兰标准时间）奥克兰',
  'TIME_ZONE_CIST': '（查塔姆群岛标准时间）查塔姆群岛',
  'TIME_ZONE_CST': '（中国标准时间）上海',
  'TIME_ZONE_UYT': '（乌拉圭时间）蒙得维的亚',
  'DEVICE_IDENTIFIER': '路由器ID码',
  'TYPE_DEVICE_IDENTIFIER': '输入您的路由器ID码',
  'INVALID_IDENTIFIER': '您输入的路由器ID码无效',
  'DEVICE_ALREADY_TAKEN': 'ID为DEVID的路由器已被注册。请联系我们的技术支持报告此问题。',
  'COPY_ERROR_DATA': '复制路由器ID',
  'MY_HOMES': '我的家庭',
  'ADD_HOME': '添加新的家庭',
  'SAVE': '保存',
  'MY_INFO': '我的信息',
  'USER': '用户',
  'LANGUAGE': '语言',
  'CHANGE_EMAIL_ADDRESS': '更改邮箱地址',
  'NEW_EMAIL_ADDRESS': '新的邮箱地址',
  'TYPE_YOUR_NEW_EMAIL_ADDRESS': '输入您的新邮箱地址',
  'CONFIRM_WITH_PASSWORD': '输入密码确认',
  'TYPE_YOUR_CURRENT_PASSWORD': '输入您现在的密码',
  'CHANGE_PASSWORD': '更改密码',
  'CURRENT_PASSWORD': '现在的密码',
  'TYPE_YOUR_NEW_PASSWORD': '输入您的新密码',
  'NEW_PASSWORD': '新密码',
  'TEMPERATURE_UNITS': '温度计量单位',
  'CELSIUS': '摄氏度',
  'FAHRENHEIT': '华氏度',
  'SELF_LEARN': '自学习中',
  'SELF_LEARN_SHORT': '自学习',
  'PRESENCE': '用户在家',
  'AWAY_OFFSET': '用户不在家时降低温度',
  'ERASE_DATA_PERMANENTLY': '永久清除数据',
  'NO_CONNECTION_WARNING': '无连接。只能访问记录',
  'SET_MODE': '设置模式',
  'AT_HOME': '在家',
  'AWAY': '不在家',
  'DEVICES_SUMMARY': '路由器概述',
  'THERMOSTATS': '温控器',
  'HEATERS': '暖气片',
  'POWER_METERS': '电表',
  'CLOSE': '关闭',
  'DETECT_SMARTBOX': '检测路由器',
  'DETECT_SMARTBOX_HELP_MSG': '如果您想寻找路由器，按路由器正面的按钮。请确保它已正确连接到您的WiFi网络。',
  'DETECT_SMARTBOX_ERROR_MSG': '很抱歉，无法找到路由器。请点击下一步并手动输入路由器识别码。',
  'CONTINUE': '下一步',
  'CANCEL_SEARCH': '取消搜索',
  'SEARCHING_NEW_DEVICES': '正在搜索新路由器…',
  'DISCOVERY_MODE_MSG': '路由器处在发现模式下。请按下相应路由器的按钮，根据说明书进行安装。',
  'DEVICE_FOUND_MSG': '已发现新路由器！',
  'GETTING_STARTED': '开始',
  'MAIN_MENU': '主菜单',
  'GO_EASILY_TO_THE_GENERAL_SETUP_OPTIONS': '点击图标访问账户设置选项',
  'NEXT': '下一步',
  'GEOLOCATION': '地理定位',
  'MODIFY_STATE_MANUALLY_FROM_AT_HOME_TO_AWAY': '用户不在家时会默认降低2°C',
  'DEVICES': '路由器',
  'YOUR_DEVICES_ARE_DIVIDED_IN_SECTIONS_HEATING_AND_ELECTRICITY': '您的路由器分为两个部分：暖气片和电表。',
  'GO_TO_A_DEVICE': '转到路由器',
  'SETUP_YOUR_DEVICE_AND_GO_TO_ITS_RECORDS': '点击路由器以设置并浏览它的记录',
  'MULTIDEVICE_ACTIONS': '多设备行为',
  'SELECT_YOUR_DEVICES_AND_GET_ACCESS_TO_THE_DIFFERENT_OPTIONS_IN_PLUS_BUTTON': '点击路由器并选择More进入多选项设置',
  'READY': '准备好了！',
  'SKIP': '跳过',
  'SKIP_HELP': '跳过帮助',
  'QUICK_SETTING': '快捷设置',
  'SLIDE_THE_TEMPERATURE_BAR_TO_QUICKLY_CHANGE_TEMPERATURE': '滑动温度块可以快速调整温度',
  'TEMPERATURES': '温度',
  'SET_THE_TEMPERATURES_FOR_THE_AVAILABLE_MODES': '为可用模式设置温度',
  'MODE': '模式',
  'WORKING_MODES': '模式',
  'SELECT_ONE_OF_THE_AVAILABLE_MODES': '设置一个可用模式',
  'SELECT_MODE': '设置模式',
  'SELECT_THE_MODE_FOR_EACH_TIME_SLOT': '为每个时段设置模式',
  'SUPPORT_BODY': '自购买产品之日起，您的帐户对安全更新的最短支持期为 3 年。如果您发现任何安全或其他问题，请向我们发送电子邮件至以下地址，描述该问题。',
  'SUPPORT_REQUEST_INTRO_HELP': '左下角有帮助按钮，包含一些可以帮助您使用此app的小贴士',
  'SUPPORT_REQUEST_INTRO_CONTACT': '如果您需要帮助，请使用以下联系方式：',
  'ADDRESS': '地址',
  'TELEPHONE': '电话',
  'FAX': '传真',
  'TC_PP_LINK_TEXT': '通过注册，我接受条款与条件和隐私政策。',
  'PP_LINK': '隐私政策',
  'PASSWORD_CHANGE_SUCCESS_MSG': '您的密码已成功更新',
  'PASSWORD_CHANGE_ERROR_MSG': '您的密码无法更新。请确认您现在的密码是否正确。',
  'EMAIL_BAD_FORMAT_ERROR_MSG': '无效的邮箱地址',
  'EMAIL_ALREADY_EXISTS_ERROR_MSG': '邮箱地址已被占用',
  'EMAIL_CHANGE_ERROR_MSG': '您的邮箱地址无法更新，请稍后尝试。',
  'HOME_TYPE': '家庭类型',
  'ETHERNET': '有线连接',
  'WIFI': 'WiFi连接',
  'DEVICE_REGISTER_ERROR': '注册无法完成',
  'WRONG_CREDENTIALS_ERROR': '错误的用户名/密码',
  'DEVICE_ADDED_MSG': '新路由器已添加',
  'CONNECTION_WARNING': 'WiFi连接',
  'WIFI_CONNECTION_1': '打开手机设置',
  'WIFI_CONNECTION_2': '选择WiFi',
  'WIFI_CONNECTION_3': '将您的手机连上WiFi',
  'WIFI_CONNECTION_4': '"如果您看见这样的提示：""WiFi has no Internet access""，选择""Yes""以保持连接"',
  'WIFI_CONNECTION_5': '回到这儿来',
  'DEVICE_CONNECTION_ERROR': '无法连接到路由器',
  'WIFI_SCAN_ERROR': '没有找到网络',
  'WRONG_PASS_ERROR': '目前配置的WiFi密码错误',
  'WRONG_ESSID_ERROR': '目前配置的WiFi不存在',
  'SET_WIFI_ERROR': '无法建立WiFi网络',
  'DEVICE_CONNECTION_SUCCESSFUL': '连接成功',
  'WIFI_SELECTION': '选择您的WiFi网络',
  'WIFI_NOT_FOUND': '无法找到我的网络',
  'TYPE_AP_ESSID': '输入您的网络名称',
  'TYPE_AP_PASS': '输入您的网络密码',
  'RETRY': '重试',
  'AP_DATA': '网络数据',
  'AP_ESSID': '名称',
  'AP_PASS': '密码',
  'EDIT_WIFI': '更改WiFi配置',
  'WRONG_WIFI_ERROR': '您没有连接到相应的WiFi',
  'COPY_PASS': '复制密码',
  'EDIT_DATA': '家庭数据',
  'WEB_WIFI_WARNING': 'WiFi配置仅在app中可用',
  'ADD_UP_TO_9_TEMPERATURE_SLOTS': '添加至多9个时段',
  'ACCEPT': '接受',
  'INVITE_USERS': '邀请用户',
  'INVITE_USER': '邀请用户',
  'INVITED_USERS': '已邀请用户',
  'INVITE_USER_TITLE': '已邀请用户',
  'INVITE_USER_SUCCESS_MSG': '新用户已邀请。我们已向客户通过邮件的形式通知他们可以访问这个家庭。',
  'INVITE_USER_ERROR_MSG': '邀请用户时出现错误。请稍后重试。',
  'REVOKE_INVITE_TITLE': '删除已邀请的客户',
  'REVOKE_INVITE_MSG': '被选择的用户将不再能够访问这个家庭',
  'REVOKE_INVITE_SUCCESS_MSG': '已邀请的用户已被删除',
  'REVOKE_INVITE_ERROR_MSG': '已邀请的用户删除时出现错误。请稍后重试',
  'CONFIRM_INVITE_SUCCESS': '您的账户已经成功创建',
  'GUEST': '访客',
  'TRV': '恒温暖气阀',
  'TRVS': '恒温暖气阀',
  'INSTALL_TRV_STEP1': '安装TRV：第一步',
  'INSTALL_TRV_HELP_STEP1': '按<strong>START SEARCH</strong>键开始TRV安装',
  'INSTALL_TRV_STEP2': '安装TRV：第二步',
  'INSTALL_TRV_HELP_STEP2': '如果您想要连接TRV，按Helki键（中键）3秒',
  'CONTROL_MODE_CHANGE_TITLE': '制热/制冷模式改变',
  'CONTROL_MODE_CHANGE_MSG': '警告：目前的时间表将被重置',
  'SAVE_THIS_POSITION': '保存这个地址',
  'SET_POSITION_MSG': '您尚未定位您的家庭。请选择地图上的定位，点击下方的按钮以保存',
  'ENABLE_GEOLOCATION': '允许地理定位',
  'OUTDOOR_RANGE': '家庭半径（米）',
  'DEV_TYPE_WIFI': 'WiFi 路由器',
  'DEV_TYPE_WIFIHTR': 'WiFi暖气片',
  'DEV_TYPE_ETHERNET': '路由器',
  'SELECT_DEVICE': '选择路由器',
  'SELECT_DEVICE_THM_HELP': '温控器将被分配给以下通路',
  'SELECT_DEVICE_HTR_HELP': '暖气将被分配给以下通路',
  'SELECT_DEVICE_ACM_HELP': '蓄电池将被分配给以下通路',
  'SELECT_DEVICE_PMO_HELP': '电表将被分配给以下通路',
  'SELECT_DEVICE_TRV_HELP': '暖气阀将被分配给以下通路',
  'ADD_DEVICE': '添加通路',
  'DEVICE_INSTALLATION': '设备安装',
  'WIFI_HEATER': 'WiFi暖气片',
  'WIFI_HEATER_HAVERLAND': '连接系统',
  'LETS_START': '让我们开始吧！',
  'INSTALL_WIFI_HEATER_HELP': '将您的暖气片接上插座。按住暖气片上的OK键直至屏幕闪烁。',
  'INSTALL_WIFI_HEATER2_HELP': '将散热器连接至插座。按下图所示的按钮，直到屏幕闪烁。',
  'INSTALL_WIFI_SMARTBOX_HELP': '将您的WiFi 路由器接入电源。如果已经接入，请断开连接并重新接入。',
  'DEV_TYPE_WIFI_CONNECTION': 'WiFi 路由器 连接',
  'DEV_TYPE_WIFIHTR_CONNECTION': 'WiFi 暖气片连接',
  'DEV_TYPE_WIFI_CONNECTION_HELP': '我们现在要连接路由器到您的家庭WiFi网络',
  'DEV_TYPE_WIFIHTR_CONNECTION_HELP': '我们现在要连接暖气片到您的家庭WiFi网络',
  'GHZ_WIFI_WARNING': '警告：只支持2.4GHz的网络',
  'CONFIGURING_WIFI_HTR': '正在配置WiFi暖气片',
  'DEV_TYPE_WIFI_CONFIG_AP_MODE': '接入点模式WiFi 路由器配置',
  'DEV_TYPE_WIFIHTR_CONFIG_AP_MODE': '接入点模式WiFi暖气片配置',
  'SET_ETHERNET_SMARTBOX_NAME': '我们该怎么称呼这个路由器呢？',
  'SET_WIFI_SMARTBOX_NAME': '我们该怎么称呼这个WiFi 路由器呢？',
  'SET_WIFI_HTR_NAME': '我们该怎么称呼这个WiFi暖气片呢？',
  'TYPE_DEVICE_NAME': '输入路由器名称',
  'CONNECTING_TO_DEV_TYPE_WIFI': '正在连接至WiFi 路由器',
  'CONNECTING_TO_DEV_TYPE_WIFIHTR': '正在连接至WiFi暖气片',
  'DEV_TYPE_WIFI_CONNECTION_SUCCESS': '已连接至有ID的WiFi 路由器',
  'DEV_TYPE_WIFIHTR_CONNECTION_SUCCESS': '已连接至有ID的WiFi暖气片',
  'MY_DEVICES': '我的路由器',
  'DEVICE_TYPE': '您的路由器看起来是什么样？',
  'CONNECT_TO_DEV_TYPE_WIFI': '正在连接至WiFi 路由器',
  'CONNECT_TO_DEV_TYPE_WIFIHTR': '正在连接至WiFi暖气片',
  'CONFIGURING_DEV_TYPE_WIFI': '正在配置WiFi 路由器',
  'CONFIGURING_DEV_TYPE_WIFIHTR': '正在配置WiFi暖气片',
  'DEVICE_LIST': '我的路由器',
  'CONNECTION_STATE': '连接状态',
  'CONFIGURE_WIFI': '配置WiFi',
  'CHANGE_HOME': '移动至另一个家庭',
  'DELETE_DEVICE': '删除路由器',
  'TRANSFER_HOME_TITLE': '移动至另一个家庭',
  'TRANSFER_HOME_WARNING': '警告：您的路由器将被转移到被选择的家庭',
  'CONFIRM_DEVICE_REMOVAL_TITLE': '删除路由器',
  'CONFIRM_DEVICE_REMOVAL_HELP': '您确定要删除这个路由器吗？',
  'INSTALL_ETHERNET_SMARTBOX_HELP': '请连接您的以太网路由器',
  'WIFI_SMARTBOX_CONFIG_SUCCESSFUL': 'WiFi 路由器安装成功',
  'ETHERNET_SMARTBOX_CONFIG_SUCCESSFUL': '以太网路由器安装成功',
  'WIFI_HTR_CONFIG_SUCCESSFUL': 'WiFi暖气片安装成功',
  'HOME_REGISTRY': '家庭注册',
  'HOME_REGISTRY_HELP': '目前您没有已注册的家庭。来添加一个吧。',
  'SET_HOME_NAME': '设置家庭名称',
  'SET_HOME_TIMEZONE': '设置家庭时区',
  'DEVICE_CONNECTION_START': '现在我们来连接至您的路由器',
  'WIFI_DEVICE': 'WiFi路由器',
  'WIFI_DEVICE_HELP': 'WiFi路由器安装仅在我们的app中可用',
  'TYPE_ESSID': '输入您的WiFi网络',
  'REGISTERING_DEVICE': '正在注册路由器',
  'WELCOME': '欢迎',
  'ESSID': '选择WiFi网络',
  'SET_BOOST_MODE': '设置强劲模式',
  'BOOST_END_TIME': '强劲模式结束时间',
  'BOOST_ACTIVE_UNTIL': '强劲模式启动直至',
  'OPERATIONAL_MODE': '操作模式',
  'FIXED_TEMPERATURE': '固定温度',
  'WEEKLY_SCHEDULE': '周程表',
  'ANTIFROST_PROTECTION': '防冻保护',
  'ID_COPIED': '识别码已复制',
  'NEW_PROFILE': '新档案',
  'PROFILE_COLOR': '颜色',
  'TYPE_PROFILE_NAME': '创建一个档案名',
  'TYPE_POWER_LIMIT': '创建一个功率值（瓦）',
  'POWER_LIMIT': '功率（瓦）',
  'EDIT_PROFILES': '档案',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_TITLE': '删除档案',
  'CONFIRM_MAX_POWER_PROFILE_REMOVAL_HELP': '您确定要删除这个功率档案吗？',
  'NUMBER_INPUT_LABEL_HELP_MSG': '范围必须处在',
  'AND': '和',
  'MANUAL_NETWORK_INPUT': '手动输入',
  'GEOLOCATION_INFO': '这个功能允许您在家庭半径以外时降低暖气片的温度',
  'GEOLOCATION_NEW_FEAT': '探索即使在您不在家时，仍能在路由器上保存的功能',
  'VOICE_CONTROL': '语音协助',
  'VOICE_CONTROL_NEW_FEAT': '请记住：这个设备可以兼容：',
  'CONFIGURE': '配置',
  'MORE_INFO_LONG': '更多信息',
  'GEOLOCATION_RATIONALE': '为了正确地定位您的地理位置，这个应用程序需要一直获取您的位置，包括您不使用手机的时候。在设置中能够授权许可。',
  'USER_DUTY_FACTOR': '功率限制因素',
  'CIRCUIT_TYPE': '线路类型',
  'PRINCIPAL_CIRCUIT': '主线路',
  'REVERSE_SIGN': '相反信号',
  'SMARTBOX_CONFIGURATION': '路由器配置',
  'MAIN_CIRCUIT_GENERATION': '主发电电路',
  'MAIN_CIRCUIT_CONSUMPTION': '主消耗电路',
  'SELECT_GENERATION_POWERMETER': '选择一个发电电表',
  'SELECT_CONSUMPTION_POWERMETER': '选择一个消耗电表',
  'POWERMETER_CONFIG': '电表配置',
  'MAX_POWER_TERM_MANAGEMENT': '最大功率时间',
  'MANAGE_MAX_POWER': '时间表',
  'POWER_MANAGEMENT': '最大功率时间配置',
  'MANAGE_ENERGY_TARIFFS': '时间表',
  'COLOR': '颜色',
  'CONSUMPTION': '消耗',
  'GENERATION': '发电',
  'EDIT_ENERGY_TARIFFS': '档案',
  'SELECT_MAX_POWER_CONFIG': '选择一个功率档案',
  'CUSTOM_TARIFF_CONFIG': '新用户档案',
  'ANTI_FROST_IN_OFF': '防冻保护',
  'POWER_LEVEL_LIMIT': '功率等级限制',
  'E2_USAGE_ENABLED': '太阳能',
  'E2_MODE': '充电设置',
  'E2_ACTIVATION_PER': '太阳能启动',
  'E2_CHARGE_EXCESS_PER': '非峰时段最大电量',
  'NODE_E2_MODE_0': '仅太阳能',
  'NODE_E2_MODE_1': '太阳能与非峰时段',
  'NODE_E2_MODE_2': '太阳能与网格调整',
  'E2_TEMP_EXCESS': '环境温度偏移',
  'E2_PRIORITY': '充电优先级',
  'MAIN_CIRCUIT': '主要',
  'DEVICE_UPDATE_SUCCESS_MSG': '路由器更新成功',
  'DEVICE_UPDATE_ERROR_MSG': '路由器无法更新，请稍后重试。',
  'SYSTEM_MAINTENANCE': '系统正在维护',
  'SYSTEM_MAINTENANCE_MSG': '很抱歉给您带来不便，我们会改进我们的服务。',
  'SOLD_SEPARATELY': '（分开销售）',
  'REMOVE_ACCOUNT': '删除账户',
  'CONFIRM_ACCOUNT_REMOVAL_TITLE': '删除账户',
  'CONFIRM_ACCOUNT_REMOVAL_HELP': '账户删除包括删除所有相关节点和数据。如果您确定要删除账户，请输入密码以确认。',
  'ACCOUNT_REMOVAL_SUCCESS': '账户成功删除',
  'ACCOUNT_REMOVAL_ERROR': '账户无法删除',
  'USE_QR': '扫描WiFi二维码',
  'USE_MANUAL_AP': '输入WiFi凭证',
  'HANDSHAKE_WARNING': '请确保您的手机正连接相同WiFi网络',
  'DEVICE_DISCONNECTED_STEP_1': '打开主菜单',
  'DEVICE_DISCONNECTED_STEP_2': '前往Homes并选择您的家庭',
  'DEVICE_DISCONNECTED_STEP_3': '选择设备',
  'DEVICE_DISCONNECTED_STEP_4': '配置设备',
  'DEVICE_DISCONNECTED_HELP_1': 'WiFi网络有网络连接',
  'DEVICE_DISCONNECTED_HELP_2': '这个设备能够连接到WiFi网络',
  'DEVICE_DISCONNECTED_HELP_3': '从这里直接检查并配置您的设备的WiFi凭证，或者按照以下顺序：',
  'DEVICE_DISCONNECTED_SUBHEADER': '这个设备已经被注册但未被正确连接。解决这个问题您可以检查：',
  'DEVICE_DISCONNECTED_HEADER': '配置过程中出现问题，设备可能会丢失',
  'DEVICE_INSTALLATION_ERROR': '设备安装时出现问题。',
  'DEVICE_ETHERNET_INSTALLATION_ERROR_INFO': '检查设备的LED状态。选择其中一个状态以获取更多信息以帮助解决问题：',
  'DEVICE_INSTALLATION_ERROR_NO_IP': '设备没有分配的IP地址。',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER': '设备未连接到服务器。',
  'DEVICE_INSTALLATION_ERROR_OK': '设备已正确连接。',
  'DEVICE_ETHERNET_NO_IP_HELP_1': '确保设备通过以太网电缆正确连接到WiFi。',
  'DEVICE_ETHERNET_NO_IP_HELP_2': '重复安装过程。',
  'DEVICE_INSTALLATION_ERROR_NO_SERVER_HELP': '检查路由器的互联网连接，并确保没有端口限制。',
  'DEVICE_INSTALLATION_ERROR_OK_HELP': '关闭并重新打开应用程序以检查设备是否已正确安装。',
  'DEVICE_WIFI_INSTALLATION_ERROR_INFO': '检查设备的LED状态。选择其中一个状态以获取更多信息以帮助解决问题：',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONFIGURED': 'WiFi网络尚未配置。',
  'DEVICE_WIFI_INSTALLATION_ERROR_NOT_CONNECTED': '设备未连接到路由器。',
  'DEVICE_WIFI_HTR_INSTALLATION_ERROR_INFO': '通过观察天线图标的状态来检查设备状态。选择其中一个状态以获取更多信息以帮助解决问题：',
  'SERVER_CONNECTION_ERROR': '应用无法连接到服务器。检查您手机的网络连接并重试',
  'AP_FALLBACK_TITLE': '让我们用Access Point方式重新开始这个过程',
  'LOADING': '装载量',
  'SIGN_IN_WITH_GOOGLE': '使用 Google 登录',
  'LOCK': '区块',
  'UNLOCK': '开锁',
  'BRIGHT_LEVEL': '屏幕亮度',
  'BACKLIGHT_TIME': '屏幕开启时间',
  'BACKLIGHT_TIME_10': '10 秒',
  'BACKLIGHT_TIME_20': '20 秒',
  'BACKLIGHT_TIME_30': '30 秒',
  'BACKLIGHT_TIME_ALWAYS': '始终',
  'DELETE_ACCOUNT_MSG': '删除用户账户即表示永久删除与其相关的所有数据。如果您同意，请按以下步骤操作。',
  'DELETE_ACCOUNT_STEPS': '删除账户的步骤',
  'DELETE_ACCOUNT_STEP1': '1. 使用用户名和密码登录应用程序。',
  'DELETE_ACCOUNT_STEP2': '2. 进入顶部菜单',
  'DELETE_ACCOUNT_STEP3': '3. 选择 "我的帐户"。',
  'DELETE_ACCOUNT_STEP4': '4. 在 "删除帐户 "部分，单击 "删除帐户 "按钮。',
  'DEPRECATION_MESSAGE': '请使用该应用程序 TARGET'
}
};
const defaultDateTimeFormat = {
  short: { year: 'numeric', month: 'short', day: 'numeric' },
  long: {
    year: 'numeric', month: 'short', day: 'numeric',
    weekday: 'long', hour: 'numeric', minute: 'numeric', hour12: false,
  },
  longDate: {
    year: 'numeric', month: 'short', day: 'numeric', weekday: 'long',
  },
  month: {
    year: 'numeric', month: 'long',
  },
};

const dateTimeFormats: any = {};
Object.keys(messages).forEach((locale: string) => {
  dateTimeFormats[locale] = defaultDateTimeFormat;
});

export default {
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  dateTimeFormats,
};
