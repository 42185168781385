import { Component, Vue, Watch } from 'vue-property-decorator';
import rootScope from '@/services/rootScope';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import InstallNodeModal from '@/components/modals/installNode/installNode.vue';
import EraseNodeModal from '@/components/modals/eraseNode/eraseNode.vue';
import SetTempModal from '@/components/modals/setTemp/setTemp.vue';
import SetTempErrorModal from '@/components/modals/setTempError/setTempError.vue';
import HtrSystemSetupModal from '@/components/modals/htrSystemSetup/htrSystemSetup.vue';
import GeneralHelpModal from '@/components/modals/generalHelp/generalHelp.vue';
import Slider from '@/components/slider/Slider.vue';
import NavButton from '@/components/button/navButton/NavButton.vue';
import DialogButton from '@/components/button/dialogButton/DialogButton.vue';
import _ from '@/services/_';
import {
  NodeMode,
  NodeCapability,
  HeatingNodeController,
  NodeController,
} from '@vdi-helki/helki-node-management';

@Component({
  components: {
    Slider,
    InstallNodeModal,
    EraseNodeModal,
    SetTempModal,
    SetTempErrorModal,
    HtrSystemSetupModal,
    GeneralHelpModal,
    NavButton,
    DialogButton,
  },
})
export default class Menu extends Vue {
  private moreDialogOpen: any = {};
  private nodeSet: any = {};
  private showInstallNodeModal = false;
  private showEraseNodeModal = false;
  private showSetTempModal = false;
  private showSetTempErrorModal = false;
  private showHtrSystemSetupModal = false;
  private showGeneralHelpModal = false;
  private showInstallNode = false;
  private showSetTemp = false;
  private showHtrSystemSetup = false;
  private showGeneralHelp = false;
  private ready = false;
  private htrNodesInstalled = false;
  private wifiHtrNodesInstalled = false;
  private nodeGroups: any = {};
  private mainCircuitPmos: any = {};
  private supportedDevices: any = {};
  private isHaverlandClient = false;

  private async mounted() {
    this.moreDialogOpen = false;
    this.nodeSet = await this.$devListService.getNodeControllerSet(this.selectedList);
    this.ready = true;
    this.supportedDevices = (await this.$configService.getConfig()).clientData.supportedDevices;
    this.isHaverlandClient = (await this.$configService.getConfig()).clientName === 'haverland';
  }

  private get selectedList(): any {
    const result: any = Array.from(this.$store.state.selectedNodes[this.homeId] || new Set())
    .map((data: any) => JSON.parse(data));
    return result;
  }

  private get homeId() {
    return this.$route.params.homeid;
  }

  @Watch('selectedList')
  private async onSelectedListChanged() {
    this.nodeSet = await this.$devListService.getNodeControllerSet(this.selectedList);
  }

  private updateList(): any {
    this.nodeGroups = [];
    return this.$devListService.getDevClassifiedNodeList(this.homeId).then(async (classifiedNodeList: any) => {
      this.nodeGroups = classifiedNodeList || [];
      this.nodeGroups.forEach((nodeGroup: any) => {
        nodeGroup.thmList = _.filter(nodeGroup.nodeList, (node: any) => {
          return (node.nodeData || {}).type === 'thm';
        });
        nodeGroup.htrList = _.filter(nodeGroup.nodeList, (node: any) => {
          return (node.nodeData || {}).type === 'htr';
        });
        nodeGroup.acmList = _.filter(nodeGroup.nodeList, (node: any) => {
          return (node.nodeData || {}).type === 'acm';
        });
        nodeGroup.htrModList = _.filter(nodeGroup.nodeList, (node: any) => {
          return (node.nodeData || {}).type === 'htr_mod';
        });
        nodeGroup.wifiHtrList = _.filter(nodeGroup.nodeList, (node: any) => {
          return (node.nodeData || {}).type === 'wifiHtr';
        });
        if (nodeGroup.htrList.length > 0 || nodeGroup.acmList.length > 0 || nodeGroup.htrModList.length > 0) {
          this.htrNodesInstalled = true;
        }
        if (nodeGroup.wifiHtrList.length > 0) {
          this.wifiHtrNodesInstalled = true;
        }
      });
    });
  }

  private showConfigBtn(): any {
    this.updateList();
    return Object.keys(this.$store.state.homes[this.homeId]?.devs || {}).length < 2
    && this.htrNodesInstalled
    && !this.wifiHtrNodesInstalled
    && this.deviceConnected;
  }

  private openInstallNodeDialog(): any {
    this.showInstallNodeModal = true;
  }

  private openEraseNodeDialog(): any {
    this.showEraseNodeModal = true;
  }

  private get anyNodeSelected(): any {
    return this.nodeSet?.size() > 0;
  }

  private anyPmoSelected(): any {
    return this.nodeSet.nodeControllers.findIndex((controller: NodeController) => {
      return (controller as any).nodeData.type === 'pmo';
    }) !== -1;
  }

  private allSelectedDevicesConnected(): boolean {
    const areAllConnected = Array.from(this.selectedDevIds()).every((devId: any) => {
     const deviceInfo = this.$store.state.homes[this.homeId].devs[devId];
     return deviceInfo && deviceInfo.connected === true;
    });
    return areAllConnected;
  }

  private selectedDevIds(): any {
    return new Set(this.selectedList.map((msg: any) => msg.devId));
  }

  private anyWifiHtrNodeSelected(): any {
    const anyWifiHtrSelected = Array.from(this.selectedDevIds()).every((devId: any) => {
      const deviceInfo = this.$store.state.homes[this.homeId].devs[devId];
      return this.isWifiHeater(deviceInfo.product_id);
    });
    return anyWifiHtrSelected;
  }

  private get deviceConnected() {
    return this.$store.state.homes[this.homeId].connected;
  }

  private mixedSelected(): any {
    const thmSelected = this.nodeSet.nodeControllers.findIndex((controller: NodeController) => {
      return (controller as any).nodeData.type === 'thm';
    }) !== -1;
    const htrSelected = this.nodeSet.nodeControllers.findIndex((controller: NodeController) => {
      return (controller as any).nodeData.type === 'htr';
    }) !== -1;
    const acmSelected = this.nodeSet.nodeControllers.findIndex((controller: NodeController) => {
      return (controller as any).nodeData.type === 'acm';
    }) !== -1;
    const htrModSelected = this.nodeSet.nodeControllers.findIndex((controller: NodeController) => {
      return (controller as any).nodeData.type === 'htr_mod';
    }) !== -1;
    return [thmSelected, htrSelected, acmSelected, htrModSelected].filter((value) => value).length > 1;
  }

  private allSelectedNodesAreOff(): any {
    return (this.nodeSet as any).nodeControllers
    .filter((nodeController: NodeController) => {
      return (nodeController as any).nodeData.type !== 'pmo';
    })
    .findIndex((nodeController: NodeController) => {
      return nodeController.isReady()
      && (nodeController as HeatingNodeController).getMode() !== NodeMode.OFF;
    }) === -1;
  }

  private allSelectedNodesAreLocked(): boolean {
    return (this.nodeSet as any).nodeControllers
    .filter((nodeController: NodeController) => {
      return (nodeController as any).nodeData.type !== 'pmo';
    })
    .findIndex((nodeController: NodeController) => {
      return nodeController.isReady()
      && (nodeController as any).nodeData.status.locked;
    }) === -1;
  }

  private openSetTempDialog(): any {
    if (!this.nodeSet?.isReady() || !this.isEditTemperatureAvailableForAllNodes()) {
      return;
    }
    if (this.mixedSelected()) {
      this.showSetTempErrorModal = true;
      return;
    }
    this.showSetTempModal = true;
  }

  private toggleMoreDialog(): any {
    this.moreDialogOpen = !this.moreDialogOpen;
  }

  private turnSelectedOff(): any {
    if (!this.nodeSet?.isReady()) {
      return;
    }
    this.$amplitudeService.sendEvent('set_mode_off');
    this.nodeSet?.setMode('off', {});
    this.moreDialogOpen = false;
    this.$store.commit('deselectNodes');
  }

  private turnSelectedOn(): any {
    if (!this.nodeSet?.isReady()) {
      return;
    }
    this.$amplitudeService.sendEvent('set_mode_on');
    this.nodeSet?.setMode('on', {});
    this.moreDialogOpen = false;
    this.$store.commit('deselectNodes');
  }

  private goToMultiThermostatSchedule(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.thermostat.schedule', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiHeaterSchedule(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.heater.schedule', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiAccumulatorSchedule(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.storageheater.schedule', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiHeaterModSchedule(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.heaterMod.schedule', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiSchedule(): any {
    if (this.mixedSelected() || !this.nodeSet?.isReady() || !this.isScheduleAvailableForAllNodes()) {
      return;
    }
    (window as any).nodeSet = this.nodeSet;
    (window as any).devId = this.selectedList[0].devId;
    (window as any).connected = true;
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'thm') {
      return this.goToMultiThermostatSchedule();
    }
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'htr') {
      return this.goToMultiHeaterSchedule();
    }
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'acm') {
      return this.goToMultiAccumulatorSchedule();
    }
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'htr_mod') {
      return this.goToMultiHeaterModSchedule();
    }
  }

  private goToMultiThermostatSetup(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.thermostat.setup', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiHeaterSetup(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.heater.setup', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiAccumulatorSetup(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.storageheater.setup', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiHeaterModSetup(): any {
    routerWrapper.push(this.$router, 'root.authenticated.root.device.heaterMod.setup', {
      addr: this.selectedList[0].addr,
      devid: this.selectedList[0].devId,
    });
  }

  private goToMultiSetup(): any {
    if (this.mixedSelected() || !this.nodeSet?.isReady() || this.multiHtrModeSelected()) {
      return;
    }
    (window as any).nodeSet = this.nodeSet;
    (window as any).devId = this.selectedList[0].devId;
    (window as any).connected = true;
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'thm') {
      return this.goToMultiThermostatSetup();
    }
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'htr') {
      return this.goToMultiHeaterSetup();
    }
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'acm') {
      return this.goToMultiAccumulatorSetup();
    }
    if (this.nodeSet.nodeControllers[0].nodeData.type === 'htr_mod') {
      return this.goToMultiHeaterModSetup();
    }
  }

  private getSelectedNodesTypes(): any {
    return new Set(this.selectedList.map((msg: any) => msg.type));
  }

  private multiHtrModeSelected(): boolean {
    return new Set(this.getSelectedNodesTypes()).has('htr_mod') && this.selectedList.length > 1;
  }

  private isScheduleAvailableForAllNodes(): any {
    return this.nodeSet?.isReady() && this.nodeSet?.getActiveCapabilities().includes(NodeCapability.SCHEDULE_AVAILABLE);
  }

  private htrModSelectedListLength(): any {
    return this.selectedList.map((item: any) => item.type === 'htr_mod').length;
  }

  private isEditTemperatureAvailableForAllNodes(): any {
    if (this.isHaverlandClient && this.nodeSet?.isReady()) {
      return true;
    }
    if (this.mixedSelected()) {
      return false;
    }
    return this.nodeSet?.isReady() &&
           this.nodeSet?.getActiveCapabilities().includes(NodeCapability.TEMPERATURE_CONTROL_AVAILABLE);
  }

  private openHtrSystemSetupDialog(): any {
    rootScope.currentDevHtrSystemPowerLimit = this.$devListService.getHtrSystemPowerLimit(this.homeId);
    (window as any).devId = undefined;
    this.showHtrSystemSetupModal = true;
  }

  private openHelp(): any {
    this.showGeneralHelpModal = true;
  }

  private get heatersInstalled(): any {
    return this.htrNodesInstalled;
  }

  private isInMainCircuit(addr: number): boolean {
    return this.mainCircuitPmos.indexOf(addr) === 0;
  }

  private async removePmoFromMainCircuit(devId: any, addr: number): Promise<any> {
    const index = this.mainCircuitPmos.indexOf(addr);
    if (index !== undefined) {
      this.mainCircuitPmos.splice(index, 1);
      await this.$apiService.setPmoSystem(devId, { main_circuit_pmos: this.mainCircuitPmos });
    }
  }

  private async updateMainCircuitPmos(devId?: any): Promise<any> {
    await this.$devListService.getDevProxy(this.homeId, devId).then(async (devProxy: any) => {
      this.mainCircuitPmos = devProxy.getMainCircuitPmos();
      return;
    });
  }

  private isWifiHeater(pid: any): boolean {
    return this.$devListService.getDevProductName(pid) === 'wifiHtr';
  }

  private async deleteDevice(devId: any): Promise<any> {
    this.$store.commit('removeDevice', { homeId: this.homeId, devId });
    await this.$devListService.deleteDevice(this.homeId, devId);
    this.$amplitudeService.sendEvent('edit_device_delete_success');
  }

  private async onEraseNodeModalClosed(removeNode: boolean): Promise<any> {
    this.showEraseNodeModal = false;
    if (!removeNode) {
      return;
    }
    this.$amplitudeService.sendEvent('remove_node');
    for (const nodeController of this.nodeSet.nodeControllers) {
      await this.updateMainCircuitPmos(nodeController.devId);
      if (nodeController.nodeData.type === 'pmo' && this.isInMainCircuit(nodeController.nodeData.addr)) {
        this.removePmoFromMainCircuit(nodeController.devId, nodeController.nodeData.addr);
      }
      const pid = nodeController.nodeData.version.pid;
      if (this.isWifiHeater(pid)) {
        this.deleteDevice(nodeController.devId);
      } else {
        await this.$apiService.eraseNode(
          nodeController.devId,
          nodeController.nodeData.addr,
          nodeController.nodeData.type,
          rootScope.eraseNodeModalData.purge,
        );
      }
    }
    this.$store.commit('deselectNodes');
  }

  private onSetTempModalClosed(): any {
    this.$store.commit('deselectNodes');
  }

  private onHtrSystemSetupModalClosed(): any {
    this.showHtrSystemSetupModal = false;
    const devId = Object.keys(this.$store.state.homes[this.homeId].devs)[0];
    this.$apiService.setHtrSystemPowerLimit(devId, rootScope.currentDevHtrSystemPowerLimit);
  }

  private installNodesAvailable(): boolean {
    const wifiHtrAvailable: boolean = Object.keys(this.supportedDevices).some(key => this.supportedDevices[key].type === "wifiHtr");
    return this.deviceConnected || wifiHtrAvailable;
  }

  private setLocked(value: boolean): any {
    this.nodeSet.setLock(value);
  }

  private installBtnAvailable(): boolean {
    return !this.anyNodeSelected && this.installNodesAvailable();
  }

  private moreBtnAvailable(): boolean {
    return this.heatersInstalled && this.allSelectedDevicesConnected() && this.anyNodeSelected && !this.anyPmoSelected();
  }

  private setupBtnAvailable(): boolean {
    return this.showConfigBtn() && !this.anyNodeSelected;
  }

  private deleteBtnAvailable(): boolean {
    return (this.anyNodeSelected && this.allSelectedDevicesConnected()) || this.anyWifiHtrNodeSelected();
  }

  private get nodes() {
    let nodeTypes = ['htr', 'acm', 'thm'];
    if (this.$route.name === 'root.authenticated.root.device.list.electricity') {
      nodeTypes = ['pmo'];
    }
    return this.$store.getters.getNodesByType(this.homeId, { nodeTypes })
    .filter((node: any) => node.installed);
  }
  private nodeControllerList: NodeController[] = [];


  private selectAllNodes() {
    if (this.nodes.length === 0) {
      return;
    }
    this.nodes.forEach((node: any) => {
      this.$store.commit('selectNode', {homeId: this.homeId, devId: node.devId, addr: node.addr, type: node.type});
    });
  }

  private deselectAllNodes() {
    this.$store.commit('deselectNodes');
  }

  private get allNodesSelected() {
    return this.nodes.length === this.$store.state.selectedNodes[this.homeId]?.size;
  }
}
