import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import TitleText from '@/components/text/title/TitleText.vue';

@Component({
  components: {
    Slider,
    TitleText,
  },
})

export default class Support extends Vue {

    private addressLines: any = {};
    private supportEmail: any = {};
    private phone: any = {};
    private fax: any = {};

    private async mounted() {
      this.addressLines = (await this.$configService.getConfig()).clientData.supportData.address;
      this.supportEmail = (await this.$configService.getConfig()).clientData.supportData.email;
      this.phone = (await this.$configService.getConfig()).clientData.supportData.phone;
      this.fax = (await this.$configService.getConfig()).clientData.supportData.fax;
    }

}
