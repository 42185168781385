import { Component, Vue } from 'vue-property-decorator';
import { geolocation } from '@/services/geolocation';
import NewFeaturesInfoModal from '@/components/modals/newFeaturesInfo/newFeaturesInfo.vue';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
    NewFeaturesInfoModal,
  },
})
export default class Root extends Vue {

  private homes: any = {};

  private showNewFeaturesInfo = false;

  private async mounted() {
    try {
      this.homes = await this.$devListService.getList();
      if ((await this.$configService.getConfig()).geolocationFlag) {
        new geolocation(
          this.$sessionService,
          this.$devListService,
          this.$configService,
        ).restartTracking();
      }
      if (this.$route.params.homeid !== undefined) {
        return;
      }
      const homeId = await this.$devListService.getLastVisited();
      if (this.homes.length > 0) {
        this.$router.replace({
          name: 'root.authenticated.root.device.list.heating',
          params: { homeid: homeId },
        });
      } else {
        this.$router.push({ name: 'root.authenticated.root.welcome', query: {} });
      }
    } catch {
      await this.$userService.signOut();
      this.$router.replace({ name: 'root.anonymous.signIn' });
    }
  }
}
