import { render, staticRenderFns } from "./setTemp.vue?vue&type=template&id=62537efe"
import script from "./setTemp.ts?vue&type=script&lang=js&external"
export * from "./setTemp.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports