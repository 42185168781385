import { render, staticRenderFns } from "./Menu.vue?vue&type=template&id=41cd63f7"
import script from "./Menu.ts?vue&type=script&lang=js&external"
export * from "./Menu.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports