import { render, staticRenderFns } from "./setTempError.vue?vue&type=template&id=6afd1204"
import script from "./setTempError.ts?vue&type=script&lang=js&external"
export * from "./setTempError.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports