import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';

@Component({
  components: {
    Slider,
  },
})

export default class ResetPass extends Vue {

    private email: any = {};
    private code: any = {};
    private verifyError: any = {};
    private verifySuccess: any = {};

    private mounted() {
      this.email = this.$route.query.user;
      this.code = this.$route.query.code;
      this.verifyError = false;
      this.verifySuccess = false;

      this.$amplitudeService.sendEvent('reset_user_password');

      this.$apiService.resetUserPassword(
        this.email,
        this.code,
      ).then(async () => {
        this.verifySuccess = true;
      }).catch(() => {
        this.verifyError = true;
      });
    }
}
