import { Component, Vue } from 'vue-property-decorator';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import { NodeTypeConfig } from '@/services/config';
import TitleText from '@/components/text/title/TitleText.vue';
import InstallInfo from '@/components/installInfo/InstallInfo.vue';

@Component({
  components: {
    Slider,
    TitleText,
    InstallInfo,
  },
})
export default class SelectType extends Vue {

  private homeId: any = {};
  private homeName: any = {};
  private timezone: any = {};
  private wifiEnabled: any = {};
  private descriptionEnabled: any = {};
  private newHome: any = {};
  private supportedDevices: NodeTypeConfig[] = [];
  private isEnabled: any = () => false;

  private async mounted() {
    this.supportedDevices = (await this.$configService.getConfig()).clientData.supportedDevices;
    this.homeId = this.$route.params.homeid;
    this.homeName = this.$route.params.homeName;
    this.timezone = this.$route.params.timezone;
    this.wifiEnabled = (await this.$configService.getConfig()).wifiFlag;
    this.descriptionEnabled = (await this.$configService.getConfig()).clientName === 'haverland';
    this.newHome = this.$route.params.newHome;
    this.$amplitudeService.sendEvent('add_device_start');
    try {
      if (this.homeName === '') {
        this.homeName = this.$devListService.getName(this.homeId);
      }
    } catch (error) {
      routerWrapper.push(this.$router, 'root.authenticated.root.addHome.setName');
    }
    if (this.timezone === '') {
      this.$devListService.getTimezone(this.homeId).then(async (timezone: any) => {
        this.timezone = timezone;
      });
    }
    this.isEnabled = (deviceType: string) => {
      return this.supportedDevices.findIndex(supportedDevice => supportedDevice.type === deviceType) !== -1;
    };
  }

  private get isWeb(): boolean {
    const w = window as any;
    return !w.cordova;
  }

  private selectWifiSmartbox(): any {
    if (this.isWeb) {
      this.$router.push({ name: 'root.authenticated.root.addDevice.notAllowed', query: {} });
    } else {
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.setAPData', {
        homeName: this.homeName,
        timezone: this.timezone,
        newHome: this.newHome,
        type: 'wifi'
      });
    }
  }

  private selectWifiHeater(subtype: string): any {
    if (this.isWeb) {
      this.$router.push({ name: 'root.authenticated.root.addDevice.notAllowed', query: {} });
    } else {
      routerWrapper.push(this.$router, 'root.authenticated.root.addDevice.wifi.setAPData', {
        homeName: this.homeName,
        timezone: this.timezone,
        newHome: this.newHome,
        type: 'wifiHtr',
        subtype,
      });
    }
  }


}
