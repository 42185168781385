import { render, staticRenderFns } from "./EditHome.vue?vue&type=template&id=9c2e9ab8"
import script from "./EditHome.ts?vue&type=script&lang=js&external"
export * from "./EditHome.ts?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports