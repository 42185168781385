import { Component, Vue, Watch } from 'vue-property-decorator';
import window from '@/services/window';
import routerWrapper from '@/services/routerWrapper';
import Slider from '@/components/slider/Slider.vue';
import VueDatePicker from 'vue2-datepicker';
import en from 'vue2-datepicker/locale/en';
import es from 'vue2-datepicker/locale/es';
import fr from 'vue2-datepicker/locale/fr';
import it from 'vue2-datepicker/locale/it';
import pt from 'vue2-datepicker/locale/pt';
import de from 'vue2-datepicker/locale/de';
import pl from 'vue2-datepicker/locale/pl';
import nl from 'vue2-datepicker/locale/nl';
import hr from 'vue2-datepicker/locale/hr';
import hu from 'vue2-datepicker/locale/hu';

import { addDays, addMonths, addYears, subDays, subMonths, subYears } from 'date-fns';

@Component({
  components: {
    Slider,
    VueDatePicker,
  },
})
export default class Stats extends Vue {

  private nodeController: any = {};
  private power: any = {};
  private dateStringData = new Date();
  private datepickerOptions: any = {};
  private type: any = {};
  private language!: string;

  private async mounted() {
    this.nodeController = (window as any).nodeSet?.nodeControllers[0];
    this.power = (window as any).nodeController?.getSettingParams('power_limit').value;
    this.language = await this.$userService.getLanguage();
    this.updateDateString();
    this.initType();
  }

  private get currentRoute(): any {
    return this.$route.name;
  }

  @Watch('currentRoute')
  @Watch('day')
  @Watch('month')
  @Watch('year')
  private onCurrentRouteChanged() {
    this.updateDateString();
    this.initType();
  }

  private get year() {
    return Number(this.$route.params.year);
  }

  private get month() {
    return Number(this.$route.params.month);
  }

  private get day() {
    return Number(this.$route.params.day);
  }

  private get dateString() {
    return this.dateStringData;
  }

  private set dateString(newDate) {
    this.dateStringData = newDate;
    if (newDate.getFullYear() === this.year && newDate.getMonth() === this.month && newDate.getDay() === this.day) {
      return;
    }
    this.goToDate(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  }

  private get calendarFormat() {
    return this.type === 'day' ? 'date' : this.type;
  }

  private get stringFormat() {
    switch(this.type) {
      case 'month':
        return this.language === 'en' ? 'YYYY-MM' : 'MM-YYYY';
      case 'year':
        return 'YYYY';
      case 'day':
      default:
        return this.language === 'en' ? 'YYYY-MM-DD' : 'DD-MM-YYYY';
      }
  }

  private currentLanguage() {
    const languages: { [key: string]: any } = {
      es: es,
      fr: fr,
      it: it,
      pt: pt,
      de: de,
      pl: pl,
      nl: nl,
      hr: hr,
      hu: hu,
      en: en,
    };

    return languages[this.language] || en;
  }

  private sameDate(year: number, month: number, day: number) {
    switch(this.type) {
      case 'year':
        return (year === Number(this.$route.params.year));
      case 'month':
        return (year === Number(this.$route.params.year) && month === Number(this.$route.params.month));
      case 'day':
      default:
        return ((year === Number(this.$route.params.year) &&
                month === Number(this.$route.params.month) &&
                day === Number(this.$route.params.day)));
    }
  }

  private goToDate(year: number, month: number, day: number): any {
    if (this.sameDate(year, month, day)) {
      return;
    }
    const nodeType: any = this.getNodeType();
    switch (this.type) {
      case 'day':
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ nodeType }.nav.stats.day`, {
          year,
          month,
          day,
        });
        break;
      case 'month':
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ nodeType }.nav.stats.month`, {
          year,
          month
        });
        break;
      case 'year':
        routerWrapper.push(this.$router, `root.authenticated.root.device.${ nodeType }.nav.stats.year`, { year });
        break;
    }
  }

  private goToPrevious(newType: any): any {
    let newDate = this.dateStringData;
    const type = newType ? newType : this.type;
    switch(type) {
      case 'year':
        newDate = subYears(this.dateStringData, 1);
        break;
      case 'month':
        newDate = subMonths(this.dateStringData, 1);
        break;
      case 'day':
      default:
        newDate = subDays(this.dateStringData, 1);
        break;
    }
    this.goToDate(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  }

  private goToNext(newType: any): any {
    let newDate = this.dateStringData;
    const type = newType ? newType : this.type;
    switch(type) {
      case 'year':
        newDate = addYears(this.dateStringData, 1);
        break;
      case 'month':
        newDate = addMonths(this.dateStringData, 1);
        break;
      case 'day':
      default:
        newDate = addDays(this.dateStringData, 1);
        break;
    }
    this.goToDate(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());
  }

  private updateType(): any {
    switch (this.type) {
    case 'day':
      return this.goToDay();
    case 'month':
      return this.goToMonth();
    case 'year':
      return this.goToYear();
    }
  }

  private hasZeroPower(): any {
    if (!(window as any).nodeController?.getSettingParams('power_limit').editable) {
      return false;
    }
    return this.power === 0;
  }

  private goToSetup(): any {
    const nodeType: any = this.getNodeType();
    return routerWrapper.push(this.$router, `root.authenticated.root.device.${ nodeType }.setup`);
  }

  private updateDateString(): any {
    this.dateStringData = this.getDateString();
  }

  private getDateString(): any {
    const year: any = Number(this.$route.params.year);
    let month: any = Number(this.$route.params.month);
    let day: any = Number(this.$route.params.day);
    if (isNaN(day)) {
      day = 1;
    }
    if (isNaN(month)) {
      month = 1;
    }
    return new Date(year, month, day);
  }

  private goToDay(): any {
    const nodeType: any = this.getNodeType();
    routerWrapper.push(this.$router, `root.authenticated.root.device.${ nodeType }.nav.stats.day`, {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      day: new Date().getDate()
    });
  }

  private goToMonth(): any {
    const nodeType: any = this.getNodeType();
    routerWrapper.push(this.$router, `root.authenticated.root.device.${ nodeType }.nav.stats.month`, {
      year: new Date().getFullYear(),
      month: new Date().getMonth()
    });
  }

  private goToYear(): any {
    const nodeType: any = this.getNodeType();
    routerWrapper.push(this.$router, `root.authenticated.root.device.${ nodeType }.nav.stats.year`, { year: new Date().getFullYear() });
  }

  private getNodeType(): any {
    const type: any = ((window as any).nodeSet?.nodeControllers[0].nodeData || {}).type || 'htr';
    switch (type) {
    case 'acm':
      return 'storageheater';
    case 'thm':
      return 'thermostat';
    case 'pmo':
      return 'powerMeter';
    case 'htr_mod':
      return 'heaterMod';
    default:
      return 'heater';
    }
  }

  private getType(): any {
    return (/\w*$/g.exec(this.$route?.name || '') || ['day'])[0];
  }

  private initType(): any {
    this.type = this.getType();
  }

}
