import { IApiService, GetUserPreferencesResp } from '@/services/api.service';
import { ISessionService, PrivacyResp } from '@/services/session.service';
import { NodeType } from '@vdi-helki/helki-node-management';
import store2 from 'store2';
import _ from 'lodash';

export interface UserServiceSetup {
  language: string;
}

export interface IUserService {
  getLanguage(): Promise<string>;
  setLanguage(language: string): void;
  signIn(username: string, password: string, remember?: boolean): Promise<void>;
  signOut(): Promise<void>;
  changePassword(oldPwd: string, newPwd: string): Promise<void>;
  changeEmail(pwd: string, email: string): Promise<void>;
  inviteUser(devId: string, email: string): Promise<void>;
  revokeInvite(devId: string, email: string): Promise<void>;
  confirmInvite(userId: string, password: string, code: string): Promise<void>;
  getPrivacy(): Promise<PrivacyResp>;
  deleteUserAccount(pwd: string): Promise<void>;
}

export class UserService implements IUserService {
  private storageInstance: any;
  private socket: any;
  private CLIENT_FORCED_CLOSE: any = 3000;
  private connecting = false;
  private listeners: any[] = [];
  private discoveryListeners: any[] = [];

  constructor(
    private i18nInstance: any,
    private apiService: IApiService,
    private sessionService: ISessionService,
    private store: any,
  ) {
    this.storageInstance = store2;
    this.fetchPreferences();
    this.connectToBackend();
    // In mobile, reconnect when app is resumed
    document.addEventListener('resume', () => this.connectToBackend(), false);
  }

  public get() {
    return { email: this.store.state.userEmail, language: this.store.state.currentLanguage };
  }

  public getLanguage(): Promise<string> {
    return Promise.resolve()
    .then(() => {
      if (!this.i18nInstance) {
        return 'en';
      }
      return this.i18nInstance.locale;
    });
  }

  public async setLanguage(language: string): Promise<void> {
    return this.apiService.getUserPreferences()
    .then((userPreferences: GetUserPreferencesResp) => {
      if (!userPreferences) {
        return;
      }
      userPreferences.lang = language;
      return this.apiService.setUserPreferences(userPreferences);
    })
    .then(() => this.storeLanguage(language))
    .catch(() => this.store.commit('setError', this.store.state.errors.userRequest));
  }

  public async getPrivacy(): Promise<PrivacyResp> {
    return this.apiService.getPrivacy(this.sessionService.getUserId());
  }

  public async signIn(username: string, password: string, remember = false): Promise<void> {
    await this.apiService.signIn({ username, password }, remember);
    await this.fetchPreferences();
    this.connectToBackend();
  }

  public signOut(): Promise<void> {
    return this.apiService.signOut();
  }

  public changePassword(oldPwd: string, newPwd: string): Promise<void> {
    return this.apiService.changeUserPassword(oldPwd, newPwd);
  }

  public changeEmail(pwd: string, email: string): Promise<void> {
    return this.apiService.changeUserEmail(pwd, email);
  }

  public async fetchPreferences(): Promise<void> {
    if (this.sessionService.getSessionData()) {
      return this.apiService.getUserPreferences()
      .then((userPreferences: GetUserPreferencesResp) => {
        if (!userPreferences) {
          return;
        }
        this.storeLanguage(this.getAdminLanguage() || userPreferences.lang || this.getLocalLanguage());
      });
    } else {
      this.storeLanguage(this.getLocalLanguage());
    }
  }

  public setPreferences(preferences: any) {
    return this.setLanguage(preferences.lang);
  }

  public async inviteUser(homeId: string, email: string): Promise<void> {
    const userId = this.sessionService.getUserId();
    return this.apiService.inviteUser(userId, homeId, email);
  }

  public async confirmInvite(userId: string, password: string, code: string): Promise<void> {
    return this.apiService.confirmInvite(userId, password, code);
  }

  public async revokeInvite(homeId: string, email: string): Promise<void> {
    const userId = this.sessionService.getUserId();
    return this.apiService.revokeInvite(userId, homeId, email);
  }

  private saveHomePreferences() {
    this.storageInstance.local.set('homePreferences', this.store.getters.getAllHomePreferences());
  }

  private storeLanguage(language: string) {
    this.i18nInstance.locale = language;
    this.storageInstance.local.set('lang', language);
  }

  private getAdminLanguage(): string|undefined {
    return this.storageInstance.local.get('adminLang') ||
    this.storageInstance.session.get('adminLang');
  }

  private getLocalLanguage(): string {
    return this.storageInstance.local.get('lang') ||
    this.storageInstance.session.get('lang') || navigator.language.split('-')[0];
  }

  private getWSUrl(): string {
    const sessionData = this.sessionService.getSessionData();
    const host = this.apiService.getHost().replace('https:', 'wss:');
    const encodedToken = encodeURIComponent(sessionData?.token || '');
    const userId = this.sessionService.getUserId();
    return `${host}:443/api/v2/ws_user?token=${encodedToken}&user_id=${userId}`;
  }

  private reconnect(timeout = 1000): void {
    setTimeout(() => {
      this.connectToBackend();
    }, timeout);
  }

  public closeConnection(): void {
    if (this.socket) {
      try {
        this.socket.close(this.CLIENT_FORCED_CLOSE);
      } catch (err) {
        // Do nothing
      }
    }
  }

  public connectToBackend(): void {
    this.connecting = true;
    this.closeConnection();
    this.socket = new WebSocket(this.getWSUrl());

    this.socket.addEventListener('open', () => {
      this.connecting = false;
      this.socket.send(JSON.stringify({ event: 'all_data' }));
    });

    this.socket.addEventListener('message', (event: any) => {
      try {
        const dataObj = JSON.parse(event.data) || {};
        switch (dataObj.event) {
          case 'all_data': {
            const data = dataObj.data;
            for (const home of data) {
              for (const device of home.devs) {
                this.store.commit('updateDevData', { dev_id: device.dev_id, data: device.devData });
              }
            }
            this.throwEvent();
            break;
          }
          case 'update':
            this.handleUpdate({ ...dataObj.data, devid: dataObj.devid })
            break;
          default:
            return;
        }
      } catch (e) {
        this.socket.close();
      }
    });

    this.socket.addEventListener('close', (event: any) => {
      if (this.connecting || (!!event && event.code === this.CLIENT_FORCED_CLOSE)) {
        return;
      }
      this.reconnect();
    });

    this.socket.addEventListener('error', () => {
      this.reconnect();
    });
  }

  private handleUpdate(data: any): void {
    // TODO: Update store with update data
    const CONNECTED_REGEXP = /^\/connected$/;
    const GEO_DATA_REGEXP = /^\/geo_data$/;
    const NODES_REGEXP = /^\/mgr\/nodes$/;
    const DISCOVERY_REGEXP = /^\/mgr\/discovery$/;
    const AWAY_STATUS_REGEXP = /^\/mgr\/away_status$/;
    const PMO_POWER_LIMIT_REGEXP = /^\/pmo\/[0-9]{1,2}\/power_limit$/;
    const THM_STATUS_REGEXP = /^\/thm\/[0-9]{1,2}\/status$/;
    const THM_PROG_REGEXP = /^\/thm\/[0-9]{1,2}\/prog$/;
    const THM_SETUP_REGEXP = /^\/thm\/[0-9]{1,2}\/setup$/;
    const HTR_STATUS_REGEXP = /^\/htr\/[0-9]{1,2}\/status$/;
    const HTR_PROG_REGEXP = /^\/htr\/[0-9]{1,2}\/prog$/;
    const HTR_SETUP_REGEXP = /^\/htr\/[0-9]{1,2}\/setup$/;
    const HTR_MOD_STATUS_REGEXP = /^\/htr_mod\/[0-9]{1,2}\/status$/;
    const HTR_MOD_PROG_REGEXP = /^\/htr_mod\/[0-9]{1,2}\/prog$/;
    const HTR_MOD_SETUP_REGEXP = /^\/htr_mod\/[0-9]{1,2}\/setup$/;
    const ACM_STATUS_REGEXP = /^\/acm\/[0-9]{1,2}\/status$/;
    const ACM_PROG_REGEXP = /^\/acm\/[0-9]{1,2}\/prog$/;
    const ACM_SETUP_REGEXP = /^\/acm\/[0-9]{1,2}\/setup$/;
    const HTR_SYSTEM_POWER_LIMIT_REGEXP = /^\/htr_system\/power_limit$/;
    const PMO_SYSTEM_REGEXP = /^\/pmo_system$/;
    const NODE_VERSION_REGEXP = /^\/[a-z]{3}\/[0-9]{1,2}\/version$/;

    if (!data || !data.path || !data.body) {
      return;
    }

    if (CONNECTED_REGEXP.test(data.path)) {
      this.handleDevUpdate('connected', data.body.connected, data.devid);
    } else if (GEO_DATA_REGEXP.test(data.path)) {
      this.handleDevPatch('geo_data', data.body, data.devid);
    } else if (NODES_REGEXP.test(data.path)) {
      this.handleDevNodePatch(data.body.nodes, data.devid);
    } else if (DISCOVERY_REGEXP.test(data.path)) {
      this.handleDevUpdate('discovery', data.body, data.devid);
      this.throwDiscoveryChangeEvent();
    } else if (AWAY_STATUS_REGEXP.test(data.path)) {
      this.handleDevUpdate('away_status', data.body, data.devid);
    } else if (PMO_POWER_LIMIT_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.PMO, 'power_limit', data.body, data.path, data.devid);
    } else if (THM_STATUS_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.THM, 'status', data.body, data.path, data.devid);
    } else if (THM_PROG_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.THM, 'prog', data.body, data.path, data.devid);
    } else if (THM_SETUP_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.THM, 'setup', data.body, data.path, data.devid);
    } else if (HTR_STATUS_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.HTR, 'status', data.body, data.path, data.devid);
    } else if (HTR_PROG_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.HTR, 'prog', data.body, data.path, data.devid);
    } else if (HTR_SETUP_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.HTR, 'setup', data.body, data.path, data.devid);
    } else if (HTR_MOD_STATUS_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.HTR_MOD, 'status', data.body, data.path, data.devid);
    } else if (HTR_MOD_PROG_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.HTR_MOD, 'prog', data.body, data.path, data.devid);
    } else if (HTR_MOD_SETUP_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.HTR_MOD, 'setup', data.body, data.path, data.devid);
    } else if (ACM_STATUS_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.ACM, 'status', data.body, data.path, data.devid);
    } else if (ACM_PROG_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.ACM, 'prog', data.body, data.path, data.devid);
    } else if (ACM_SETUP_REGEXP.test(data.path)) {
      this.handleDevNodeUpdate(NodeType.ACM, 'setup', data.body, data.path, data.devid);
    } else if (HTR_SYSTEM_POWER_LIMIT_REGEXP.test(data.path)) {
      this.handleHtrSystemUpdate('htr_system', data.body, data.devid);
    } else if (PMO_SYSTEM_REGEXP.test(data.path)) {
      this.handlePmoSystemUpdate('pmo_system', data.body, data.devid);
    } else if (NODE_VERSION_REGEXP.test(data.path)) {
      const type: NodeType | null = this.getNodeTypeFromPath(data.path);
      if (type) {
        this.handleDevNodeUpdate(type, 'version', data.body, data.path, data.devid);
      }
    }
    this.throwEvent();
  }

  private handleHtrSystemUpdate(key: any, data: any, devId: string) {
    if (!_.isObject(data)) {
      this.handleDevUpdate(key, data, devId);
    } else {
      const updateData: any = {};
      const fieldKey: any = Object.keys(data)[0] || 'data';
      updateData[fieldKey] = data;
      this.handleDevUpdate(key, updateData, devId);
    }
  }

  private handlePmoSystemUpdate(key: any, data: any, devId: string) {
    this.handleDevUpdate(key, data, devId);
  }

  private handleDevUpdate(key: string, data: any, devId: string) {
    this.store.commit('updateDevDataField', { dev_id: devId, key, data });
  }

  private handleDevPatch(key: string, data: any, devId: string) {
    this.store.commit('patchDevDataField', { dev_id: devId, key, data });
  }

  private handleDevNodePatch(data: any, devId: string) {
    this.store.commit('patchDevNodes', { dev_id: devId, data });
  }

  private handleDevNodeUpdate(type: NodeType, key: string, data: any, path: any, devId: string) {
    const addr = this.getNodeAddrFromPath(path);
    this.store.commit(
      'updateDevNodeDataField',
      {
        key,
        data,
        type,
        addr,
        dev_id: devId,
      },
    );
  }

  private getNodeTypeFromPath(path: string): NodeType|null {
    const tokens = path.split('/');
    const addr = Number(tokens[2]);
    if (tokens && tokens.length > 2 && !Number.isNaN(addr)) {
      return (tokens[1] as unknown) as NodeType;
    }
    return null;
  }

  private getNodeAddrFromPath(path: string): number|null {
    const tokens = path.split('/');
    const addr = Number(tokens[2]);
    if (tokens && tokens.length > 2 && !Number.isNaN(addr)) {
      return addr;
    }
    return null;
  }

  private addListener(homeId: string, listener: any) {
    this.listeners.push(listener);
  }

  private removeListener(homeId: string, listener: any) {
    _.pull(this.listeners, listener);
  }

  private addDiscoveryListener(homeId: string, listener: any) {
    this.discoveryListeners.push(listener);
  }

  private removeDiscoveryListener(homeId: string, listener: any) {
    _.pull(this.discoveryListeners, listener);
  }

  private throwEvent() {
    for (const listener of this.listeners) {
      listener();
    }
  }

  private throwDiscoveryChangeEvent() {
    for (const listener of this.discoveryListeners) {
      listener();
    }
  }

  public deleteUserAccount(pwd: string): Promise<void> {
    const userId = this.sessionService.getUserId();
    return this.apiService.deleteUserAccount(userId, pwd);
  }
}
