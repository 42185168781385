import { render, staticRenderFns } from "./VoiceAssistantHelp.vue?vue&type=template&id=aa968132&scoped=true"
import script from "./VoiceAssistantHelp.ts?vue&type=script&lang=js&external"
export * from "./VoiceAssistantHelp.ts?vue&type=script&lang=js&external"
import style0 from "./VoiceAssistantHelp.vue?vue&type=style&index=0&id=aa968132&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa968132",
  null
  
)

export default component.exports