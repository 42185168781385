import { Component, Vue } from 'vue-property-decorator';
import Slider from '@/components/slider/Slider.vue';
import ModalHelpContent from '@/components/modalComponents/modalHelpContent/ModalHelpContent.vue';
import SkipButton from '@/components/button/skipButton/SkipButton.vue';
import MainButton from '@/components/button/mainButton/MainButton.vue';

@Component({
  components: {
    Slider,
    ModalHelpContent,
    SkipButton,
    MainButton,
  },
})

export default class heaterDayProgHelp extends Vue {

  private slickSettings: any = {};
  private slickPosition: any = 0;
  private slick: any = {};

  private mounted() {
    this.slickSettings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      dots: true,
      method: {},
      initialSlide: 0,
      arrows: false,
      nextArrow: false,
      prevArrow: false,
      event: {
        beforeChange: (event: any, slick: any, oldPos: any, newPos: any) => {
          this.slickPosition = newPos;
        },
        init: (event: any, slick: any) => {
          this.slick = slick;
          this.slickPosition = 0;
        }
      }
    };
  }

  private close(): any {
    this.$emit('close');
  }

  private nextStep(): any {
    (this.$refs.slick as any).goTo(this.slickPosition + 1);
    this.slickPosition ++;
  }
}
